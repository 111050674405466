import * as Types from "@/lib/Util/Types/Types"
import * as Common from "@/lib/Util/Common"

import * as Email from "@/lib/Util/Types/Common/Email"
import * as Currency from "@/lib/Util/Types/Common/Currency"

import * as DocumentType from "./DocumentType"
import * as Prepayment from "./Prepayment"

type Source = {
    document_type: DocumentType.T;
    invoice_number: string;
    invoice_date: Date;
    order_number: string;
    order_amount_value: number | null;
    order_amount_currency: Currency.T | null;
    due_date: Date;
    external_acceptance: boolean;
    gross_amount_currency: Currency.T;
    gross_amount_value: number;
    supply_date_from: Date;
    supply_date_to: Date;
    discount_due_date: Date | null;
    discount_amount_value: number | null;
    discount_amount_currency: Currency.T | null;
    comments: string;
    delivery_note_number: string;
    external_id: string | null;
    needs_certificate_of_origin: boolean | null;
    needs_supplier_declaration: boolean | null;
    buyer_email: Email.Email | null;
    prepayment: Prepayment.T;
    accept_supplier_due_date: boolean | null;
}

export type T = Types.Nullable<Source>

export function tryParse(x?: any): T | null {
    if (x) {
        const cur = Currency.getEmpty()
        const document_type = DocumentType.tryParse(x.document_type) ?? DocumentType.getEmpty()
        const gross_amount_currency = Currency.tryParse(x.gross_amount_currency) ?? cur
        const discount_amount_currency = Currency.tryParse(x.discount_amount_currency)
        const order_amount_currency = Currency.tryParse(x.order_amount_currency)
        const due_date = Common.parseServerDate(x.due_date)
        const invoice_date = Common.parseServerDate(x.invoice_date)
        const supply_date_from = Common.parseServerDate(x.supply_date_from)
        const supply_date_to = Common.parseServerDate(x.supply_date_to)
        const discount_due_date = Common.parseServerDate(x.discount_due_date)
        const external_acceptance = x.external_acceptance ?? false
        const needs_certificate_of_origin = x.needs_certificate_of_origin ?? false
        const needs_supplier_declaration = x.needs_supplier_declaration ?? false
        const prepayment = Prepayment.tryParse(x.prepayment)
        const accept_supplier_due_date = x.accept_supplier_due_date ?? false
        return {
            ...x,
            document_type,
            gross_amount_currency,
            discount_amount_currency,
            order_amount_currency,
            invoice_date,
            due_date,
            supply_date_from,
            supply_date_to,
            discount_due_date,
            external_acceptance,
            needs_certificate_of_origin,
            needs_supplier_declaration,
            prepayment,
            accept_supplier_due_date
        }
    }

    console.error("HeaderData.tryParse", x)
    return null
}

export function getEmpty(): T {
    return {
        document_type: DocumentType.getEmpty(),
        invoice_number: "",
        invoice_date: Common.parseServerDate("20200101"),
        order_number: null,
        order_amount_value: null,
        order_amount_currency: null,
        due_date: Common.parseServerDate("20200101"),
        external_acceptance: false,
        gross_amount_currency: { kind: "EUR", EUR: true },
        gross_amount_value: 0,
        supply_date_from: Common.parseServerDate("20200101"),
        supply_date_to: Common.parseServerDate("20200101"),
        discount_due_date: null,
        discount_amount_value: null,
        discount_amount_currency: null,
        comments: "",
        delivery_note_number: "",
        external_id: "",
        needs_certificate_of_origin: false,
        needs_supplier_declaration: false,
        buyer_email: null,
        prepayment: Prepayment.getEmpty(),
        accept_supplier_due_date: false
    }
}
