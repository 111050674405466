
import Vue from "vue"
import { Component, Watch, Prop } from "vue-property-decorator"

import * as lodash from "lodash"

import * as Country from "@/lib/Util/Types/Common/Country"
import * as Currency from "@/lib/Util/Types/Common/Currency"

import * as HeaderData from "@/lib/Util/Types/Workflow/Doc/HeaderData"
import * as DocumentType from "@/lib/Util/Types/Workflow/Doc/DocumentType"
import * as Prepayment from "@/lib/Util/Types/Workflow/Doc/Prepayment"
import * as AccountTimestamp from "@/lib/Util/Types/Workflow/Doc/AccountTimestamp"

import CurrencyInput from "@/components/Util/CurrencyInput.vue"
import DateInput from "@/components/Util/DateInput.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"

@Component({
    components: {
        CurrencyInput,
        DateInput,
        TooltipIcon,

    },
})
export default class HeaderDataPartView extends Vue {
    @Prop({ default: null }) item!: HeaderData.T | null
    @Prop({ default: "DE" }) country!: Country.Kind
    @Prop({ default: "EUR" }) currency!: Currency.Kind

    available_document_types = DocumentType.KEYS
    available_prepayments = Prepayment.KEYS
    loaded = lodash.cloneDeep(this.item)

    available_dd = [
        { name: "Lastschrifteinzug", account: "DE95110101015910955489" }
    ]

    available_cc = [
        { name: "CC RK", account: "4349712019797508" },
        { name: "CC SB", account: "4349712017270499" },
        { name: "CC DR", account: "4349712019797501" },
        { name: "CC Amex", account: "4349712019797502" }
    ]

    get used_document_type(): DocumentType.Kind {
        return this.loaded?.document_type?.kind ?? "Invoice"
    }

    set used_document_type(v: DocumentType.Kind) {
        if (this.loaded) {
            this.loaded.document_type = DocumentType.getByKind(v)
        }
    }

    get used_prepayment_kind(): Prepayment.Kind {
        return this.loaded?.prepayment?.kind ?? "Nothing"
    }

    set used_prepayment_kind(v: Prepayment.Kind) {
        if (this.loaded) {
            if (v === "Nothing") {
                this.loaded.prepayment = Prepayment.getEmpty()
            }
            else if (v === "CreditCard") {
                this.loaded.prepayment = {
                    kind: "CreditCard",
                    CreditCard: AccountTimestamp.getEmpty(),
                    value: AccountTimestamp.getEmpty()
                }
            }
            else {
                this.loaded.prepayment = {
                    kind: "BankDebit",
                    BankDebit: AccountTimestamp.getEmpty(),
                    value: AccountTimestamp.getEmpty()
                }
            }
        }
        console.log("SET KIND", this.loaded?.prepayment?.kind)
    }

    get used_prepayment_date() {
        if (this.loaded?.prepayment && this.loaded.prepayment.kind !== "Nothing") {
            return this.loaded.prepayment.value.timestamp
        }
        return null
    }

    set used_prepayment_date(x: Date | null) {
        if (this.loaded?.prepayment && x) {
            if (this.loaded.prepayment.kind === "CreditCard") {
                this.loaded.prepayment.CreditCard.timestamp = x
                this.loaded.prepayment.value.timestamp = x
            }
            if (this.loaded.prepayment.kind === "BankDebit") {
                this.loaded.prepayment.BankDebit.timestamp = x
                this.loaded.prepayment.value.timestamp = x
            }
        }
    }

    get used_prepayment_account() {
        if (this.loaded?.prepayment && this.loaded.prepayment.kind !== "Nothing") {
            return this.loaded.prepayment.value.account
        }
        return null
    }

    set used_prepayment_account(x: string | null) {
        console.log("SET ACCOUNT", x)
        if (this.loaded?.prepayment && x) {
            if (this.loaded.prepayment.kind === "CreditCard") {
                this.loaded.prepayment.CreditCard.account = x
                this.loaded.prepayment.value.account = x
            }
            if (this.loaded.prepayment.kind === "BankDebit") {
                this.loaded.prepayment.BankDebit.account = x
                this.loaded.prepayment.value.account = x
            }
        }
    }

    get available_accounts() {
        if (this.loaded?.prepayment?.kind === "BankDebit") {
            console.log(">>> 100 AVAILABLE ACCOUNTS", this.available_dd)
            return this.available_dd
        }
        else if (this.loaded?.prepayment?.kind === "CreditCard") {
            console.log(">>> 200 AVAILABLE ACCOUNTS", this.available_cc)
            return this.available_cc
        }
        return null
    }

    get show_prepayment() {
        return this.used_prepayment_kind !== "Nothing" ?? false
    }

    @Watch("item")
    onItemChanged(nv: HeaderData.T | null) {
        if (this.loaded === nv || lodash.isEqual(this.loaded, nv)) {
            return
        }
        this.loaded = lodash.cloneDeep(this.item)
    }

    @Watch("loaded", { deep: true })
    onLoadedChanged(nv: HeaderData.T | null) {
        console.log("HeaderData.update")
        this.$emit("update", nv)
    }

    get allowNegative() {
        return (this.loaded?.document_type?.kind ?? "Invoice") === "InternalReceipt" ||
            (this.loaded?.document_type?.kind ?? "Invoice") === "ExternalReceipt"
    }

    get isCreditNote() {
        return this.loaded?.external_id?.startsWith("CN")
    }

    get creditNoteRef() {
        return this.loaded?.external_id?.substring(3)
    }
}
