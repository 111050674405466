import * as Types from "@/lib/Util/Types/Types"

import * as Item from "@/lib/Util/Types/Workflow/SearchListItem/Tail"

export const VALUES = {
    Finished: true,
    Waiting: true,
    Overdue: true,
    Urgent: true,
    Alarm: true,
    Empty: true,
}

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function to(x: Item.T): Kind {
    if (x.payment_summary.supplier_payment.kind === "Empty") {
        return "Empty"
    }
    else if (x.payment_summary.supplier_payment.kind !== "Finished") {
        const terms = x.doc_summary.payment_terms
        const today = Date.now().valueOf()
        if (terms) {
            if (terms.using_receipt_date_and_npt.valueOf() < today) {
                if (x.payment_summary.paid.kind === "NotPaid") {
                    return "Urgent"
                }
                return "Alarm"
            } else if (terms.using_invoice_date_and_npt.valueOf() < today) {
                if (x.payment_summary.paid.kind === "NotPaid") {
                    return "Overdue"
                }
                return "Urgent"
            } else if (terms.due_date.valueOf() < today) {
                if (x.payment_summary.paid.kind === "NotPaid") {
                    return "Waiting"
                }
                return "Overdue"
            }
            else {
                return "Waiting"
            }
        }
    }
    return "Finished"
}