
import Vue from "vue"
import * as lodash from "lodash/fp"

import * as Shared from "./Shared"

export default Vue.extend({
    name: "EditDialog",
    props: {
        item: {
            type: Object as () => Shared.EditWrapper,
        } as Vue.PropOptions<Shared.EditWrapper>,
        dialogSave: {
            type: Function
        },
        dialogCancel: {
            type: Function
        }
    },
    data() {
        return {
            valid: true,
            rules: {
                code: [
                    (v: string) => {
                        return v.length > 0 || "code must not be empty";
                    },
                ],
                desc: [
                    (v: string) => {
                        return v.length > 0 || "desc must not be empty"
                    }
                ]
            }
        }
    },
    computed: {
        loaded() {
            return lodash.cloneDeep(this.item)
        },
    },
    watch: {
        valid: {
            handler(nv: boolean) {
                console.log("WATCH VALID:", nv)
            }
        }
    },
    methods: {
        save() {
            this.dialogSave(this.loaded)
        },
        cancel() {
            this.dialogCancel()
        }
    }
});
