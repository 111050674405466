import * as Types from '@/lib/Util/Types/Types'

export const VALUES = {
    Yes: true,
    No: true
}

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getEmpty(): T {
    return { kind: "No", No: true }
}

export const tryParse = Types.buildTryParse<T, true>(VALUES, "kind", true, "Buyer")
export const parseDefault = Types.buildParseDefault(tryParse, getEmpty)

export const getByKind = (x: Kind): T => Types.makeSingleDUFromString(x)
export const tryGetByKind = (x: string): T | null => Types.tryMakeSingleDUFromString(x, KEYS)
export const getByKindDefault = (x: string): T => tryGetByKind(x) ?? getEmpty()


export function isBuyer(x: T) {
    switch (x.kind) {
        case "No": return false
        case "Yes": return true
    }
}