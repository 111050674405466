import * as Types from "@/lib/Util/Types/Types"

import * as Doc from "../Summary/Doc"
import * as Payment from "../Summary/Payment"
import * as Prepayment from "../Doc/Prepayment"
import * as Target from "@/lib/Util/REST/Target"

import * as Base from "./Base"

//This ListItem is used in Payment, Finished & Archive
export type T = Base.T & {
    payment_short_id: string;
    doc_summary: Doc.DocSummary;
    payment_summary: Payment.T;
    prepayment: Prepayment.T;
}

export function tryParse(x?: any): T | null {
    try {
        if (x) {
            const source = Base.tryParse(x)
            if (source) {
                const doc_summary = Doc.tryParseDocSummary(x.doc_summary)
                const payment_summary = Payment.tryParse(x.payment_summary)
                const prepayment = Prepayment.tryParse(x.prepayment)
                if (doc_summary && payment_summary) {
                    return {
                        ...x,
                        ...source,

                        doc_summary,
                        payment_summary,
                        prepayment
                    }
                }
                console.log("100 Tail.tryParse", source, doc_summary, payment_summary, prepayment)
            }
            console.log("200 Tail.tryParse", source)
        }

        console.error("SearchListItem/Tail.tryParse", x)
        return null
    }
    catch (ex) {
        console.error(ex)
        return null
    }
}

export type Kind = Extract<Target.TargetKind, "Pay" | "Finish" | "Archive">

export function isKind(x: any): x is Kind {
    return x === "Pay" || x === "Finish" || x === "Archive"
}

export const tryParseList = Types.buildTryParseList(tryParse, "SearchListItem/Tail")

export function isTypeOf(x: any): x is T {
    return x.payment_summary && x.doc_summary
}