
import Vue from "vue";

export default Vue.extend({
  name: "CardTitle",
  props: {
    title: {
      type: String,
    },
  },
});
