
import Vue from "vue"

import { ExportToCsv } from "export-to-csv"
import { Component } from "vue-property-decorator"

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"])

import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import SearchForm from "@/components/Util/SearchForm/Main.vue"
import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"

import ListFilterView from "./Filter.vue"

import store from "@/store/store"

import * as Timespan from "@/lib/Util/Types/Common/Timespan"
import * as ServicesReceived from "@/lib/Util/Types/Reports/ServicesReceived"

import * as Common from "@/lib/Util/Common"
import router from "@/router/router"
import * as Shared from "./Shared"

import * as Filter from "@/store/Reports/ServicesReceived/Filter"

Component.registerHooks(["beforeRouteUpdate"])
@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
        SearchForm,
        ListFilterView,
    },
})
export default class ReportsServicesReceivedListMainView extends Vue {
    headers = Shared.HEADERS
    timespans = Timespan.filter([
        { prefix: "2024", period: "Q" },
        { prefix: "2024", period: "M" },
        { prefix: "2023", period: "M" },
        { prefix: "2023", period: "Q" },
        { prefix: "2022", period: "M" },
        { prefix: "2022", period: "Q" },
    ])

    get items() {
        const result = store.state.services_received.list.value
        return ServicesReceived.sort(result)
    }

    get msg() {
        return store.state.services_received.list.message
    }

    get filter() {
        return store.state.services_received.list.filter
    }

    set filter(x: Filter.T) {
        store.commit.services_received.list.updateFilter(x)
        const query = Filter.toQuery(x)
        store.dispatch.services_received.list.LOAD(query)
    }

    static routeChange(to: any, from: any, next: any) {
        const filter = Filter.from(to.query)
        console.log("100 ROUTE CHANGE", filter, to.query)
        if (!Common.isEmpty(filter)) {
            store.commit.services_received.list.updateFilter(filter)
            const query = Filter.toQuery(filter)
            console.log("200 ROUTE CHANGE", query)
            store.dispatch.services_received.list.LOAD(query)
            next()
        } else {
            console.log("300 ROUTE CHANGE", to)
            router.push({
                path: "/sections/reports/services_received/list",
                query: Filter.getEmpty(),
            })
        }
    }

    beforeRouteEnter(to: any, from: any, next: any) {
        ReportsServicesReceivedListMainView.routeChange(to, from, next)
    }

    beforeRouteUpdate(to: any, from: any, next: any) {
        console.log("100 ROUTE UPDATE", to)
        ReportsServicesReceivedListMainView.routeChange(to, from, next)
    }

    exportCSV() {
        const i = Timespan.getInterval(this.filter.timespan)
        const s = Common.toShortDate((i?.start as Date) ?? new Date())
        const e = Common.toShortDate((i?.end as Date) ?? new Date())
        const filename = `SERE.${s}_${e}`
        const records = this.items.map(Shared.toCSVRecord)
        const options = {
            fieldSeparator: ";",
            quoteStrings: '"',
            decimalSeparator: ",",
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            filename: filename,
            useKeysAsHeaders: true,
        }

        const exporter = new ExportToCsv(options)
        exporter.generateCsv(records)
    }
}
