
import Vue from "vue"
import * as lodash from "lodash/fp"

import * as Country from "@/lib/Util/Types/Common/Country"
import * as Currency from "@/lib/Util/Types/Common/Currency"

import * as VatRules from "@/lib/Util/Types/VAT/VatRules"
import * as InvoicingTerms from "@/lib/Util/Types/Biz/InvoicingTerms"

import * as BusinessProcess from "@/lib/Util/Types/Masterdata/BusinessProcess/BusinessProcess"
import * as ProcessType from "@/lib/Util/Types/Masterdata/BusinessProcess/ProcessType"
import * as PerVolume from "@/lib/Util/Types/Masterdata/BusinessProcess/PerVolume"
import * as PerLineItem from "@/lib/Util/Types/Masterdata/BusinessProcess/PerLineItem"
import * as AccountingData from "@/lib/Util/Types/Masterdata/BusinessProcess/AccountingData"

import store from "@/store/store"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import EditForm from "@/components/Util/EditForm.vue"

import PricingInformation from "./PricingInformation/Main.vue"
import AddressView from "./Address.vue"
import EmailReceiversView from "./EmailReceivers/Main.vue"
import ExternalUsersView from "./ExternalUsers/Main.vue"
import CostTypesView from "./CostTypes/Main.vue"
import AccountingDataView from "./AccountingData/Main.vue"

import * as Shared from "./Shared"

export default Vue.extend({
    name: "MasterdataProcessMainView",
    components: {
        SuccessMessage,
        ErrorMessage,
        AddressView,
        EmailReceiversView,
        PricingInformation,
        ExternalUsersView,
        CostTypesView,
        AccountingDataView,
        EditForm,
    },
    data() {
        return {
            headers: [],
            available_countries: Country.KEYS,
            available_currencies: Currency.KEYS,
            available_process_types: ProcessType.KEYS,
            available_invoicing_terms: InvoicingTerms.KEYS,
            loaded: Shared.prepare(store.state.client.sub_item.value),
            isDirty: false,
            bus: new Vue(),
            per_volume: PerVolume.getEmpty,
            per_lineitem: PerLineItem.getEmpty,
        }
    },
    beforeRouteEnter(to, from, next) {
        if (to.params.id && typeof to.params.id === "string") {
            if (to.params.id === "new") {
                console.log("100 BEFORE ROUTE ENTER", to.params)
                const country = store.state.client.single.value?.country?.kind ?? ("DE" as VatRules.NarrowCountry)
                const vats = store.state.authentication?.vatRules ?? null
                console.log("150 BEFORE ROUTE ENTER", country, vats)
                if (vats) {
                    console.log("200 BEFORE ROUTE ENTER", to.params)

                    const cid = to.params.client_id
                    const vat_rule = VatRules.getVatRule(vats, country as VatRules.NarrowCountry)
                    store.commit.client.sub_item.updateValue(BusinessProcess.getEmpty(cid, vat_rule))
                    console.log("300 BEFORE ROUTE ENTER", to.params)
                }
            } else {
                console.log("BEFORE ROUTE ENTER", to.params)
                store.dispatch.client.sub_item.LOAD(to.params.id)
            }
        }
        next()
    },
    beforeRouteLeave(to, from, next) {
        const event = {
            to,
            from,
            next,
        }
        this.bus.$emit("on-route-leave", event)
    },
    watch: {
        loaded: {
            handler: function (newval: BusinessProcess.T | null, oldval: BusinessProcess.T | null) {
                this.isDirty = !lodash.equals(this.loaded, this.item)
            },
            deep: true,
        },
        item: function (newval: BusinessProcess.T | null, oldval: BusinessProcess.T) {
            this.loaded = Shared.prepare(this.item)
        },
    },
    computed: {
        item: () => Shared.prepare(store.state.client.sub_item.value),
        msg: () => store.state.client.sub_item.message,
        used_country: {
            get: function (): Country.Kind {
                return this.loaded?.country?.kind ?? "DE"
            },
            set: function (v: Country.Kind) {
                if (this.loaded) {
                    this.loaded.country = Country.getByKind(v)
                    const rules = store.state.authentication?.vatRules as VatRules.T
                    const vats = VatRules.getVatRule(rules, v as VatRules.NarrowCountry)
                    const ad = AccountingData.updateVatRule(this.loaded.accounting_data, vats)
                    this.loaded.accounting_data = ad
                    //todo: Change the accounting_data vat-types also
                }
            },
        },
        used_currency: {
            get: function (): Currency.Kind {
                return this.loaded?.currency?.kind ?? "EUR"
            },
            set: function (v: Currency.Kind) {
                if (this.loaded) {
                    this.loaded.currency = Currency.getByKind(v)
                }
            },
        },
        used_process_type: {
            get: function (): ProcessType.Kind {
                return this.loaded?.process_type?.kind ?? "InvoiceToPay"
            },
            set: function (v: ProcessType.Kind) {
                if (this.loaded) {
                    this.loaded.process_type = ProcessType.getByKind(v)
                }
            },
        },
        used_invoicing_terms: {
            get: function (): InvoicingTerms.Kind {
                return this.loaded?.invoicing_terms?.kind ?? "PerTrx"
            },
            set: function (v: InvoicingTerms.Kind) {
                if (this.loaded) {
                    this.loaded.invoicing_terms = InvoicingTerms.getByKind(v)
                }
            },
        },
        payment_terms: {
            get: function (): number {
                return this.loaded?.payment_terms ?? 0
            },
            set: function (v: string) {
                if (this.loaded) {
                    this.loaded.payment_terms = this.$ci.parse(v, {}) ?? 0
                }
            },
        },
        negotiated_payment_terms: {
            get: function (): number {
                return this.loaded?.negotiated_payment_terms ?? 0
            },
            set: function (v: string) {
                if (this.loaded) {
                    this.loaded.negotiated_payment_terms = this.$ci.parse(v, {}) ?? 0
                }
            },
        },
        auth_limit: {
            get: function (): number {
                return this.loaded?.auth_limit ?? 0
            },
            set: function (v: string) {
                if (this.loaded) {
                    this.loaded.auth_limit = this.$ci.parse(v, {}) ?? 0
                }
            },
        },
        due_date: {
            get: function (): number {
                return this.loaded?.due_date ?? 0
            },
            set: function (v: string) {
                if (this.loaded) {
                    this.loaded.due_date = this.$ci.parse(v, {}) ?? 0
                }
            },
        },
    },
    methods: {
        save() {
            if (this.loaded) {
                store.dispatch.client.sub_item.SAVE(this.loaded)
            }
        },
        cancel() {
            this.loaded = Shared.prepare(this.item)
        },
    },
})
