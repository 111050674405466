
import Vue from "vue"
import { Component } from "vue-property-decorator"

import * as AuthenticationRequest from "@/lib/Util/Types/Login/AuthenticationRequest"
import * as AuthenticationResult from "@/lib/Util/Types/Login/AuthenticationResult"

import store from "@/store/store"

import RequestPinForm from "./RequestPinForm.vue"
import AuthenticationRequestForm from "./AuthenticationRequestForm.vue"
import ErrorForm from "./ErrorForm.vue"

type LoginStatus = "RequestPin" | "Authenticate" | "Success" | "Error"

Component.registerHooks(["beforeRouteEnter"])
@Component({
    components: {
        RequestPinForm,
        AuthenticationRequestForm,
        ErrorForm,
    },
})
export default class LoginForm extends Vue {
    usedItem: AuthenticationRequest.T = { UserName: "", Pin: "" }
    status: LoginStatus = "RequestPin"

    beforeRouteEnter(to: any, from: any, next: any) {
        if (to.query.force) {
            store.commit.updateAuthentication(null)
        }
        if (AuthenticationResult.isValid(store.state.authentication)) {
            next({ path: "/sections/workflow/prepare/list" })
        }
        next()
    }

    initiateAuthenticationRequest(x: string) {
        this.usedItem.UserName = x
        this.status = "Authenticate"
    }

    authenticationSuccess(v: any) {
        store.commit.updateAuthentication(v)
        const result = store.dispatch.loadVatRules()
        this.$router.push({ path: "/sections/workflow/prepare/list" })
    }

    tryAgain() {
        console.log("TryAgain")
        this.status = "RequestPin"
    }
}
