
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import * as Types from "@/lib/Util/Types/Types"

import * as Advice from "@/lib/Util/Types/Remittance/Advice"
import * as State from "@/lib/Util/Types/Remittance/State"

import TooltipIcon from "@/components/Util/TooltipIcon.vue"

type Key = 0 | 1 | 2
const map: Record<string, Key> = {
    Open: 0,
    Closed: 1,
    Canceled: 2,
}

const lookup = Types.buildLookup(map)

@Component({
    components: { TooltipIcon },
})
export default class StateToolbar extends Vue {
    @Prop({ default: null }) item!: Advice.T
    @Prop({ default: null }) run!: (x: State.Kind) => void

    get selected() {
        const kind = this.item.state.kind
        return lookup.source[kind]
    }

    set selected(x: Key) {
        const s = lookup.target[x]
        this.run(s as State.Kind)
    }

    get active() {
        console.log("ACTIVE?", this.selected, this.selected === map.open)
        return this.selected === map.Open
    }
}
