
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

@Component({
    components: {},
})
export default class TooltipIcon extends Vue {
    @Prop({ default: "" }) icon!: string | null
    @Prop({ default: "" }) text!: string
    @Prop({ default: "" }) to!: string
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: "" }) color!: string
    @Prop({ default: null }) click!: (() => void) | null

    onClick() {
        if (this.click) {
            this.click()
        }
    }
}
