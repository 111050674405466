import MainView from "./Main.vue"
import ListMainView from "./List/Main.vue"
import SingleMainView from "./Single/Main.vue"

const router = {
    path: 'remittance',
    component: MainView,
    children: [
        {
            path: 'list',
            component: ListMainView
        },
        {
            path: 'single/:id?',
            component: SingleMainView
        },
    ]
}

export default router