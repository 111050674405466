import * as Client from "@/lib/Util/Types/Masterdata/Client/Client";

export type T = {
    isActive: boolean | null;
    clients: string[];
    contacts: string[];
}

export function getEmpty(): T {
    return {
        clients: [],
        contacts: [],
        isActive: false,
    }
}

export function filter(f: T, xs: Client.T[]): Client.T[] {
    let result = xs
    if (f.isActive) {
        result = result.filter(x => x.active === f.isActive)
    }
    if (f.clients.length >= 1) {
        result = result.filter(x => f.clients.includes(x.name))
    }
    if (f.contacts.length >= 1) {
        result = result.filter(x => f.contacts.includes(x.last_name))
    }

    return result
}
