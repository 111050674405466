
import Vue from "vue"
import { Component, Watch } from "vue-property-decorator"
import lodash from "lodash"

import * as Filters from "@/Filters"
import * as InvoiceState from "@/lib/Util/Types/Invoice/InvoiceState"
import * as Doc from "@/lib/Util/Types/Workflow/Summary/Doc"

import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import SuccessMessage from "@/components/Util/SuccessMessage.vue"

import * as Shared from "@/components/Sections/Invoice/Shared"
import * as SharedSingle from "@/components/Sections/Invoice/Single/Shared"

import store from "@/store/store"
import * as ServicesSoldList from "@/store/Reports/ServicesSold/ListModule"

Component.registerHooks(["beforeRouteEnter"])

@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
    },
})
export default class InvoiceSingleMainView extends Vue {
    payment_item_headers = SharedSingle.PAYMENT_ITEM_HEADERS
    communication_item_headers = SharedSingle.COMMUNICATION_ITEM_HEADERS
    workflow_item_headers = SharedSingle.WORKFLOW_ITEM_HEADERS
    loadDocument = Shared.loadDocument
    vats = 0

    beforeRouteEnter(to: any, from: any, next: any) {
        if (to.params.id && typeof to.params.id === "string") {
            store.dispatch.invoice.single.LOAD(to.params.id)
        }
        if (to.params.bp_id) {
            store.dispatch.invoice.single.LOAD_BY_QUERY(to.params)
        }
        next()
    }

    @Watch("item")
    async onItemChanged() {
        if (this.item?.terse_id) {
            const result = await ServicesSoldList.load(
                { invoice_no: this.item.terse_id },
                store.state.authentication?.token ?? "NOTOKEN"
            )
            console.log("100 LOADED", result)
            if (result.value) {
                const sums = result.value.map((v) => v.vat_amount.value)
                console.log("100 LOADED", sums)
                this.vats = lodash.sum(sums)
            }
        }
    }

    get item() {
        return store.state.invoice.single.value
    }

    get msg() {
        return store.state.invoice.single.message
    }

    get icon() {
        return Shared.getStatusIcon(store.state.invoice.single.value)
    }

    get payment_items() {
        const item = store.state.invoice.single.value
        if (item) {
            const states = InvoiceState.extract(item.data.invoice_state)
            const nonnulls = states?.filter((x) => x !== null) ?? []
            const result = nonnulls.map((x) => {
                return {
                    ...x,
                    timestamp: Filters.format(x.timestamp),
                    amount: Filters.format(x.amount),
                }
            })
            return result
        }
        return []
    }
    get workflow_items() {
        return store.state.invoice.single.value?.line_items.map(Doc.asBase) ?? []
    }
}
