
import Vue from "vue"
import { Component, Watch, Prop } from "vue-property-decorator"

import * as ProcessReference from "@/lib/Util/Types/Workflow/Doc/ProcessReference"
import ProcessReferenceDialog from "./ProcessReferenceDialog.vue"

import store from "@/store/store"

@Component({
    components: { ProcessReferenceDialog },
})
export default class ProcessReferencePartView extends Vue {
    @Prop({ default: null }) item!: ProcessReference.T | null

    search = ""
    isLoading = false
    showDialog = false

    get cpc_ids() {
        if (store.state.lookup.list.value.length > 0) {
            return store.state.lookup.list.value
        } else {
            if (this.loaded) {
                return [this.loaded]
            } else {
                return []
            }
        }
    }

    get loaded() {
        return this.item
    }

    set loaded(x: ProcessReference.T | null) {
        this.$emit("update", x)
    }

    @Watch("search", { deep: true })
    async onChanged(nv: string, ov: any) {
        if (nv && nv.length >= 4) {
            this.isLoading = true
            await store.dispatch.lookup.list.load(nv)
            this.isLoading = false
            console.log("CPC_IDS", this.cpc_ids)
        }
    }

    update(x: ProcessReference.T | null) {
        this.showDialog = false
        if (x) {
            store.commit.lookup.list.updateValue([x])
            this.$emit("update", x)
        }
    }

    runDialog() {
        this.showDialog = true
        console.log("TEST")
    }
}
