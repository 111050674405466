import * as Types from "@/lib/Util/Types/Types"
import * as PaymentMode from "./PaymentMode"

export type T = {
    id: string;
    short_id: string;
    created_at: Date;
    updated_at: Date;
    payment_mode: PaymentMode.T;
    comment?: string;
    closed: boolean
}

export function tryParse(x?: any): T | null {
    if (x) {
        console.log
        const payment_mode = PaymentMode.getByKindDefault(x.payment_mode)
        const result = {
            ...x,
            payment_mode
        } as T
        return result
    }
    console.error("PaymentState.tryParse", x)
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "PaymentState")

export function getEmpty(id: string, short_id: string) {
    return {
        id,
        short_id,
        created_at: new Date(),
        updated_at: new Date(),
        payment_mode: PaymentMode.getEmpty(),
        comment: "",
        closed: false

    }
}