import * as Types from '@/lib/Util/Types/Types'
import * as datefns from 'date-fns'

export const VALUES = {
    'Last 7d': true,
    'Last 14d': true,
    'Last 30d': true,
    'Last 90d': true,
    'Last 180d': true,
    'Last 360d': true,
    '2024': true,
    '2024-12': true,
    '2024-11': true,
    '2024-10': true,
    '2024-09': true,
    '2024-08': true,
    '2024-07': true,
    '2024-06': true,
    '2024-05': true,
    '2024-04': true,
    '2024-03': true,
    '2024-02': true,
    '2024-01': true,
    '2024-Q4': true,
    '2024-Q3': true,
    '2024-Q2': true,
    '2024-Q1': true,
    '2023': true,
    '2023-12': true,
    '2023-11': true,
    '2023-10': true,
    '2023-09': true,
    '2023-08': true,
    '2023-07': true,
    '2023-06': true,
    '2023-05': true,
    '2023-04': true,
    '2023-03': true,
    '2023-02': true,
    '2023-01': true,
    '2023-Q4': true,
    '2023-Q3': true,
    '2023-Q2': true,
    '2023-Q1': true,
    '2022': true,
    '2022-12': true,
    '2022-11': true,
    '2022-10': true,
    '2022-09': true,
    '2022-08': true,
    '2022-07': true,
    '2022-06': true,
    '2022-05': true,
    '2022-04': true,
    '2022-03': true,
    '2022-02': true,
    '2022-01': true,
    '2022-Q4': true,
    '2022-Q3': true,
    '2022-Q2': true,
    '2022-Q1': true,
    '2021': true,
    '2021-12': true,
    '2021-11': true,
    '2021-10': true,
    '2021-09': true,
    '2021-08': true,
    '2021-07': true,
    '2021-06': true,
    '2021-05': true,
    '2021-04': true,
    '2021-03': true,
    '2021-02': true,
    '2021-01': true,
    '2021-Q4': true,
    '2021-Q3': true,
    '2021-Q2': true,
    '2021-Q1': true,
    '2020': true,
    '2020-Q4': true,
    '2020-Q3': true,
    '2020-Q2': true,
    '2020-Q1': true,
    '2020-12': true,
    '2020-11': true,
    '2020-10': true,
    '2020-09': true,
    '2020-08': true,
    '2020-07': true,
    '2020-06': true,
    '2020-05': true,
    '2020-04': true,
    '2020-03': true,
    '2020-02': true,
    '2020-01': true,
    '2019': true,
    '2019-Q4': true,
    '2019-Q3': true,
    '2019-Q2': true,
    '2019-Q1': true,
    '2019-12': true,
    '2019-11': true,
    '2019-10': true,
    '2019-09': true,
    '2019-08': true,
    '2019-07': true,
    '2019-06': true,
    '2019-05': true,
    '2019-04': true,
    '2019-03': true,
    '2019-02': true,
    '2019-01': true,
    'All': true,
}

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

function mkYear(year: number) {
    const start = new Date(year, 0)
    const end = new Date(year, 11, 31, 23, 59, 59)
    return {
        start, end
    }
}

function mkQuarter(year: number, quarter: number): datefns.Interval | null {
    let end = new Date()
    let start = end
    switch (quarter) {
        case 1:
            start = new Date(year, 0)
            end = new Date(year, 2, 31, 23, 59, 59)
            return {
                start, end
            }
        case 2:
            start = new Date(year, 3)
            end = new Date(year, 5, 30, 23, 59, 59)
            return {
                start, end
            }
        case 3:
            start = new Date(year, 6)
            end = new Date(year, 8, 30, 23, 59, 59)
            return {
                start, end
            }
        case 4:
            start = new Date(year, 9)
            end = new Date(year, 11, 31, 23, 59, 59)
            return {
                start, end
            }

        default: return null
    }
}

function mkMonth(year: number, month: number): datefns.Interval | null {
    let end = new Date()
    let start = end
    let day = 31
    switch (month) {
        case 0:
        case 2:
        case 4:
        case 6:
        case 7:
        case 9:
        case 11:
            start = new Date(year, month)
            end = new Date(year, month, 31, 23, 59, 59)
            return {
                start, end
            }
        case 1:
            start = new Date(year, month)
            day = datefns.isLeapYear(start) ? 29 : 28
            end = new Date(year, month, day, 23, 59, 59)
            return {
                start, end
            }
        case 3:
        case 5:
        case 8:
        case 10:
            start = new Date(year, month)
            end = new Date(year, month, 30, 23, 59, 59)
            return {
                start, end
            }
        default: return null
    }
}

export function getInterval(x: Kind): datefns.Interval | null {
    const end = new Date()
    let start = end
    switch (x) {
        case "All":
            start = new Date(2016, 0, 1)
            return { start, end }
        case "Last 7d":
            start = datefns.addDays(end, -7)
            return { start, end }
        case "Last 14d":
            start = datefns.addDays(end, -14)
            return { start, end }
        case "Last 30d":
            start = datefns.addDays(end, -30)
            return { start, end }
        case "Last 90d":
            start = datefns.addDays(end, -90)
            return { start, end }
        case "Last 180d":
            start = datefns.addDays(end, -180)
            return { start, end }
        case "Last 360d":
            start = datefns.addDays(end, -360)
            return { start, end }
        default: {
            const period = x.split("-")
            if (period.length === 1) {
                const year = parseInt(period[0])
                return mkYear(year)
            }
            else if (period.length === 2) {
                if (period[1].startsWith("Q")) {
                    const year = parseInt(period[0])
                    const quarter = parseInt(period[1].substr(1))
                    return mkQuarter(year, quarter)
                }
                else {
                    const year = parseInt(period[0])
                    const month = parseInt(period[1])
                    return mkMonth(year, month - 1)
                }
            }
            else {
                return null
            }
        }
    }
}

export function getPrevMonthKind(x: Date): Kind {
    const m = x.getMonth()
    const y = x.getFullYear()
    if (m > 0) {
        if (m < 10) {
            return `${y}-0${m}` as Kind
        }
        else {
            return `${y}-${m}` as Kind
        }
    }
    else {
        return `${y - 1}-12` as Kind
    }
}


export function getPrevQuarterKind(x: Date) {
    const m = x.getMonth() - 1
    const y = x.getFullYear()
    if (m >= 0 && m <= 2) {
        return `${y - 1}-Q4` as Kind
    }
    else if (m >= 3 && m <= 5) {
        return `${y}-Q1` as Kind
    }
    else if (m >= 6 && m <= 8) {
        return `${y}-Q2` as Kind
    }
    else {
        return `${y}-Q3` as Kind
    }
}

export function tryParseKind(x: string): Kind | null {
    if (KEYS.includes(x as Kind)) {
        return x as Kind
    }
    return null
}

export function parseKind(x: string, defaultval: Kind): Kind {
    if (KEYS.includes(x as Kind)) {
        return x as Kind
    }
    return defaultval
}

export type Prefix =
    | "Last"
    | "2024"
    | "2023"
    | "2022"
    | "2021"
    | "2020"
    | "2019"

export type Period =
    | "A"
    | "Y"
    | "Q"
    | "M"

export type Filter = {
    period: Period;
    prefix: Prefix;
}

const Ys: Kind[] = [
    '2024',
    '2023',
    '2022',
    '2021',
    '2020',
    '2019',
]

const Qs: Kind[] = [
    'Last 90d',
    'Last 180d',
    'Last 360d',
    '2024-Q1',
    '2024-Q2',
    '2024-Q3',
    '2024-Q4',
    '2023-Q1',
    '2023-Q2',
    '2023-Q3',
    '2023-Q4',
    '2022-Q1',
    '2022-Q2',
    '2022-Q3',
    '2022-Q4',
    '2021-Q1',
    '2021-Q2',
    '2021-Q3',
    '2021-Q4',
    '2020-Q1',
    '2020-Q2',
    '2020-Q3',
    '2020-Q4',
    '2019-Q1',
    '2019-Q2',
    '2019-Q3',
    '2019-Q4',
]

const Ms: Kind[] = function () {
    const YandQs: Kind[] = Ys.concat(Qs)
    const filtered = KEYS.filter(x => !YandQs.includes(x))
    const result = filtered.concat(['Last 7d', 'Last 14d', 'Last 30d'])
    return result
}()

export function getActiveKind(x: Period): Kind | null {
    const now = Date.now()
    switch (x) {
        case "M": return datefns.format(now, "yyyy-MM") as Kind
        case "Q": return datefns.format(now, "yyyy-qqq") as Kind
        case "Y": return datefns.format(now, "yyyy") as Kind
        case "A": return null
    }
}

function filterNoFuture(xs: Kind[]): Kind[] {
    const with_interval = xs.map(y => { return { key: y, interval: getInterval(y) } })
    const non_nulls = with_interval.filter(y => y.interval !== null) as { key: Kind; interval: Interval }[]
    const no_future = non_nulls.filter(y => y.interval.start < new Date())
    const result = no_future.map(y => y.key)
    return result
}

export function filter(x: Filter): Kind[];
export function filter(xs: Filter[]): Kind[];

export function filter(x: Filter | Filter[]): Kind[] {
    if (Array.isArray(x)) {
        const filtered = x.map(filter)
        return filtered.reduce((acc, cur) => acc.concat(cur))
    }
    else {
        switch (x.period) {
            case "A":
                return ["All"]
            case "Y":
                return filterNoFuture(Ys.filter(y => y.startsWith(x.prefix)))
            case "Q":
                return filterNoFuture(Qs.filter(y => y.startsWith(x.prefix)))
            case "M":
                return filterNoFuture(Ms.filter(y => y.startsWith(x.prefix)))
        }
    }
}