import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=84c05450&"
import script from "./Main.vue?vue&type=script&lang=ts&"
export * from "./Main.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VContainer,VDataTable,VIcon,VOverlay,VProgressCircular,VSimpleCheckbox})
