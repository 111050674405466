
import Vue from "vue"
import Component from "vue-class-component"

import store from "@/store/store"
import * as Supplier from "@/lib/Util/Types/Supplier/Supplier"
import * as ListModule from "@/store/Supplier/ListModule"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"

import * as Shared from "./Shared"

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"])

@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
    },
})
export default class MasterdataSupplierListMainView extends Vue {
    headers = Shared.HEADERS

    filter = {} as ListModule.Filter

    get items() {
        return Supplier.sort(store.state.supplier.list.value)
    }

    get msg() {
        return store.state.supplier.list.message
    }

    executeSearch() {
        store.commit.supplier.list.updateFilter(this.filter)
        store.dispatch.supplier.list.LOAD(this.filter)
    }
}
