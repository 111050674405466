
import Vue from "vue"
import Component from "vue-class-component"

import * as common from "@/components/Util/Common"
import * as Protocols from "@/lib/Util/Types/Protocols"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import FlexibleEditTable from "@/components/Util/FlexibleEditTable.vue"
import TriStateCheckBox from "@/components/Util/TriStateCheckBox.vue"
import SearchForm from "@/components/Util/SearchForm/Main.vue"

import * as Template from "@/lib/Util/Types/Masterdata/Template/Template"

import EditDialog from "./EditDialog.vue"
import store from "@/store/store"

import * as Shared from "./Shared"

const lang = Template.LANG

@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        EditDialog,
        FlexibleEditTable,
        TriStateCheckBox,
        SearchForm,
    },
})
export default class MasterdataTemplateListMainView extends Vue {
    headers = Shared.HEADERS

    onCreate = Template.getEmpty
    onSort = Template.sort
    convertRow = Shared.toEditWrapper
    filter = {} as Protocols.QueryExpression<typeof lang>
    lang = lang

    get items() {
        const filtered = Template.filter(this.filter, store.state.template.list.value)
        return filtered
    }

    get msg() {
        return store.state.template.list.message
    }

    updateFilter(x: Protocols.QueryExpression<typeof lang>) {
        this.filter = x
    }

    onAction(command: string, item: common.EditWrapper<Template.T>) {
        switch (command) {
            case "delete":
                return false
            default:
                return true
        }
    }

    async onSave(x: Template.T, is_new: boolean) {
        console.log("Template.Main.onSave", x)
        if (is_new) {
            return await store.dispatch.template.single.SAVE(x)
        }
        return null
    }

    updateList(xs: Template.T[]) {
        store.commit.template.list.updateValue(xs)
    }

    beforeRouteEnter(to: any, from: any, next: any) {
        store.dispatch.template.list.LOAD()
        next()
    }
}
