
import Vue from "vue"
import { Prop } from "vue-property-decorator"
import Component from "vue-class-component"

import * as Common from "@/lib/Util/Common"

import * as ExternalUser from "@/lib/Util/Types/Masterdata/BusinessProcess/ExternalUser"

import ListView from "./List.vue"
import TreeView from "./Tree.vue"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"

import * as Shared from "./Shared"
import store from "@/store/store"

@Component({
    components: {
        ListView,
        TreeView,
        SuccessMessage,
        ErrorMessage,
    },
})
export default class ExternalUsersView extends Vue {
    @Prop({ default: () => [] }) items!: ExternalUser.T[]
    @Prop({ default: "no_bp_sid" }) bp_sid!: string
    @Prop({ default: "no_bp_id" }) bp_id!: string

    tabs = "list"
    filename = ""

    get msg() {
        return store.state.external_user.single.message
    }

    exportCSV(): void {
        const result = this.items.map(Shared.toProtocols)
        Shared.exportCSV(this.bp_sid, result)
    }

    async addFile(e: any) {
        const result = await Common.parseDropedCSVFile(e)
        if (result) {
            this.filename = result.filename
            Shared.importFromCSV(result.data, this.bp_id)
        }
        console.log("ADD FILE", result)
    }
}
