import { defineModule } from "direct-vuex"

import * as ListModule from "./ListModule"

export const internal = {
    namespaced: true as const,
    modules: {
        list: ListModule.module,
    }
}

export const module = defineModule(internal)

export default module
