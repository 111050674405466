import * as Types from "@/lib/Util/Types/Types"

export type T = {
    account_id: string;
    bic?: string;
    kind: "IBAN" | "OTHER";
}

export function getEmpty(): T {
    return {
        account_id: "X",
        kind: "IBAN"
    }
}

export function tryParse(x: any): T | null {
    if (x) {
        const result = Types.isOfType(x, getEmpty)
        if (result) {
            return x
        }
        console.error("AccountKey.tryParse - parse error", x)
        return null
    }
    console.error("AccountKey.tryParse - is null | undedefined", x)
    return null
}