import * as Types from '@/lib/Util/Types/Types'

export const VALUES = {
    Opened: true,
    Clearing: true,
    Closed: true,
} as const

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getEmpty(): T {
    return { kind: "Closed", Closed: true }
}

export const tryParse = Types.buildTryParse<T, true>(VALUES, "kind", true, "EditState")
