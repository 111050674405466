import * as lodash from "lodash"

export const HEADERS = [
    {
        text: 'Short-ID',
        value: "payment_state.short_id",
    },
    {
        text: 'Area',
        value: 'state',
        width: "7%"
    },
    {
        text: 'Is Paid',
        value: 'isPaid',
        width: "7%"
    },
    {
        text: 'Mode',
        value: 'payment_state.payment_mode',
        width: "7%"
    },
    {
        text: 'Supplier',
        value: 'supplier',
    },
    {
        text: 'Invoice#',
        value: 'invoice_no',
    },
    {
        text: 'Client',
        value: 'client',
    },

    {
        text: 'Buyer',
        value: 'buyer',
    },
    {
        text: 'Updated At',
        value: 'payment_state.updated_at',
    },
    {
        text: '',
        value: 'actions',
    },
]

export const FILTER = {
    invoice_number: "",
    supplier_name: "",
    short_id: "",
    order_number: "",
    external_id: "",
    supplier_account: "",
}

export type Filter = typeof FILTER

export function parseFilter(x: any): Filter {
    if (x) {

        return {
            invoice_number: x.invoice_number ?? "",
            supplier_name: x.supplier_name ?? "",
            short_id: x.short_id ?? "",
            order_number: x.order_number ?? "",
            external_id: x.external_id ?? "",
            supplier_account: x.supplier_account ?? "",
        }
    }
    else {
        return FILTER
    }
}

export function isEmpty(x: Filter): boolean {
    return lodash.isEqual(x, FILTER)
}