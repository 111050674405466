
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import * as lodash from "lodash/fp"

import * as Currency from "@/lib/Util/Types/Common/Currency"
import * as VatType from "@/lib/Util/Types/VAT/VatType"

import * as Supplier from "@/lib/Util/Types/Supplier/Supplier"
import * as Target from "@/lib/Util/REST/Target"

import * as Workflow from "@/lib/Util/Types/Workflow/Workflow"
import * as EditState from "@/lib/Util/Types/Workflow/EditState"
import * as StepState from "@/lib/Util/Types/Workflow/StepState"
import * as CostEntityLine from "@/lib/Util/Types/Workflow/Doc/CostEntityLine"

import * as ProcessReference from "@/lib/Util/Types/Workflow/Doc/ProcessReference"
import * as PosDataLineItem from "@/components/Sections/Workflow/View/Single/PosDataLineItem"
import * as LineItem from "@/lib/Util/Types/Workflow/Doc/LineItem"
import * as HeaderData from "@/lib/Util/Types/Workflow/Doc/HeaderData"
import * as DeclineReason from "@/lib/Util/Types/Workflow/DeclineReason"
import * as PaymentState from "@/lib/Util/Types/Workflow/PaymentState"
import * as PaymentMode from "@/lib/Util/Types/Workflow/PaymentMode"

import ProcessReferencePartView from "./ProcessReferencePart.vue"
import SupplierPartView from "./SupplierPart.vue"
import HeaderPartView from "./HeaderDataPart.vue"
import PositionDataPartView from "./PositionDataPart.vue"
import FeesPartView from "./FeesPart.vue"
import AutoFeesView from "./AutoFees.vue"
import EditStateToolbar from "./EditStateToolbar.vue"
import StepStateToolbar from "./StepStateToolbar.vue"
import WorkflowStateToolbar from "./WorkflowStateToolbar.vue"
import DeclineDialog from "./DeclineDialog.vue"
import PaymentStateDialog from "./PaymentStateDialog.vue"

import EditForm from "@/components/Util/EditForm.vue"
import EditToolbar from "@/components/Util/EditToolbar.vue"
import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"

import store from "@/store/store"
import * as WarningStore from "@/store/Warning/store"

import { Direction } from "@/store/Workflow/SingleModule"

@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
        EditForm,
        EditToolbar,
        ProcessReferencePartView,
        SupplierPartView,
        HeaderPartView,
        PositionDataPartView,
        FeesPartView,
        AutoFeesView,
        EditStateToolbar,
        StepStateToolbar,
        DeclineDialog,
        PaymentStateDialog,
        WorkflowStateToolbar,
    },
})
export default class WorkflowSingleMainDetailView extends Vue {
    @Prop({ default: "Prepare" }) area!: Target.TargetKind
    @Prop({ default: null }) item!: Workflow.T

    bus = new Vue()
    selectedItem = ""

    isDirty = false
    loaded = lodash.cloneDeep(this.item) as Workflow.T | null
    show_decline = false
    show_payment_state = false

    payment_state: PaymentState.T | null = null

    async mounted() {
        this.payment_state = await WarningStore.loadPaymentState(this.loaded?.id ?? "NO ID")
    }

    get country() {

        return this.loaded?.doc.process_reference?.country.kind
    }

    get currency() {
        return this.loaded?.doc.process_reference?.currency.kind
    }

    get position_data(): PosDataLineItem.T[] {
        if (this.loaded) {
            return PosDataLineItem.bundlePosDataLineItems(this.loaded.doc)
        }
        return []
    }

    get isEditStateToolbarActive() {
        return this.loaded?.state.step_state?.kind === "Edit" ?? false
    }

    get isStepStateToolbarActive() {
        return this.loaded?.state.step_state?.kind === "Edit" ?? false
    }

    get showToolbar() {
        return this.area === "Prepare" || this.area === "Verify" || this.area === "Approve"
    }

    get infoText() {
        let decline_text = ""
        if (this.loaded?.last_decline) {
            decline_text = `<b>Last Decline</b></br>${this.loaded.last_decline.kind.toUpperCase()}<br>`
            let decline_description = ""
            switch (this.loaded.last_decline.kind) {
                case "TooManyLineItems":
                    decline_description = this.loaded.last_decline.TooManyLineItems ?? ""
                    break
                case "TooFewLineItems":
                    decline_description = this.loaded.last_decline.TooFewLineItems ?? ""
                    break
                case "Other":
                    decline_description = this.loaded.last_decline.Other ?? ""
                    break
            }
            decline_text = decline_text + decline_description
        }

        return `click to copy short id </br>${decline_text}`
    }

    @Watch("loaded", { deep: true })
    onLoadedChanged(nv: Workflow.T | null, ov: Workflow.T | null) {
        this.isDirty = !lodash.isEqual(this.loaded, store.state.workflow.single.value)
    }

    @Watch("item", { deep: true })
    onItemChanged(nv: Workflow.T | null, ov: Workflow.T | null) {
        this.loaded = lodash.cloneDeep(this.item)
    }

    get showAutoFees() {
        if (
            this.loaded?.doc.header_data?.document_type &&
            this.loaded.doc.header_data.document_type.kind === "InternalReceipt"
        ) {
            return false
        }
        return true
    }

    get showManualFees() {
        if (
            this.loaded?.doc.header_data?.document_type &&
            this.loaded.doc.header_data.document_type.kind === "InternalReceipt"
        ) {
            return false
        }
        return true
    }

    get showSupplier() {
        if (
            this.loaded?.doc.header_data?.document_type &&
            this.loaded.doc.header_data.document_type.kind === "InternalReceipt"
        ) {
            return false
        }
        return true
    }

    get warningIcon() {
        const icons: Record<PaymentMode.Kind, string> = {
            PREPAYMENT: "mdi-cash-plus",
            AFTER_PAYMENT: "mdi-cash-clock",
            FASTTRACK: "mdi-fast-forward",
            REMINDER_1: "mdi-numeric-1-circle",
            REMINDER_2: "mdi-numeric-2-circle",
            REMINDER_3: "mdi-numeric-3-circle",
            COLLECTION_THREAT: "mdi-bomb",
            COLLECTION: "mdi-nuke",
            CLIENT_REQUEST: "mdi-chat-question",
            ON_HOLD: "mdi-lock",
        }
        if (this.payment_state) {
            return icons[this.payment_state.payment_mode.kind]
        }
        else {
            return "mdi-bell-ring-outline"
        }
    }

    updateProcessReference(x: ProcessReference.T | null) {
        if (this.loaded && x) {
            this.loaded.doc.process_reference = x
        }
    }

    updateHeaderData(x: HeaderData.T | null) {
        if (this.loaded && x) {
            this.loaded.doc.header_data = x
        }
    }

    updateSupplier(x: Supplier.T | null) {
        if (this.loaded && x) {
            this.loaded.doc.supplier = x
        }
    }

    updatePositionData(xs: PosDataLineItem.T[]) {
        if (this.loaded) {
            this.loaded.doc = PosDataLineItem.unbundlePosDataLineItems(this.loaded.doc, xs)
        }
    }

    updateManualFees(xs: LineItem.T[]) {
        if (this.loaded) {
            this.loaded.doc.manual_fees = xs
        }
    }

    async updatePaymentState(x: PaymentState.T | null) {
        if (x) {
            this.payment_state = x
        }
        this.show_payment_state = false
        if (this.payment_state) {
            await WarningStore.save(this.payment_state)
        }
        console.log("updatePaymentState", this.payment_state)
    }

    updateBeforeSending() {
        //update all amounts in the header data to have also a currency
        //this is not exactly a bug but a ambiguity in the spec
        const doc = this.loaded?.doc

        const currency = doc?.process_reference?.currency ?? Currency.getEmpty()
        if (doc) {
            if (doc.header_data?.order_amount_value) {
                doc.header_data.order_amount_currency = currency
            }
            if (doc.header_data?.gross_amount_value) {
                doc.header_data.gross_amount_currency = currency
            }
            if (doc.header_data?.discount_amount_value) {
                doc.header_data.discount_amount_currency = currency
            }
            const ref = doc.process_reference
            if (doc.header_data) {
                const header = doc.header_data
                if (ref?.process_type.kind === "OrderToPay") {
                    header.buyer_email = ref.employee_email
                } else {
                    header.buyer_email = null
                }
            }

            if (!this.showAutoFees) {
                doc.auto_fees = []
            }
            if (!this.showManualFees) {
                doc.manual_fees = []
            }

            if (this.area === "Prepare") {
                //no costypes allowed in preparation area
                if (doc.cost_types.length >= 1) {
                    doc.cost_types = []
                }

                if (doc.vat_table.length === 0) {
                    //todo: my what an ugly hack. Needs to fix on the server
                    doc.vat_table = [VatType.getEmpty()]
                }
            }

            //There are empty CostEnties send from the server that create issues on when Sealing after Preparation
            if (this.area !== "Prepare") {
                doc.cost_types = doc.cost_types.filter(v => !CostEntityLine.isEmpty(v))
            }
        }

        //todo: there are many more tests like this defined in type WorkflowAccess on the server
        //we need to find a better way for this.

        if (doc && (doc.header_data?.document_type?.kind ?? "Invoice") === "InternalReceipt") {
            console.log("UPDATE-BEFORE-SENDING", doc.process_reference, doc.process_reference?.country.kind, Supplier.COMPRAGAS[doc.process_reference?.country.kind ?? "DE"])
            doc.supplier = Supplier.COMPRAGAS[doc.process_reference?.country.kind ?? "DE"]
            doc.supplier.account = {
                kind: "OTHER",
                account_id: "Internal",
            }
        }
    }

    copyClipBoard() {
        navigator.clipboard.writeText(this.loaded?.short_id ?? "NO ID")
    }

    async save() {
        if (this.loaded) {
            this.updateBeforeSending()
            store.dispatch.workflow.single.SAVE({ area: this.area, w: this.loaded })
            console.log("SAVE!")
        }
    }

    cancel() {
        this.loaded = lodash.cloneDeep(store.state.workflow.single.value)
    }

    changeEditState(x: EditState.Kind) {
        console.log("RUN ", x)
        const payload = {
            id: this.loaded?.id ?? "NO ID",
            area: this.area,
            editstate: x,
        }
        store.dispatch.workflow.single.CHANGE_EDIT_STATE(payload)
    }

    changeStepState(x: StepState.Kind) {
        if (x !== "Declined") {
            const payload = {
                id: this.loaded?.id ?? "NO ID",
                area: this.area,
                state: x,
            }
            store.dispatch.workflow.single.CHANGE_STEP_STATE(payload)
        } else {
            this.show_decline = true
        }
    }

    executeDecline(x: DeclineReason.T) {
        const payload = {
            id: this.loaded?.id ?? "NO ID",
            area: this.area,
            state: "Declined" as StepState.Kind,
            reason: x,
        }
        store.dispatch.workflow.single.CHANGE_STEP_STATE(payload)
    }

    async move(x: Direction) {
        const payload = {
            id: this.loaded?.id ?? "NO ID",
            area: this.area,
            direction: x,
        }
        const _ = await store.dispatch.workflow.single.MOVE(payload)
        this.$router.push(`/sections/workflow/${this.area.toLowerCase()}/list`)
    }

    resend_email() {
        store.dispatch.workflow.single.RESEND_MAIL(this.loaded?.id ?? "NO ID")
    }

    toOverView() {
        this.$emit("change-view")
    }

    openApprovalPage() {
        const params = `${this.loaded?.short_id}?email=${this.loaded?.doc.process_reference?.employee_email.Value}`
        window.open(`https://compraga-solutions-web.azurewebsites.net/approve/load/${params}`, '_blank')
    }

    editPaymentState() {
        if (!this.payment_state) {
            this.payment_state = PaymentState.getEmpty(this.loaded?.id ?? "NO ID", this.loaded?.short_id ?? "NO SID")
        }
        this.show_payment_state = true
        console.log("EDIT PAYMENT STATE", this.payment_state)
    }
}
