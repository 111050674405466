import * as Common from "@/lib/Util/Common"

export type T = {
    npt: number;
    payment_term_diff: number;
    given_payment_term_span: number;
    due_date: Date;
    receipt_date: Date;
    using_invoice_date_and_npt: Date;
    using_receipt_date_and_npt: Date;
}

export function tryParse(x?: any): T | null {
    try {
        if (x) {
            const due_date = Common.parseServerDate(x.due_date)
            const receipt_date = Common.parseServerDate(x.receipt_date)
            const using_invoice_date_and_npt = Common.parseServerDate(x.using_invoice_date_and_npt)
            const using_receipt_date_and_npt = Common.parseServerDate(x.using_receipt_date_and_npt)
            if (due_date && receipt_date && using_invoice_date_and_npt && using_receipt_date_and_npt) {
                return {
                    ...x,
                    due_date,
                    receipt_date,
                    using_invoice_date_and_npt,
                    using_receipt_date_and_npt
                }
            }
        }

        console.error("PaymentTerms.tryParse", x)
        return null
    }
    catch (ex) {
        console.error("PaymentTerms.tryParse", ex)
        return null
    }
}
