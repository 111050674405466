
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import * as lodash from "lodash"

import * as Common from "@/lib/Util/Common"

import * as Country from "@/lib/Util/Types/Common/Country"
import * as Currency from "@/lib/Util/Types/Common/Currency"

import * as VatType from "@/lib/Util/Types/VAT/VatType"
import * as VatRules from "@/lib/Util/Types/VAT/VatRules"

import * as Measure from "@/lib/Util/Types/Workflow/Doc/Measure"
import * as PosDataLineItem from "@/components/Sections/Workflow/View/Single/PosDataLineItem"

import NumberInput from "@/components/Util/NumberInput.vue"

import * as Shared from "./Shared"
import store from "@/store/store"

@Component({
    components: {
        NumberInput,
    },
})
export default class PositionDataPartView extends Vue {
    @Prop({ default: [] }) items!: PosDataLineItem.T[]
    @Prop({ default: null }) country!: Country.Kind | null
    @Prop({ default: null }) currency!: Currency.Kind | null
    @Prop({ default: 0 }) gross_amount!: number

    loaded = lodash.cloneDeep(this.items)
    available_measures = Measure.VALS
    focus_index = null as null | number

    get utils() {
        return new Shared.Utils(this.currency, this.country, store.state.authentication?.vatRules ?? null)
    }

    get available_vat_types(): VatType.T[] {
        if (store.state.authentication?.vatRules && this.country) {
            const rules = VatRules.getCountryDUArray(store.state.authentication?.vatRules, this.country)
            console.log("AVAILABLE_VAT_TYPES", rules)
            if (rules.length > 0) {
                const particular = (x: VatType.T) => {
                    return !(
                        (x.kind === "NormalExtended" || x.kind === "ReducedExtended" || x.kind === "ZeroExtended") &&
                        x.value === 0
                    )
                }
                const not_contains_extended = (x: VatType.T) => {
                    return x.kind !== "NormalExtended" && x.kind !== "ReducedExtended"
                }
                const should_remove = this.loaded.map(v => not_contains_extended(v.vat)).every(y => y)
                const filtered = rules.filter(particular)
                const result = should_remove ? filtered.filter(not_contains_extended) : filtered
                return result
            }
            console.error("Country not found in loaded VAT Rules", store.state.authentication.vatRules, this.country)
            return []
        }
        console.error("Not Authenticated or no VAT Rules loaded", store.state.authentication, this.country)
        return []
    }

    get net() {
        const nets = this.loaded.map(x => x.amount_value * x.units_count)
        return nets.reduce((prev, curr) => prev + curr, 0)
    }

    get vat() {
        const nets = this.loaded.map(x => (x.amount_value * x.units_count * (x.vat.value ?? 0)) / 100)
        return nets.reduce((prev, curr) => prev + curr, 0)
    }

    get total() {
        return Math.round((this.net + this.vat) * 100) / 100
    }

    get isGrossError() {
        const diff = this.total - this.gross_amount
        return diff > 0.02 || diff < -0.02
    }

    remove(x: number) {
        this.loaded = Common.removeArrayElement(this.loaded, x)
    }

    add() {
        const result = this.utils.emptyPosDataLineItem
        if (result) {
            this.loaded.push(result)
        } else {
            console.error("POsDataLineItem.T could not be created")
        }
    }

    get overflowSize(): number {
        const count = 4 // number of columns the overflow should use
        const r = this.$refs["pos-table"] as any
        const cells = r?.$el?.querySelector("table")?.rows[0]?.cells
        const overflows = lodash.take(cells, count) as { offsetWidth: number }[]
        const result = overflows.reduce((prev, x) => x.offsetWidth + prev, 0)

        return result
    }

    onFocus(e: any, i: number) {
        this.focus_index = i
    }

    onBlur(e: any) {
        this.focus_index = null
    }

    is_big_text_field(i: number) {
        return this.focus_index === i
    }

    big_text_field_style(i: number) {
        if (this.is_big_text_field(i)) {
            return {
                width: `${this.overflowSize}px`,
            }
        } else {
            return {}
        }
    }

    showTD(i: number) {
        return this.focus_index === i ? "display: none" : "display: table-cell"
    }

    @Watch("items")
    onItemChanged(nv: PosDataLineItem.T[] | null) {
        if (this.loaded === nv || lodash.isEqual(this.loaded, nv)) {
            return
        }
        this.loaded = lodash.cloneDeep(this.items)
    }

    @Watch("loaded", { deep: true })
    onChange(nv: PosDataLineItem.T[]) {
        this.$emit("update", nv)
    }
}
