import * as lodash from 'lodash/fp';
import { defineModule, localActionContext } from "direct-vuex"

import * as Workflow from "@/lib/Util/Types/Workflow/Workflow"
import * as EditState from "@/lib/Util/Types/Workflow/EditState"
import * as StepState from "@/lib/Util/Types/Workflow/StepState"
import * as DeclineReason from "@/lib/Util/Types/Workflow/DeclineReason"

import * as REST from '@/lib/Util/REST'
import * as Target from '@/lib/Util/REST/Target'
import { RootState } from '@/store/Shared'

export type Direction =
    | "next"
    | "prev"
    | "cancel"

export type StepStateChange = {
    id: string,
    area: Target.TargetKind,
    state: StepState.Kind,
    reason?: DeclineReason.T
}

const state = {
    value: null as Workflow.T | null,
    message: REST.getEmpty()
}

const mutations = {
    updateValue(state: State, v: Workflow.T | null) {
        state.value = v;
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    },
}

async function saving(context: any, url: string, x: Workflow.T) {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const { commit, state } = actionCtx(context)
    const rootState = context.rootState as RootState
    try {
        const loading_msg = {
            ...state.message,
            loading: true
        }
        commit.updateMsg(loading_msg)

        console.log("SAVING", x)

        const req = {
            token: rootState.authentication?.token ?? "NO TOKEN",
            url,
            payload: x,
            transformer: Workflow.tryParse
        };

        const result = await REST.POST(state.message, req);
        if (result.value) {
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text: `Successfully saved Workflow: ${x.short_id}`
            }
            commit.updateValue(result.value)
            commit.updateMsg(msg)
        }
        else {
            const text = typeof result.msg.text === "string" ? result.msg.text : result.msg.text.errors.join("|")
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text
            }
            commit.updateMsg(msg)
        }
    }
    catch (ex) {
        const msg = {
            ...lodash.cloneDeep(state.message),
            text: JSON.stringify(ex)
        }
        commit.updateMsg(msg)
    }
}

async function loading(context: any, url: string) {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const { commit, state } = actionCtx(context)
    const rootState = context.rootState as RootState
    try {
        const loading_msg = {
            ...state.message,
            loading: true
        }
        commit.updateMsg(loading_msg)

        const req = {
            token: rootState.authentication?.token ?? "NO TOKEN",
            url,
            payload: {},
            transformer: Workflow.tryParse
        };
        const result = await REST.GET(state.message, req);
        if (result.value) {
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text: `Successfully loaded Workflow ${result.value?.short_id}`
            }
            commit.updateValue(result.value)
            commit.updateMsg(msg)
        }
        else {
            //todo: REALLY CHANGE THIS!
            //this is really shitty and should be fixed on the server side
            //such that the server send propper JSON
            const err = JSON.parse(result.msg.text as string)
            const text = err.errors.join("|")
            //this is even shittier as we do the redirect here aka. we are executing a sideefect
            const regex = /The Workflow has been moved to \[(\w+)\]/
            const matches = regex.exec(text)
            if (matches) {
                const area = Target.toTargetKindString(matches[1])
                if (Target.isTargetKind(area)) {
                    const target = Target.LOOKUP.target[area].toLowerCase()
                    const location_pattern = /.+\/workflow(\/\w+\/).*/
                    const old_loc = window.location.href.match(location_pattern)
                    if (old_loc) {
                        const old_target = old_loc[1]
                        const new_target = window.location.href.replace(old_target, "/" + target + "/")
                        window.location.href = new_target
                    }
                }

            }
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text
            }
            commit.updateMsg(msg)
        }
    }
    catch (ex) {
        const msg = {
            ...lodash.cloneDeep(state.message),
            text: JSON.stringify(ex)
        }
        commit.updateMsg(msg)
    }
}

const actions = {
    async LOAD(context: any, x: { id: string, area: Target.TargetKind }) {
        const target = Target.LOOKUP.target[x.area].toLowerCase()
        await loading(context, `/${target}/${x.id}`)
    },
    async SAVE(context: any, x: { area: Target.TargetKind, w: Workflow.T }) {
        const target = Target.LOOKUP.target[x.area].toLowerCase()
        await saving(context, `/${target}/${x.w.id}/update`, x.w)
    },

    async CREATE(context: any) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context)
        const rootState = context.rootState as RootState
        try {
            const loading_msg = {
                ...state.message,
                loading: true
            }
            commit.updateMsg(loading_msg)

            const req = {
                token: rootState.authentication?.token ?? "NO TOKEN",
                url: "/create_empty",
                payload: {},
                transformer: Workflow.tryParse
            };
            const result = await REST.GET(state.message, req);
            if (result.value) {
                const msg = {
                    ...lodash.cloneDeep(result.msg),
                    text: `Successfully created Workflow ${result.value?.short_id}`
                }
                commit.updateValue(result.value)
                commit.updateMsg(msg)
            }
            else {
                const text = typeof result.msg.text === "string" ? result.msg.text : result.msg.text.errors.join("|")
                const msg = {
                    ...lodash.cloneDeep(result.msg),
                    text
                }
                commit.updateMsg(msg)
            }
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg(msg)
        }
    },
    async CHANGE_EDIT_STATE(context: any, x: { id: string, area: Target.TargetKind, editstate: EditState.Kind }) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context)
        const rootState = context.rootState as RootState
        const target = Target.LOOKUP.target[x.area].toLowerCase()
        const editstate = x.editstate.toLowerCase()
        ///api/preparation/%s/edit_state/%s"
        try {
            const loading_msg = {
                ...state.message,
                loading: true
            }
            commit.updateMsg(loading_msg)

            const req = {
                token: rootState.authentication?.token ?? "NO TOKEN",
                url: `/${target}/${x.id}/edit_state/${editstate}`,
                payload: {},
                transformer: Workflow.tryParse
            };
            const result = await REST.GET(state.message, req);
            if (result.value) {
                const msg = {
                    ...lodash.cloneDeep(result.msg),
                    text: `Successfully changed EditState for ${result.value?.short_id}`
                }
                commit.updateValue(result.value)
                commit.updateMsg(msg)
            }
            else {
                const text = typeof result.msg.text === "string" ? result.msg.text : result.msg.text.errors.join("|")
                const msg = {
                    ...lodash.cloneDeep(result.msg),
                    text
                }
                commit.updateMsg(msg)
            }
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg(msg)
        }
    },
    async CHANGE_STEP_STATE(context: any, x: StepStateChange) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context)
        const rootState = context.rootState as RootState
        const target = Target.LOOKUP.target[x.area].toLowerCase()
        const s = x.state.toLowerCase()
        try {
            const loading_msg = {
                ...state.message,
                loading: true
            }
            commit.updateMsg(loading_msg)

            const req = {
                token: rootState.authentication?.token ?? "NO TOKEN",
                url: `/${target}/${x.id}/step_state/${s}`,
                payload: x.reason ?? {},
                transformer: Workflow.tryParse
            };
            const result = await REST.POST(state.message, req);
            if (result.value) {
                const msg = {
                    ...lodash.cloneDeep(result.msg),
                    text: `Successfully changed StepState for ${result.value?.short_id}`
                }
                commit.updateValue(result.value)
                commit.updateMsg(msg)
            }
            else {
                const text = typeof result.msg.text === "string" ? result.msg.text : result.msg.text.errors.join("|")
                const msg = {
                    ...lodash.cloneDeep(result.msg),
                    text
                }
                commit.updateMsg(msg)
            }
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg(msg)
        }
    },
    async MOVE(context: any, x: { id: string, area: Target.TargetKind, direction: Direction }) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context)
        const rootState = context.rootState as RootState
        const target = Target.LOOKUP.target[x.area].toLowerCase()
        try {
            const loading_msg = {
                ...state.message,
                loading: true
            }
            commit.updateMsg(loading_msg)

            const req = {
                token: rootState.authentication?.token ?? "NO TOKEN",
                url: `/${target}/${x.id}/workflow_state/${x.direction}`,
                payload: {},
                transformer: Workflow.tryParse
            };
            const result = await REST.GET(state.message, req);
            if (result.value) {
                const msg = {
                    ...lodash.cloneDeep(result.msg),
                    text: `Successfully moved ${result.value?.short_id} to ${x.direction} area`
                }
                commit.updateValue(result.value)
                commit.updateMsg(msg)
            }
            else {
                const text = typeof result.msg.text === "string" ? result.msg.text : result.msg.text.errors.join("|")
                const msg = {
                    ...lodash.cloneDeep(result.msg),
                    text
                }
                commit.updateMsg(msg)
            }
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg(msg)
        }
    },
    async RESEND_MAIL(context: any, x: string) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context)
        const rootState = context.rootState as RootState
        const req = {
            token: rootState.authentication?.token ?? "NO TOKEN",
            url: `/approval/${x}/resend_email`,
            payload: {},
            transformer: (v: any) => v
        };
        await REST.GET(state.message, req);
    }
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    actions,
}

export type Mutations = typeof mutations
export type State = typeof state
export type Actions = typeof actions

export const module = defineModule(internal)

export default module

const actionCtx = (context: any) => localActionContext(context, module)
