
import Vue from "vue";

export default Vue.extend({
    name: "TriStateCheckBox",
    props: {
        value: Boolean,
        label: String,
    },
    methods: {
        emitUpdate(v: boolean | null) {
            this.$emit("input", v);
        },
    },
});
