import ReportsSupplierPaymentMainView from "@/components/Sections/Bank/SupplierPayments/Main.vue"
import ReportsSupplierPaymentListMainView from "@/components/Sections/Bank/SupplierPayments/List/Main.vue"
import ReportsSupplierPaymentSingleMainView from "@/components/Sections/Bank/SupplierPayments/Single/Main.vue"

const router = {
    path: 'supplier_payments',
    component: ReportsSupplierPaymentMainView,
    children: [
        {
            path: 'list/:timespan?',
            component: ReportsSupplierPaymentListMainView
        },
        {
            path: 'single/:id?',
            component: ReportsSupplierPaymentSingleMainView
        },
        {
            path: 'single/:account_id/:year/:number',
            component: ReportsSupplierPaymentSingleMainView
        },

    ]
}

export default router