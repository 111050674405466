
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import TooltipIcon from "@/components/Util/TooltipIcon.vue"

@Component({
    components: {
        TooltipIcon,
    },
})
export default class EditToolbar extends Vue {
    @Prop({ default: null }) save!: () => void | null
    @Prop({ default: null }) cancel!: () => void | null
    @Prop({ default: false }) isDirty!: boolean
    @Prop({ default: false }) disabled!: boolean
}
