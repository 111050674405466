import * as Types from "@/lib/Util/Types/Types"

import * as lodash from "lodash"
import * as Common from "@/lib/Util/Common"
import * as Country from "@/lib/Util/Types/Common/Country"
import * as BatchOutputType from "@/lib/Util/Types/Biz/BatchOutputType"
import * as Protocols from "@/lib/Util/Types/Protocols"
import * as TemplateType from "./TemplateType"

export type T = {
    uri?: string;
    data?: string; //base64 encoded string
    upload_date: Date;
    template_type: TemplateType.T;
    batch_output_type: BatchOutputType.T;
    country: Country.T;
}

export function getEmpty(): T {
    return {
        upload_date: new Date(),
        template_type: TemplateType.getEmpty(),
        batch_output_type: BatchOutputType.getEmpty(),
        country: Country.getEmpty(),
        data: ""
    }
}

export function tryParse(x: any): T | null {
    if (x) {
        const template_type = TemplateType.tryParse(x.template_type)
        const batch_output_type = BatchOutputType.tryParse(x.batch_output_type)
        const country = Country.tryParse(x.country)
        const upload_date = Common.parseServerDate(x.upload_date)
        if (template_type && batch_output_type && country && upload_date) {
            return {
                uri: x.uri,
                template_type,
                batch_output_type,
                country,
                upload_date
            }
        }
    }
    console.error("Template.tryParse", x)
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "Template")

export function sort(xs: T[]): T[] {
    return lodash.sortBy(xs, ["country.kind", "template_type.kind", "batch_output_type.kind", "upload_date"])
}

export const LANG = {
    template_type: { kind: "select_multi", value: TemplateType.KEYS },
    batch_output_type: { kind: "select_multi", value: BatchOutputType.KEYS },
    country: { kind: "select_multi", value: Country.KEYS },
    upload_date_from: { kind: "date" },
    upload_date_to: { kind: "date" },
    only_latest: { kind: "boolean" }
} as const

function onlyLatest(xs: T[]): T[] {
    const by_uri = lodash.groupBy(xs, (x) => x.country.kind + x.template_type.kind + x.batch_output_type.kind)
    const result: T[] = []
    for (const key in by_uri) {
        const max = lodash.maxBy(by_uri[key], "upload_date")
        if (max) {
            result.push(max)
        }
    }
    return result
}

export const filter: Protocols.Filter<typeof LANG, T> = (x, y) => {
    let result = y
    if (x.template_type && x.template_type.kind === "select_multi") {
        const expr = x.template_type
        result = result.filter(v => expr.value.includes(v.template_type.kind))
    }
    if (x.batch_output_type && x.batch_output_type.kind === "select_multi") {
        const expr = x.batch_output_type
        result = result.filter(v => expr.value.includes(v.batch_output_type.kind))
    }
    if (x.country && x.country.kind === "select_multi") {
        const expr = x.country
        result = result.filter(v => expr.value.includes(v.country.kind))
    }
    if (x.upload_date_from && x.upload_date_from.kind === "date") {
        const expr = x.upload_date_from
        result = result.filter(v => v.upload_date >= expr.value)
    }
    if (x.upload_date_to && x.upload_date_to.kind === "date") {
        const expr = x.upload_date_to
        result = result.filter(v => v.upload_date <= expr.value)
    }
    if (x.only_latest && x.only_latest.kind === "boolean" && x.only_latest.value) {
        result = onlyLatest(result)
    }
    return result
}

