
import Vue from "vue"
import { Component, Watch, Prop } from "vue-property-decorator"

import * as Common from "@/lib/Util/Common"
import store from "@/store/store"

import * as Gender from "@/lib/Util/Types/Common/Gender"
import * as Email from "@/lib/Util/Types/Common/Email"
import * as REST from "@/lib/Util/REST"

import * as ProcessReference from "@/lib/Util/Types/Workflow/Doc/ProcessReference"
import * as Client from "@/lib/Util/Types/Masterdata/Client/Client"
import * as BusinessProcess from "@/lib/Util/Types/Masterdata/BusinessProcess/BusinessProcess"
import * as ExternalUser from "@/lib/Util/Types/Masterdata/BusinessProcess/ExternalUser"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"

Component.registerHooks(["mounted"])

@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
    },
})
export default class ProcessReferenceDialog extends Vue {
    @Prop({ default: false }) show!: boolean
    search = ""
    isLoading = false
    client: Client.T | null = null
    processes: BusinessProcess.T[] = []
    process: BusinessProcess.T | null = null
    user = ExternalUser.getEmpty()
    valid = false
    available_genders = Gender.KEYS

    rules = {
        email: [
            function checkLen(v?: string): boolean | string {
                return (v?.length ?? 0) !== 0 || "Email must not be empty"
            },
            function isEmail(v?: string): boolean | string {
                return Email.validate(v) || "Please enter valid email"
            },
            function isNotDefaultEmail(v?: string): boolean | string {
                return v !== "unknown@unknown.com" || "Please enter different email"
            },
        ],
        name: [
            (v: string) => {
                return v.length > 0 || "Name must not be empty"
            },
            (v: string) => {
                return v.split(" ").length === 2 || "Name must contain exactly one firstname and one lastname"
            },
            (v: string) => {
                const names = v.split(" ")
                const firstname = names[0]
                return firstname?.length >= 2 || "firstname must contain at least 2 characters"
            },
            (v: string) => {
                const names = v.split(" ")
                const lastname = names[1]
                return lastname?.length >= 2 || "lastname must contain at least 2 characters"
            },
        ],
    }

    resetValues() {
        this.search = ""
        this.isLoading = false
        this.client = null
        this.processes = []
        this.process = null
        this.user = ExternalUser.getEmpty()
        this.valid = false
        store.commit.external_user.single.updateMsg(REST.getEmpty())
        store.commit.client.list.updateValue([])
    }

    get used_email() {
        return this.user.email.Value ?? "unknown@unknown.com"
    }
    set used_email(v: string) {
        this.user.email = Email.Email.tryParse(v) ?? Email.getEmpty()
    }

    get used_gender(): Gender.Kind {
        return this.user?.gender?.kind ?? "M"
    }
    set used_gender(v: Gender.Kind) {
        if (this.user) {
            this.user.gender = Gender.getByKind(v)
        }
    }
    get name() {
        return this.user.name
    }
    set name(v: string) {
        this.user.name = v
        this.user.cpc_id = this.updateCpcId(v)
    }

    @Watch("search", { deep: true })
    async onChanged(nv: string, ov: any) {
        console.log("100 WATCH SEARCH", nv, ov)
        if (nv && nv.length >= 4) {
            this.isLoading = true
            await store.dispatch.client.list.LOAD({ name: nv })
            console.log("200 WATCH SEARCH", store.state.client.list.value)
            this.isLoading = false
        }
        console.log("300 WATCH SEARCH", nv, ov)
    }

    @Watch("client", { deep: true })
    async onChangeClient(nv: any, ov: any) {
        if (this.client) {
            await store.dispatch.client.single.LOAD(this.client.id ?? "NO-ID-PROVIDED")
            this.processes = store.state.client.single.value?.business_processes ?? []
        }
    }

    get clients() {
        if (store.state.client.list.value.length > 0) {
            return store.state.client.list.value
        } else {
            return []
        }
    }

    get msg() {
        return store.state.external_user.single.message
    }

    updateCpcId(v: string) {
        const name = v.split(" ")
        let fn = name[0]
        let ln = name[name.length - 1]
        if (fn === "") {
            fn = "Vorname"
        }
        if (ln === "") {
            ln = "Nachname"
        }
        const ids = this.process?.users.map((x) => x.cpc_id.substr(5)) ?? []
        const new_id = Common.createCombinedID(fn, ln, ids)
        const micro_sid = Common.microShortId(this.process?.short_id ?? "XXX-XXX-XXX")
        return micro_sid + "-" + new_id
    }

    async save() {
        const bp_id = this.process?.id
        const user: ExternalUser.T = {
            ...this.user,
            buyer: { kind: "Yes", Yes: true },
            line_manager_reminder: { kind: "Yes", Yes: true },
            limit_trigger_notification: undefined,
            reminders: {
                ...this.user.reminders,
                involve_line_manager: this.client?.email,
            },
        }
        await store.dispatch.external_user.single.SAVE({
            user,
            bp_id,
        })
        if (store.state.external_user.single.value && this.process && this.client) {
            const user = store.state.external_user.single.value
            const loaded: ProcessReference.T = {
                cpc_id: user.cpc_id,
                process_id: this.process.id ?? "NO PROC ID!",
                process_name: this.process.description,
                process_short_id: this.process.short_id ?? "NO PROC SHORT ID",
                bp_desc: this.process.description,
                client_id: this.client.id ?? "NO CLIENT ID",
                client_name: this.client.name,
                employee_email: user.email,
                employee_name: user.name,
                currency: this.process.currency,
                country: this.process.country,
                process_type: this.process.process_type,
                negotiated_payment_terms: this.process.negotiated_payment_terms,
                use_new_api: this.process.use_new_api,
            }
            this.resetValues()
            this.$emit("update", loaded)
        }
    }

    cancel() {
        console.log("CANCEL")
        this.resetValues()
        this.$emit("update", null)
    }
}
