import * as Types from "@/lib/Util/Types/Types"

import * as Country from "@/lib/Util/Types/Common/Country"
import * as Currency from "@/lib/Util/Types/Common/Currency"
import * as Address from "@/lib/Util/Types/Common/Address"

import * as Common from '@/lib/Util/Common'
import * as Validation from "@/lib/Util/Validation"
import * as InvoicingTerms from "@/lib/Util/Types/Biz/InvoicingTerms"
import * as VatRule from "@/lib/Util/Types/VAT/VatRule"

import * as ProcessType from "./ProcessType"
import * as ExternalUser from "./ExternalUser"
import * as FeesVisibility from "./FeesVisibility"
import * as PricingInformation from "./PricingInformation"
import * as EmailReceiver from "./EmailReceiver"
import * as AccountingData from "./AccountingData"
import * as CostTypes from "./CostTypes"
import * as PaymentIntegration from "./PaymentIntegration"

export type T = {
    id?: string;
    short_id?: string;
    _etag: string;
    created_at: Date;
    active: boolean;
    client_id: string;

    description: string;
    currency: Currency.T;
    country: Country.T;
    process_type: ProcessType.T;
    invoicing_terms: InvoicingTerms.T;
    fees_visibility: FeesVisibility.T;

    payment_terms: number;
    negotiated_payment_terms: number;
    req_delivery_note_num: boolean;
    require_order_num: boolean;
    create_seperate_invoice: boolean;
    auth_limit: number;
    is_pro_forma_invoice: boolean;
    use_new_api: boolean;

    code: string;
    range_of_numbers: string;
    due_date: number;
    expense_account: string;

    pricing_information: PricingInformation.T;
    address: Address.T | null;
    email_receivers: EmailReceiver.T[];
    users: ExternalUser.T[];
    accounting_data: AccountingData.T;
    cost_types: CostTypes.T;
    payment_integration: PaymentIntegration.T;
}

export function getEmpty(client_id: string, vat: VatRule.T): T {
    return {
        client_id,
        _etag: "",

        description: "",
        code: "",
        range_of_numbers: "0",
        process_type: ProcessType.getEmpty(),
        due_date: 0,
        expense_account: "0",
        fees_visibility: { auto_fees: false, manual_fees: false },
        currency: { kind: "EUR", EUR: true },
        country: { kind: "DE", DE: true },
        created_at: new Date(),
        payment_terms: 1,
        negotiated_payment_terms: 30,
        active: true,
        req_delivery_note_num: false,
        require_order_num: false,
        invoicing_terms: InvoicingTerms.getEmpty(),
        create_seperate_invoice: false,
        auth_limit: 10000,
        is_pro_forma_invoice: false,
        use_new_api: true,

        pricing_information: PricingInformation.getEmpty(),
        address: null,
        email_receivers: [],
        users: [],
        accounting_data: AccountingData.getEmpty(vat),
        cost_types: CostTypes.getEmpty(),
        payment_integration: PaymentIntegration.getEmpty(),
    }
}

export function tryParse(x: any): T | null {
    if (x) {
        const created_at = Common.parseServerDate(x.created_at)
        const country = Country.tryParse(x.country)
        const currency = Currency.tryParse(x.currency)
        const process_type = ProcessType.tryParse(x.process_type)
        const pricing_information = PricingInformation.tryParse(x.pricing_information)
        const address = Address.tryParse(x.address)
        const email_receivers = EmailReceiver.tryParseList(x.email_receivers)
        const users = ExternalUser.tryParseList(x.users)
        const cost_types = CostTypes.tryParse(x.cost_types)
        const accounting_data = AccountingData.tryParse(x.accounting_data)
        const invoicing_terms = InvoicingTerms.tryParse(x.invoicing_terms)

        return {
            ...x,
            created_at,
            country,
            currency,
            process_type,
            pricing_information,
            address,
            email_receivers,
            users,
            cost_types,
            accounting_data,
            invoicing_terms
        }
    }

    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "BusinessProcess")

export class ValidationProtocol {
    public get rules() {
        const self = this as any as T
        const locale = self.country.kind.toLowerCase()
        return {
            description: [
                (v: string) => Validation.hasMaxLen(50, v),
                (v: string) => Validation.isNotEmpty(v),
            ],
            code: [(v: string) => Validation.hasMaxLen(5, v)],
            range_of_numbers: [(v: number) => Validation.isMax(99999999, v, locale)],
            due_date: [(v: number) => Validation.isMax(90, v, locale)],
            payment_terms: [(v: number) => Validation.isMax(60, v, locale)],
            negotiated_payment_terms: [(v: number) => Validation.isMax(90, v, locale)]
        }
    }
}

export function addValidationProtocol(x: T | null): T & ValidationProtocol | null {
    if (x) {
        const result = Object.assign(new ValidationProtocol(), x)
        return result
    }
    return null
}
