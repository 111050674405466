
import Vue from "vue"

import * as common from "@/components/Util/Common"
import * as Locale from '@/lib/Util/Types/Common/Locale';
import * as PerInvoiceType from "@/lib/Util/Types/Masterdata/BusinessProcess/PerInvoiceType"
import * as Shared from "../Shared"

import store from "@/store/store"

export default Vue.extend({
    name: "EditDialogPerVolume",
    props: {
        item: {
            type: Object as () => Shared.EditPrice<PerInvoiceType.T>,
        } as Vue.PropOptions<Shared.EditPrice<PerInvoiceType.T>>,
        valid: {
            type: Boolean
        }
    },
    data() {
        const locale = Locale.getLocale(store.state.client.sub_item.value?.country.kind ?? "DE")
        return {
            selling_price: common.format(this.item.value.selling_price, locale.country),
            currency_format: {
                locale: locale.language,
                currency: locale.currency,
                distractionFree: true,
                allowNegative: false,
            },
            isValid: this.valid
        }
    },
    watch: {
        selling_price() {
            this.item.value.selling_price = common.getRefValue(this.$refs.selling_price)
        },
    },
});
