
import Vue from "vue"
import fp from "lodash/fp"

import * as Email from "../../lib/Util/Types/Common/Email"
import { getServer } from "../../lib/Util/environment"
import CardTitle from "./CardTitle.vue"

import * as SuperAgent from "superagent"

export default Vue.extend({
    name: "RequestPinForm",
    components: {
        CardTitle,
    },
    props: {
        item: {
            type: String,
        },
    },
    data: function () {
        return {
            usedItem: this.item,
            overlay: false,
            emailRules: [
                function checkLen(v?: string): boolean | string {
                    return (v?.length ?? 0) !== 0 || "Email must not be empty"
                },
                function isEmail(v?: string): boolean | string {
                    return Email.validate(v) || "Please enter valid email"
                },
            ],
        }
    },
    computed: {
        isEmailValid: function (): boolean {
            const v = this.usedItem
            return fp.every(
                fp.identity,
                this.emailRules.map((x) => x(v)).map((x) => (typeof x === "string" ? false : x))
            )
        },
    },
    methods: {
        requestPin: function () {
            const req = { UserName: this.usedItem }
            this.overlay = true
            SuperAgent.post(getServer() + "/sendPin")
                .send(req)
                .then((_) => {
                    this.overlay = false
                    this.$emit("success-request-pin", this.usedItem)
                })
                .catch((_) => {
                    this.overlay = false
                    this.$emit("error-request-pin", this.usedItem)
                })
        },
    },
})
