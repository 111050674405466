import * as Types from "@/lib/Util/Types/Types"
import * as Common from "@/lib/Util/Common"

export const SourceValues = {
    "preparation": "Prepare",
    "verification": "Verify",
    "approval": "Approve",
    "payment": "Pay",
    "cancelation": "Cancel",
    "finish": "Finish",
    "archive": "Archive",
    "global_search": "GlobalSearch"
} as const

export type Source = typeof SourceValues
export type Target = Types.UniqueReverser<Source>

export type SourceKind = keyof Source
export type TargetKind = keyof Target
export type ShortSourceKind = Exclude<SourceKind, "global_search">
export type ShortTargetKind = Exclude<TargetKind, "GlobalSearch">

export type SourceKindFull = Types.KindedValuedUnionCase<SourceKind>
export type TargetKindFull = Types.KindedValuedUnionCase<TargetKind>

export const LOOKUP = Types.buildLookup(SourceValues)

export function isSourceKind(x: any): x is SourceKind {
    return ((LOOKUP.source as any)[x] ?? false) == false ? false : true
}

export function isTargetKind(x: any): x is TargetKind {
    //fix for finished -> finish
    let index = x
    if (index == "finished") {
        index = "finish"
    }
    return ((LOOKUP.target as any)[index] ?? false) == false ? false : true
}

export function toTargetKindString(x: string): string {
    let result = Common.capitalize(x)
    //fix Server Bug
    if (result === "Finished") {
        result = "Finish"
    }
    return result as TargetKind
}

export function getEmpty(): TargetKindFull {
    return { kind: "Archive", Archive: true, value: "Archive" }
}

export const tryParse = Types.buildTryParse<TargetKindFull, true>(LOOKUP.target, "kind", true, "Target")

