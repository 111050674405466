
import Vue from "vue";

import CardTitle from "./CardTitle.vue";

export default Vue.extend({
  name: "RequestPinForm",
  components: {
    CardTitle,
  },
});
