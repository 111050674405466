import MainView from "./Main.vue"
import ListMainView from "./List/Main.vue"


const router = {
    path: 'services_sold',
    component: MainView,
    children: [
        {
            path: 'list/:timespan?',
            component: ListMainView
        },

    ]
}

export default router