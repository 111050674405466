import * as lodash from "lodash/fp"
import { defineModule, localActionContext } from "direct-vuex"

import * as Environment from "@/lib/Util/environment"
import * as Advice from "@/lib/Util/Types/Remittance/Advice"
import * as REST from "@/lib/Util/REST"
import { RootState } from "@/store/Shared"

export type UploadAdviceAttachmentRequest = {
    data: string
    id: string
}

const state = {
    value: null as Advice.T | null,
    message: REST.getEmpty(),
}

const mutations = {
    updateValue(state: State, v: Advice.T | null) {
        state.value = v
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    },
}

async function saving(context: any, url: string, x: Advice.T) {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const { commit, state } = actionCtx(context)
    const rootState = context.rootState as RootState
    try {
        const loading_msg = {
            ...state.message,
            loading: true,
        }
        commit.updateMsg(loading_msg)

        const req = {
            token: rootState.authentication?.token ?? "NO TOKEN",
            url,
            payload: x,
            transformer: Advice.tryParse,
        }
        const result = await REST.POST(state.message, req)
        if (result.value) {
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text: `Successfully saved Advice: ${x.storage?.short_id ?? "NO SHORT ID"}`,
            }
            commit.updateValue(result.value)
            commit.updateMsg(msg)
        } else {
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text: `Error: ${result.msg.text}`,
            }
            commit.updateMsg(msg)
        }
    } catch (ex) {
        const msg = {
            ...lodash.cloneDeep(state.message),
            text: JSON.stringify(ex),
        }
        commit.updateMsg(msg)
    }
}

async function upload(context: any, x: UploadAdviceAttachmentRequest) {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const { commit, state } = actionCtx(context)
    const rootState = context.rootState as RootState
    try {
        const loading_msg = {
            ...state.message,
            loading: true,
        }
        commit.updateMsg(loading_msg)

        const req = {
            token: rootState.authentication?.token ?? "NO TOKEN",
            url: "/remittance/upload",
            payload: x,
            transformer: Advice.tryParse,
        }
        console.log("100 UPLOAD!")
        const result = await REST.POST(state.message, req)
        if (result.value) {
            const text = `Successfully uploaded Advice file for: ${result.value?.id ?? "NO SHORT ID"}`
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text,
            }
            commit.updateValue(result.value)
            commit.updateMsg(msg)
        } else {
            commit.updateMsg(result.msg)
        }
    } catch (ex) {
        const msg = {
            ...lodash.cloneDeep(state.message),
            text: JSON.stringify(ex),
        }
        commit.updateMsg(msg)
    }
}

async function loading(context: any, url: string) {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const { commit, state } = actionCtx(context)
    const rootState = context.rootState as RootState
    try {
        const loading_msg = {
            ...state.message,
            loading: true,
        }
        commit.updateMsg(loading_msg)
        const req = {
            token: rootState.authentication?.token ?? "NO TOKEN",
            url,
            payload: {},
            transformer: Advice.tryParse,
        }
        const result = await REST.GET(state.message, req)
        const msg = {
            ...lodash.cloneDeep(result.msg),
            text: `Successfully loaded Advice ${result.value?.storage?.short_id}`,
        }
        commit.updateValue(result.value)
        commit.updateMsg(msg)
    } catch (ex) {
        const msg = {
            ...lodash.cloneDeep(state.message),
            text: JSON.stringify(ex),
        }
        commit.updateMsg(msg)
    }
}

const actions = {
    async LOAD(context: any, x: string) {
        await loading(context, `/remittance/${x}`)
    },
    async SAVE(context: any, x: Advice.T) {
        await saving(context, `/remittance/update`, x)
        return
    },
    async CREATE(context: any) {
        const x = Advice.getEmpty()
        if (Environment.getServer() === Environment.URLS.SOLUTIONS) {
            x.client_id = "b8b1064a-9ca1-49df-b859-1a2628825378"
        } else {
            x.client_id = "91663651-e0a1-4096-aab7-8773edd0d339"
        }
        await saving(context, `/remittance/create`, x)
        return
    },
    async CLOSE(context: any, x: Advice.T) {
        await saving(context, `/remittance/close`, x)
        return
    },
    async CANCEL(context: any, x: Advice.T) {
        await saving(context, `/remittance/cancel`, x)
        return
    },
    async TOGGLE(context: any, x: string) {
        loading(context, `/compragauser/${x}/activate`)
    },
    async UPLOAD(context: any, x: UploadAdviceAttachmentRequest) {
        await upload(context, x)
    },
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    actions,
}

export type Mutations = typeof mutations
export type State = typeof state
export type Actions = typeof actions

export const module = defineModule(internal)

export default module

const actionCtx = (context: any) => localActionContext(context, module)
