import * as Types from "@/lib/Util/Types/Types"
import * as Common from "@/lib/Util/Common"

export type Record = {
    NotPaid: true;
    Single: Date;
    Split: [Date | null, Date | null];
}

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']
export const KEYS: Kind[] = ["NotPaid", "Single", "Split"]

export function tryParse(x?: any): T | null {
    if (x) {
        if (x.NotPaid) {
            return {
                kind: "NotPaid",
                NotPaid: true
            }
        }

        else if (x.Single) {
            const result = Common.parseServerDate(x.Single)
            if (result) {
                return {
                    kind: "Single",
                    Single: result
                }
            }
        }
        else if (x.Split) {
            const first = Common.parseServerDate(x.Split[0])
            const second = Common.parseServerDate(x.Split[1])
            return {
                kind: "Split",
                Split: [first, second]
            }
        }
    }
    console.error("PaidInfo.tryParse", x)
    return null
}
