import * as Types from '@/lib/Util/Types/Types'
import * as Protocols from "@/lib/Util/Types/Protocols"

export type Record = {
    M: true;
    F: true;
    D: true;
}

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const VALUES: Record = {
    M: true,
    F: true,
    D: true,
}

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getEmpty(): T {
    return {
        kind: "D",
        D: true
    }
}

export const tryParse = Types.buildTryParse<T, true>(VALUES, "kind", true, "Gender")
export const parseDefault = Types.buildParseDefault(tryParse, getEmpty)

export const getByKind = (x: Kind): T => Types.makeSingleDUFromString(x)
export const tryGetByKind = (x: string): T | null => Types.tryMakeSingleDUFromString(x, KEYS)
export const getByKindDefault = (x: string): T => tryGetByKind(x) ?? getEmpty()

export class SearcheableProtocol implements Protocols.Searcheable {
    search(x: string): boolean {
        const self = this as any as T
        return self.kind.includes(x)
    }
}