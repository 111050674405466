import * as IncomingPaymentInfo from "./IncomingPaymentInfo"
import { RecToDUWithValueOfKindName, getKindNames } from '../Types'

export type Record = {
    Open: null;
    PartiallyOpen: IncomingPaymentInfo.T[];
    PartiallyClosed: IncomingPaymentInfo.T[];
    Closed: IncomingPaymentInfo.T[];
}

export type T = RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export function getEmpty(): T {
    return { kind: 'Open' }
}

export const VALUES: Record = {
    Open: null,
    PartiallyOpen: [IncomingPaymentInfo.getEmpty()],
    PartiallyClosed: [IncomingPaymentInfo.getEmpty()],
    Closed: [IncomingPaymentInfo.getEmpty()]
}

export const KEYS: Kind[] = getKindNames<Record, Kind>(VALUES)

export function tryParse(x: any): T | null {
    const x_keys = Object.getOwnPropertyNames(x)
    const xs =
        KEYS
            .map((x) => x_keys.includes(x) ? x : null)
            .filter((x) => x !== null)
    if (xs.length == 1) {
        const key = xs[0] as Kind
        const result: { [k: string]: any } = {};
        result['kind'] = key
        if (key !== "Open") {
            result[key] = IncomingPaymentInfo.tryParseList(x[key])
        }
        else {
            result[key] = true
        }
        console.log("InvoiceState.tryParse", result)
        return result as T
    }

    return null
}

export function extract(x: T): IncomingPaymentInfo.T[] | null {
    switch (x.kind) {
        case "Closed": return x.Closed;
        case "Open": return null;
        case "PartiallyClosed": return x.PartiallyClosed;
        case "PartiallyOpen": return x.PartiallyOpen;
    }
}