
import Vue from "vue";

import * as common from "@/components/Util/Common"

import * as EmailReceiver from "@/lib/Util/Types/Masterdata/BusinessProcess/EmailReceiver"

import EditTable from "@/components/Util/EditTable.vue"

import * as Shared from "./Shared"
import EditDialog from "./EditDialog.vue"

export default Vue.extend({
    name: "EmailReceiversView",
    components: {
        EditTable,
        EditDialog,
    },
    props: {
        items: {
            type: Array,
        } as Vue.PropOptions<EmailReceiver.T[]>,
    },
    data() {
        return {
            headers: Shared.HEADERS,
            addRow: EmailReceiver.getEmpty,
            convertRow: common.toEditWrapper,
            convertRowList: common.toWrapperList,
            sort: EmailReceiver.sort
        }
    },
    methods: {
        updateList(xs: any[]) {
            console.log("EMAILRECEIVER,UPDATELIST", xs)
            this.$emit("update:items", xs)
        },
    }
});
