import { RecToDUWithValueOfKindName, getKindNames, buildTryParse } from '@/lib/Util/Types/Types'

export type Record = {
    over_due: true;
    over_due_older_than_30: true;
    over_due_from_15_to_30_ago: true;
    over_due_from_8_to_14_ago: true;
    over_due_from_1_to_7_ago: true;
    due_today: true;
    due_next_7: true;
    due_next: true;
}

export type T = RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const VALUES: Record = {
    over_due: true,
    over_due_older_than_30: true,
    over_due_from_15_to_30_ago: true,
    over_due_from_8_to_14_ago: true,
    over_due_from_1_to_7_ago: true,
    due_today: true,
    due_next_7: true,
    due_next: true
}

export const KEYS: Kind[] = getKindNames<Record, Kind>(VALUES)