
import Vue from "vue"
import * as lodash from "lodash/fp"

import * as Country from '@/lib/Util/Types/Common/Country';
import * as Address from "@/lib/Util/Types/Common/Address"

import TooltipIcon from '@/components/Util/TooltipIcon.vue';

export default Vue.extend({
    components: { TooltipIcon },
    name: "AddressView",
    props: {
        item: {
            type: Object as () => Address.T | null,
        } as Vue.PropOptions<Address.T | null>,
    },
    data() {
        return {
            available_countries: Country.KEYS,
            isValid: true,
            loaded: lodash.cloneDeep(this.item),
            rules: [
                (v: string) => {
                    return v.length > 0 || "Field must not be empty"
                }
            ]
        }
    },
    watch: {
        item: {
            handler(nv: Address.T | null, ov: Address.T | null) {
                this.loaded = lodash.cloneDeep(this.item)
            }
        },
        loaded: {
            handler(nv: Address.T | null, ov: Address.T | null) {
                console.log("100 Address.vue.watch.loaded", nv, ov)
                if (!lodash.isEqual(this.loaded, this.item)) {
                    console.log("200 Address.vue.watch.loaded", nv, ov)
                    this.$emit("update:item", this.loaded)
                }
            },
            deep: true
        },
    },
    computed: {
        used_country: {
            get: function (): Country.Kind {
                return this.loaded?.country.kind ?? "DE";
            },
            set: function (v: Country.Kind) {
                if (this.loaded) {
                    this.loaded.country = Country.getByKind(v);
                }
            }
        },
    },
    methods: {
        addAddress() {
            this.$emit("update:item", Address.getEmpty())
        },
        removeAddress() {
            this.$emit("update:item", null)
        }
    }
});
