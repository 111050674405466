import * as Types from "@/lib/Util/Types/Types"
import * as Common from "@/lib/Util/Common"

export const VALUES = {
    Active: true,
    Deactive: new Date(),
    Pending: new Date(),
    Disabled: new Date(),
}

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getEmpty(): T {
    return { kind: "Active", Active: true }
}

export function make(x: Kind): T {
    switch (x) {
        case "Active": return { kind: "Active", Active: true }
        default: {
            const result: { [k: string]: any } = {};
            result["kind"] = x
            result[x] = new Date()
            return result as T
        }
    }
}

export function tryParse(x: any | null): T | null {
    if (x) {
        if (x.Active) {
            return {
                kind: "Active",
                Active: true
            }
        }
        else if (x.Deactive && Common.parseServerDate(x.Deactive)) {
            return {
                kind: "Deactive",
                Deactive: Common.parseServerDate(x.Deactive) as Date
            }
        }
        if (x.Pending && Common.parseServerDate(x.Pending)) {
            return {
                kind: "Pending",
                Pending: Common.parseServerDate(x.Pending) as Date
            }
        }
        if (x.Disabled && Common.parseServerDate(x.Disabled)) {
            return {
                kind: "Disabled",
                Disabled: Common.parseServerDate(x.Disabled) as Date
            }
        }
        console.error("ActivationStatus.tryParse", x)
    }
    console.error("ActivationStatus.tryParse", x)
    return null
}