
import Vue from "vue"
import { Component, Watch } from "vue-property-decorator"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"

import * as Shared from "./Shared"

import store from "@/store/store"

import * as Head from "@/lib/Util/Types/Workflow/SearchListItem/Head"

import * as EmailKind from "@/lib/Util/Types/Biz/EmailKind"
import * as EmailTarget from "@/lib/Util/Types/Biz/EmailTarget"


Component.registerHooks(["beforeRouteEnter"])

@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
    },
})
export default class MasterdataSupplierSingleMainView extends Vue {
    headers = Shared.HEADERS
    dashboard_headers = Shared.DASHBOARD_HEADERS
    filter = {}

    async beforeRouteEnter(to: any, from: any, next: any) {
        if (to.params.id) {
            store.dispatch.supplier.single.LOAD(to.params.id)
            next()
        } else {
            console.error("Could not enter Route", to, from)
        }
    }

    get loaded() {
        const loaded = store.state.supplier.single.value
        if (loaded) {
            this.filter = {
                supplier_account: loaded.account.account_id,
                supplier_name: loaded.name.substring(0, 1),
            }
        }
        return store.state.supplier.single.value
    }

    get msg() {
        return store.state.supplier.single.message
    }

    get workflows_msg() {
        return store.state.workflow.list.search.message
    }

    get items() {
        console.log("ITEMS")
        return store.state.workflow.list.search.value
    }

    get dashboard() {
        const list = store.state.workflow.list.search.value
        const result = Shared.mkDashboard(list)
        console.log("DASHBOARD", result, list)
        return result
    }

    @Watch("filter")
    onPropertyChanged(ov: any) {
        console.log("WATCH FILTER")
        store.dispatch.workflow.list.search.LOAD(this.filter)
    }

    area(x: Head.T) {
        return x.state.work_state.kind.toLowerCase() ?? "prepare"
    }

    step_state(x: Head.T) {
        return x.state.step_state?.kind ?? null
    }

    edit_state(x: Head.T) {
        return x.state.edit_state?.kind ?? null
    }

    workflow_state(x: Head.T) {
        return x.state.work_state?.kind ?? null
    }

    extractEmail(x: EmailKind.Kind) {
        const found = EmailTarget.search(this.loaded?.emails ?? [], x)
        return found?.email.Value ?? ""
    }

    get used_info_email() {
        return this.extractEmail("Info")
    }

    get used_sales_email() {
        return this.extractEmail("Sales")
    }

    get used_accounting_email() {
        return this.extractEmail("Accounting")
    }
}
