import Vue from 'vue';
import * as lodash from 'lodash/fp';
import { defineModule, localActionContext } from "direct-vuex"

import * as ReportFormat from "@/lib/Util/Types/Biz/ReportFormat"
import * as TemplateType from "@/lib/Util/Types/Biz/TemplateType"
import * as Common from "@/lib/Util/Common"
import * as REST from '@/lib/Util/REST'

import * as Invoice from "@/lib/Util/Types/Invoice/Invoice";

import * as Filter from './Filter'

import { RootState } from '@/store/Shared'

export type Document = {
    filename: string;
    data: Blob | string;
    format: ReportFormat.Kind;
}

const state = {
    value: [] as Invoice.T[],
    filter: Filter.getEmpty(),
    message: REST.getEmpty(),
    document: null as Document | null
}

const mutations = {
    updateValue(state: State, v: Invoice.T[] | null) {
        state.value = v ?? []
    },
    updateListWithItem(state: State, v: Invoice.T | null) {
        if (v) {
            const index = state.value.findIndex((x) => v.id === x.id);
            if (index >= 0) {
                Vue.set(state.value, index, v);
            }
            else {
                state.value.push(v)
            }
        }
    },
    updateFilter(state: State, v: Filter.T) {
        state.filter = v
    },
    clearFilter(state: State) {
        state.filter = Filter.getEmpty()
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    },
    updateDocument(state: State, v: Document | null) {
        state.document = v
    }
}

const getters = {
    avaliableClients: (state: State) => { return Common.getUnique((x: Invoice.T) => x.data.name, state.value).sort() },
    avaliableProcesses: (state: State) => {
        return Common.getUnique((x: Invoice.T) => x.data.process_des, state.value).sort();
    },
}

export function load(payload: Filter.Query, token?: string, msg?: REST.RestMessage) {
    const req = {
        token: token ?? "NO TOKEN",
        url: "/invoice",
        payload,
        transformer: Invoice.tryParseList
    };
    return REST.GET(msg ?? REST.getEmpty(), req);
}

const actions = {
    async LOAD(context: any, x: Filter.Query) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context)
        const rootState = context.rootState as RootState
        try {
            const loading_msg = {
                ...state.message,
                active: false,
                loading: true
            }
            commit.updateValue([])
            commit.updateMsg(loading_msg)
            const result = await load(x, rootState.authentication?.token, state.message)
            console.log("LOAD INVOICES", result)
            //todo: this is ugly! urgghh! remove it!
            if (result.msg.success) {
                result.msg.text = "Successfully loaded invoice list"
            }
            commit.updateValue(result.value)
            commit.updateMsg(result.msg)
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg(msg)
        }
    },
    async sendEmail(context: any, x: string) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context)
        const rootState = context.rootState as RootState
        try {
            const loading_msg = {
                ...state.message,
                loading: true
            }
            commit.updateMsg(loading_msg)
            const req = {
                token: rootState.authentication?.token ?? "NO TOKEN",
                url: "/email/invoice/send",
                payload: { ids: [x] },
                transformer: Invoice.tryParse
            };
            const result = await REST.GET(state.message, req);
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text: `Successfully sent mail for ${result.value?.terse_id}`
            }
            commit.updateListWithItem(result.value)
            commit.updateMsg(msg)
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg(msg)
        }
    },
    async loadDocument(context: any, { x, format }: { x: Invoice.T; format: ReportFormat.Kind }) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context)
        const rootState = context.rootState as RootState
        try {
            const loading_msg = {
                ...state.message,
                loading: true
            }
            commit.updateMsg(loading_msg)
            const tt: TemplateType.Kind = x.line_items.length === 1 ? "SingleInvoice" : "BatchInvoice";
            const payload = {
                terse_id: x.terse_id,
                country: x.data.bp_country.kind,
                template_type: tt,
                report_format: format,
                batch_output_type: "Full", //TODO: Change this. Using hard-coded version.
            };
            const transformer = (v: string): Document => {
                if (format === "CSV") {
                    return {
                        filename: x.terse_id + "." + format.toLowerCase(),
                        data: v,
                        format
                    }
                }
                else {
                    return {
                        filename: x.terse_id + "." + format.toLowerCase(),
                        data: Common.convertBase64StringToBlob(v),
                        format
                    }
                }
            }
            const req = {
                token: rootState.authentication?.token ?? "NO TOKEN",
                url: "/invoice/generate",
                payload: payload,
                transformer: transformer
            };
            const result = await REST.GET(state.message, req);
            console.log("LOAD DOCUMENT", result)
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text: `Successfully downloaded ${x?.terse_id}`
            }
            commit.updateDocument(result.value)
            commit.updateMsg(msg)
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg(msg)
        }
    }
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    getters,
    actions
}

export type Mutations = typeof mutations
export type State = typeof state
export type Getters = typeof getters
export type Actions = typeof actions

export const module = defineModule(internal)

export default module

const actionCtx = (context: any) => localActionContext(context, module)
