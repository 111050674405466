import * as lodash from "lodash/fp"
import { defineModule, localActionContext, localGetterContext } from "direct-vuex"

import * as IntervalRequest from "@/store/IntervalRequest"

import * as Timespan from "@/lib/Util/Types/Common/Timespan"
import * as SupplierPayments from "@/lib/Util/Types/SupplierPayments/SupplierPayments"

import * as REST from "@/lib/Util/REST"
import { RootState } from "@/store/Shared"

const state = {
    value: [] as SupplierPayments.T[],
    filter: "Last 7d" as Timespan.Kind,
    message: REST.getEmpty(),
}

const mutations = {
    updateValue(state: State, model: SupplierPayments.T[] | null) {
        const values = model ?? []
        state.value = values
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    },
    updateFilter(stare: State, v: Timespan.Kind) {
        state.filter = v
    },
}

export function migrate(token?: string) {
    const req = {
        token: token ?? "NO TOKEN",
        url: "/supplier_payments/migrate",
        payload: {},
        transformer: function(v: any) {
            console.log("TRANSFORM:", v)
            return null
        },
    }
    return REST.GET(state.message, req)
}

const getters = {}

const actions = {
    async LOAD(context: any, filter: IntervalRequest.CreatedDate) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context)
        const rootState = context.rootState as RootState
        try {
            const loading_msg = {
                ...state.message,
                loading: true,
            }
            commit.updateMsg(loading_msg)
            commit.updateValue([])
            const req = {
                token: rootState.authentication?.token ?? "NO TOKEN",
                url: "/supplier_payments",
                payload: filter,
                transformer: SupplierPayments.tryParseList,
            }
            const result = await REST.GET(state.message, req)
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text: "Successfully loaded supplier payments list",
                timeout: 5000,
            }
            commit.updateValue(result.value)
            commit.updateMsg(msg)
        } catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex),
            }
            commit.updateMsg(msg)
        }
    },
    async UPDATEFILTER(context: any, v: Timespan.Kind) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state, dispatch } = actionCtx(context)
        if (state.filter !== v) {
            const req = IntervalRequest.mkCreatedDate(v)
            dispatch.LOAD(req)
        }
        commit.updateFilter(v)
    },
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    getters,
    actions,
}

export type Mutations = typeof mutations
export type State = typeof state
export type Getters = typeof getters
export type Actions = typeof actions

export const module = defineModule(internal)
export type Module = typeof module
export default module

const actionCtx = (context: any) => localActionContext(context, module)
