import * as lodash from 'lodash/fp';
import { defineModule, localActionContext } from "direct-vuex"

import * as Supplier from "@/lib/Util/Types/Supplier/Supplier"
import * as REST from '@/lib/Util/REST'
import { RootState } from '@/store/Shared'

const state = {
    value: null as Supplier.T | null,
    message: REST.getEmpty(),
    timestamp: new Date()
}

const mutations = {
    updateValue(state: State, v: Supplier.T | null) {
        state.value = v;
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    },
}


export function migrate(token?: string) {
    const req = {
        token: token ?? "NO TOKEN",
        url: "/suppliers/migrate",
        payload: {},
        transformer: function (v: any) { console.log("TRANSFORM:", v); return null }
    };
    return REST.GET(state.message, req);
}

async function loading(context: any, x: string) {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const { commit, state } = actionCtx(context)
    const rootState = context.rootState as RootState
    try {
        const loading_msg = {
            ...state.message,
            loading: true
        }
        commit.updateMsg(loading_msg)
        const req = {
            token: rootState.authentication?.token ?? "NO TOKEN",
            url: `/suppliers/iban/${x}`,
            payload: {},
            transformer: Supplier.tryParseList
        };
        const result = await REST.GET(state.message, req);
        console.log("100 LOADED SUPPLIER", result)
        const msg = {
            ...lodash.cloneDeep(result.msg),
            text: `Successfully loaded supplier ${result.value?.[0].short_id}`
        }
        if (result.value?.[0]) {
            console.log("200 LOADED SUPPLIER", result)

            commit.updateValue(result.value?.[0])
            commit.updateMsg(msg)
        }
    }
    catch (ex) {
        const msg = {
            ...lodash.cloneDeep(state.message),
            text: JSON.stringify(ex)
        }
        commit.updateMsg(msg)
    }
}

async function saving(context: any, x: { url: string; supplier: Supplier.T }) {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const { commit, state } = actionCtx(context)
    const rootState = context.rootState as RootState
    try {
        const loading_msg = {
            ...state.message,
            loading: true
        }
        commit.updateMsg(loading_msg)
        const req = {
            token: rootState.authentication?.token ?? "NO TOKEN",
            url: `/${x.url}/suppliers/create_update`,
            payload: x.supplier,
            transformer: Supplier.tryParse
        };
        const result = await REST.POST(state.message, req);
        console.log("100 LOADED SUPPLIER", result)
        const msg = {
            ...lodash.cloneDeep(result.msg),
            text: `Successfully loaded supplier ${result.value?.short_id}`
        }
        if (result.value) {
            console.log("200 LOADED SUPPLIER", result)

            commit.updateValue(result.value)
            commit.updateMsg(msg)
        }
    }
    catch (ex) {
        const msg = {
            ...lodash.cloneDeep(state.message),
            text: JSON.stringify(ex)
        }
        commit.updateMsg(msg)
    }
}

const actions = {
    async LOAD(context: any, x: string) {
        await loading(context, x)
    },
    async SAVE(context: any, x: { url: string; supplier: Supplier.T }) {
        await saving(context, x)
    }
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    actions,
}

export type Mutations = typeof mutations
export type State = typeof state
export type Actions = typeof actions

export const module = defineModule(internal)

export default module

const actionCtx = (context: any) => localActionContext(context, module)
