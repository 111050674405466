
import Vue from "vue"

import * as datefns from "date-fns"
import * as lodash from "lodash"
import { Component, Prop, Watch } from "vue-property-decorator"

import { ExportToCsv } from "export-to-csv"

import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import SearchForm from "@/components/Util/SearchForm/Main.vue"
import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"

import * as Common from "@/lib/Util/Common"
import * as Timespan from "@/lib/Util/Types/Common/Timespan"

import * as Protocols from "@/lib/Util/Types/Protocols"
import * as PaymentMode from "@/lib/Util/Types/Workflow/PaymentMode"
import * as PaymentState from "@/lib/Util/Types/Workflow/PaymentState"
import * as Item from "@/lib/Util/Types/Workflow/SearchListItem/Tail"
import * as Payment from "@/lib/Util/Types/Workflow/Summary/Payment"

import router from "@/router/router"
import * as SupplierPaymentsModule from "@/store/SupplierPayments/SingleModule"
import * as WarningStore from "@/store/Warning/store"
import store from "@/store/store"


import * as REST from "@/lib/Util/REST"
import * as Shared from "./Shared/Shared"

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"])
@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
        SearchForm,
    },
})
export default class TailListMainView extends Vue {
    @Prop({ default: "Pay" }) area!: Item.Kind
    @Prop() query!: any

    selected: Item.T[] = []
    headers = Shared.getHeaders(this.area)
    filter = Shared.parseFilter(this.query)
    lang = Shared.LANG
    initial_filter = null as null | Protocols.QueryExpression<typeof Shared.LANG>
    create_msg = REST.getEmpty()
    payment_states: PaymentState.T[] = []

    timespans = Timespan.filter([
        { prefix: "2024", period: "Q" },
        { prefix: "2024", period: "M" },
        { prefix: "2023", period: "Q" },
        { prefix: "2023", period: "M" },
        { prefix: "2022", period: "Q" },
        { prefix: "2022", period: "M" },
        { prefix: "2021", period: "Q" },
        { prefix: "2021", period: "M" },
        { prefix: "2020", period: "Q" },
        { prefix: "2019", period: "Q" },
    ])

    timespan: Timespan.Kind = this.initialTimespan

    get initialTimespan() {
        const default_kind = Timespan.getActiveKind("M") as Timespan.Kind
        return Timespan.parseKind(this.query.timespan, default_kind)
    }

    get items() {
        const items = store.getters.workflow.list.tail.items(this.area)
        const lis = Shared.toListItems(items, this.creditNotes, this.payment_states)
        const result = Shared.filter(this.filter, lis)
        return result
    }

    get creditNotes() {
        console.log("CHANGED CREDIT NOTES", store.state.workflow.list.search.credit_notes)
        return Shared.toCreditNotes(store.state.workflow.list.search.credit_notes)
    }

    get msg() {
        if (this.create_msg.active) {
            return this.create_msg
        } else {
            return store.state.workflow.list.tail.value[this.area].message
        }
    }

    get selected_amount() {
        if (this.selected.length >= 0) {
            let result = 0
            for (const item of this.selected) {
                result += item.payment_summary.gross.value
            }
            return result
        } else {
            return 0
        }
    }

    async mounted() {
        const filter = Shared.parseFilter(this.query)
        this.initial_filter = filter
        const pss = await WarningStore.loadOpenPaymentStates()
        if (pss) {
            this.payment_states = pss
        }
    }

    async beforeRouteEnter(to: any, from: any, next: any) {
        const regex = /^\/sections\/workflow\/(\w+)\/list$/
        const result = (to.path as string).match(regex)

        console.log("100 TailListMainView.beforeRouteEnter", to, from, result)
        if (result && result.length === 2) {
            const target = Common.capitalize(result[1])

            if (Item.isKind(target)) {
                console.log("LOADING TAIL")
                let query = {}
                if (target === "Finish") {
                    const default_kind = Timespan.getActiveKind("M") as Timespan.Kind
                    const timespan = Timespan.parseKind(to.query.timespan, default_kind)
                    const interval = Timespan.getInterval(timespan)
                    query = {
                        wa_date_from: Common.toServerDate(interval?.start as Date),
                        wa_date_to: Common.toServerDate(interval?.end as Date),
                    }
                }
                if (target === "Pay") {
                    store.dispatch.workflow.list.search.LOAD_CREDIT_NOTES()
                }
                const req = {
                    area: target,
                    query,
                }
                store.dispatch.workflow.list.tail.LOAD(req)
                next()
            } else {
                console.error("Could not enter Route", to, from, target)
            }
        } else {
            console.error("Could not enter Route", to, from)
        }
    }

    paidIcon(x: Shared.ListItem) {
        if (x.prepayment && x.prepayment.kind !== "Nothing") {
            return "mdi-cancel"
        }
        else {
            switch (x.paid_state) {
                case "Finished":
                    return "mdi-bank-transfer-out"
                case "Waiting":
                    return "mdi-timer-sand-empty"
                case "Overdue":
                    return "mdi-timer-sand-full"
                case "Urgent":
                    return "mdi-alert-circle"
                case "Alarm":
                    return "mdi-alarm-light"
                case "Empty":
                    return "mdi-cancel"
            }
        }
    }

    discountStateIcon(x: Shared.ListItem) {
        switch (x.discount_state) {
            case "Today":
                return "mdi-clock-time-twelfe"
            case "<= 3 Days":
                return "mdi-clock-time-nine"
            case "<= 5 Days":
                return "mdi-clock-time-seven"
            case "Future":
                return "mdi-clock-time-three"
        }
        return null
    }

    //needs extension as soon as Reminders are integrated into the systems
    paidIconColor(x: Shared.ListItem) {
        if (x.prepayment && x.prepayment.kind !== "Nothing") {
            if (x.payment_summary.supplier_payment.kind === "OnRequest") {
                return "red"
            }
            else {
                return "green"
            }
        }
        else {
            switch (x.paid_state) {
                case "Finished":
                    return "green"
                case "Waiting":
                    return "yellow"
                case "Overdue":
                    return "amber"
                case "Urgent":
                    return "orange"
                case "Alarm":
                    return "deep-orange"
                case "Empty":
                    return "light-green"
            }
        }
    }

    paidText(x: Shared.ListItem) {
        switch (x.payment_summary.supplier_payment.kind) {
            case "Finished": {
                const [_, logentry] = x.payment_summary.supplier_payment.Finished
                return Common.toShortDate(logentry.timestamp)
            }
        }
        return ""
    }

    paidClick(x: Shared.ListItem) {
        switch (x.payment_summary.supplier_payment.kind) {
            case "Finished": {
                return () => {
                    const sid = Payment.getSupplierPaymentId(x.payment_summary.supplier_payment) ?? "NO ID"
                    const path = `/sections/bank/supplier_payments/single/${sid}`
                    router.push({ path: path })
                }
            }
        }
        return () => {
            //
        }
    }

    invoiceGeneratedIcon(x: Item.T) {
        if (x.payment_summary.invoice.kind === "Empty") {
            return "mdi-cancel"
        }
        else if (x.payment_summary.invoice.kind === "Single" && x.payment_summary.invoice.Single.kind === "Finished") {
            return "mdi-file-check"
        }
        else {
            return "mdi-calendar-clock"
        }
    }

    moneyReceivedIcon(x: Item.T) {
        if (x.payment_summary.invoice.kind === "Empty") {
            return null
        }
        else if (x.payment_summary.paid.kind === "NotPaid") {
            return "mdi-exclamation-thick"
        }
        else {
            return "mdi-bank-transfer-in"
        }
    }

    paymentStateIcon(x: PaymentState.T) {
        const icons: Record<PaymentMode.Kind, string> = {
            PREPAYMENT: "mdi-cash-plus",
            AFTER_PAYMENT: "mdi-cash-clock",
            FASTTRACK: "mdi-fast-forward",
            REMINDER_1: "mdi-numeric-1-circle",
            REMINDER_2: "mdi-numeric-2-circle",
            REMINDER_3: "mdi-numeric-3-circle",
            COLLECTION_THREAT: "mdi-bomb",
            COLLECTION: "mdi-nuke",
            CLIENT_REQUEST: "mdi-chat-question",
            ON_HOLD: "mdi-lock",
        }
        if (x) {
            return icons[x.payment_mode.kind]
        }
        else {
            return ""
        }
    }

    download(x: any) {
        const s = datefns.format(new Date(), "dd.MM.yyyy-HHmmss")
        const filename = `${this.area.toLowerCase()}.${s}`
        const records = this.items.map(Shared.toCSVRecord)
        const options = {
            fieldSeparator: ";",
            quoteStrings: '"',
            decimalSeparator: ",",
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            filename: filename,
            useKeysAsHeaders: true,
        }

        const exporter = new ExportToCsv(options)
        exporter.generateCsv(records)
    }

    updateFilter(x: Protocols.QueryExpression<typeof Shared.LANG>) {
        this.filter = x
    }

    async generateSupplierPayment() {
        console.log("100 generateSupplierPayment", this.selected)
        const req: SupplierPaymentsModule.CreateRequest =
        {
            wf_id: this.selected.map((x) => x.id)
        }
        this.create_msg.active = true
        this.create_msg.loading = true
        const result = await SupplierPaymentsModule.CREATE(req, store.state.authentication?.token)
        router.push({ path: `/sections/bank/supplier_payments/single/${result.value?.id}` })
        this.create_msg = result.msg
    }

    async generateSupplierPaymentForPrepayments() {
        console.log("100 generateSupplierPaymentForPrepayments", this.selected)
        const splitter = (v: Item.T) => {
            if (v.prepayment.kind !== "Nothing") {
                return v.prepayment.value.account
            }
            else {
                return "ERROR"
            }
        }
        let filtered = this.items.filter(v => v.payment_summary.supplier_payment.kind === "OnRequest")
        const grouped = lodash.groupBy(filtered, splitter)
        const keys = lodash.keys(grouped)
        for (var key of keys) {
            let items = grouped[key]
            console.log("200 generateSupplierPaymentForPrepayments:", items)
            let req: SupplierPaymentsModule.CreateRequest =
            {
                account: key,
                wf_id: items.map(x => x.id)
            }
            console.log("210 generateSupplierPaymentForPrepayments:", req)
            this.create_msg.active = true
            this.create_msg.loading = true
            const result = await SupplierPaymentsModule.CREATE(req, store.state.authentication?.token)
            this.create_msg = result.msg
        }
        console.log("300 generateSupplierPaymentForPrepayments Finished")
        router.push({ path: `/sections/bank/supplier_payments/list/Last 7d` })
    }

    @Watch("timespan", { deep: true })
    onTimespanChanged(newval: Timespan.Kind) {
        console.log("LOADING TAIL")
        const interval = Timespan.getInterval(newval)

        const query = {
            wa_date_from: Common.toServerDate(interval?.start as Date),
            wa_date_to: Common.toServerDate(interval?.end as Date),
        }
        const req = {
            area: this.area,
            query,
        }
        store.dispatch.workflow.list.tail.LOAD(req)
    }

    get onlyPrepaidItems() {
        return this.items.every(v => v.prepayment && v.prepayment.kind !== "Nothing" && v.payment_summary.supplier_payment.kind === "OnRequest")
    }

}
