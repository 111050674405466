import * as Types from "@/lib/Util/Types/Types"
import * as Common from "@/lib/Util/Common"

import * as IncrementalCounter from "@/lib/Util/Types/Biz/IncrementalCounter"
import * as LogEntry from "@/lib/Util/Types/Workflow/LogEntry"

export type Record<T> = {
    Timed: Date;
    OnRequest: true;
    Immediate: true;
    Empty: true;
    Finished: [IncrementalCounter.T<T>, LogEntry.T];
}

export type T<S> = Types.RecToDUWithValueOfKindName<Record<S>>
export type Kind<S> = T<S>['kind']

export const KEYS: Kind<0>[] = ["Timed", "OnRequest", "Immediate", "Finished"]

export function tryParse<S>(x: any | undefined, f: (v: any) => S | null): T<S> | null {
    if (x) {
        if (x.Timed) {
            const result = Common.parseServerDate(x.Timed)
            if (result) {
                return {
                    kind: "Timed",
                    Timed: result
                }
            }
        }
        else if (x.OnRequest || x === "OnRequest") {
            return {
                kind: "OnRequest",
                OnRequest: true
            }
        }
        else if (x.Immediate || x === "Immediate") {
            return {
                kind: "Immediate",
                Immediate: true
            }
        }
        else if (x.Empty || x === "Empty") {
            return {
                kind: "Empty",
                Empty: true
            }
        }
        else if (x.Finished) {
            const counter = IncrementalCounter.tryParse(x.Finished[0], f) as IncrementalCounter.T<S> | null
            const logentry = LogEntry.tryParse(x.Finished[1])
            if (counter && logentry) {
                return {
                    kind: "Finished",
                    Finished: [counter, logentry]
                }
            }
        }
    }
    console.error("ProcessingState.tryParse", x)
    return null
}
