<template>
    <v-app id="appy" style="overflow-y: hidden">
        <div class="etabs-tabgroup">
            <div class="etabs-tabs"></div>
            <div class="etabs-buttons"></div>
        </div>
        <div class="etabs-views">
            <router-view></router-view>
        </div>
    </v-app>
</template>

<script lang="js">
import Vue from 'vue'

import VueCurrencyInput from 'vue-currency-input'
import VueMask from 'v-mask'

import * as Filters from "./Filters"
import store from '@/store/store'
import router from '@/router/router'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueCurrencyInput)
Vue.use(VueMask)
Vue.use(VueClipboard)

Vue.filter("kind", Filters.kind)
Vue.filter("format", Filters.format)
Vue.filter("truncate", Filters.truncate)

export default Vue.extend({
    name: "App",
    router: router,
    created() {
        if (!store.state.authentication) {
            router.push({ path: 'login' })
        }
    }
});
</script>

<style scoped>
</style>