import * as Protocols from "@/lib/Util/Types/Protocols"
import * as Supplier from "@/lib/Util/Types/Supplier/Supplier"

export const HEADERS = [
    {
        text: 'ShortID',
        value: 'short_id',
    },
    {
        text: 'Name',
        value: 'name',
    },
    {
        text: 'Account',
        value: 'account.account_id',
    },
    {
        text: 'Email',
        value: 'email.Value',
    },
    {
        text: 'Country',
        value: 'country.kind',
    },
    {
        text: 'City',
        value: 'city',
    },
    {
        text: 'Created At',
        value: 'created_at',
    },
    {
        text: 'Actions',
        value: 'actions',
    },
]

export function toListItems(xs: Supplier.T[]): (Supplier.T & Protocols.Searcheable)[] {
    return xs.map(x => Protocols.addSearcheable(x, Supplier.SearcheableProtocol)) as (Supplier.T & Protocols.Searcheable)[]
}