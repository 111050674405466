import * as Email from "@/lib/Util/Types/Common/Email"
import * as Gender from "@/lib/Util/Types/Common/Gender"

import * as ExternalUser from "@/lib/Util/Types/Masterdata/BusinessProcess/ExternalUser"
import * as ApprovalProcess from "@/lib/Util/Types/Masterdata/BusinessProcess/ApprovalProcess"

import * as Buyer from "@/lib/Util/Types/Masterdata/BusinessProcess/Buyer"
import * as ReportPeriod from "@/lib/Util/Types/Masterdata/BusinessProcess/ReportPeriod"

import * as common from "@/components/Util/Common"
import * as Common from "@/lib/Util/Common";

import { ExportToCsv } from "export-to-csv"
import { toInteger } from "lodash"

import store from "@/store/store"


export const HEADERS = [
    {
        text: 'Email',
        value: 'email.Value',
    },
    {
        text: 'Name',
        value: 'name',
    },
    {
        text: 'CPC-ID',
        value: 'cpc_id',
    },
    {
        text: 'Buyer',
        value: 'is_buyer',
    },
    {
        text: 'Limit Trigger',
        value: 'limit_trigger',
        align: "end"
    },
    {
        text: 'Transaction Limit',
        value: 'trx_limit_trigger',
        align: "end"
    },
    {
        text: 'Monthly Limit',
        value: 'monthly_limit_trigger',
        align: "end"
    },
    {
        text: 'Reports To',
        value: 'reports_to.Value',
    },
    {
        text: 'Status',
        value: 'activation_status',
    },
    {
        text: '',
        value: 'actions',
        align: "center"
    },
]

export const SIMPLE_HEADERS = [
    {
        text: 'Email',
        value: 'email.Email',
    },
    {
        text: 'Name',
        value: 'name',
    },
    {
        text: 'CPC-ID',
        value: 'cpc_id',
    },
    {
        text: 'Buyer',
        value: 'buyer',
    },
]

export class Protocols {
    get self() {
        return this as any as ExternalUser.T & Protocols
    }
    get is_buyer(): boolean {
        return this.self.buyer.kind === "Yes"
    }
    get is_approved_by() {
        switch (this.self.approval_process.kind) {
            case "MustBeApproved": return this.self.approval_process.value
            case "MustBeApprovedOnLimit": return this.self.approval_process.value[1]
            case "TwoSteps": return this.self.approval_process.value[0]
            case "NotAtAll": return undefined
        }
    }

    set is_approved_by(x: Email.Email | undefined) {
        if (x) {
            const req = {
                e1: x,
                limit: this.approval_limit,
                e2: this.limit_is_approved_by
            }
            this.self.approval_process = ApprovalProcess.create(req)
        }
        else {
            this.self.approval_process = ApprovalProcess.create()
        }
    }

    get limit_is_approved_by() {
        switch (this.self.approval_process.kind) {
            case "TwoSteps": return this.self.approval_process.value[2]
            default: return undefined
        }
    }

    set limit_is_approved_by(x: Email.Email | undefined) {
        if (this.is_approved_by) {
            const req = {
                e1: this.is_approved_by,
                limit: this.approval_limit,
                e2: x
            }
            this.self.approval_process = ApprovalProcess.create(req)
        }
        else {
            this.self.approval_process = ApprovalProcess.create()
        }
    }

    get approval_limit() {
        switch (this.self.approval_process.kind) {
            case "MustBeApprovedOnLimit": return this.self.approval_process.value[0]
            case "TwoSteps": return this.self.approval_process.value[1]
            default: return undefined
        }
    }

    set approval_limit(x: number | undefined) {
        if (this.is_approved_by) {
            const req = {
                e1: this.is_approved_by,
                limit: x,
                e2: this.limit_is_approved_by
            }
            this.self.approval_process = ApprovalProcess.create(req)
        }
        else {
            this.self.approval_process = ApprovalProcess.create()
        }
    }
    get activation_status() {
        switch (this.self.status.kind) {
            case "Active": return "Active"
            case "Deactive": return "Deactive"
            case "Disabled": return "Disabled"
            case "Pending": return "Pending"
        }
    }
}

export type ExtendendExternalUser = (ExternalUser.T & Protocols)
export type EditWrapper = common.EditWrapper<ExtendendExternalUser>

export function toProtocols(x: ExternalUser.T): ExtendendExternalUser {
    return Object.assign(new Protocols(), x)
}

export function toEditWrapper(x: ExternalUser.T, index: number, new_item = false): EditWrapper {
    const result = toProtocols(x)
    return common.toEditWrapper(result, index, new_item)
}

export function toWrapperList(xs: ExternalUser.T[]): EditWrapper[] {
    return xs.map((x, i) => toEditWrapper(x, i, false))
}

export function exportCSV(bp_sid: string, records: ExtendendExternalUser[]) {
    const filename = `${bp_sid}.external-users.${Common.toServerDate(new Date())}`
    const recs = ExternalUser.sort(records)

    const transform = (x: ExternalUser.T & Protocols) => {
        return {
            cpc_id: x.cpc_id,
            email: x.email.Value,
            gender: x.gender.kind,
            name: x.name,
            telefon: `'${x.telephone}`,
            buyer: x.buyer.kind,
            reports_to: x.reports_to?.Value ?? "",
            is_approved_by: x.is_approved_by?.Value ?? "",
            limit_is_approved_by: x.limit_is_approved_by?.Value ?? "",
            buyer_report: x.buyer_report.kind,
            line_manager_report: x.line_manager_report.kind,
            line_manager_reminder: x.line_manager_reminder.kind,
            trx_limit_trigger: x.trx_limit_trigger ?? "",
            monthly_limit_trigger: x.monthly_limit_trigger ?? "",
            limit_trigger_notification: x.limit_trigger_notification?.Value ?? "",
            first_reminder: x.reminders.first_reminder ?? "",
            second_reminder: x.reminders.second_reminder ?? "",
            involve_line_manager: x.reminders.involve_line_manager?.Value ?? ""
        }
    }

    const transformed = recs.map(transform)

    const options = {
        fieldSeparator: ";",
        quoteStrings: '"',
        decimalSeparator: ",",
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        filename: filename,
        useKeysAsHeaders: true,
    };

    const exporter = new ExportToCsv(options)

    exporter.generateCsv(transformed)
}

export async function importFromCSV(xs: string[][], bp_id: string) {
    const transform = (x: string[]): ExtendendExternalUser => {
        const init = ExternalUser.getEmpty()
        const result = toProtocols(init)
        result.cpc_id = x[0]
        result.email = Email.Email.parseDefault(x[1])
        result.gender = Gender.getByKindDefault(x[2])
        result.name = x[3]
        result.telephone = x[4].replaceAll("'", "")
        result.buyer = Buyer.getByKindDefault(x[5])
        result.reports_to = Email.Email.tryParse(x[6]) ?? undefined
        result.is_approved_by = Email.Email.tryParse(x[7]) ?? undefined
        result.limit_is_approved_by = Email.Email.tryParse(x[8]) ?? undefined
        result.buyer_report = ReportPeriod.getByKindDefault(x[9])
        result.line_manager_report = ReportPeriod.getByKindDefault(x[10])
        result.line_manager_reminder = Buyer.getByKindDefault(x[11])
        result.trx_limit_trigger = toInteger(x[12])
        result.monthly_limit_trigger = toInteger(x[13])
        result.limit_trigger_notification = Email.Email.tryParse(x[14]) ?? undefined
        result.reminders.first_reminder = toInteger(x[15])
        result.reminders.second_reminder = toInteger(x[16])
        result.reminders.involve_line_manager = Email.Email.tryParse(x[17]) ?? undefined
        return result
    }

    const transformed = xs.map(transform)

    for (const user of transformed) {
        await store.dispatch.external_user.single.SAVE({ user, bp_id })
        await Common.delay(500)
    }

    console.log("IMPORT CSV", transformed)

}