
import Vue from "vue"
import { Prop } from "vue-property-decorator"
import Component from "vue-class-component"

import * as lodash from "lodash"
import * as Protocols from "@/lib/Util/Types/Protocols"

import * as ExternalUser from "@/lib/Util/Types/Masterdata/BusinessProcess/ExternalUser"

import SearchForm from "@/components/Util/SearchForm/Main.vue"
import EditTable from "@/components/Util/EditTable.vue"

import * as Shared from "./Shared"
import EditDialog from "./EditDialog.vue"

const lang = ExternalUser.LANG

@Component({
    components: {
        EditDialog,
        EditTable,
        SearchForm,
    },
})
export default class ExternalUsersListView extends Vue {
    @Prop({ default: () => [] }) items!: ExternalUser.T[]
    @Prop({ default: "no_bp_sid" }) bp_sid!: string
    @Prop({ default: "no_bp_id" }) bp_id!: string

    headers = Shared.HEADERS
    addRow = ExternalUser.getEmpty
    convertRow = Shared.toEditWrapper
    convertRowList = Shared.toWrapperList
    sort = ExternalUser.sort
    filter = {} as Protocols.QueryExpression<typeof lang>
    lang = lang

    get filtered() {
        const filtered = ExternalUser.filter(this.filter, this.items)
        return filtered
    }

    get emails() {
        return this.items.map((x) => x.email.Value)
    }

    get cpc_ids() {
        return this.items.map((x) => x.cpc_id)
    }

    updateList(xs: any[]) {
        console.log("EXTERNALUSERS,UPDATELIST", xs)
        this.$emit("update-items", lodash.cloneDeep(xs))
    }

    updateFilter(x: Protocols.QueryExpression<typeof lang>) {
        this.filter = x
    }
}
