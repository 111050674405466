import * as lodash from "lodash"
import * as Types from "@/lib/Util/Types/Types"

import * as AbstractCostEntity from "@/lib/Util/Types/Masterdata/BusinessProcess/AbstractCostEntity"
import * as CostInfo from "./CostInfo"

export type T = Partial<AbstractCostEntity.T<CostInfo.T>>

export function getEmpty(): T {
    return {}
}

export function tryParse(x: any | null): T | null {
    return AbstractCostEntity.tryParse(x, CostInfo.tryParse) as T
}

export const tryParseList = Types.buildTryParseList(tryParse, "CostEntityLine")

export function isEmpty(x: T): boolean {
    return lodash.isEqual(x, {})
}