
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import * as LineItem from "@/lib/Util/Types/Workflow/Doc/LineItem"

@Component({
    components: {},
})
export default class AutoFeesPartView extends Vue {
    @Prop({ default: [] }) items!: LineItem.T[]
    headers = [
        {
            text: "Description",
            value: "description",
            sortable: false,
        },
        {
            text: "Count",
            value: "units_count",
            align: "end",
            sortable: false,
        },
        {
            text: "Measure",
            value: "units_measures.kind",
            sortable: false,
        },
        {
            text: `Amount`,
            sortable: false,
            value: "amount_value",
            align: "end",
        },
        {
            text: "VAT%",
            sortable: false,
            value: "vat_in",
            align: "end",
        },
        {
            text: "VAT",
            sortable: false,
            value: "vat_amount",
            align: "end",
        },
        {
            text: "Total",
            sortable: false,
            value: "total",
            align: "end",
        },
    ]
}
