import * as Advice from "@/lib/Util/Types/Remittance/Advice"

export const HEADERS = [
    {
        text: 'Short ID',
        value: 'storage.short_id',
    },
    {
        text: 'Document ID',
        value: 'document_id',
    },
    {
        text: 'Kind',
        value: 'kind.kind',
    },
    {
        text: 'State',
        value: 'state.kind',
    },
    {
        text: 'Client',
        value: 'client_id'
    },
    {
        text: 'amount',
        value: 'amount',
        align: 'end'
    },
    {
        text: 'Count',
        value: 'line_items.length',
        align: 'end'
    },
    {
        text: 'Advice Date',
        value: 'advice_date'
    },
    {
        text: 'Payment Date',
        value: 'payment_date',
    },
    {
        text: 'Closing Date',
        value: 'closing_date',
    },
    {
        text: 'Account',
        value: 'bank_account',
    },
    {
        text: '',
        value: 'actions',
    },
]



export function toCSVRecords(x: Advice.T) {
    return null
}

