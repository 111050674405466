
import Vue from "vue"
import { Prop, Watch } from "vue-property-decorator"
import Component from "vue-class-component"

import numeral from "numeral"

@Component({
    components: {},
})
export default class CurrencyInput extends Vue {
    @Prop({ default: null }) item!: number | null
    @Prop({ default: "no label" }) label!: string
    @Prop({ default: false }) outlined!: boolean
    @Prop({ default: false }) clearable!: boolean
    @Prop({ default: false }) hideDetails!: boolean | string
    @Prop({ default: false }) disabled!: boolean | string

    loaded = this.initialize()

    initialize(): string | null {
        if (this.item) {
            const result = numeral(this.item)
            return result.format("0,0.00")
        }
        return null
    }
    @Watch("item", { deep: true })
    onItemChanged(ov: number | null) {
        this.loaded = this.initialize()
    }

    get value() {
        if (this.loaded) {
            const result = numeral(this.loaded)
            return result.format("0,0.00")
        }
        return null
    }

    onBlur(x: any) {
        //to have the propper format at the end
        this.loaded = this.value
        const result = numeral(this.loaded)
        this.$emit("update:item", result.value())
    }

    onKey(e: KeyboardEvent) {
        const isRemoveKey = ["Backspace", "Delete"].includes(e.key)
        const isMinusKey = e.key === "-"
        const isNavigationKey = ["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDow", "Home", "End", "Tab"].includes(e.key)
        const isNumberKey = e.key >= "0" && e.key <= "9"
        const isGrouping = e.key === "." || e.key === ","
        if (!isNavigationKey && !isNumberKey && !isGrouping && !isRemoveKey && !isMinusKey) {
            e.preventDefault()
        }
    }
}
