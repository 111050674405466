
import Vue from "vue"
import * as lodash from "lodash/fp"
import * as PricingInformation from "@/lib/Util/Types/Masterdata/BusinessProcess/PricingInformation"
import EditDialogPerVolume from "./PerVolume.vue"
import EditDialogPerLineItem from "./PerLineItem.vue"
import EditDialogPerInvoiceType from "./PerInvoiceType.vue"

export default Vue.extend({
    name: "EditDialogMain",
    components: {
        EditDialogPerVolume,
        EditDialogPerLineItem,
        EditDialogPerInvoiceType
    },
    props: {
        item: {
            type: Object as () => PricingInformation.PricingType,
        } as Vue.PropOptions<PricingInformation.PricingType>,
        dialogSave: {
            type: Function
        },
        dialogCancel: {
            type: Function
        }
    },
    data() {
        console.log("EDITDIALOG", this.item)
        return {
            loaded: lodash.cloneDeep(this.item),
            valid: true
        }
    },
    watch: {
        item: {
            handler(nv: PricingInformation.PricingType, ov: PricingInformation.PricingType) {
                this.loaded = lodash.cloneDeep(nv)
            }
        },
    },
    methods: {
        save() {
            console.log("EDITDIALOG.SAVE", this.loaded, this.valid)
            this.dialogSave(this.loaded)
        },
        cancel() {
            console.log("EDITDIALOG.CANCEL", this.loaded, this.valid)
            this.dialogCancel()
        }
    }
});
