
import Vue from "vue"
import Component from "vue-class-component"
import * as Common from "@/lib/Util/Common"

import store from "@/store/store"
import router from "@/router/router"

@Component({})
export default class RemittanceMainView extends Vue {
    get item() {
        return store.state.supplier_payments.single.value
    }

    get listFilter() {
        return Common.toQueryString(store.state.remittance.list.filter)
    }

    beforeRouteEnter(to: any, from: any, next: any) {
        Common.rerouteRegex(router, to, from, next, "/sections/bank/remittance/", "list")
    }

    beforeRouteUpdate(to: any, from: any, next: any) {
        Common.rerouteRegex(router, to, from, next, "/sections/bank/remittance/", "list")
    }
}
