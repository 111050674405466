import * as datefns from 'date-fns'

export function kind(x: { kind: string }) {
    return x.kind
}

export function format(x: any, format?: any) {
    if (x && datefns.isDate(x)) {
        if (format && format.toUpperCase() === 'LONG') {
            return datefns.format(x, "yyyy-MM-dd")
        }
        else {
            return datefns.format(x, "yy-MM-dd")
        }
    }
    if (typeof x == "number") {
        return new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(x)
    }
    if (!x) {
        return ""
    }
    return `${x}`
}

export function truncate(y: string, x: number) {
    if (y && y.length > x) {
        return y.substr(0, x) + "..."
    }
    return y
}
