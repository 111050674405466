import * as Types from '@/lib/Util/Types/Types'
import * as VatRule from "@/lib/Util/Types/VAT/VatRule"

import * as Services from "./Services"
import * as AccountSimple from "./AccountSimple"

export type T = {
    services_received: Services.T;
    services_sold: Services.T;
    accounts_payable: AccountSimple.T;
    accounts_receivable: AccountSimple.T;
    internal_accounting: AccountSimple.T[];
}

export function getEmpty(x: VatRule.T): T {
    return {
        services_received: Services.getEmpty(x),
        services_sold: Services.getEmpty(x),
        accounts_payable: AccountSimple.getEmpty(),
        accounts_receivable: AccountSimple.getEmpty(),
        internal_accounting: []
    }
}

export function tryParse(x: any): T | null {
    if (x) {
        const services_received = Services.tryParse(x.services_received)
        const services_sold = Services.tryParse(x.services_sold)
        const result = {
            ...x,
            services_received,
            services_sold,
        }
        if (Types.checkNonNull(result)) {
            return result
        }
        console.error("100 AccountingDatatryParse", result, x)
        return null
    }
    console.error("200 AccountingDatatryParse", x)
    return null
}

export function updateVatRule(x: T, y: VatRule.T): T {
    return {
        ...x,
        services_received: Services.updateTaxRate(x.services_received, y),
        services_sold: Services.updateTaxRate(x.services_sold, y)
    }
}
