
export const HEADERS = [
    {
        text: 'Short-ID',
        value: 'short_id',
    },
    {
        text: 'Supplier',
        value: 'supplier.name',
    },
    {
        text: 'Currency',
        value: 'total.currency.kind',
    },
    {
        text: 'Total',
        value: 'incoming_position_data.value',
        align: 'end',
    },
    {
        text: 'Discounted',
        value: 'discounted.value',
        align: 'end',
    },
    {
        text: 'Created At',
        value: 'created_at',
    },
    {
        text: 'invoice_no',
        value: 'invoice_no',
    },
    {
        text: 'Account',
        value: 'supplier_account.account_id',
    },
    {
        text: 'Client',
        value: 'client',
    },
    {
        text: '',
        value: 'actions',
    },

]
