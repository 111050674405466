import * as Common from "@/lib/Util/Common"
import * as Types from "@/lib/Util/Types/Types"

import * as Email from "@/lib/Util//Types/Common/Email"

import * as ProcessType from "@/lib/Util/Types/Masterdata/BusinessProcess/ProcessType"

import * as State from "../State"

export type T = {
    id: string;
    short_id: string;
    secondary_id: string;

    state: State.T;
    process_type: ProcessType.T;

    last_edit_time: Date;
    last_edit_compragaUser: Email.Email;

    invoice_number: string;
}

export function tryParse(x?: any): T | null {
    if (x) {
        const state = State.tryParse(x.state)
        const process_type = ProcessType.tryParse(x.process_type)
        const last_edit_time = Common.parseServerDate(x.last_edit_time)
        const last_edit_compragaUser = Email.Email.tryParse(x.last_edit_compragaUser)
        const result = {
            ...x,
            state,
            process_type,
            last_edit_time,
            last_edit_compragaUser
        }

        if (Types.checkNonNull(result)) {
            return result
        }
    }
    console.error("SearchListItem/Base.tryParse", x)
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "SearchListItem/Base")