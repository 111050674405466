import * as Types from "@/lib/Util/Types/Types"

import * as common from "@/components/Util/Common"

import * as PerInvoiceType from "@/lib/Util/Types/Masterdata/BusinessProcess/PerInvoiceType"
import * as PerVolume from "@/lib/Util/Types/Masterdata/BusinessProcess/PerVolume"
import * as PerLineItem from "@/lib/Util/Types/Masterdata/BusinessProcess/PerLineItem"
import * as PricingInformation from "@/lib/Util/Types/Masterdata/BusinessProcess/PricingInformation"

export const PER_VOLUME_HEADERS = [
    {
        text: 'Limit',
        value: 'limit',
        align: "end"
    },
    {
        text: 'Selling Price',
        value: 'selling_price',
        align: "end"
    },
    {
        text: 'Buying price',
        value: 'buying_price',
        align: "end"
    },
    {
        text: '',
        value: 'actions',
        align: "center"
    },
]

export const PER_LINEITEM_HEADERS = [
    {
        text: 'Limit',
        value: 'limit',
        align: "end"
    },
    {
        text: 'Selling Price',
        value: 'selling_price',
        align: "end"
    },
    {
        text: '',
        value: 'actions',
        align: "center"
    },
]

export const PER_INVOICE_TYPE_HEADERS = [
    {
        text: 'Type',
        value: 'type.kind',
        align: "start"
    },
    {
        text: 'Selling Price',
        value: 'selling_price',
        align: "end"
    },
    {
        text: '',
        value: 'actions',
        align: "center"
    },
]

export type EditPrice<T> = common.EditWrapper<T> & {
    kind: PricingInformation.PricingTypeKind;
}

export type EditPriceType =
    | EditPrice<PerInvoiceType.T>
    | EditPrice<PerVolume.T>
    | EditPrice<PerLineItem.T>

export type EditPriceTypeList = Types.DU2Array<EditPriceType>

export function toEditPrice(x: PerInvoiceType.T, index: number, new_item?: boolean): EditPrice<PerInvoiceType.T>;
export function toEditPrice(x: PerVolume.T, index: number, new_item?: boolean): EditPrice<PerVolume.T>;
export function toEditPrice(x: PerLineItem.T, index: number, new_item?: boolean): EditPrice<PerLineItem.T>;
export function toEditPrice(x: PricingInformation.PricingType, index: number, new_item = false) {
    const kind = PricingInformation.getPricingTypeKind(x)
    return {
        value: x,
        kind,
        index,
        new_item
    }
}

export function toEditPriceList(x: PerInvoiceType.T[]): EditPrice<PerInvoiceType.T>[];
export function toEditPriceList(x: PerVolume.T[]): EditPrice<PerVolume.T>[];
export function toEditPriceList(x: PerLineItem.T[]): EditPrice<PerLineItem.T>[];
export function toEditPriceList(x: PricingInformation.PricingTypeList): EditPriceTypeList {
    const sorter = PricingInformation.sortPricingTypeList as
        (x: PricingInformation.PricingTypeList) => PricingInformation.PricingTypeList
    const sorted = sorter(x)
    const f = toEditPrice as (x: PricingInformation.PricingType, index: number, new_item?: boolean) => EditPriceType
    return (sorted as Array<PricingInformation.PricingType>).map((v, i) => f(v, i, false)) as EditPriceTypeList
}
