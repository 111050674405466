import * as Types from "@/lib/Util/Types/Types"
import * as datefns from 'date-fns'
import * as Common from "@/lib/Util/Common"

import * as Item from "@/lib/Util/Types/Workflow/SearchListItem/Tail"

export const VALUES = {
    Today: true,
    "<= 3 Days": true,
    "<= 5 Days": true,
    Future: true,
    Irrelevant: true,
}

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function to(x: Item.T): Kind {
    if (x.doc_summary.discount) {
        const init = new Date()
        const now = Common.getJustDate(init)
        const due_date = Common.getJustDate(x.doc_summary.discount.due_date)
        const difference = datefns.differenceInDays(due_date, now)
        if (difference < 0) {
            return "Irrelevant";
        }
        if (difference === 0) {
            return "Today";
        }
        else if (difference <= 3) {
            return "<= 3 Days"
        }
        else if (difference <= 5) {
            return "<= 5 Days"
        }
        else {
            return "Future"
        }
    }
    return "Irrelevant"
}