import * as Types from "@/lib/Util/Types/Types"
import { RecToDU, RecToDURaw, getKindNames } from '../Types'
import * as MailReport from "@/lib/Util/Types/Biz/MailReport"

export const VALUES = {
    Invoice: MailReport.getEmpty(),
    Reminder1: MailReport.getEmpty(),
    Reminder2: MailReport.getEmpty()
} as const

export type Record = typeof VALUES

export type T = RecToDU<Record>
export type Kind = T['kind']
export type DURecord = RecToDURaw<Record>

export function getEmpty(): T {
    return {
        kind: "Invoice", ...MailReport.getEmpty()
    }
}

export const KEYS: Kind[] = getKindNames<Record, Kind>(VALUES)

function extractKind(x: any): { kind: Kind } | null {
    if (x.kind.Invoice) {
        return { kind: "Invoice" }
    }
    if (x.kind.Reminder1) {
        return { kind: "Reminder1" }
    }
    if (x.kind.Reminder2) {
        return { kind: "Reminder2" }
    }
    return null
}

// oh my this one returns the json already with kind!
export function tryParse(x: any): T | null {
    const report = MailReport.tryParse(x)
    const kind = extractKind(x)
    if (kind && report) {
        const result = {
            ...kind,
            ...report
        }
        return result
    }
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "InvoiceMailReport")

export function getRanking(x: T): number {
    switch (x.kind) {
        case "Invoice": return 0
        case "Reminder1": return 1
        case "Reminder2": return 2
    }
}

export function sort(l: T, r: T) {
    const lr = getRanking(l)
    const rr = getRanking(r)
    if (lr < rr) return -1
    if (lr > rr) return 1
    return 0
}

function asPart(x: T): DURecord {
    switch (x.kind) {
        case "Invoice": return { Invoice: x }
        case "Reminder1": return { Reminder1: x }
        case "Reminder2": return { Reminder2: x }
    }
}

export function asPartial(xs: T[]): Partial<Record> {
    const ps = xs.map(asPart)
    const result = ps.reduce((acc, v) => {
        return { ...acc, ...v }
    }, {})
    return result
}
