
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import * as lodash from "lodash"

import * as Shared from "./Shared"
import FilesImportDialog from "./FilesImportDialog.vue"

import * as AssociatedFileType from "@/lib/Util/Types/Workflow/AssociatedFiles/AssociatedFileType"

import store from "@/store/store"

@Component({
    components: {
        FilesImportDialog,
    },
})
export default class FilesPartView extends Vue {
    @Prop({ default: [] }) items!: AssociatedFileType.T[]
    @Prop({ default: "NO WORKFLOW ID" }) workflow_id!: string

    selected_file_type_kind = this.default_file_type
    show_dialog = false

    get loaded() {
        return lodash.cloneDeep(this.items)
    }

    get selected_file_type() {
        if (this.loaded && this.loaded.length > 0) {
            const selected = this.loaded.filter((x) => x.kind === this.selected_file_type_kind)
            if (selected.length > 0) {
                return selected[0]
            }
        }
        return null
    }

    get source() {
        const selected = this.selected_file_type
        if (selected) {
            const value = selected.value
            if (!Array.isArray(value)) {
                return value.uri
            }
        }
        return ""
    }

    get usable_file_types(): AssociatedFileType.Kind[] {
        const filter = (v: AssociatedFileType.T) => {
            if (Array.isArray(v.value)) {
                return false
            } else {
                return v.value.active
            }
        }
        const filtered = this.loaded.filter(filter)
        return filtered.map((x) => x.kind)
    }

    get default_file_type(): AssociatedFileType.Kind {
        if (this.usable_file_types.includes("Invoice")) {
            return "Invoice"
        }
        return "OrderClient"
    }

    openDialog() {
        this.show_dialog = true
    }

    async deactivate() {
        if (this.selected_file_type?.kind) {
            const payload: Shared.ChangeRequest = {
                workflow_id: this.workflow_id,
                file_type: this.selected_file_type.kind,
                uri: this.source,
            }
            const result = await Shared.changeFile(
                "preparation",
                "deactivate_file",
                payload,
                store.state.authentication?.token ?? "NO TOKEN"
            )

            if (result.value) {
                result.msg.text = `successfully deactivated ${this.selected_file_type.kind.toUpperCase()}`
                store.commit.workflow.single.updateValue(result.value)
                this.selected_file_type_kind = this.default_file_type
            }
            store.commit.workflow.single.updateMsg(result.msg)
        }
    }

    async change() {
        //todo: needs implementation to change file type
        console.error("NO IMPL YET")
    }
}
