import * as Types from '@/lib/Util/Types/Types'
import * as AccountTimestamp from "./AccountTimestamp"

export const VALUES = {
    Nothing: true,
    CreditCard: AccountTimestamp.getEmpty(),
    BankDebit: AccountTimestamp.getEmpty()
}

type Record = typeof VALUES

export type T = Types.RecToDUWithKindValueAndOriginal<Record>
export type Kind = T['kind']
export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getEmpty(): T {
    return {
        kind: "Nothing",
        Nothing: true,
        value: true
    }
}

export function tryParse(x: any): T | null {
    if (x) {
        if (x.CreditCard) {
            const value = AccountTimestamp.tryParse(x.CreditCard)
            if (value) {
                return {
                    kind: "CreditCard",
                    CreditCard: value,
                    value
                }
            }
        }
        else if (x.BankDebit) {
            const value = AccountTimestamp.tryParse(x.BankDebit)
            if (value) {
                return {
                    kind: "BankDebit",
                    BankDebit: value,
                    value
                }
            }
        }
        else if (x.Nothing) {
            return getEmpty()
        }

        return null
    }
    return null
}