import * as ServicesReceived from "@/lib/Util/Types/Reports/ServicesReceived"
import * as datefns from 'date-fns'

export const HEADERS = [
    {
        text: 'Ref',
        value: 'ref_id'
    },
    {
        text: 'Approval Date',
        value: 'approval_date',
    },
    {
        text: 'Currency',
        value: 'currency.kind',
    },
    {
        text: 'Country',
        value: 'country.kind',
    },
    {
        text: 'Vat Kind',
        value: 'revenue_account.tax_rate.kind',
    },
    {
        text: 'Account',
        value: 'revenue_account.account'
    },
    {
        text: 'Asset Account',
        value: 'asset_account.account'
    },
    {
        text: 'Gross',
        value: 'gross_amount',
        align: 'end'
    },
    {
        text: 'Net',
        value: 'net_amount',
        align: 'end'
    },
    {
        text: 'Vat',
        value: 'vat_amount',
        align: 'end'
    },
    {
        text: 'Invoice No',
        value: 'invoice_no'
    },
    {
        text: 'Invoice Date',
        value: 'invoice_date'
    },
    {
        text: 'Supplier',
        value: 'supplier_name'
    },
    {
        text: '',
        value: 'actions',
    },
]

export function toCSVRecord(x: ServicesReceived.T) {

    function asDate(x: Date | null | undefined) {
        if (x) {
            return datefns.format(x, "dd.MM.yyyy")
        }
    }

    const terse_id =
        x.workflow_secondary_id.substr(5, 2) +
        x.workflow_secondary_id.substr(8, 3) +
        x.workflow_secondary_id.substr(14, 2) +
        x.workflow_secondary_id.substr(18)

    return {
        id: x.id,
        workflow_ref: x.workflow_ref,
        workflow_secondary_id: x.workflow_secondary_id,
        terse_id: terse_id,
        invoice_no: x.invoice_no,
        invoice_date: asDate(x.invoice_date),
        receipt_date: asDate(x.receipt_date),
        approval_date: asDate(x.approval_date),
        supplier_name: x.supplier_name,
        supplier_city: x.supplier_city,
        vat_kind: x.revenue_account.tax_rate.kind,
        tax_rate: x.revenue_account.tax_rate.value,
        gross_amount: x.gross_amount.value,
        net_amount: x.net_amount.value,
        vat_amount: x.vat_amount.value,
        country: x.country.kind,
        currency: x.currency.kind,
        revenue_account: x.revenue_account.account,
        tax_key: x.revenue_account.tax_key,
        asset_account: x.asset_account.account,
        client: x.client,
        bp: x.bp_name,
        bp_short_id: x.bp_short_id
    }
}
