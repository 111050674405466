import * as Country from "@/lib/Util/Types/Common/Country";

import * as Client from "@/lib/Util/Types/Masterdata/Client/Client";

export function makeCountryProperty(x: Client.T | null) {
    if (x) {
        const prop = {
            get: function (): Country.Kind | null {
                console.log("makeCountryProperty.Get", x, x.country?.kind)
                return x.country?.kind ?? null
            },
            set: function (v: Country.Kind): void {
                x.country = Country.getByKind(v)
                console.log("makeCountryProperty", v, x)
            }
        }
        return {
            ...x,
            used_country: prop
        }
    }
    return null
}

export const HEADERS = [
    {
        text: 'ShortID',
        value: 'short_id',
    },
    {
        text: 'Description',
        value: 'description',
    },
    {
        text: 'Created',
        value: 'created_at',
    },
    {
        text: 'Country',
        value: 'country',
    },
    {
        text: 'Currency',
        value: 'currency',
    },
    {
        text: 'Payment Terms',
        value: 'payment_terms',
    },
    {
        text: 'Process Type',
        value: 'process_type',
    },
    {
        text: 'Actions',
        value: 'actions',
    },
]