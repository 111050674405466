import * as lodash from "lodash/fp";

import * as SuperAgent from "superagent";
import * as Common from "./Common"
import { getServer } from "@/lib/Util/environment";

export type ServerError = {
    errors: string[];
    message: string;
    status: number;
}

export type RestMessage = {
    loading: boolean;
    success: boolean;
    active: boolean;
    timeout: number;
    text: string | ServerError;
}

export type Request<I extends Common.AnyObject, O> = {
    token: string;
    url: string;
    payload: I;
    transformer: (v: any) => O | null;
}

export type Result<T> = {
    value: T | null;
    msg: RestMessage;
}

export function getEmpty(): RestMessage {
    return {
        loading: false,
        success: true,
        active: false,
        timeout: 15000,
        text: ""
    }
}

function getServerError(x: string): string | ServerError {
    try {
        return JSON.parse(x)
    }
    catch (ex) {
        console.error("getErrorText failed", x, ex)
        return x
    }
}

export async function GET<I extends Common.AnyObject, O>(msg: RestMessage, req: Request<I, O>): Promise<Result<O>> {
    try {
        console.log("GET", req.payload)
        const response = await SuperAgent
            .get(getServer() + req.url)
            .ok(res => res.status < 500)
            .set({ Authorization: "Bearer " + req.token })
            .query(req.payload)

        let transformed = null
        const next_msg = {
            ...lodash.cloneDeep(msg),
            loading: false,
            active: true
        }
        if (response.status === 200) {
            transformed = req.transformer(response.body)
            next_msg.success = true
        }
        else {
            transformed = null
            next_msg.success = false
            next_msg.text = JSON.stringify(response.body)
        }
        return {
            value: transformed,
            msg: next_msg
        }
    }
    catch (ex) {
        const next_msg = {
            ...lodash.cloneDeep(msg),
            loading: false,
            success: false,
            active: true,
            text: JSON.stringify(ex)
        }
        return {
            value: null,
            msg: next_msg
        }
    }
}

export async function GET2<I extends Common.AnyObject, O>(req: Request<I, O>) {
    return GET(getEmpty(), req);
}

export async function POST<I extends Common.AnyObject, O>
    (msg: RestMessage, req: Request<I, O>): Promise<Result<O>> {
    msg.loading = true;
    try {
        //const payload = Common.cleanup(req.payload) as Common.AnyObject
        const payload = req.payload as Common.AnyObject
        const response = await
            SuperAgent
                .post(getServer() + req.url)
                .ok(res => res.status < 500)
                .set({ Authorization: "Bearer " + req.token })
                .send(payload)
        let transformed = null
        const next_msg = {
            ...lodash.cloneDeep(msg),
            loading: false,
            success: true,
            active: true
        }
        if (response.status === 200) {
            console.log("500 POST")
            transformed = req.transformer(response.body)
            console.log("510 POST", transformed)
            next_msg.success = true
        }
        else {
            transformed = null
            next_msg.success = false
            next_msg.text = JSON.stringify(response.text)
        }
        return {
            value: transformed,
            msg: next_msg
        }
    }
    catch (ex) {
        console.error("900 POST!!!", (ex as any).response)
        const next_msg = {
            ...lodash.cloneDeep(msg),
            loading: false,
            success: false,
            active: true,
            text: getServerError((ex as any).response.text)
        }
        return {
            value: null,
            msg: next_msg
        }
    }
}
