
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import lodash from "lodash"

import * as Timespan from "@/lib/Util/Types/Common/Timespan"
import * as Client from "@/lib/Util/Types/Masterdata/Client/Client"
import * as State from "@/lib/Util/Types/Remittance/State"

import * as Filter from "@/store/Remittance/FilterNew"
import store from "@/store/store"

import TooltipIcon from "@/components/Util/TooltipIcon.vue"

@Component({
    components: { TooltipIcon },
})
export default class RemittanceListFilterView extends Vue {
    @Prop({ default: null }) item!: Filter.T

    timespans = Timespan.filter([
        { prefix: "2024", period: "A" },
        { prefix: "2024", period: "Q" },
        { prefix: "2024", period: "M" },
        { prefix: "2023", period: "A" },
        { prefix: "2023", period: "Q" },
        { prefix: "2023", period: "M" },
        { prefix: "2022", period: "A" },
        { prefix: "2022", period: "Y" },
        { prefix: "2022", period: "Q" },
        { prefix: "2022", period: "M" },
        { prefix: "2021", period: "A" },
        { prefix: "Last", period: "Q" },
        { prefix: "2021", period: "Y" },
        { prefix: "2021", period: "Q" },
        { prefix: "2021", period: "M" },
        { prefix: "2020", period: "Y" },
        { prefix: "2020", period: "Q" },
        { prefix: "2019", period: "Y" },
    ])
    states = State.KEYS
    loaded = lodash.cloneDeep(this.item)
    clients = [] as Client.T[]
    search = ""

    get available_clients() {
        const result = store.state.client.list.full_list?.result ?? []
        const uc = result.filter(v => this.loaded?.parent_id?.includes(v.id ?? ""))
        this.used_clients = uc
        return lodash.sortBy(result, "name")
    }

    @Watch("item", { deep: true })
    onItemChanged(nv: Filter.T) {
        console.log("FILTER LOADED", nv)
        this.loaded = lodash.cloneDeep(this.item)
    }

    clearing() {
        this.$emit("update:item", lodash.cloneDeep(Filter.getEmpty()))
    }

    searching() {
        const client_ids = this.clients.map(v => v.id ?? "")
        this.loaded.parent_id = client_ids
        this.$emit("update:item", this.loaded)
    }

    get used_clients() {
        return this.clients
    }

    set used_clients(xs: Client.T[]) {
        console.log("USED CLIENTS", xs)
        this.clients = xs
    }

    get reference() {
        return this.loaded.reference?.join(", ") ?? undefined
    }
}
