import * as Types from "@/lib/Util/Types/Types"
import * as Target from "@/lib/Util/REST/Target"

import * as Common from "@/lib/Util/Common"
import * as Email from "@/lib/Util//Types/Common/Email"

import * as AssociatedFileType from "../AssociatedFiles/AssociatedFileType"
import * as DocumentType from "../Doc/DocumentType"

import * as Base from "./Base"

//This ListItem is used in Preparation, Verification, Approval & Cancel
export type T = Base.T & {
    start_date?: Date;
    wa_change_time?: Date;
    version_number?: string;

    client_name: string;
    bp_name: string;
    buyer_email: Email.Email;
    cpc_id: string;

    order_number?: string;
    external_id?: string;
    document_type?: DocumentType.T;

    supplier_name?: string;

    net_amount?: number;
    gross_amount?: number;

    associated_files: AssociatedFileType.T[];
}

export type Kind = Extract<Target.TargetKind, "Prepare" | "Verify" | "Approve" | "Cancel">

export function isKind(x: any): x is Kind {
    return x === "Prepare" || x === "Verify" || x === "Approve" || x === "Cancel"
}


export function tryParse(x?: any): T | null {
    if (x) {
        const source = Base.tryParse(x)
        if (source) {
            const start_date = Common.parseServerDate(x.start_date)
            const wa_change_date = Common.parseServerDate(x.wa_change_date)
            const buyer_email = Email.Email.tryParse(x.buyer_email)
            const document_type = DocumentType.tryParse(x.document_type)

            const associated_files = AssociatedFileType.tryParseList(x.associated_files)

            if (buyer_email) {
                return {
                    ...x,
                    ...source,

                    buyer_email,

                    start_date,
                    wa_change_date,

                    associated_files,
                    document_type,
                }
            }
        }
    }

    console.error("SearchListItem/Head.tryParse", x)
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "SearchListItem/Head")

export function isTypeOf(x: any): x is T {
    return x.associated_files && x.buyer_email
}