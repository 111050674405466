
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import * as Common from "@/lib/Util/Common"
import * as Target from "@/lib/Util/REST/Target"

import FilesPartView from "./FilesPart.vue"
import DetailView from "./Detail.vue"
import OverView from "./Over.vue"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"

import store from "@/store/store"

Component.registerHooks(["beforeRouteEnter"])
@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        DetailView,
        OverView,
        TooltipIcon,
        FilesPartView,
    },
})
export default class WorkflowSingleMainView extends Vue {
    @Prop({ default: "Prepare" }) area!: Target.TargetKind
    showOverview = this.area === "Pay" || this.area === "Finish"

    beforeRouteEnter(to: any, from: any, next: any) {
        const regex = /^\/sections\/workflow\/(\w+)\/single\/.*$/
        const result = (to.path as string).match(regex)
        console.log("beforeEnterRoute", result)

        if (to.params.id && result && result.length === 2) {
            const target = Common.capitalize(result[1])
            if (Target.isTargetKind(target)) {
                store.dispatch.workflow.single.LOAD({ id: to.params.id, area: target })
                next()
            } else {
                console.error("100 Could not enter Route", to, from, target)
            }
        } else {
            console.error("200 Could not enter Route", to, from)
        }
    }

    get loaded() {
        return store.state.workflow.single.value
    }

    get msg() {
        return store.state.workflow.single.message
    }

    changeView() {
        this.showOverview = !this.showOverview
    }
}
