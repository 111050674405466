import * as Types from '@/lib/Util/Types/Types'
import * as lodash from 'lodash'
import * as FileResource from "./FileResource"

export const VALUES = {
    Invoice: FileResource.getEmpty(),
    OrderClient: FileResource.getEmpty(),
    OrderConfirmation: FileResource.getEmpty(),
    DeliveryNote: FileResource.getEmpty(),
    Reminder1: FileResource.getEmpty(),
    Reminder2: FileResource.getEmpty(),
    Reminder3: FileResource.getEmpty(),
    CertificateOfOrigin: FileResource.getEmpty(),
    SupplierDeclaration: FileResource.getEmpty(),
    MiscClient: [] as FileResource.T[],
    MiscSupplier: [] as FileResource.T[],
    AttendeeListing: FileResource.getEmpty(),
} as const

type Record = typeof VALUES

export type T = Types.RecToDUWithKindValueAndOriginal<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getEmpty(): T {
    return { kind: "MiscClient", MiscClient: [], value: [] }
}

export function tryParse(x?: any): T | null {
    if (x) {
        if (x.Invoice) {
            const value = FileResource.tryParse(x.Invoice)
            if (value) {
                return {
                    kind: "Invoice",
                    Invoice: value,
                    value
                }
            }
        }
        else if (x.OrderClient) {
            const value = FileResource.tryParse(x.OrderClient)
            if (value) {
                return {
                    kind: "OrderClient",
                    OrderClient: value,
                    value
                }
            }
        }
        else if (x.OrderConfirmation) {
            const value = FileResource.tryParse(x.OrderConfirmation)
            if (value) {
                return {
                    kind: "OrderConfirmation",
                    OrderConfirmation: value,
                    value
                }
            }
        }
        else if (x.DeliveryNote) {
            const value = FileResource.tryParse(x.DeliveryNote)
            if (value) {
                return {
                    kind: "DeliveryNote",
                    DeliveryNote: value,
                    value
                }
            }
        }
        else if (x.Reminder1) {
            const value = FileResource.tryParse(x.Reminder1)
            if (value) {
                return {
                    kind: "Reminder1",
                    Reminder1: value,
                    value
                }
            }
        }
        else if (x.Reminder2) {
            const value = FileResource.tryParse(x.Reminder2)
            if (value) {
                return {
                    kind: "Reminder2",
                    Reminder2: value,
                    value
                }
            }
        }
        else if (x.Reminder3) {
            const value = FileResource.tryParse(x.Reminder3)
            if (value) {
                return {
                    kind: "Reminder3",
                    Reminder3: value,
                    value
                }
            }
        }
        else if (x.CertificateOfOrigin) {
            const value = FileResource.tryParse(x.CertificateOfOrigin)
            if (value) {
                return {
                    kind: "CertificateOfOrigin",
                    CertificateOfOrigin: value,
                    value
                }
            }
        }
        else if (x.MiscClient) {
            if (Array.isArray(x.MiscClient)) {
                const value = x.MiscClient.map(FileResource.tryParse)
                if (value) {
                    return {
                        kind: "MiscClient",
                        MiscClient: value,
                        value
                    }
                }
            }
        }
        else if (x.MiscSupplier) {
            if (Array.isArray(x.MiscSupplier)) {
                const value = x.MiscSupplier.map(FileResource.tryParse)
                if (value) {
                    return {
                        kind: "MiscSupplier",
                        MiscSupplier: value,
                        value
                    }
                }
            }
        }
        else if (x.AttendeeListing) {
            const value = FileResource.tryParse(x.AttendeeListing)
            if (value) {
                return {
                    kind: "AttendeeListing",
                    AttendeeListing: value,
                    value
                }
            }
        }
    }
    console.error("AssociatedFileType.tryParse", x)
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "AssociatedFileType")

export function isActive(x: T): boolean {
    const fr = x.value
    if (lodash.isArray(fr)) {
        return lodash.some(fr, v => v.active)
    }
    else {
        return fr.active
    }
}

export function hasFileOfType(xs: T[], file_type: Kind) {
    return xs.some(x => x.kind === file_type && isActive(x))
}