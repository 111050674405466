
import Vue from "vue";
import store from "@/store/store"

export default Vue.extend({
    name: "EmptyToolbar",
    mounted() {
        store.commit.updateToolbar(false)
    }
});
