import { getValue } from "vue-currency-input"
import numeral from "numeral"
import * as Country from "@/lib/Util/Types/Common/Country"
import * as Locale from "@/lib/Util/Types/Common/Locale"
import * as Router from "vue-router"

export type Emitter<T> = {
    $emit: (key: string, v: T) => any;
}

export type EditWrapper<T> = {
    value: T;
    index: number;
    new_item: boolean;
}

export type LeaveRoute = {
    route: Router.RawLocation | null;
    show: boolean;
    force_route: boolean;
}

export type TableItemType =
    | "text"
    | "checkbox"
    | "link"

export type DataTableHeaderItem = {
    text: string;
    value: string;
    align?: string;
    sortable?: boolean;
    cell_type?: TableItemType;
}

export type Action<T> = {
    command: (x: EditWrapper<T>) => EditWrapper<T>;
    text: string;
    icon: string;
    name: string;
}

export type DataTableHeaders = DataTableHeaderItem[]

export type AddRow<T> = () => T
export type Sort<T> = (xs: T[]) => T[]
export type ConvertRow<S, T extends S> = (x: S, index: number, new_item: boolean) => EditWrapper<T>
export type ConvertRowList<S, T extends S> = (x: S[]) => EditWrapper<T>[]

export type LinkType<T> = EditWrapper<T> & {
    link: {
        href: string;
        download: string;
        text: string;
    };
}

export function emit<T>(e: Emitter<T>, key: string, v: T) {
    return e.$emit(key, v)
}

export function buildEmitter<T>(e: Emitter<T>, key: string) {
    return function (v: T) {
        e.$emit(key, v)
        return v
    }
}

export function getRefValue(x: any): number {
    if (x) {
        console.log("getREF", x)
        return getValue(x as HTMLInputElement) ?? 0
    }
    return 0
}

export function format(x: number, c: Country.Kind) {
    const ls = Locale.getLocaleString(c)
    const formatter = new Intl.NumberFormat(ls, { style: 'decimal' })
    const result = formatter.format(x)
    return result
}

export function parse(x: string | undefined | null, l: Country.Kind): number | null {
    if (x && x.length > 0) {
        numeral.locale(l.toLowerCase())
        try {
            const n = numeral(x)
            return n.value()
        }
        catch (ex) {
            console.log("parse failed", ex)
            return null
        }
    }
    return null

}

export function toEditWrapper<T>(x: T, index: number, new_item = false): EditWrapper<T> {
    return {
        value: x,
        index,
        new_item
    }
}

export function toWrapperList<T>(xs: T[]): EditWrapper<T>[] {
    return xs.map((x, i) => toEditWrapper(x, i, false))
}

export function noSort<T>(xs: T[]): T[] {
    return xs
}
