import Numeral from "numeral"

import * as Currency from "@/lib/Util/Types/Common/Currency"
import * as Country from "@/lib/Util/Types/Common/Country"

import * as Workflow from "@/lib/Util/Types/Workflow/Workflow"
import * as Measure from "@/lib/Util/Types/Workflow/Doc/Measure"
import * as LineItem from "@/lib/Util/Types/Workflow/Doc/LineItem"
import * as PosDataLineItem from "@/components/Sections/Workflow/View/Single/PosDataLineItem"
import * as AssociatedFileType from "@/lib/Util/Types/Workflow/AssociatedFiles/AssociatedFileType"

import * as VatType from "@/lib/Util/Types/VAT/VatType"
import * as VatRules from "@/lib/Util/Types/VAT/VatRules"

import * as Target from "@/lib/Util/REST/Target"
import * as REST from '@/lib/Util/REST'

export type UploadRequest = {
    workflow_id: string;
    file_type: AssociatedFileType.Kind;
    text: string | null;
    data: string;
}

export type ChangeAction =
    | "deactivate_file"
    | "change_file"

export type UserView =
    | "Overview"
    | "DetailView"

export type ChangeRequest = {
    workflow_id: string;
    file_type: AssociatedFileType.Kind;
    uri: String;
}

export function getHeaders(x: Currency.Kind) {
    return [
        {
            text: 'Description',
            width: "30%",
            sortable: false,
        },
        {
            text: 'Count',
            width: "10%",
            sortable: false,
        },
        {
            text: 'Measure',
            width: "15%",
            sortable: false,
        },
        {
            text: `Amount ${x}`,
            width: "14%",
            sortable: false,
        },
        {
            text: 'VAT in',
            width: "15%",
            sortable: false,
        },
        {
            text: 'VAT out',
            width: "15%",
            sortable: false,
        },
        {
            text: `Total ${x}`,
            width: "13%",
            sortable: false,
        },
        {
            text: '',
            width: "2%",
            sortable: false,
        },
    ]
}

export function getHeadersLineItem(x: Currency.Kind) {
    return [
        {
            text: 'Description',
            width: "40%",
            sortable: false,
        },
        {
            text: 'Count',
            width: "10%",
            sortable: false,
        },
        {
            text: 'Measure',
            width: "15%",
            sortable: false,
        },
        {
            text: `Amount ${x}`,
            width: "15%",
            sortable: false,
        },
        {
            text: `Net ${x}`,
            width: "15%",
            sortable: false,
        },
        {
            text: '',
            width: "5%",
            sortable: false,
        },
    ]
}


export class Utils {
    readonly country: Country.Kind | null;
    readonly currency: Currency.Kind | null;
    readonly rules: VatRules.T | null;

    constructor(currency: Currency.Kind | null, country: Country.Kind | null, rules: VatRules.T | null) {
        this.country = country
        this.currency = currency
        this.rules = rules
    }

    get headers() {
        return getHeaders(this.currency ?? "EUR")
    }

    displayVat(x: VatType.T) {
        const value = Numeral(x.value)
        switch (x.kind) {
            case "Normal":
                return value.format("0.0") + " " + "N"
            case "Reduced":
                return value.format("0.0") + " " + "R"
            case "ReducedSpecial":
                return value.format("0.0") + " " + "NA"
            case "NormalExtended":
                return value.format("0.0") + " " + "NE"
            case "ReducedExtended":
                return value.format("0.0") + " " + "RE"
            case "Zero":
                return value.format("0.0") + " " + "EU"
            case "ZeroExtended":
                return value.format("0.0") + " " + "ZE"
        }
    }

    calcTotal(count: number, amount: number, vat: number) {
        const net_val = count * amount
        const vat_val = (net_val * vat) / 100
        return net_val + vat_val
    }

    get normalVat() {
        if (this.rules && this.country && VatRules.isNarrowCountry(this.country)) {
            const vat_rule = VatRules.getVatRule(this.rules, this.country)
            const result: VatType.T = { kind: "Normal", Normal: vat_rule.Normal, value: vat_rule.Normal }
            return result
        }
        else {
            console.error("VatRules our country could not be found. Check Authentication or country")
            return null
        }
    }
    get empty() {
        if (this.normalVat && this.country && this.currency) {
            const result: LineItem.T = {
                amount_currency: Currency.getByKind(this.currency),
                amount_value: 0,
                description: "",
                units_count: 0,
                units_measures: Measure.getEmpty(),
                vat: this.normalVat,
            }
            return result
        }
        console.error("VatRules our currency could not be found. Check Authentication or currency")
        return null
    }

    get emptyPosDataLineItem() {
        const li = this.empty
        if (li) {
            const result: PosDataLineItem.T = {
                ...li,
                vat_out: li.vat,
                cost_entity_line: {}
            }
            return result
        }
        console.error("VatRules our currency could not be found. Check Authentication or currency")
        return null
    }
}

export async function uploadImportedFile(area: string, payload: UploadRequest, token: string) {
    const input_msg = REST.getEmpty()
    const req = {
        token,
        url: `/${area}/upload_file`,
        payload,
        transformer: Workflow.tryParse
    };
    return REST.POST(input_msg, req);
}

export async function changeFile(area: string, action: ChangeAction, payload: ChangeRequest, token: string) {
    const input_msg = REST.getEmpty()
    const req = {
        token,
        url: `/${area}/${action}`,
        payload,
        transformer: Workflow.tryParse
    };
    return REST.POST(input_msg, req);
}