import * as Common from "@/lib/Util/Common"
import * as Email from "@/lib/Util//Types/Common/Email"

import * as ProcessType from "@/lib/Util/Types/Masterdata/BusinessProcess/ProcessType"
import * as Doc from "@/lib/Util/Types/Workflow/Summary/Doc"
import * as Payment from "../Summary/Payment"
import * as State from "../State"

export type T = {
    id: string;
    short_id: string;
    secondary_id: string;

    state: State.T;
    process_type?: ProcessType.T;

    last_edit_time: Date;
    last_edit_compragaUser: Email.Email;

    payment_short_id: string;

    invoice_number: string;
    /// Only used by frontend

    doc_summary: Doc.DocSummary;
    payment_summary: Payment.T;
}

export function tryParse(x?: any): T | null {
    if (x) {
        const state = State.tryParse(x.state)
        const process_type = ProcessType.tryParse(x.process_type)
        const last_edit_time = Common.parseServerDate(x.last_edit_time)
        const last_edit_compragaUser = Email.Email.tryParse(x.last_edit_compragaUser)
        const doc_summary = Doc.tryParseDocSummary(x.doc_summary)
        const payment_summary = Payment.tryParse(x.payment_summary)
        if (last_edit_time && doc_summary && payment_summary && process_type && state) {
            return {
                ...x,
                state,
                process_type,
                last_edit_time,
                last_edit_compragaUser,
                doc_summary,
                payment_summary,
            }
        }
    }
    console.error("Payment.tryParse", x)
    return null
}