import ReportsMainView from "@/components/Sections/Reports/Main.vue";
import ReportsToolbarView from "@/components/Sections/Reports/Toolbar.vue"

import ReportsServicesReceivedRouter from "@/components/Sections/Reports/ServicesReceived/router"
import ReportsServicesSoldRouter from "@/components/Sections/Reports/ServicesSold/router"

const router = {
    path: 'reports',
    components: {
        default: ReportsMainView,
        toolbar: ReportsToolbarView
    },
    children: [
        ReportsServicesReceivedRouter,
        ReportsServicesSoldRouter,
    ]
}

export default router