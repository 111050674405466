import { defineModule } from "direct-vuex"

import * as ListModule from './ListModule'
import * as SingleModule from './SingleModule'

export const internal = {
    namespaced: true as const,
    modules: {
        list: ListModule.module,
        single: SingleModule.module,
    }
}

export const module = defineModule(internal)

export default module
