import MainView from "./Main.vue";
import Toolbar from "./Toolbar.vue"

import * as view_router from "./View/router"
import SearchListView from "./View/SearchList/Main.vue"
import WarnListView from "./View/WarnList/Main.vue"

const router = {
    path: 'workflow',
    components: {
        default: MainView,
        toolbar: Toolbar
    },
    children: [
        view_router.createRouter("Prepare"),
        view_router.createRouter("Verify"),
        view_router.createRouter("Approve"),
        view_router.createRouter("Pay"),
        view_router.createRouter("Finish"),
        view_router.createRouter("Cancel"),
        {
            path: "warning",
            component: WarnListView,
            props: (route: any) => ({ query: route.query })
        },
        {
            path: "search",
            component: SearchListView,
            props: (route: any) => ({ query: route.query })
        }
    ]
}

export default router