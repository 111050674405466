import * as lodash from "lodash/fp"
import * as Types from "@/lib/Util/Types/Types"

import * as AccessLevels from "./AccessLevels"

export type T = {
    preparation: AccessLevels.T;
    verification: AccessLevels.T;
    approval: AccessLevels.T;
    payment: AccessLevels.T;
    finished: AccessLevels.T;
    cancelled: AccessLevels.T;
    archived: AccessLevels.T;
    administration: AccessLevels.T;
    reporting: AccessLevels.T;
    invoicing: AccessLevels.T;
}


export function getEmpty(): T {
    return {
        preparation: AccessLevels.getEmpty(),
        verification: AccessLevels.getEmpty(),
        approval: AccessLevels.getEmpty(),
        payment: AccessLevels.getEmpty(),
        finished: AccessLevels.getEmpty(),
        cancelled: AccessLevels.getEmpty(),
        archived: AccessLevels.getEmpty(),
        administration: AccessLevels.getEmpty(),
        reporting: AccessLevels.getEmpty(),
        invoicing: AccessLevels.getEmpty(),
    }
}

export type ListItem = AccessLevels.T & { key: keyof T }

export function toList(x: T): ListItem[] {
    const keys = Object.keys(x) as (keyof T)[]
    return keys.map(v => { return { key: v, ...x[v] } })
}

export function fromList(xs: ListItem[]): T | null {
    const result: Partial<T> = getEmpty()
    const keys = Object.keys(result) as (keyof T)[]

    function replace(x: keyof T) {
        const found = lodash.cloneDeep(xs.find(y => y.key === x))
        result[x] = found
    }
    keys.map(replace)
    if (Types.checkNonNull(result)) {
        return result as T
    }
    return null
}