import * as lodash from 'lodash/fp';
import { defineModule, localActionContext } from "direct-vuex"

import * as REST from '@/lib/Util/REST'
import { RootState } from '@/store/Shared'

import * as Item from "@/lib/Util/Types/Workflow/SearchListItem/Global"

export type Request = {
    short_id?: string[];
    id?: string[];
    order_number?: string;
    external_id?: string;
    invoice_number?: string;
    supplier_account?: string;
    supplier_name?: string;
    workflow_state?: string[];
    bp_id?: string;
    bp_name?: string;
}

const state = {
    value: [] as Item.T[],
    credit_notes: [] as Item.T[],
    message: REST.getEmpty(),
    timestamp: null as null | Date,
    filter: {} as Request,
}

const mutations = {
    updateValue(state: State, model: Item.T[] | null) {
        const values = model ?? []
        state.value = values;
    },
    updateCreditNotes(state: State, model: Item.T[] | null) {
        const values = model ?? []
        state.credit_notes = values;
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    },
    updateFilter(state: State, v: Request) {
        state.filter = v
    }
}

export function load(token: string, filter: Request) {
    const req = {
        token: token,
        url: `/search`,
        payload: filter,
        transformer: Item.tryParseList
    };
    return REST.GET(REST.getEmpty(), req);
}

const getters = {
}

const actions = {
    async LOAD(context: any, filter: Request) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context) //as unknown as {commit: any, state: any}
        const rootState = context.rootState as RootState
        try {
            const loading_msg = {
                ...state.message,
                loading: true
            }
            commit.updateMsg(loading_msg)
            commit.updateValue([])
            const req = {
                token: rootState.authentication?.token ?? "NO TOKEN",
                url: `/search`,
                payload: filter,
                transformer: Item.tryParseList
            };
            const result = await REST.GET(state.message, req);
            if (result.value) {
                const msg = {
                    ...lodash.cloneDeep(result.msg),
                    text: `Successfully loaded Workflow list`
                }
                commit.updateValue(result.value)
                commit.updateMsg(msg)
            }
            else {
                const text = typeof result.msg.text === "string" ? result.msg.text : result.msg.text.errors.join("|")
                const msg = {
                    ...lodash.cloneDeep(result.msg),
                    text
                }
                commit.updateMsg(msg)
            }
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg(msg)
        }
    },
    async LOAD_CREDIT_NOTES(context: any) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context) //as unknown as {commit: any, state: any}
        const rootState = context.rootState as RootState
        const filter: Request =
        {
            external_id: "CN.",
            workflow_state: ["preparation", "verification", "approval", "payment"]
        }

        try {
            const loading_msg = {
                ...state.message,
                loading: true
            }
            commit.updateMsg(loading_msg)
            commit.updateCreditNotes([])
            const req = {
                token: rootState.authentication?.token ?? "NO TOKEN",
                url: `/search`,
                payload: filter,
                transformer: Item.tryParseList
            };
            const result = await REST.GET(state.message, req);
            if (result.value) {
                const msg = {
                    ...lodash.cloneDeep(result.msg),
                    text: `Successfully loaded Credit Notes`
                }
                commit.updateCreditNotes(result.value)
                commit.updateMsg(msg)
            }
            else {
                const text = typeof result.msg.text === "string" ? result.msg.text : result.msg.text.errors.join("|")
                const msg = {
                    ...lodash.cloneDeep(result.msg),
                    text
                }
                commit.updateMsg(msg)
            }
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg(msg)
        }
    },
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    getters,
    actions
}

export type Mutations = typeof mutations
export type State = typeof state
export type Getters = typeof getters
export type Actions = typeof actions

export const module = defineModule(internal)
export type Module = typeof module
export default module

const actionCtx = (context: any) => localActionContext(context, module)
