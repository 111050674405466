
import Vue from "vue";
import store from "@/store/store"

export default Vue.extend({
    name: "InvoiceMainView",
    computed: {
        item: () => store.state.invoice.single.value,
    }
});
