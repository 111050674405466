import * as ServicesSold from "@/lib/Util/Types/Reports/ServicesSold"
import * as datefns from 'date-fns'

export const HEADERS = [
    {
        text: 'Ref',
        value: 'ref_id'
    },
    {
        text: 'Invoice Date',
        value: 'invoice_date',
    },
    {
        text: 'Currency',
        value: 'currency.kind',
    },
    {
        text: 'Country',
        value: 'country.kind',
    },
    {
        text: 'Vat Kind',
        value: 'revenue_account.tax_rate.kind',
    },
    {
        text: 'Account',
        value: 'revenue_account.account'
    },
    {
        text: 'Asset Account',
        value: 'asset_account.account'
    },
    {
        text: 'Gross',
        value: 'gross_amount',
        align: 'end'
    },
    {
        text: 'Net',
        value: 'net_amount',
        align: 'end'
    },
    {
        text: 'Vat',
        value: 'vat_amount',
        align: 'end'
    },
    {
        text: 'Client',
        value: 'client'
    },
    {
        text: '',
        value: 'actions',
    },
]

export function toCSVRecord(x: ServicesSold.T) {

    function asDate(x: Date | null | undefined) {
        if (x) {
            return datefns.format(x, "dd.MM.yyyy")
        }
    }

    return {
        id: x.id,
        short_id: x.storage.short_id,
        invoice_no: x.invoice_no,
        invoice_date: asDate(x.invoice_date),
        vat_kind: x.revenue_account.tax_rate.kind,
        tax_rate: x.revenue_account.tax_rate.value,
        gross_amount: x.gross_amount.value,
        net_amount: x.net_amount.value,
        vat_amount: x.vat_amount.value,
        currency: x.currency.kind,
        country: x.country.kind,
        revenue_account: x.revenue_account.account,
        tax_key: x.revenue_account.tax_key,
        asset_account: x.asset_account.account,
        client: x.client,
        bp: x.bp_name,
        bp_short_id: x.bp_short_id
    }
}
