
import Vue from "vue"
import { Prop } from "vue-property-decorator"
import Component from "vue-class-component"
import * as Common from "@/components/Util/Common"

import * as lodash from "lodash/fp"

import TooltipIcon from "@/components/Util/TooltipIcon.vue"
import { AnyObject } from "@/lib/Util/Common"

@Component({
    components: {
        TooltipIcon,
    },
})
export default class EditTableView<S, T extends S> extends Vue {
    @Prop({ default: () => [] }) headers!: Common.DataTableHeaders
    @Prop({ default: () => [] }) items!: S[]

    @Prop({ default: false }) isStatic!: boolean
    @Prop({ default: false }) showAll!: boolean
    @Prop({ default: true }) searcheable!: boolean

    @Prop({ default: null }) addRow!: Common.AddRow<S> | null
    @Prop({ default: null }) convertRow!: Common.ConvertRow<S, T> | null
    @Prop({ default: null }) convertRowList!: Common.ConvertRowList<S, T> | null
    @Prop({ default: null }) sort!: Common.Sort<S> | null
    @Prop({ default: "" }) search!: any
    item = null as Common.EditWrapper<T> | null

    is_searcheable = this.searcheable
    getValue = (path: string, v: AnyObject) => lodash.get(path, v)
    cellType = (path: string, v: AnyObject) => {
        if (path === "actions") {
            return "actions"
        }
        if (typeof lodash.get(path, v) === "boolean") {
            return "boolean"
        }
        return "any"
    }

    get loaded() {
        if (this.sort && this.convertRowList) {
            const sorted = this.sort(this.items)
            const converted = this.convertRowList(sorted)
            return converted
        }
        console.error("EditTable.loaded failed", this.sort, this.convertRowList)
        return []
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    runFilter(value: any, search: any, item: any) {
        console.log("RUNFILTER", value)
        if (item.value?.search) {
            return item.value?.search(search)
        }
        const values = Object.values(item.value).map(x => "" + x)
        const s = search.toLowerCase()
        const found = lodash.find(x => x.toLowerCase().includes(s), values)
        return found ? true : false
    }

    addEmpty() {
        if (this.addRow && this.convertRow) {
            const row = this.addRow()
            const result = this.convertRow(row, 0, true)
            console.log("EDITTABLE.ADDEMPTY", result)
            this.item = result
        } else {
            console.error("EditTable.addEmpty", this.addEmpty, this.convertRow)
        }
    }

    deleteRow(x: number) {
        const prep = lodash.cloneDeep(this.loaded)
        prep.splice(x, 1)
        const result = prep.map(x => x.value)
        console.log("EDITTABLE.DELETEROW")
        this.$emit("update-list", result)
    }

    edit(x: Common.EditWrapper<T>) {
        console.log("EDITTABLE.EDIT", x)
        this.item = lodash.cloneDeep(x)
    }

    dialogSave(x: Common.EditWrapper<T>) {
        console.log("100 EDITTABLE.DIALOG-SAVE", x)
        this.item = null
        if (x.new_item) {
            this.updateList(x, 0)
        } else {
            this.updateList(x, 1)
        }
    }

    dialogCancel() {
        console.log("EDITTABLE.DIALOG-CANCEL", this.loaded)
        this.item = null
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateList(x: any, replace_count: number) {
        console.log("EDITTABLE.UPDATELIST", x, replace_count)
        const prep = lodash.cloneDeep(this.loaded)
        prep.splice(x.index, replace_count, x)
        const extracted = prep.map((v: any) => v.value)
        if (this.sort) {
            const result = this.sort(extracted)
            this.$emit("update-list", result)
        } else {
            console.error("EditTable.updateList", this.sort)
        }
    }
}
