import numeral from "numeral"

export function hasMaxLen(l: number, x: string): true | string {
    if (x.trim().length <= l) {
        return true
    }
    return `String "${x}" is longer than ${l}`
}

export function isNumeric(x: string, locale: string): true | string {
    numeral.locale(locale)
    console.log("ISNUMERIC", x)
    if (numeral.validate(x.trim(), locale)) {
        return true
    }
    return `String "${x}" is not numeric`
}
export function isNotEmpty(x: string): true | string {
    if (x.trim().length >= 1) {
        return true
    }
    return `String "${x}" is empty or contains only spaces`
}

export function isInRange(min: number, max: number, x: number, locale: string): true | string {
    if (x >= min && x <= max) {
        return true
    }
    return `${x} < ${min} || ${x} > ${max}`

}

export function isMax(max: number, x: number, locale: string): true | string {
    return isInRange(0, max, x, locale)
}
