import * as Types from '@/lib/Util/Types/Types'

import * as AbstractCostEntity from "./AbstractCostEntity"
import * as CostKind from "./CostKind"

export type T = AbstractCostEntity.T<CostKind.T>

export function getEmpty(): T {
    return AbstractCostEntity.getEmpty(CostKind.getEmpty)
}

export function tryParse(x: any | null): T | null {
    const result = AbstractCostEntity.tryParse(x, CostKind.tryParse)
    if (result) {
        const check = Types.checkNonNullWithKeys(["cost_center", "cost_unit", "cost_element", "ledger_account", "entity01", "entity02"], result)
        if (check) {
            return result as T
        }
    }
    return null
}