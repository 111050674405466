import * as lodash from "lodash"
import * as datefns from 'date-fns'

import * as Types from "@/lib/Util/Types/Types"

import * as Protocols from "@/lib/Util/Types/Protocols"
import * as REST from '@/lib/Util/REST'

import * as ProcessType from "@/lib/Util/Types/Masterdata/BusinessProcess/ProcessType"

import * as Head from "@/lib/Util/Types/Workflow/SearchListItem/Head"
import * as StepState from "@/lib/Util/Types/Workflow/StepState"
import * as EditState from "@/lib/Util/Types/Workflow/EditState"

import * as Target from "@/lib/Util/REST/Target"

export function getHeaders(x: Target.TargetKind) {
    const result = [
        {
            text: 'Short-ID',
            value: "short_id",
        },
        {
            text: 'State',
            value: 'state',
            width: "5%"
        },
        (x === "Prepare" || x === "Approve" ? {
            text: 'Next',
            value: 'next',
            width: "3%"
        } : null),
        (x === "Prepare" ? {
            text: 'O2P',
            value: 'process_type',
            width: "3%"
        } : null),
        {
            text: 'Edited at',
            value: 'last_edit_time'
        },
        {
            text: 'Client',
            value: 'client_name',
        },
        {
            text: 'Buyer',
            value: 'buyer_email',
        },
        (x === "Prepare" ? {
            text: 'PO',
            value: 'order_number',
        } : null),
        {
            text: 'Supplier',
            value: 'supplier_name',
        },
        {
            text: 'Invoice#',
            value: 'invoice_number',
        },
        {
            text: 'CN',
            value: 'credit_note',
        },
        {
            text: '',
            value: 'actions',
        },
    ]
    return result.filter(x => x !== null)
}

export function sort(xs: Head.T[]): Head.T[] {
    return lodash.orderBy(xs, ["last_edit_time", "order_number"], ["desc", "asc"])
}

export type Direction =
    | "next"
    | "prev"
    | "cancel"

// "/api/${area}/${workflow_id}/workflow_state/${direction}"

export async function changeWorkflowArea(area: Head.Kind, dir: Direction, id: string, token: string) {
    const input_msg = REST.getEmpty()
    const target = Target.LOOKUP.target[area].toLowerCase()
    const req = {
        token,
        url: `/${target}/${id}/workflow_state/${dir}`,
        payload: {},
        transformer: () => true
    };
    return REST.GET(input_msg, req);
}

export const LANG = {
    short_id: { kind: "regex" },
    invoice_no: { kind: "regex" },
    order_no: { kind: "regex" },
    external_id: { kind: "regex" },
    last_user: { kind: "regex" },
    last_edit_time: { kind: "date" },
    supplier: { kind: "regex" },
    client: { kind: "regex" },
    buyer: { kind: "regex" },
    process: { kind: "regex" },
    step_state: { kind: "select_multi", value: StepState.KEYS },
    edit_state: { kind: "select_multi", value: EditState.KEYS },
    process_type: { kind: "select_multi", value: ProcessType.KEYS },

} as const

type Filter = Types.RequireAtLeastOne<typeof LANG>

export const filter: Protocols.Filter<Filter, Head.T> = (expression, values) => {
    const filters: [Protocols.ValuePath<Head.T>, Protocols.QueryExpressionValue | undefined][] = [
        [t => t.short_id, expression.short_id],
        [t => t.invoice_number, expression.invoice_no],
        [t => t.order_number, expression.order_no],
        [t => t.external_id, expression.external_id],
        [t => t.last_edit_compragaUser.Value, expression.last_user],
        [t => t.last_edit_time, expression.last_edit_time],
        [t => t.supplier_name, expression.supplier],
        [t => t.client_name, expression.client],
        [t => t.buyer_email.Value, expression.buyer],
        [t => t.bp_name, expression.process],
        [t => t.state.edit_state?.kind, expression.edit_state],
        [t => t.state.step_state?.kind, expression.step_state],
        [t => t.process_type?.kind, expression.process_type],
    ]
    return Protocols.filter(values, filters)
}


export function toCSVRecord(x: Head.T) {

    function asDate(x: Date | null | undefined) {
        if (x) {
            return datefns.format(x, "dd.MM.yyyy")
        }
        return ""
    }

    return {
        id: x.short_id,
        start_date: asDate(x.start_date),
        last_edit_time: asDate(x.last_edit_time),
        client: x.client_name,
        bp: x.bp_name,
        buyer_email: x.buyer_email.Value,
        order_number: x.order_number ?? "",
        process_type: x.process_type.kind === "InvoiceToPay" ? "I2P" : "O2P",
        supplier_name: x.supplier_name ?? "",
        invoice_number: x.invoice_number,
        gross_amount: x.gross_amount ?? 0,
        net_amount: x.net_amount ?? 0,
        edit_state: x.state.edit_state?.kind ?? "",
        step_state: x.state.step_state?.kind ?? "",
        work_state: x.state.work_state?.kind ?? "",
    }
}
