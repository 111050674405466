import * as Email from '@/lib/Util/Types/Common/Email'

export type T = {
    first_reminder?: number;
    second_reminder?: number;
    involve_line_manager?: Email.Email;
}

export function getEmpty(): T {
    return {
        first_reminder: 3,
        second_reminder: 5
    }
}

export function tryParse(x: any): T | null {
    try {

        const involve_line_manager = Email.Email.tryParse(x.involve_line_manager?.Email ?? null) ?? undefined
        const first_reminder = x.first_reminder ?? 3
        const second_reminder = x.second_reminder ?? 5
        const result = {
            ...x,
            first_reminder,
            second_reminder,
            involve_line_manager
        }
        return result
    }
    catch (ex) {
        console.error("Reminders.tryParse ! PANIC", x, ex)
        return getEmpty()
    }

}