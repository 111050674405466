import * as Types from '../Types'

export const VALUES = {
    "Closed": true,
    "ClosedWithDifference": true,
    "WaitInvoice": true,
    "WaitReminder1": true,
    "WaitReminder2": true,
    "Urgent": true,
    "OpenWithDifference": true,
    "Quiet": true,
    "Quiet1": true,
    "Quiet2": true,
    "Unknown": true,
} as const

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getByKind(x: Kind): T {
    return Types.makeSingleDUFromString(x)
}

export function getEmpty(): T {
    return { kind: "Urgent", Urgent: true }
}

export const tryParse = Types.buildTryParse<T, true>(VALUES, "kind", true, "PaymentState")

export const NEEDS_ACTION: Kind[] = [
    "Urgent",
    "WaitInvoice",
    "WaitReminder1",
    "WaitReminder2",
    "OpenWithDifference"
]
