
import Vue from "vue"

import { Component, Prop } from "vue-property-decorator"
import { ExportToCsv } from "export-to-csv"
import * as datefns from "date-fns"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"
import SearchForm from "@/components/Util/SearchForm/Main.vue"

import * as Common from "@/lib/Util/Common"

import * as Protocols from "@/lib/Util/Types/Protocols"
import * as Head from "@/lib/Util/Types/Workflow/SearchListItem/Head"

import * as AssociatedFileType from "@/lib/Util/Types/Workflow/AssociatedFiles/AssociatedFileType"

import store from "@/store/store"
import router from "@/router/router"

import * as Shared from "./Shared"

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"])
@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
        SearchForm,
    },
})
export default class HeadListMainView extends Vue {
    @Prop({ default: "Prepare" }) area!: Head.Kind

    headers = Shared.getHeaders(this.area)
    filter = {} as Protocols.QueryExpression<typeof Shared.LANG>
    lang = Shared.LANG

    get items() {
        const filtered = Shared.filter(this.filter, store.getters.workflow.list.head.items(this.area))
        return Shared.sort(filtered)
    }

    get msg() {
        return store.state.workflow.list.head.value[this.area].message
    }

    step_state(x: Head.T) {
        return x.state.step_state?.kind ?? null
    }

    edit_state(x: Head.T) {
        return x.state.edit_state?.kind ?? null
    }

    next(x: Head.T) {
        if (this.area === "Prepare") {
            const has_invoice = AssociatedFileType.hasFileOfType(x.associated_files, "Invoice")
            const has_order = AssociatedFileType.hasFileOfType(x.associated_files, "OrderClient")
            if (x.process_type?.kind === "OrderToPay" && has_order && has_invoice) {
                return "mdi-check-circle"
            } else if (x.process_type?.kind === "OrderToPay" && has_order) {
                return "mdi-circle-half-full"
            } else if (x.process_type?.kind === "InvoiceToPay" && has_invoice) {
                return "mdi-check-circle"
            } else {
                return "mdi-help-circle"
            }
        } else if (this.area === "Approve") {
            if (x.state.step_state) {
                switch (x.state.step_state.kind) {
                    case "Edit":
                        return "mdi-circle-outline"
                    case "Confirmed": {
                        switch (x.state.step_state.Confirmed.kind) {
                            case "None":
                                return "mdi-check-circle"
                            case "OneStep":
                                return "mdi-check-circle"
                            case "TwoStep-A":
                                return "mdi-circle-half-full"
                            case "TwoStep-B":
                                return "mdi-check-circle"
                        }
                        break
                    }
                    case "Declined":
                        return "mdi-close-circle"
                    case "Canceled":
                        return "mdi-close-circle"
                }
            }
            return "mdi-help-circle"
        } else {
            return "mdi-help-circle"
        }
    }

    dir(x: Head.T): Shared.Direction | null {
        if (this.area === "Approve") {
            if (x.state.step_state) {
                switch (x.state.step_state.kind) {
                    case "Confirmed": {
                        switch (x.state.step_state.Confirmed.kind) {
                            case "None":
                                return "next"
                            case "OneStep":
                                return "next"
                            case "TwoStep-A":
                                return null
                            case "TwoStep-B":
                                return "next"
                        }
                        break
                    }
                    case "Declined":
                        return "prev"
                    case "Canceled":
                        return "cancel"
                    default:
                        return null
                }
            }
            return null
        }
        return null
    }

    getActionIcon(x: Head.T) {
        const dir = this.dir(x)
        switch (dir) {
            case "prev":
                return "mdi-arrow-left-bold-circle"
            case "next":
                return "mdi-arrow-right-bold-circle"
            case "cancel":
                return "mdi-close-circle"
            default:
                return null
        }
    }

    async changeWorkflow(x: Head.T) {
        console.log("100 CHANGE WORKFLOW", x)
        const direction = this.dir(x)
        const token = store.state.authentication?.token
        if (direction && token) {
            console.log("200 CHANGE WORKFLOW", direction)
            const result = await Shared.changeWorkflowArea(this.area, direction, x.id, token)
            if (result.msg.success) {
                store.commit.workflow.list.head.removeItem({ area: this.area, model: x })
            } else {
                console.error("WorkflowListDefaultMainView", x, direction, token)
            }
        } else {
            console.error("WorkflowListDefaultMainView", x, direction, token)
        }
    }

    beforeRouteEnter(to: any, from: any, next: any) {
        const regex = /^\/sections\/workflow\/(\w+)\/list$/
        const result = (to.path as string).match(regex)

        console.log("100 PrepareWorkflowListMain.beforeRouteEnter", to, from, result)
        if (result && result.length === 2) {
            const target = Common.capitalize(result[1])
            if (Head.isKind(target)) {
                store.dispatch.workflow.list.head.LOAD(target)
                next()
            } else {
                console.error("Could not enter Route", to, from, target)
            }
        } else {
            console.error("Could not enter Route", to, from)
        }
    }

    download(x: any) {
        const s = datefns.format(new Date(), "dd.MM.yyyy-HHmmss")
        const filename = `${this.area}.${s}`
        const records = this.items.map(Shared.toCSVRecord)
        const options = {
            fieldSeparator: ";",
            quoteStrings: '"',
            decimalSeparator: ",",
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            filename: filename,
            useKeysAsHeaders: true,
        }

        const exporter = new ExportToCsv(options)
        exporter.generateCsv(records)
    }

    updateFilter(x: Protocols.QueryExpression<typeof Shared.LANG>) {
        this.filter = x
    }

    async addEmptyWorkflow() {
        console.log("EMPTY WORKFLOW")
        const _ = await store.dispatch.workflow.single.CREATE()
        const id = store.state.workflow.single.value?.id ?? "new"
        router.push({ path: `/sections/workflow/prepare/single/${id}` })
    }

    creditNoteIcon(x: Head.T) {
        if (x.external_id?.startsWith("CN.")) {
            if (x.document_type?.kind === "ExternalReceipt") {
                return "mdi-arrow-left-circle-outline"
            }
            if (x.document_type?.kind === "InternalReceipt") {
                return "mdi-arrow-right-circle-outline"
            }
            return ""
        }
        else {
            return ""
        }
    }
}
