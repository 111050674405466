import * as Types from '@/lib/Util/Types/Types'

export const VALUES = {
    AsDocument: true,
    Virtual: true,
} as const

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export const tryParse = Types.buildTryParse<T, true>(VALUES, "kind", true, "Remittance.Kind")

export function getEmpty(): T {
    return {
        kind: "Virtual",
        Virtual: true
    }
}

export function getByKind(x: Kind): T {
    return Types.makeSingleDUFromString(x)
}