
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import * as lodash from "lodash"
import * as lodash_fp from "lodash/fp"

import * as Common from "@/lib/Util/Common"

import * as Currency from "@/lib/Util/Types/Common/Currency"

import * as Invoice from "@/lib/Util/Types/Invoice/Invoice"
import * as LineItem from "@/lib/Util/Types/Remittance/LineItem"

import DateInput from "@/components/Util/DateInput.vue"
import NumberInput from "@/components/Util/NumberInput.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"

import * as InvoiceList from "@/store/Invoice/ListModule"
import store from "@/store/store"
import * as Shared from "./Shared"

@Component({
    components: {
        TooltipIcon,
        DateInput,
        NumberInput,
    },
})
export default class LineItemsPartView extends Vue {
    @Prop({ default: [] }) items!: LineItem.T[]
    @Prop({ default: false }) disabled!: boolean

    loaded = Shared.toLineItems(this.items)
    available_currencies = Currency.VALS
    isDirty = false
    headers = Shared.HEADERS
    focus_index = null as null | number

    async created() {
        await Shared.loadBusinessProcesses()
    }

    @Watch("items", { deep: true })
    onItemChanged(nv: LineItem.T[] | null) {
        const asParents = Shared.toLineItemParents(this.loaded)
        if (!lodash.isEqual(asParents, nv)) {
            this.loaded = Shared.toLineItems(nv)
        }
    }

    @Watch("loaded", { deep: true })
    onLoadedChanged(nv: Shared.LineItem[] | null, ov: Shared.LineItem[] | null) {
        //check that we dont have an infinite loop
        //because we transform the items to better work with auto-complete
        //so when this.item and the new value are the same after converting back
        //no need to update
        const updates = Shared.toLineItemParents(nv ?? [])
        const noLoop = !lodash_fp.equals(this.items, updates)
        if (nv && noLoop) {
            this.$emit("update:items", updates)
        }
    }

    add() {
        const line = Shared.toLineItem(LineItem.getEmpty())
        this.loaded.push(line)
    }

    remove(x: number) {
        this.loaded = Common.removeArrayElement(this.loaded, x)
    }

    activation(index: number) {
        if (this.focus_index === index) {
            this.focus_index = null
        }
        else {
            this.focus_index = index
        }
    }

    is_big_text_field(i: number) {
        return this.focus_index === i
    }

    
    big_text_field_style(i: number) {
        if (this.is_big_text_field(i)) {
            return {
                width: `${this.overflowSize}px`,
            }
        } else {
            return {}
        }
    }

    get overflowSize(): number {
        const count = 3 // number of columns the overflow should use
        const r = this.$refs["pos-table"] as any
        const cells = r?.$el?.querySelector("table")?.rows[0]?.cells
        const overflows = lodash.take(cells, count) as { offsetWidth: number }[]
        const result = overflows.reduce((prev, x) => x.offsetWidth + prev, 0)

        return result
    }

}
