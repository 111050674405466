export type T<S> = {
    cost_unit: S;
    cost_center: S;
    cost_element: S;
    ledger_account: S;
    entity01: S;
    entity02: S;
}

export function getEmpty<S>(x: () => S): T<S> {
    return {
        cost_unit: x(),
        cost_center: x(),
        cost_element: x(),
        ledger_account: x(),
        entity01: x(),
        entity02: x(),
    }
}

export function tryParse<S>(x: any | null, parse: (v: any) => S): T<S> | null {
    if (x) {
        const cost_unit = parse(x.cost_unit)
        const cost_center = parse(x.cost_center)
        const cost_element = parse(x.cost_element)
        const ledger_account = parse(x.ledger_account)
        const entity01 = parse(x.entity01)
        const entity02 = parse(x.entity02)
        if (cost_center && cost_unit && cost_element && ledger_account && entity01 && entity02) {
            return {
                cost_unit,
                cost_center,
                cost_element,
                ledger_account,
                entity01,
                entity02,
            }
        }
    }
    console.error("AbstractCostEntity.tryParse", x)
    return null
}