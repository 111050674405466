import * as Types from "@/lib/Util/Types/Types"
import * as VatType from "./VatType"

export type T = VatType.Record

export function getEmpty(): T {
    return VatType.VALUES
}

export function getByKind(k: VatType.Kind, r: T): VatType.T {
    const result: any = {
        kind: k,
        value: r[k]
    }
    result[k] = r[k]

    return result as VatType.T
}

export function toList(x: T): VatType.T[] {
    const keys = Object.keys(x)
    return keys.map((k) => getByKind(k as VatType.Kind, x))
}

export function fromList(xs: VatType.T[]): T {
    function search(s: VatType.Kind): number | 0 | null {
        return xs.find((v: VatType.T) => v.kind === s)?.value ?? null
    }
    return {
        Normal: search("Normal") as number,
        Reduced: search("Reduced") as number,
        NormalExtended: search("NormalExtended") as number | null,
        ReducedExtended: search("ReducedExtended") as number | null,
        ReducedSpecial: search("ReducedSpecial") as number | null,
        Zero: search("Zero") as 0,
        ZeroExtended: search("ZeroExtended") as 0,
    }
}

export const VALUES = VatType.VALUES

export function tryParse(x: any): T | null {
    if (x) {
        const l = VatType.tryParseList(x)
        console.log("010 VatRule.tryParse", l)
        const result = fromList(l)
        console.log("020 VatRule.tryParse", result)
        if (Types.checkNonNull(result)) {
            return result
        }
        console.error("100 failed: VatRule.tryParse", result, x, l)
        return null
    }
    console.error("200 failed: VatRule.tryParse", x)
    return null
}