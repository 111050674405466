
import Vue from "vue"
import { Component, Watch } from "vue-property-decorator"

import * as lodash from "lodash/fp"

import * as Country from "@/lib/Util/Types/Common/Country"
import * as Gender from "@/lib/Util/Types/Common/Gender"
import * as Email from "@/lib/Util/Types/Common/Email"

import * as Client from "@/lib/Util/Types/Masterdata/Client/Client"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import EditForm from "@/components/Util/EditForm.vue"

import TooltipIcon from "@/components/Util/TooltipIcon.vue"
import * as SearchListItem from "@/lib/Util/Types/Workflow/SearchListItem/Global"

import * as Shared from "./Shared"

import store from "@/store/store"
import * as WorkflowSearchList from "@/store/Workflow/List/SearchListModule"
import { RootState } from '@/store/Shared'

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"])

@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        EditForm,
        TooltipIcon,
    },
})
export default class MasterdataClientSingleMainView extends Vue {
    valid = true
    isDirty = false
    showSaveDialog = false
    available_countries = Country.KEYS
    available_genders = Gender.KEYS
    loaded = lodash.cloneDeep(store.state.client.single.value)
    headers = Shared.HEADERS
    bus = new Vue()
    workflows = [] as SearchListItem.T[]

    beforeRouteEnter(to: any, from: any, next: any) {
        console.log("WTF!")
        if (to.params.id && typeof to.params.id === "string") {
            if (to.params.id === "new") {
                const v = Client.getEmpty()
                store.commit.client.single.updateValue(v)
            } else {
                store.dispatch.client.single.LOAD(to.params.id)
            }
            next()
        }
        console.error("Could not enter Route", to, from)
    }

    beforeRouteLeave(to: any, from: any, next: any) {
        const event = {
            to,
            from,
            next,
        }
        this.bus.$emit("on-route-leave", event)
    }

    @Watch("loaded", { deep: true })
    onLoadedChanged(newval: Client.T | null, oldval: Client.T | null) {
        this.isDirty = !lodash.equals(this.loaded, this.item)
    }

    @Watch("item", { deep: true })
    async onItemChanged(newval: Client.T | null, oldval: Client.T) {
        this.loaded = lodash.cloneDeep(this.item)

        console.log("GET WORKFLOWS", this.item)
        const queries = this.item?.business_processes.map(v => { return { bp_name: v.description } })
        const promises = queries?.map(v => WorkflowSearchList.load(store.state.authentication?.token ?? "NO-TOKEN", v))
        const results = await Promise.all(promises ?? [])
        this.workflows = results.map(v => v.value ?? []).flat()
    }

    get item() {
        return store.state.client.single.value
    }
    get msg() {
        return store.state.client.single.message
    }

    get used_country(): Country.Kind {
        return this.loaded?.country?.kind ?? "DE"
    }
    set used_country(v: Country.Kind) {
        if (this.loaded) {
            this.loaded.country = Country.getByKind(v)
        }
    }

    get used_gender(): Gender.Kind {
        return this.loaded?.gender?.kind ?? "M"
    }
    set used_gender(v: Gender.Kind) {
        if (this.loaded) {
            this.loaded.gender = Gender.getByKind(v)
        }
    }

    get used_email(): string {
        return this.loaded?.email.Value ?? "unknown@unknown.com"
    }
    set used_email(v: string) {
        if (this.loaded) {
            this.loaded.email = Email.Email.tryParse(v) ?? Email.getEmpty()
        }
    }

    toggle(x: string) {
        return function () {
            store.dispatch.client.sub_item.TOGGLE(x).then(function () {
                store.commit.client.single.toggleBusinessProcessActivation(x)
            })
        }
    }
    save() {
        if (this.loaded) {
            //todo: remove when not needed anyore
            console.log("SAVE", this.loaded)
            if (!this.loaded.group_id) {
                this.loaded.group_id = this.loaded.name.split(" ")[0].toUpperCase()
            }
            store.dispatch.client.single.SAVE(this.loaded)
        }
    }
    cancel() {
        this.loaded = lodash.cloneDeep(this.item)
    }

    input(v: any): void {
        console.log("FORM.INPUT", v, this.loaded)
    }

    area(x: SearchListItem.T) {
        return x.state.work_state.kind.toLowerCase() ?? "prepare"
    }

    step_state(x: SearchListItem.T) {
        return x.state.step_state?.kind ?? null
    }

    edit_state(x: SearchListItem.T) {
        return x.state.edit_state?.kind ?? null
    }

    workflow_state(x: SearchListItem.T) {
        return x.state.work_state?.kind ?? null
    }

    get remittanceFilter() {
        return `/#/sections/bank/remittance/list?parent_id=${this?.item?.id}`
    }

}
