
import Vue from "vue";
import store from "@/store/store";

export default Vue.extend({
    name: "MasterdataToolbar",
    mounted() {
        store.commit.updateToolbar(true);
    },
});
