import * as Types from '@/lib/Util/Types/Types'
import * as Protocols from "@/lib/Util/Types/Protocols"

export const VALUES = {
    DE: true,
    AT: true,
    CH: true,
    NL: true,
    FR: true,
    ES: true,
    PL: true,
    CN: true,
    IN: true,
    TR: true,
    US: true,
    UK: true,
    HU: true,
    RO: true,
    HR: true,
    CY: true,
    CZ: true,
    DK: true,
    EE: true,
    FI: true,
    GR: true,
    IE: true,
    IT: true,
    LV: true,
    LT: true,
    LU: true,
    MT: true,
    PT: true,
    SK: true,
    SI: true,
    SE: true,
}

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getByKind(x: Kind): T {
    return Types.makeSingleDUFromString(x) as T
}

export function getEmpty(): T {
    return { kind: "DE", DE: true }
}

export const tryParse = Types.buildTryParse<T, true>(VALUES, "kind", true, "Country")

export class SearcheableProtocol implements Protocols.Searcheable {
    search(x: string): boolean {
        const self = this as any as T
        return self.kind.includes(x)
    }
}
