import MainView from "./Main.vue"
import HeadListView from "./HeadList/Main.vue"
import TailListView from "./TailList/Main.vue"
import SingleMainView from "./Single/Main.vue"

import * as Target from "@/lib/Util/REST/Target"

function getListComponent(x: Target.ShortTargetKind) {
    switch (x) {
        case "Prepare": return HeadListView
        case "Approve": return HeadListView
        case "Verify": return HeadListView
        case "Cancel": return HeadListView
        case "Pay": return TailListView
        case "Finish": return TailListView
        case "Archive": return TailListView
    }
}
export function createRouter(x: Target.ShortTargetKind) {
    const list_component = getListComponent(x)
    return {
        path: x,
        component: MainView,
        props: {
            area: x
        },
        children: [
            {
                path: 'list',
                component: list_component,
                props: (route: any) => ({ query: route.query, area: x })
            },
            {
                path: 'single/:id',
                component: SingleMainView,
                props: {
                    area: x
                }
            },
        ]
    }
}
