import * as lodash from "lodash/fp"

import * as Types from "@/lib/Util/Types/Types"

import * as Protocols from "@/lib/Util/Types/Protocols"

import * as Common from "@/lib/Util/Common"
import * as Validation from "@/lib/Util/Validation"

import * as Account from "@/lib/Util/Types/Common/Account"
import * as Country from "@/lib/Util/Types/Common/Country"
import * as Email from "@/lib/Util/Types/Common/Email"

import * as EmailKind from "@/lib/Util/Types/Biz/EmailKind"
import * as EmailTarget from "@/lib/Util/Types/Biz/EmailTarget"

export type T = {
    id?: string;
    short_id?: string;
    _etag?: string;
    created_at: Date;

    account: Account.T;
    name: string;
    legal_form: string;
    country: Country.T;
    vat_id?: string;

    street?: string;
    housenumber?: string;
    zip?: string;
    city?: string;

    emails: EmailTarget.T[];
    phone?: string;
    mobile?: string;
}

export function getEmpty() {
    return {
        created_at: new Date(),

        account: Account.getEmpty(),
        name: "unknown",
        legal_form: "",
        country: Country.getEmpty(),

        street: "",
        housenumber: "",
        zip: "",
        city: "",

        emails: [],
        phone: "",
        mobile: "",
    }
}

export const COMPRAGA_DE: T = {
    created_at: new Date(2022, 0, 1),

    account: {
        kind: "IBAN",
        account_id: "DE71110101015952612183",
    },

    name: "Compraga Solutions",
    legal_form: "GmbH",
    country: Country.getEmpty(),
    vat_id: "DE348662424",

    street: "Eichendorffstrasse",
    housenumber: "36",
    zip: "82140",
    city: "Olching",

    emails: [
        {
            kind: EmailKind.getEmpty(),
            email: Email.Email.parseDefault("info@compraga-solutions.com")
        }
    ],
    phone: "",
    mobile: "",
}

export const COMPRAGA_AT: T = {
    created_at: new Date(2022, 0, 1),

    account: {
        kind: "IBAN",
        account_id: "DE71110101015952612183",
    },

    name: "Compraga Solutions",
    legal_form: "GmbH",
    country: Country.getByKind("AT"),
    vat_id: "ATU78118305",

    street: "Eichendorffstrasse",
    housenumber: "36",
    zip: "82140",
    city: "Olching",

    emails: [
        {
            kind: EmailKind.getEmpty(),
            email: Email.Email.parseDefault("info@compraga-solutions.com")
        }
    ],
    phone: "",
    mobile: "",
}

export const COMPRAGA_CH: T = {
    created_at: new Date(2022, 0, 1),

    account: {
        kind: "IBAN",
        account_id: "DE71110101015952612183",
    },

    name: "Compraga Solutions",
    legal_form: "GmbH",
    country: Country.getByKind("CH"),
    vat_id: "CHE-166.545.992",

    street: "Eichendorffstrasse",
    housenumber: "36",
    zip: "82140",
    city: "Olching",

    emails: [
        {
            kind: EmailKind.getEmpty(),
            email: Email.Email.parseDefault("info@compraga-solutions.com")
        }
    ],
    phone: "",
    mobile: "",
}

export const COMPRAGAS: Record<string, T> = {
    DE: COMPRAGA_DE,
    AT: COMPRAGA_AT,
    CH: COMPRAGA_CH,
}

export function tryParse(x: any): T | null {
    const emails = EmailTarget.tryParseList(x.emails) ?? []
    const country = Country.tryParse(x.country) ?? Country.getEmpty()
    const created_at = Common.parseServerDate(x.created_at) ?? new Date()
    const result = getEmpty()

    if (created_at) {
        return {
            ...result,
            ...x,
            emails,
            country,
            created_at,
        }
    }
    console.error("Supplier.tryParse", x)
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "Supplier")

export function sort(xs: T[]): T[] {
    const result = lodash.cloneDeep(xs)
    result.sort(function (a, b) {
        if (a.name === b.name) {
            return 0
        }
        if (a.name < b.name) {
            return -1
        }
        else return 1
    })
    return result
}

export class ValidationProtocol implements Protocols.HasRules<T> {
    public get rules() {
        return {
            name: [
                (v: string) => Validation.hasMaxLen(50, v),
            ],
        }
    }
}


export class SearcheableProtocol implements Protocols.Searcheable {
    search(x: string): boolean {
        const self = this as any as T
        return (self.short_id?.toLowerCase().includes(x) ?? false) ||
            (self.name.toLowerCase().includes(x)) ||
            (self.city?.toLowerCase().includes(x) ?? false) ||
            self.account.account_id.toLowerCase().includes(x) ||
            (self.country.kind.includes(x)) ||
            (Email.search(EmailTarget.extractEmails(self.emails), x, "ANY"))
    }
}

export const LANG = {
    account: { kind: "string" },
    name: { kind: "string" },
    email: { kind: "string" },
    phone: { kind: "string" },
    country: { kind: "select_multi", value: Country.KEYS },
    created_at_from: { kind: "date" },
    created_at_to: { kind: "date" },
} as const

export const filter: Protocols.Filter<typeof LANG, T> = (x, y) => {
    let result = y
    if (x.account && x.account.kind === "string") {
        const expr = x.account
        result = result.filter(v => v.account.account_id.includes(expr.value))
    }
    if (x.name && x.name.kind === "string") {
        const expr = x.name
        result = result.filter(v => v.name.includes(expr.value))
    }
    if (x.email && x.email.kind === "string") {
        const expr = x.email
        result = result.filter(v => Email.search(EmailTarget.extractEmails(v.emails), expr.value, "ANY"))

    }
    if (x.phone && x.phone.kind === "string") {
        const expr = x.phone
        result = result.filter(v => v.phone?.includes(expr.value) ?? true)
    }
    if (x.country && x.country.kind === "select_multi") {
        const expr = x.country
        result = result.filter(v => expr.value.includes(v.country.kind))
    }
    if (x.created_at_from && x.created_at_from.kind === "date") {
        const expr = x.created_at_from
        result = result.filter(v => v.created_at >= expr.value)
    }
    if (x.created_at_to && x.created_at_to.kind === "date") {
        const expr = x.created_at_to
        result = result.filter(v => v.created_at <= expr.value)
    }
    return result
}