import * as Types from "@/lib/Util/Types/Types"

import * as lodash from "lodash/fp"
import numeral from "numeral"

import * as Protocols from "@/lib/Util/Types/Protocols"

import * as Email from "@/lib/Util/Types/Common/Email"
import * as Gender from "@/lib/Util/Types/Common/Gender"

import * as Storage from "@/lib/Util/Types/Storage/Storage"
import * as ActivationStatus from "@/lib/Util/Types/Biz/ActivationStatus"

import * as Buyer from "./Buyer"
import * as ReportPeriod from "./ReportPeriod"
import * as ApprovalProcess from "./ApprovalProcess"
import * as Reminders from "./Reminders"

export type T =
    {
        id: string;
        cpc_id: string;
        bp_id: string | null;
        storage?: Storage.T;
        status: ActivationStatus.T;
        email: Email.Email;
        gender: Gender.T;
        name: string;
        telephone: string;
        buyer: Buyer.T;
        reports_to?: Email.Email;
        approval_process: ApprovalProcess.T;
        buyer_report: ReportPeriod.T;
        line_manager_report: ReportPeriod.T;
        line_manager_reminder: Buyer.T;
        trx_limit_trigger?: number;
        monthly_limit_trigger?: number;
        limit_trigger_notification?: Email.Email;
        reminders: Reminders.T;
        cost_codes: string[];
    }

export function sort<S extends T>(xs: S[]): S[] {
    console.log("ExternalUser.sort", xs)
    const result = lodash.cloneDeep(xs)
    result.sort(function (a, b) {
        if (a.email.Value === b.email.Value) {
            return 0
        }
        if (a.email.Value < b.email.Value) {
            return -1
        }
        else return 1
    })
    return result
}

export function getEmpty(): T {
    return {
        id: "",
        cpc_id: "",
        bp_id: null,
        email: Email.getEmpty(),
        gender: Gender.getEmpty(),
        name: "",
        telephone: "",
        buyer: Buyer.getEmpty(),
        approval_process: ApprovalProcess.getEmpty(),
        buyer_report: ReportPeriod.getEmpty(),
        line_manager_report: ReportPeriod.getEmpty(),
        line_manager_reminder: Buyer.getEmpty(),
        trx_limit_trigger: 0,
        monthly_limit_trigger: 0,
        limit_trigger_notification: Email.getEmpty(),
        reminders: Reminders.getEmpty(),
        status: ActivationStatus.getEmpty(),
        cost_codes: [],
    }
}

export function tryParse(x: any): T {
    const bp_id = x.storage?.parent_id ?? null
    const email = Email.Email.tryParse(x.email.Email)
    const gender = Gender.tryParse(x.gender)
    const reports_to = Email.Email.tryParse(x.reports_to?.Email)
    const approval_process = ApprovalProcess.tryParse(x.approval_process)
    const limit_trigger_notification = Email.Email.tryParse(x.limit_trigger_notification?.Email)
    const buyer = Buyer.tryParse(x.buyer)
    const line_manager_reminder = Buyer.tryParse(x.line_manager_reminder)
    const trx_limit_trigger = numeral(x.trx_limit_trigger).value() ?? 0
    const monthly_limit_trigger = numeral(x.monthly_limit_trigger).value() ?? 0
    const buyer_report = ReportPeriod.tryParse(x.buyer_report)
    const line_manager_report = ReportPeriod.tryParse(x.line_manager_report)
    const status = ActivationStatus.tryParse(x.status)
    const reminders = Reminders.tryParse(x.reminders)
    const result: T = {
        ...x,
        bp_id,
        email,
        gender,
        reports_to,
        approval_process,
        limit_trigger_notification,
        buyer,
        trx_limit_trigger,
        monthly_limit_trigger,
        buyer_report,
        line_manager_report,
        line_manager_reminder,
        status,
        reminders,
    }
    return result
}

export const tryParseList = Types.buildTryParseList(tryParse, "ExternalUser")

export const LANG = {
    cpc_id: { kind: "string" },
    email: { kind: "string" },
    name: { kind: "string" },
    buyer: { kind: "boolean" },
    status: { kind: "select_multi", value: ActivationStatus.KEYS },
    reports_to: { kind: "string" },
} as const


export const filter: Protocols.Filter<typeof LANG, T> = (x, y) => {
    let result = y
    if (x.cpc_id && x.cpc_id.kind === "string") {
        const expr = x.cpc_id
        result = result.filter(v => v.cpc_id.includes(expr.value))
    }
    if (x.email && x.email.kind === "string") {
        const expr = x.email
        result = result.filter(v => v.email.Value.includes(expr.value))
    }
    if (x.name && x.name.kind === "string") {
        const expr = x.name
        result = result.filter(v => v.name.includes(expr.value))
    }
    if (x.buyer && x.buyer.kind === "boolean" && x.buyer.value) {
        result = result.filter(v => v.buyer.kind === "Yes")
    }
    if (x.status && x.status.kind === "select_multi") {
        const expr = x.status
        result = result.filter(v => expr.value.includes(v.status.kind))
    }
    if (x.reports_to && x.reports_to.kind === "string") {
        const expr = x.reports_to
        result = result.filter(v => v.reports_to?.Value.includes(expr.value))
    }
    return result
}
