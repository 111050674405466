import * as Common from '@/lib/Util/Common'

import * as Invoice from '@/lib/Util/Types/Invoice/Invoice'
import * as ReportFormat from "@/lib/Util/Types/Biz/ReportFormat"

import { saveAs } from "file-saver"
import store from "@/store/store"

export type StatusIcon = {
    icon: string;
    color: string;
}

export const UNKNOWN: StatusIcon = {
    color: "red",
    icon: "mdi-help-circle",
}

export function getStatusIcon(x: Invoice.T | null): StatusIcon {
    if (x) {
        switch (x.data.payment_status_fine.kind) {
            case "Closed":
                return {
                    icon: "mdi-check-circle",
                    color: "green",
                }
            case "ClosedWithDifference":
                return {
                    icon: "mdi-check-circle-outline",
                    color: "light-green",
                }
            case "WaitInvoice":
                return {
                    color: "red",
                    icon: "mdi-email-open",
                }
            case "Quiet":
                return {
                    color: "grey lighten-2",
                    icon: "mdi-email-send",
                }
            case "WaitReminder1":
                return {
                    color: "deep-orange lighten-2",
                    icon: "mdi-alert",
                }
            case "Quiet1":
                return {
                    color: "grey lighten-2",
                    icon: "mdi-alert",
                }
            case "WaitReminder2":
                return {
                    color: "deep-orange darken-1",
                    icon: "mdi-alert-octagon",
                }
            case "Quiet2":
                return {
                    color: "grey lighten-2",
                    icon: "mdi-alert-octagon",
                }
            case "Urgent":
                return {
                    color: "red",
                    icon: "mdi-alarm-light",
                }
            case "OpenWithDifference":
                return {
                    color: "deep-orange darken-1",
                    icon: "mdi-help-circle",
                }
            case "Unknown":
                return UNKNOWN
        }
    }
    return UNKNOWN
}

export async function loadDocument(x: Invoice.T, format: ReportFormat.Kind) {
    await store.dispatch.invoice.list.loadDocument({ x, format })
    if (store.state.invoice.list.document) {
        const doc = store.state.invoice.list.document
        if (doc.format === "CSV") {
            Common.downloadCSV(doc.data as string, doc.filename)
        }
        else {
            saveAs(doc.data, doc.filename)
        }
    }
}
