import ReportsServicesReceivedMainView from "@/components/Sections/Reports/ServicesReceived/Main.vue"
import ReportsServicesReceivedListMainView from "@/components/Sections/Reports/ServicesReceived/List/Main.vue"


const router = {
    path: 'services_received',
    component: ReportsServicesReceivedMainView,
    children: [
        {
            path: 'list/:timespan?',
            component: ReportsServicesReceivedListMainView
        },

    ]
}

export default router