import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'
import "./lib/plugins/vuetify";

import BeeGridTable from "beegridtable"
import "beegridtable/dist/styles/beegridtable.css";

import App from './App.vue'

Vue.use(BeeGridTable, {
  capture: true,
  transfer: true,
  zIndex: 2000,   //set z-index of beegridtable 
});

Vue.config.productionTip = false

Vue.use(Vuetify)

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi'
  }
})


new Vue({
  // router,
  //store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
