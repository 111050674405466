
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import * as lodash from "lodash"

import * as Common from "@/lib/Util/Common"
import * as Currency from "@/lib/Util/Types/Common/Currency"

import * as Advice from "@/lib/Util/Types/Remittance/Advice"
import * as LineItem from "@/lib/Util/Types/Remittance/LineItem"
import * as Kind from "@/lib/Util/Types/Remittance/Kind"
import * as Source from "@/lib/Util/Types/Remittance/Source"
import * as State from "@/lib/Util/Types/Remittance/State"

import EditForm from "@/components/Util/EditForm.vue"
import DateInput from "@/components/Util/DateInput.vue"
import NumberInput from "@/components/Util/NumberInput.vue"
import EditToolbar from "@/components/Util/EditToolbar.vue"
import LineItemsPartView from "./LineItemsPart.vue"
import StateToolbar from "./StateToolbar.vue"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"

import * as Shared from "./Shared"

import store from "@/store/store"

@Component({
    components: {
        EditForm,
        EditToolbar,
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
        DateInput,
        NumberInput,
        LineItemsPartView,
        StateToolbar,
    },
})
export default class EditPartView extends Vue {
    @Prop({ default: null }) item!: Advice.T | null

    available_kinds = Kind.KEYS
    available_sources = Source.VALS
    available_currencies = Currency.VALS
    loaded = lodash.cloneDeep(this.item)
    bank_accounts = Shared.BANK_ACCOUNTS
    booking_accounts = Shared.BOOKING_ACCOUNTS
    isDirty = false
    headers = Shared.HEADERS
    bus = new Vue()

    get used_kind(): Kind.Kind {
        return this.loaded?.kind?.kind ?? Kind.getEmpty().kind
    }
    set used_kind(v: Kind.Kind) {
        if (this.loaded) {
            this.loaded.kind = Kind.getByKind(v)
        }
    }

    getDifference(x: LineItem.T) {
        return x.amount - x.ref_amount
    }

    get clients() {
        const result = lodash.sortBy(store.state.client.list.full_list?.result ?? [], "name")
        return result
    }

    created() {
        store.dispatch.client.list.LOAD_LIST()
    }

    async save() {
        if (this.loaded) {
            await store.dispatch.remittance.single.SAVE(this.loaded)
        } else {
            console.error("Remittance could not be saved", this.loaded)
        }
    }

    cancel() {
        console.log("CANCEL")
        this.loaded = lodash.cloneDeep(this.item)
    }

    @Watch("loaded", { deep: true })
    onLoadedChanged(nv: Advice.T | null, ov: Advice.T | null) {
        this.isDirty = !lodash.isEqual(this.loaded, store.state.remittance.single.value)
    }

    @Watch("item", { deep: true })
    onItemChanged(nv: Advice.T | null, ov: Advice.T | null) {
        this.loaded = lodash.cloneDeep(this.item)
    }

    get balance() {
        if (this.loaded) {
            const sum = this.loaded?.line_items.map((v) => v.amount).reduce((prev, curr) => prev + curr, 0)
            const result = sum - this.loaded.amount
            return Common.roundNumber(result, 2)
        } else {
            return 0
        }
    }

    get isStateToolbarActive() {
        return true
    }

    get disabled() {
        return this.loaded?.state.kind !== "Open"
    }

    changeState(x: State.Kind) {
        if (this.loaded) {
            if (x === "Closed") {
                store.dispatch.remittance.single.CLOSE(this.loaded)
            }
            if (x === "Canceled") {
                store.dispatch.remittance.single.CANCEL(this.loaded)
            }
        } else {
            console.error("could not close advice:", this.loaded)
        }
    }
}
