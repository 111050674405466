import * as Types from "@/lib/Util/Types/Types"

import * as common from '../../../Util/Common'

export type T = {
    timestamp: Date;
    amount: number;
    description: string;
    //todo: enable for the future
    //when Remmitances can be referenced here
    remittance_reference?: string;
}

export function getEmpty() {
    return {
        timestamp: new Date(1970, 1, 1),
        amount: 0,
        description: ""
    }
}

export function tryParse(x: any): T | null {
    const timestamp = common.parseServerDate(x.timestamp)
    return {
        ...x,
        ...{ timestamp }
    }
}

export const tryParseList = Types.buildTryParseList(tryParse, "IncomingPaymentInfo")