import * as Types from "@/lib/Util/Types/Types"

import * as Email from "@/lib/Util/Types/Common/Email"
import * as Currency from "@/lib/Util/Types/Common/Currency"
import * as Country from "@/lib/Util/Types/Common/Country"

import * as ProcessType from "@/lib/Util/Types/Masterdata/BusinessProcess/ProcessType"
import * as CostTypes from "@/lib/Util/Types/Masterdata/BusinessProcess/CostTypes"

export type T = {
    cpc_id: string;
    process_id: string;
    process_name: string;
    process_short_id: string;
    bp_desc: string;
    client_id: string;
    client_name: string;
    employee_email: Email.Email;
    employee_name: string;
    currency: Currency.T;
    country: Country.T;
    process_type: ProcessType.T;
    cost_types?: CostTypes.T;
    negotiated_payment_terms: number;
    use_new_api: boolean;
}

export function getEmpty(): T {
    return {
        cpc_id: "",
        process_id: "",
        process_name: "",
        process_short_id: "",
        bp_desc: "",
        client_id: "",
        client_name: "",
        employee_email: Email.getEmpty(),
        employee_name: "",
        currency: Currency.getEmpty(),
        country: Country.getEmpty(),
        process_type: { kind: "OrderToPay", OrderToPay: true },
        negotiated_payment_terms: 30,
        use_new_api: false
    }
}

export function tryParse(x?: any): T | null {
    if (x) {
        try {
            const employee_email = Email.Email.tryParse(x.employee_email.Email)
            const process_type = ProcessType.tryParse(x.process_type)
            const country = Country.tryParse(x.country)
            const currency = Currency.tryParse(x.currency)
            const use_new_api = x.use_new_api ?? false
            if (employee_email && process_type && currency && country) {
                return {
                    ...x,
                    country,
                    currency,
                    employee_email,
                    process_type,
                    use_new_api
                }
            }
        }
        catch (ex) {
            console.error("ProcessReference.tryParse", ex)
        }
    }
    console.error("ProcessReference.tryParse", x)
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "ProcessReference")