
import Vue from "vue"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"

import EditTable from "@/components/Util/EditTable.vue"
import * as lodash from "lodash/fp"

import { AnyObject } from "@/lib/Util/Common"
import * as AccountingData from "@/lib/Util/Types/Masterdata/BusinessProcess/AccountingData"
import * as Services from "@/lib/Util/Types/Masterdata/BusinessProcess/Services"
import * as AccountWithTaxRate from "@/lib/Util/Types/Masterdata/BusinessProcess/AccountWithTaxRate"

import * as Shared from "./Shared"
import * as common from "@/components/Util/Common"
import EditDialog from "./EditDialog.vue"

@Component({
    components: {
        EditTable,
        EditDialog,
    },
})
export default class AccountingDataView extends Vue {
    @Prop({ default: null }) item!: AccountingData.T
    @Prop({ default: "NO pb_sid" }) bp_sid!: string

    headers = Shared.HEADERS
    addRow = (): null => null
    convertRow = common.toEditWrapper
    convertRowList = common.toWrapperList
    sort = (v: AnyObject): AnyObject => v
    loaded = this.item

    updateListServicesSold(xs: AccountWithTaxRate.T[]): void {
        const s = Services.fromList(xs)
        if (s) {
            const result = lodash.cloneDeep(this.$props.item) as AccountingData.T
            result.services_sold = s
            this.$emit("update:item", result)
        } else {
            console.error("AccountingData.Main.vue.updateListServicesSold", xs)
        }
    }
    updateListServicesReceived(xs: AccountWithTaxRate.T[]): void {
        console.log("100 updateListServicesReceived", xs)
        const s = Services.fromList(xs)
        if (s) {
            console.log("200 updateListServicesReceived", xs)
            const result = lodash.cloneDeep(this.$props.item) as AccountingData.T
            result.services_received = s
            this.$emit("update:item", result)
        } else {
            console.error("AccountingData.Main.vue.updateListServicesSold", xs)
        }
    }

    get services_sold(): AccountWithTaxRate.T[] {
        return Services.toList(this.item.services_sold)
    }
    get services_received(): AccountWithTaxRate.T[] {
        return Services.toList(this.item.services_received)
    }
}
