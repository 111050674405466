import * as Target from "@/lib/Util/REST/Target"
import * as StepState from "./StepState"
import * as EditState from "./EditState"

export type T = {
    work_state: Target.TargetKindFull;
    step_state?: StepState.T;
    edit_state?: EditState.T;
    //DO WE NEED THIS HERE? Prolly not
    //import * as ServicesReceivedReferences from "./ServicesReceivedReferences"
    //conf_state?: Confirmation;  NOT SURE IF WE REALLY NEED THIS
    //services_received_references?: ServicesReceivedReferences.T;
    //decline_reason?: Pending<DeclineReason>;
}

export function getEmpty(): T {
    return {
        work_state: Target.getEmpty()
    }
}

export function tryParse(x?: any): T | null {
    if (x) {
        const work_state = Target.tryParse(x.work_state)
        const step_state = StepState.tryParse(x.step_state) ?? undefined
        const edit_state = EditState.tryParse(x.edit_state) ?? undefined
        if (work_state) {
            return {
                work_state,
                edit_state,
                step_state
            }
        }
    }
    console.error("State.tryParse", x)
    return null
}