import numeral from "numeral"
import * as Currency from "./Currency"
import * as Country from "./Country"

export type Language =
    | "en"
    | "de"
    | "fr"
    | "nl"
    | "hu"
    | "ro"

export type T = {
    language: Language;
    currency: Currency.Kind;
    country: Country.Kind;
}

export const LOCALES: T[] = [
    {
        language: "en",
        currency: "GBP",
        country: "UK"
    },
    {
        language: "de",
        currency: "EUR",
        country: "DE"
    },
    {
        language: "de",
        currency: "EUR",
        country: "AT"
    },
    {
        language: "de",
        currency: "CHF",
        country: "CH"
    },
]

export function getDefaultLanguage(x: Country.Kind): Language {
    switch (x) {
        case "AT": return "de"
        case "CH": return "de"
        case "DE": return "de"
        case "FR": return "fr"
        case "HU": return "hu"
        case "NL": return "nl"
        case "RO": return "ro"
        case "UK": return "en"
        case "US": return "en"
        default: return "de"
    }
}

export function getLocale(c: Country.Kind, l?: Language): T {
    if (l) {
        const found = LOCALES.find((x) => x.language === l && x.country === c)
        if (found) {
            return found
        }
        return getLocale("DE")
    }
    const ll = getDefaultLanguage(c)
    return getLocale(c, ll)
}

export function getLocaleString(c: Country.Kind, l?: Language): string {
    const loc = getLocale(c, l)
    return `${loc.language}-${loc.country}`
}

numeral.register('locale', 'at', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'tsd',
        million: 'mil',
        billion: 'mrd',
        trillion: 'bil'
    },
    ordinal: function (number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: '€'
    }
});
numeral.register('locale', 'ch', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'tsd',
        million: 'mil',
        billion: 'mrd',
        trillion: 'bil'
    },
    ordinal: function (number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: 'CHF'
    }
});
numeral.register('locale', 'de', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'tsd',
        million: 'mil',
        billion: 'mrd',
        trillion: 'bil'
    },
    ordinal: function (number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: '€'
    }
});

console.log("NUMERAL", numeral.locales)