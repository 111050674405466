
import Vue from "vue"
import fp from "lodash/fp"

import * as AuthenticationRequest from "@/lib/Util/Types/Login/AuthenticationRequest"
import * as AuthenticationResult from "@/lib/Util/Types/Login/AuthenticationResult"

import { getServer } from "../../lib/Util/environment"
import CardTitle from "./CardTitle.vue"

import * as SuperAgent from "superagent"

export default Vue.extend({
    name: "AuthenticationRequestForm",
    components: {
        CardTitle,
    },
    props: {
        item: {
            type: Object as () => AuthenticationRequest.T,
        },
    },
    data: function () {
        return {
            usedItem: this.item,
            overlay: false,
            pinRules: [
                function checkLen(v?: string): boolean | string {
                    return (v?.length ?? 0) !== 0 || "PIN must not be empty"
                },
            ],
        }
    },
    computed: {
        isPinValid: function (): boolean {
            const v = this.usedItem.Pin
            return fp.every(
                fp.identity,
                this.pinRules.map((x) => x(v)).map((x) => (typeof x === "string" ? false : x))
            )
        },
    },
    methods: {
        sendAuthenticationRequest: function () {
            this.overlay = true
            SuperAgent.post(getServer() + "/login")
                .send(this.usedItem)
                .then((response) => {
                    this.overlay = false
                    const result = AuthenticationResult.tryParse(response.body)
                    this.$emit("success-authenticate", result)
                })
                .catch((error) => {
                    this.overlay = false
                    this.$emit("error-authenticate", error)
                })
        },
    },
})
