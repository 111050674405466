import EmptyToolbarView from '@/components/Util/EmptyToolbar.vue'

import InvoiceMainView from "@/components/Sections/Invoice/Main.vue";
import InvoiceListMainView from "@/components/Sections/Invoice/List/Main.vue";
import InvoiceDashboardMainView from "@/components/Sections/Invoice/Dashboard/Main.vue"
import InvoiceSingleMainView from "@/components/Sections/Invoice/Single/Main.vue"


const router = {
    path: 'invoices',
    components: {
        default: InvoiceMainView,
        toolbar: EmptyToolbarView
    },
    children: [
        {
            path: 'list',
            component: InvoiceListMainView
        },
        {
            path: 'dashboard',
            component: InvoiceDashboardMainView
        },
        {
            path: 'single/:id?',
            component: InvoiceSingleMainView
        },
        {
            path: 'single/:bp_id/:year/:number',
            component: InvoiceSingleMainView
        },
    ]
}

export default router