import *as lodash from "lodash/fp"

export type T = {
    limit: number;
    selling_price: number;
    buying_price: number;
}

export function getEmpty(): T {
    return {
        limit: 0,
        selling_price: 0,
        buying_price: 0
    }
}

export function isType(x: any): x is T {
    return x.limit !== undefined
        && x.selling_price !== undefined
        && x.buying_price !== undefined
}

export function sort(x: T[]) {
    const result = lodash.cloneDeep(x)
    result.sort((a, b) => a.limit - b.limit)
    return result
}