export type T<S> = {
    key: S;
    year: number;       //the year of the invoice (should be
    number: number;     //a unique incrementing number per BP and year
}

export function getEmpty<S>(f: () => S): T<S> {
    return {
        key: f(),
        year: 0,       //the year of the invoice (should be
        number: 0,     //a unique incrementing number per BP and year
    }
}

export function tryParse<S>(x: any | undefined, fn: (v: any) => S): T<S> | null {
    if (x) {
        const key = fn(x.key)
        if (key) {
            return {
                ...x,
                key
            }
        }
    }
    console.error("IncrementalCounter.tryParse", x)
    return null
}