import MasterdataMainView from "@/components/Sections/Masterdata/Main.vue";
import MasterdataToolbarView from "@/components/Sections/Masterdata/Toolbar.vue"

import MasterdataClientRouter from "@/components/Sections/Masterdata/Client/router"
import MasterdataInternalUserRouter from "@/components/Sections/Masterdata/InternalUser/router"
import MasterdataSupplierRouter from "@/components/Sections/Masterdata/Supplier/router"
import MasterdataTemplateRouter from "@/components/Sections/Masterdata/Template/router"

const router = {
    path: 'masterdata',
    components: {
        default: MasterdataMainView,
        toolbar: MasterdataToolbarView
    },
    children: [
        MasterdataClientRouter,
        MasterdataInternalUserRouter,
        MasterdataSupplierRouter,
        MasterdataTemplateRouter,
    ]
}

export default router