import * as Types from "@/lib/Util/Types/Types"

import * as Common from "@/lib/Util/Common"
import { Email } from "@/lib/Util/Types/Common/Email"

import * as Country from "../../Common/Country"
import * as Money from "../../Common/Money"

import * as AccountingData from "@/lib/Util/Types/Masterdata/BusinessProcess/AccountingData"

import * as AccountKey from "../../Biz/AccountKey"
import * as Discount from "../../Biz/Discount"
import * as PaymentTerms from "../../Biz/PaymentTerms"
import * as Durations from "../../Biz/Durations"
import * as SupplierInfo from "./SupplierInfo"

export type Base = {
    workflow_ref: string;
    short_id: string;
    secondary_id?: string;
    bp_ref_desc: string;
    bp_ref_id: string;
    client: string;
    country?: Country.T;
    created_at: Date;
    invoice_no: string;
    order_no?: string;
    external_id?: string;
    employee: Email;

    total: Money.T;

    supplier: SupplierInfo.T;
    supplier_account: AccountKey.T;
    discount?: Discount.T;
    payment_terms?: PaymentTerms.T;
    durations?: Durations.T;

    accounting_data?: AccountingData.T;
}

type HasPositionData = {
    incoming_position_data: Money.T;
    outgoing_position_data: Money.T;
}

type HasFees = {
    auto_fees: Money.T;
    manual_fees: Money.T;
}

export type DocSummary = Base & HasPositionData & HasFees & { discounted: Money.T }
export type DocSummaryLineItems = Base & HasPositionData
export type DocSummaryFees = Base & HasFees

export type Record = {
    Full: DocSummary;
    OnlyLineItems: DocSummaryLineItems;
    OnlyFees: DocSummaryFees;
}

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const VALUES: Record = {
    Full: (null as unknown) as DocSummary,
    OnlyLineItems: (null as unknown) as DocSummaryLineItems,
    OnlyFees: (null as unknown) as DocSummaryFees,
}

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function asBase(x: T): Base {
    switch (x.kind) {
        case "Full": return x.Full
        case "OnlyFees": return x.OnlyFees
        case "OnlyLineItems": return x.OnlyLineItems
    }
}

export function asDocSummary(x: T): DocSummary {
    switch (x.kind) {
        case "Full": return x.Full
        case "OnlyFees":
            return {
                ...x.OnlyFees,
                ...{
                    incoming_position_data: Money.create(0, x.OnlyFees.total.currency),
                    outgoing_position_data: Money.create(0, x.OnlyFees.total.currency),
                    discounted: Money.create(0, x.OnlyFees.total.currency),
                }
            }
        case "OnlyLineItems":
            return {
                ...x.OnlyLineItems,
                ...{
                    auto_fees: Money.create(0, x.OnlyLineItems.total.currency),
                    manual_fees: Money.create(0, x.OnlyLineItems.total.currency),
                    discounted: Money.create(x.OnlyLineItems.incoming_position_data.value, x.OnlyLineItems.total.currency),
                }
            }
    }
}

function tryParseBase(x: any): Base | null {
    const country = x.country ? Country.tryParse(x.country) : undefined
    const total = Money.tryParse(x.total)
    const created_at = Common.parseServerDate(x.created_at)
    const employee = Email.tryParse(x.employee.Email)
    const payment_terms = PaymentTerms.tryParse(x.payment_terms)
    const discount = Discount.tryParse(x.discount)
    const accounting_data = AccountKey.tryParse(x.accounting_data)

    const supplier = x.supplier ?? {
        name: x.supplier_name,
        city: x.supplier_city
    }

    if (total) {
        return {
            ...x,
            ...{
                country,
                total,
                created_at,
                employee,
                payment_terms,
                supplier,
                discount,
                accounting_data
            }
        }
    }
    return null
}
function tryPositionData(x: any): HasPositionData | null {
    const incoming_position_data =
        Money.tryParse(x.incoming_position_data) ?? Money.tryParse(x.position_data_in)
    const outgoing_position_data =
        Money.tryParse(x.outgoing_position_data) ?? Money.tryParse(x.position_data_out)
    if (incoming_position_data && outgoing_position_data) {
        return { incoming_position_data, outgoing_position_data }
    }
    return null;
}

function tryFees(x: any): HasFees | null {
    const auto_fees = Money.tryParse(x.auto_fees)
    const manual_fees = Money.tryParse(x.manual_fees)
    if (auto_fees && manual_fees) {
        return {
            auto_fees,
            manual_fees,
        }
    }
    return null
}

export function tryParseDocSummary(x: any): DocSummary | null {
    if (x) {
        const b = tryParseBase(x)
        const fees = tryFees(x)
        const pd = tryPositionData(x)
        const discounted = b?.discount?.amount ?? pd?.incoming_position_data as Money.T
        if (b && fees && pd) {
            const ds: DocSummary = {
                ...b,
                ...fees,
                ...pd,
                discounted
            }
            return ds
        }
        console.error("100 tryParseDocSummary", b, fees, pd, discounted)
    }
    console.error("900 tryParseDocSummary", x)
    return null
}

function tryParseInternal(x: T): T | null {
    const base = asBase(x)
    const b = tryParseBase(base)
    switch (x.kind) {
        case "Full": {
            const ds = tryParseDocSummary(x.Full)
            if (ds) {
                return {
                    ...x,
                    ...{
                        Full: ds
                    }
                }
            }
            console.error("100 Doc.tryParseInternal")
            return null
        }
        case "OnlyFees": {
            const fees = tryFees(x.OnlyFees)
            if (b && fees) {
                const ds: DocSummaryFees = {
                    ...b,
                    ...fees,
                }
                return {
                    ...x,
                    ...{
                        OnlyFees: ds
                    }
                }
            }
            return null
        }
        case "OnlyLineItems": {
            const pd = tryPositionData(x.OnlyLineItems)
            if (b && pd) {
                const ds: DocSummaryLineItems = {
                    ...b,
                    ...pd,
                }
                return {
                    ...x,
                    ...{
                        OnlyLineItems: ds
                    }
                }
            }
            return null
        }
    }
}

export function tryParse(x: any): T | null {
    try {
        const x_keys = Object.getOwnPropertyNames(x)
        const xs =
            KEYS
                .map((v) => x_keys.includes(v) ? v : null)
                .filter((v) => v !== null) as Kind[]
        if (xs.length == 1) {
            const key = xs[0]
            const result: T = {
                ...x,
                ...{ kind: key }
            }
            return tryParseInternal(result)
        }
        console.error("Summary/Doc.tryParse", x)
        return null
    }
    catch (ex) {
        console.error(ex)
        return null
    }
}

export const tryParseList = Types.buildTryParseList(tryParse, "DocSummary")