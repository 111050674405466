import * as lodash from "lodash"

export const HEADERS = [
    {
        text: 'Short-ID',
        value: "short_id",
    },
    {
        text: 'State',
        value: 'state',
        width: "7%"
    },

    {
        text: 'O2P',
        value: 'process_type',
        width: "3%"
    },
    {
        text: 'Edited at',
        value: 'last_edit_time'
    },
    {
        text: 'Client',
        value: 'client_name',
    },

    {
        text: 'Buyer',
        value: 'buyer_email',
    },
    {
        text: 'PO',
        value: 'order_number',
    },
    {
        text: 'Ex-ID',
        value: 'external_id',
    },
    {
        text: 'Supplier',
        value: 'supplier_name',
    },
    {
        text: 'Invoice#',
        value: 'invoice_number',
    },
    {
        text: '',
        value: 'actions',
    },
]

export const FILTER = {
    invoice_number: "",
    supplier_name: "",
    short_id: "",
    order_number: "",
    external_id: "",
    supplier_account: "",
}

export type Filter = typeof FILTER

export function parseFilter(x: any): Filter {
    if (x) {

        return {
            invoice_number: x.invoice_number ?? "",
            supplier_name: x.supplier_name ?? "",
            short_id: x.short_id ?? "",
            order_number: x.order_number ?? "",
            external_id: x.external_id ?? "",
            supplier_account: x.supplier_account ?? "",
        }
    }
    else {
        return FILTER
    }
}

export function isEmpty(x: Filter): boolean {
    return lodash.isEqual(x, FILTER)
}