import * as lodash from 'lodash/fp';
import { defineModule, localActionContext } from "direct-vuex"

import * as BusinessProcess from "@/lib/Util/Types/Masterdata/BusinessProcess/BusinessProcess";
import * as REST from '@/lib/Util/REST'
import { RootState } from '@/store/Shared'

const state = {
    value: null as BusinessProcess.T | null,
    message: REST.getEmpty()
}

const mutations = {
    updateValue(state: State, v: BusinessProcess.T | null) {
        state.value = v;
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    },
}

export function load_list(token?: string) {
    const req = {
        token: token ?? "NO TOKEN",
        url: "/bp",
        payload: {},
        transformer: BusinessProcess.tryParseList
    };
    return REST.GET(state.message, req);
}

async function saving(context: any, text: string, url: string, x: BusinessProcess.T) {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const { commit, state } = actionCtx(context)
    const rootState = context.rootState as RootState
    try {
        const loading_msg = {
            ...state.message,
            loading: true
        }
        commit.updateMsg(loading_msg)

        const req = {
            token: rootState.authentication?.token ?? "NO TOKEN",
            url,
            payload: x,
            transformer: BusinessProcess.tryParse
        };
        const result = await REST.POST(state.message, req);
        const msg = {
            ...lodash.cloneDeep(result.msg),
            text
        }
        console.log("SAVE", result)
        commit.updateValue(result.value)
        commit.updateMsg(msg)
    }
    catch (ex) {
        const msg = {
            ...lodash.cloneDeep(state.message),
            text: JSON.stringify(ex)
        }
        commit.updateMsg(msg)
    }
}

async function loading(context: any, url: string) {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const { commit, state } = actionCtx(context)
    const rootState = context.rootState as RootState
    try {
        const loading_msg = {
            ...state.message,
            loading: true
        }
        commit.updateMsg(loading_msg)
        const req = {
            token: rootState.authentication?.token ?? "NO TOKEN",
            url: url,
            payload: {},
            transformer: BusinessProcess.tryParse
        };
        const result = await REST.GET(state.message, req);
        const msg = {
            ...lodash.cloneDeep(result.msg),
            text: `Successfully loaded ${result.value?.short_id}`
        }
        commit.updateValue(result.value)
        commit.updateMsg(msg)
    }
    catch (ex) {
        const msg = {
            ...lodash.cloneDeep(state.message),
            text: JSON.stringify(ex)
        }
        commit.updateMsg(msg)
    }
}

const actions = {
    async LOAD(context: any, x: string) {
        loading(context, `/bp/${x}`)
    },
    async SAVE(context: any, x: BusinessProcess.T) {
        if (x.id) {
            saving(context, `Successfully saved Business Process ${x.short_id}`, `/bp/${x.id}/update`, x)
            return
        }
        if (!x.id && !x.short_id && !x._etag) {
            saving(context, `Successfully created Business Process ${x.description}`, "/bp/create", x)
        }
        else {
            console.error("Serious Error while saving Business Process",)
        }
    },
    async TOGGLE(context: any, x: string) {
        loading(context, `/bp/${x}/activate`)
    },
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    actions,
}

export type Mutations = typeof mutations
export type State = typeof state
export type Actions = typeof actions

export const module = defineModule(internal)

export default module

const actionCtx = (context: any) => localActionContext(context, module)
