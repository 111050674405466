import * as lodash from "lodash"
import * as datefns from 'date-fns'
import numeral from "numeral"
import Papa from "papaparse"

import * as Country from "@/lib/Util/Types/Common/Country"

export type AnyObject = Record<string, unknown>
export type Language =
    {
        Code: string;
        Translations: Record<string, string>;
        DefaultTranslations?: Record<string, string>;
    }

export function parseServerDate(x?: string): Date | null {
    if (x) {
        const d = x.substr(0, 8)
        const result = datefns.parse(d, 'yyyyMMdd', new Date())
        result.toJSON = function (key: any) {
            return toServerDate(this)
        }
        return result
    }
    return null
}

export function parseISODate(x: string | null): Date | null {
    if (x) {
        const result = datefns.parseISO(x)
        if (!isNaN(result.valueOf())) {
            result.toJSON = function (key: any) {
                return toServerDate(this)
            }
            return result
        }
    }
    return null
}

export function toServerDate(x: Date) {
    return datefns.format(x, "yyyyMMdd-HHmmss")
}

export function toShortDate(x: Date) {
    return datefns.format(x, "yyyy-MM-dd")
}

export function getJustDate(x: Date): Date {
    const y = x.getFullYear()
    const m = x.getMonth()
    const d = x.getDate()

    return new Date(y, m, d);
}

export function convertBase64StringToBlob(b64Data: string, sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "" });
    return blob;
}

export function convertArrayBufferToBase64String(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export async function fromFileToString(xs: File[]): Promise<{ name: string; value: string } | null> {
    if (xs.length > 0) {
        const name = xs[0].name
        const buffer = await xs[0].arrayBuffer()
        const value = convertArrayBufferToBase64String(buffer)
        return { name, value }
    } else {
        console.error("could not transform files", xs)
        return null
    }
}

export function getUnique<T>(fn: (x: T) => string, xs: T[]): string[] {
    function isUnique(value: string, index: number, self: string[]): boolean {
        return self.indexOf(value) === index;
    }
    return xs.map((x) => fn(x)).filter(isUnique);
}

//x.lengeht >= 2
export function extendString(x: string) {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    const len = 6 - x.length
    const num_chars = len <= 0 ? 0 : len
    let result = x
    for (let i = 0; i < num_chars; i++) {
        const f = result.charCodeAt(i) - 65
        const s = result.charCodeAt(i + 1) - 65
        const next_char_code = f + s
        const next_char = next_char_code >= 26 ? next_char_code - 26 : next_char_code
        result = result + chars.charAt(next_char)
    }
    return result

}

export function createCombinedID(first: string, second: string, existing: string[]) {
    const first_clean = first
        .toUpperCase()
        .replaceAll("Ü", "UE")
        .replaceAll("Ö", "OE")
        .replaceAll("Ä", "AE")
        .replaceAll("ß", "SS")
    const second_clean = second
        .toUpperCase()
        .replaceAll("Ü", "UE")
        .replaceAll("Ö", "OE")
        .replaceAll("Ä", "AE")
        .replaceAll("ß", "SS")
    const fn = extendString(first_clean)
    const sn = extendString(second_clean)
    let result = ""
    for (let i = 0; i < 3; i++) {
        const x = fn.substr(i, 3)
        const y = sn.substr(i, 3)
        result = x + y
        if (existing.includes(result)) {
            const x = fn.substr(i + 1, 3)
            const y = sn.substr(i, 3)
            result = x + y
            if (existing.includes(result)) {
                const x = fn.substr(i, 3)
                const y = sn.substr(i + 1, 3)
                result = x + y
                if (!existing.includes(result)) {
                    break
                }
            }
            else {
                break
            }
        }
        else {
            break
        }
    }
    return result
}

export function microShortId(x: string, start = 0, len = 4) {
    const sidparts = x.split("-")
    const joined = sidparts.join("")
    const result = joined.substr(start, len)
    return result
}

export function toCurrencyString(x: number, country: Country.Kind | null) {
    numeral.locale(country?.toLowerCase() ?? "de")
    const result = numeral(x)
    return result.format("-0,0.00 $")
}

export function removeArrayElement<T>(xs: T[], index: number): T[] {
    if (index < 0) {
        return xs
    }
    if (index > xs.length - 1) {
        return xs
    }
    const filtered = xs.filter((v, i) => i !== index)
    return filtered
}

export function capitalize(txt: string) {
    return txt.charAt(0).toUpperCase() + txt.slice(1)
}

export function mapOverRecord<X extends Record<string, V>, V, T>(f: (v: V) => T, x: X): Record<string, T> {
    const keys = Object.keys(x)
    const result: Record<string, T> = {}
    for (const key in keys) {
        result[key] = f(x[key])
    }
    return result
}

//this is to clean up the problematic representation of DATE values on the wire
export function cleanup(x: any): any {
    const replacer = (key: any, value: any) => {
        console.log("100 REPLACER", key, value, typeof value)
        if (value instanceof Date && !isNaN(value.getDate())) {
            console.log("500 REPLACER", value)
            return toServerDate(value)
        }
        return value
    }
    const stringified = JSON.stringify(x, replacer)
    console.log("100 CLEANUP", stringified)
    const result = JSON.parse(stringified)
    console.log("200 CLEANUP", result)
    return result
}

export function roundNumber(rnum: number, rlength: number) {
    const newnumber = Math.round(rnum * Math.pow(10, rlength)) / Math.pow(10, rlength);
    return newnumber;
}


export function removeUndefined<T extends Record<string, any>>(x: T) {
    const clone = lodash.cloneDeep(x)
    Object.keys(clone).forEach(key => {
        if (clone[key] === undefined || clone[key] === null) {
            delete clone[key];
        }
    });
    return clone
}

export function isEmpty<T extends Record<string, any>>(x: T) {
    const result = removeUndefined(x)
    return Object.keys(result).length === 0
}

export function toQueryString<T extends Record<string, any>>(x: T) {
    const transform = (v: any, key: string): string => {
        if (lodash.isNumber(v) || lodash.isBoolean(v) || lodash.isString(v)) {
            return `${key}=${v}`
        }
        else if (Array.isArray(v)) {
            return v.map(y => transform(y, key)).join('&')
        }
        else if (lodash.isObject(v)) {
            return `${key}=${toQueryString(v)}`
        }
        else {
            return ""
        }
    }
    return Object.keys(x)
        .map(key => `${transform(x[key] as any, key)}`)
        .join('&')
}

export function downloadCSV(data: string, filename: string) {
    const blob = new Blob([data], { "type": "text/csv;charset=utf8;" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute('visibility', 'hidden');
    link.download = filename

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export async function parseDropedCSVFile(e: any) {
    const files = e.dataTransfer.files as File[]
    if (files) {
        const filename = files[0].name
        const buffer = await files[0].text()
        const parser = Papa.parse(buffer, {
            quoteChar: '"',
            delimiter: ";",
        })
        const last = parser.data[parser.data.length - 1] as string[]
        if (last.length === 1 && last[0] === "") {
            parser.data.pop()
        }
        const data = parser.data.slice(1) as string[][]
        return { filename, data }
    }
    return null
}

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export function rerouteRegex(router: any, to: any, from: any, next: any, r: string, target: string) {
    const regex = new RegExp(`^${r}?$`)
    const result = (to.path as string).match(regex)

    if (result) {
        router.push({ path: `${r}${target}` })
    } else {
        next()
    }
}

export function createKey() {
    const result = new Date()
    return toServerDate(result)
}

export function isArrayOfStrings(value: unknown): value is string[] {
    return Array.isArray(value) && value.every(item => typeof item === "string");
}

export function convertArrayToObject<T>(xs: T[], extractor: (v: T) => string) {
    const values = lodash.groupBy(xs, extractor)
    const result: Record<string, T> = {}
    for (const v in values) {
        if (values[v]) {
            result[v] = values[v][0]
        }
    }
    return result
}