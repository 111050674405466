
import Vue from "vue"

import { Component } from "vue-property-decorator"

import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"

import store from "@/store/store"

import * as Timespan from "@/lib/Util/Types/Common/Timespan"
import * as SupplierPayments from "@/lib/Util/Types/SupplierPayments/SupplierPayments"

import * as MainShared from "../Shared"
import * as Shared from "./Shared"

import router from "@/router/router"

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"])

@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
    },
})
export default class BankSupplierPaymentsListMainView extends Vue {
    headers = Shared.HEADERS
    timespans = Timespan.filter([
        { prefix: "Last", period: "M" },
        { prefix: "2024", period: "M" },
        { prefix: "2023", period: "M" },
        { prefix: "2022", period: "M" },
        { prefix: "2021", period: "M" },
        { prefix: "2020", period: "M" },
        { prefix: "2019", period: "M" },
    ])

    get filter() {
        return store.state.supplier_payments.list.filter
    }

    set filter(ov: Timespan.Kind) {
        this.$router.push({ path: `${ov}` })
    }

    get items() {
        return SupplierPayments.sort(store.state.supplier_payments.list.value)
    }

    get msg() {
        return store.state.supplier_payments.list.message
    }

    beforeRouteEnter(to: any, from: any, next: any) {
        if (to.params.timespan) {
            const filter = Timespan.tryParseKind(to.params.timespan)
            if (filter) {
                store.dispatch.supplier_payments.list.UPDATEFILTER(filter)
                next()
            } else {
                router.push({ path: "/sections/bank/supplier_payments/list/Last 7d" })
            }
        } else {
            router.push({ path: "/sections/bank/supplier_payments/list/Last 7d" })
        }
    }

    beforeRouteUpdate(to: any, from: any, next: any) {
        if (to.params.timespan) {
            const filter = Timespan.tryParseKind(to.params.timespan)
            if (filter) {
                store.dispatch.supplier_payments.list.UPDATEFILTER(filter)
                next()
            } else {
                router.push({ path: "/sections/bank/supplier_payments/list/Last 7d" })
            }
        } else {
            router.push({ path: "/sections/bank/supplier_payments/list/Last 7d" })
        }
    }

    exportCSV() {
        const filename = `supplier_payment.timeframe.${this.filter}.csv`
        MainShared.exportAccountingCSV(this.items, filename)
    }
}
