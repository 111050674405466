import * as Common from "@/lib/Util/Common"

import * as Timespan from "@/lib/Util/Types/Common/Timespan";

import * as State from "@/lib/Util/Types/Remittance/State";
import * as Kind from "@/lib/Util/Types/Remittance/Kind";

import * as IntervalRequest from "@/store/IntervalRequest"

export type T = {
    parent_id?: string[];

    remittance_doc_kind?: Kind.Kind[];
    remittance_state?: State.Kind[];

    advice_date?: Timespan.Kind;
    payment_date?: Timespan.Kind;

    document_id?: string[];

    reference?: string[];
}

export type Query = {
    parent_id?: string[];

    remittance_doc_kind?: string[];
    remittance_state?: string[];

    advice_date?: string;
    payment_date?: string

    document_id?: string[];

    reference?: string[];
}

export function getEmpty(): T {
    const result: T = {
        remittance_state: [State.getEmpty().kind],
        payment_date: "Last 360d"
    }
    return result
}

export function toQuery(x: T): Query {
    const asDocKindParam = (x: Kind.Kind) => {
        switch (x) {
            case "AsDocument": return "DOCUMENT"
            case "Virtual": return "VIRTUAL"
        }
    }
    const advice_date = IntervalRequest.mkIntervalTupleString(x.advice_date)
    const payment_date =
        x.payment_date && x.payment_date !== "All"
            ? IntervalRequest.mkIntervalTupleString(x.payment_date)
            : undefined
    console.log("remittance_state", x.remittance_state)
    const remittance_state = x.remittance_state ? x.remittance_state.map(x => x.toUpperCase()) : undefined
    const remittance_doc_kind = x.remittance_doc_kind ? x.remittance_doc_kind.map(asDocKindParam) : undefined

    return {
        ...x,
        advice_date,
        payment_date,
        remittance_state,
        remittance_doc_kind,
    }
}

export function from(x: any): T {
    const payment_date = x.payment_date
    const advice_date = x.advice_date
    const remittance_state =
        x.remittance_state ? (Array.isArray(x.remittance_state) ? x.remittance_state : [x.remittance_state]) : undefined
    const remittance_doc_kind =
        x.remittance_doc_kind
            ? (Array.isArray(x.remittance_doc_kind)
                ? x.remittance_doc_kind
                : [x.remittance_doc_kind])
            : undefined
    const parent_id = x.parent_id ? (Array.isArray(x.parent_id) ? x.parent_id : [x.parent_id]) : undefined
    const document_id = x.document_id ? (Array.isArray(x.document_id) ? x.document_id : [x.document_id]) : undefined
    const reference = x.reference ? (Array.isArray(x.reference) ? x.reference : [x.reference]) : undefined

    const result: T = {
        payment_date,
        advice_date,
        remittance_state,
        remittance_doc_kind,
        parent_id,
        document_id,
        reference,
    }
    return Common.removeUndefined(result)
}