import * as Currency from "@/lib/Util/Types/Common/Currency"
import * as Types from "@/lib/Util/Types/Types"
import * as Common from "@/lib/Util/Common"

import * as Storage from "@/lib/Util/Types/Storage/Storage"

import * as Kind from "./Kind"
import * as State from "./State"
import * as LineItem from "./LineItem"

export type T = {
    id?: string;
    storage?: Storage.T;
    _etag: string;

    virtual_id?: string;
    document_id?: string;
    uri?: string;
    kind: Kind.T;
    state: State.T;

    client_id: string;
    advice_date: Date;
    payment_date: Date;
    closing_date?: Date;
    amount: number;
    currency: Currency.T;
    line_items: LineItem.T[];

    bank_account: string
    transfer_account: string
}

export function getEmpty(): T {
    return {
        id: "",
        _etag: "",

        kind: Kind.getEmpty(),
        state: State.getEmpty(),

        document_id: "",
        virtual_id: "",

        client_id: "",
        advice_date: Common.getJustDate(new Date()),
        payment_date: Common.getJustDate(new Date()),
        amount: 0,
        currency: Currency.getEmpty(),
        line_items: [],

        bank_account: "",
        transfer_account: ""
    }
}

export function tryParse(x: any): T | null {
    if (x) {
        const advice_date = Common.parseServerDate(x.advice_date) ?? new Date()
        const payment_date = Common.parseServerDate(x.payment_date) ?? new Date()
        const closing_date = Common.parseServerDate(x.closing_date) ?? undefined
        const kind = Kind.tryParse(x.kind) ?? Kind.getEmpty()
        const state = State.tryParse(x.state) ?? State.getEmpty()
        const currency = Currency.tryParse(x.currency) ?? Currency.getEmpty()
        const line_items = LineItem.tryParseList(x.line_items) ?? []
        const document_id = x.document_id ?? ""
        const virtual_id = x.virtual_id ?? ""
        const result: T = {
            ...x,
            advice_date,
            closing_date,
            payment_date,
            kind,
            state,
            currency,
            line_items,
            document_id,
            virtual_id
        }
        return result
    }
    console.error("200 Remittance.Advice.tryParse", x)
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "Advice")