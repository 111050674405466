
import Vue from "vue"
import * as lodash from "lodash/fp"

import * as common from "@/components/Util/Common"
import * as Country from "@/lib/Util/Types/Common/Country"
import * as Email from "@/lib/Util/Types/Common/Email"

import * as InternalUser from "@/lib/Util/Types/InternalUser/InternalUser"
import * as AreasAccessLevels from "@/lib/Util/Types/InternalUser/AreasAccessLevels"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import EditForm from "@/components/Util/EditForm.vue"
import EditTable from "@/components/Util/EditTable.vue"
import EditDialog from "./EditDialog.vue"

import * as Shared from "./Shared"

import store from "@/store/store"

export default Vue.extend({
    name: "MasterdataInternalUserSingleMainView",
    components: {
        SuccessMessage,
        ErrorMessage,
        EditForm,
        EditTable,
        EditDialog,
    },
    data() {
        return {
            valid: true,
            isDirty: false,
            showSaveDialog: false,
            available_countries: Country.KEYS,
            loaded: lodash.cloneDeep(store.state.internal_user.single.value),
            headers: Shared.HEADERS,
            bus: new Vue(),
            addRow: () => null,
            convertRow: common.toEditWrapper,
            convertRowList: common.toWrapperList,
            sort: (v: any) => v,
            rules: {
                email: [
                    function checkLen(v?: string): boolean | string {
                        return (v?.length ?? 0) !== 0 || "Email must not be empty"
                    },
                    function isEmail(v?: string): boolean | string {
                        return Email.validate(v) || "Please enter valid email"
                    },
                ],
            },
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log("WTF!")
        if (to.params.id && typeof to.params.id === "string") {
            if (to.params.id === "new") {
                const v = InternalUser.getEmpty()
                store.commit.internal_user.single.updateValue(v)
            } else {
                store.dispatch.internal_user.single.LOAD(to.params.id)
            }
            next()
        }
        console.error("Could not enter Route", to, from)
    },
    beforeRouteLeave(to, from, next) {
        const event = {
            to,
            from,
            next,
        }
        this.bus.$emit("on-route-leave", event)
    },
    watch: {
        loaded: {
            handler: function(newval: InternalUser.T | null, oldval: InternalUser.T | null) {
                this.isDirty = !lodash.equals(this.loaded, this.item)
            },
            deep: true,
        },
        item: {
            handler: function(newval: InternalUser.T | null, oldval: InternalUser.T) {
                this.loaded = lodash.cloneDeep(this.item)
                //we assume that this happens due to trying to savewhile dirty
                //if (this.leave_route_info.force_route) {
                //    this.$router.push(this.leave_route_info.route)
                //}
            },
            deep: true,
        },
    },
    computed: {
        item: () => store.state.internal_user.single.value,
        msg: () => store.state.internal_user.single.message,
        used_country: {
            get: function(): Country.Kind {
                return this.loaded?.country?.kind ?? "DE"
            },
            set: function(v: Country.Kind) {
                if (this.loaded) {
                    this.loaded.country = Country.getByKind(v)
                }
            },
        },
        used_email: {
            get: function(): string {
                return this.loaded?.email.Value ?? "unknown@unknown.com"
            },
            set: function(v: string) {
                if (this.loaded) {
                    if (Email.Email.tryParse(v)) {
                        this.loaded.email = Email.Email.tryParse(v) ?? Email.getEmpty()
                    }
                }
            },
        },
        items: {
            get: function() {
                if (this.loaded) {
                    const result = AreasAccessLevels.toList(this.loaded.areas)
                    return result
                }
                return []
            },
        },
    },
    methods: {
        save() {
            if (this.loaded) {
                store.dispatch.internal_user.single.SAVE(this.loaded)
            }
        },
        cancel() {
            this.loaded = lodash.cloneDeep(this.item)
        },
        updateList(xs: AreasAccessLevels.ListItem[]) {
            const l = AreasAccessLevels.fromList(xs)
            if (l && this.loaded) {
                this.loaded.areas = l
            } else {
                console.error("InternalUser.Main.vue.updateList", xs)
            }
            console.log("UPDATELIST", xs)
        },
    },
})
