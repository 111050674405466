
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import * as lodash from "lodash"

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"])

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"
import SearchForm from "@/components/Util/SearchForm/Main.vue"

import * as Head from "@/lib/Util/Types/Workflow/SearchListItem/Head"

import store from "@/store/store"

import * as Shared from "./Shared"

@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
        SearchForm,
    },
})
export default class SearchMainView extends Vue {
    @Prop() query!: any
    headers = Shared.HEADERS
    filter: Shared.Filter = Shared.FILTER

    mounted() {
        this.filter = Shared.parseFilter(this.query)
        console.log("FILTER", this.filter)
        if (!Shared.isEmpty(this.filter)) {
            this.executeSearch()
        }
    }

    get items() {
        return store.state.workflow.list.search.value
    }

    get msg() {
        return store.state.workflow.list.search.message
    }

    area(x: Head.T) {
        return x.state.work_state.kind.toLowerCase() ?? "prepare"
    }

    step_state(x: Head.T) {
        return x.state.step_state?.kind ?? null
    }

    edit_state(x: Head.T) {
        return x.state.edit_state?.kind ?? null
    }

    workflow_state(x: Head.T) {
        return x.state.work_state?.kind ?? null
    }

    executeSearch() {
        const filter = {
            invoice_number: this.filter.invoice_number !== "" ? this.filter.invoice_number : undefined,
            supplier_name: this.filter.supplier_name !== "" ? this.filter.supplier_name : undefined,
            short_id: this.filter.short_id !== "" ? [this.filter.short_id] : undefined,
            order_number: this.filter.order_number !== "" ? this.filter.order_number : undefined,
            supplier_account: this.filter.supplier_account !== "" ? this.filter.supplier_account : undefined,
            external_id: this.filter.external_id !== "" ? this.filter.external_id : undefined,
        }
        store.commit.workflow.list.search.updateFilter(filter)
        store.dispatch.workflow.list.search.LOAD(filter)
    }
}
