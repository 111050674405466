import Vue from 'vue';
import * as lodash from 'lodash/fp';
import { defineModule, localActionContext } from "direct-vuex"

import * as REST from '@/lib/Util/REST'
import * as Target from '@/lib/Util/REST/Target'

import { RootState } from '@/store/Shared'

import * as Item from "@/lib/Util/Types/Workflow/SearchListItem/Tail"

export type Query = Partial<{
    wa_date_from: string;
    wa_date_to: string;
    po: string[];
    id: string[];
}>

export type Request = {
    area: Item.Kind;
    query: Query;
}

const ITEMS = {
    items: [] as Item.T[],
    message: REST.getEmpty(),
    timestamp: null as null | Date
}

type Items = typeof ITEMS

export const VALUES: Record<Item.Kind, Items> = {
    Pay: lodash.cloneDeep(ITEMS),
    Finish: lodash.cloneDeep(ITEMS),
    Archive: lodash.cloneDeep(ITEMS),
} as const

export type T = typeof VALUES

const state = {
    value: lodash.cloneDeep(VALUES),
}

export function load(token: string, request: Request) {
    const target = Target.LOOKUP.target[request.area].toLowerCase()
    const req = {
        token: token,
        url: `/${target}/search`,
        payload: request.query,
        transformer: Item.tryParseList
    };
    return REST.GET(REST.getEmpty(), req);
}

const mutations = {
    updateValue(state: State, x: { area: Item.Kind, model: Item.T[] }) {
        state.value[x.area].items = x.model
        state.value[x.area].timestamp = new Date()
    },
    updateListWithItem(state: State, x: { area: Item.Kind, model: Item.T | null }) {
        if (x.model) {
            const index = state.value[x.area].items.findIndex(v => v.id === x.model?.id)
            if (index >= 0) {
                Vue.set(state.value, index, x.model)
            }
            else {
                state.value[x.area].items.push(x.model)
            }
        }
    },
    removeItem(state: State, x: { area: Item.Kind, model: Item.T }) {
        const index = state.value[x.area].items.findIndex(v => v.id === x.model.id)
        state.value[x.area].items.splice(index, 1)
    },
    updateMsg(state: State, x: { area: Item.Kind, msg: REST.RestMessage }) {
        state.value[x.area].message = x.msg
    },
}

const getters = {
    items: (state: State) => (area: Item.Kind) => state.value[area].items,
}
const actions = {
    async LOAD(context: any, x: Request) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context) //as unknown as {commit: any, state: any}
        const rootState = context.rootState as RootState
        try {
            const loading_msg = {
                ...state.value[x.area].message,
                loading: true
            }
            commit.updateMsg({ area: x.area, msg: loading_msg })
            commit.updateValue({ area: x.area, model: [] })
            const target = Target.LOOKUP.target[x.area].toLowerCase()
            const req = {
                token: rootState.authentication?.token ?? "NO TOKEN",
                url: `/${target}/search`,
                payload: x.query,
                transformer: Item.tryParseList
            };
            const result = await REST.GET(state.value[x.area].message, req);
            console.log("LOAD", result)
            if (result.value) {
                const msg = {
                    ...lodash.cloneDeep(result.msg),
                    text: `Successfully loaded ${x.area} list`
                }
                commit.updateValue({ area: x.area, model: result.value })
                commit.updateMsg({ area: x.area, msg })
            }
            else {
                const text = typeof result.msg.text === "string" ? result.msg.text : result.msg.text.errors.join("|")
                const msg = {
                    ...lodash.cloneDeep(result.msg),
                    text
                }
                commit.updateMsg({ area: x.area, msg })
            }
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.value[x.area].message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg({ area: x.area, msg })
        }
    },
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    getters,
    actions
}

export type Mutations = typeof mutations
export type State = typeof state
export type Getters = typeof getters
export type Actions = typeof actions

export const module = defineModule(internal)
export type Module = typeof module
export default module

const actionCtx = (context: any) => localActionContext(context, module)
