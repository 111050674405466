import * as lodash from "lodash"
import * as Types from "@/lib/Util/Types/Types"

import * as Email from "@/lib/Util/Types/Common/Email"
import * as EmailKind from "@/lib/Util/Types/Biz/EmailKind"

export type T = {
    email: Email.Email;
    kind: EmailKind.T;
}

export function getEmpty() {
    return {
        email: Email.getEmpty(),
        kind: EmailKind.getEmpty()
    }
}

export function tryParse(x: any): T | null {
    if (x) {
        const email = Email.Email.tryParse(x.email?.Email) ?? Email.getEmpty()
        const kind = EmailKind.tryParse(x.kind)
        if (email && kind) {
            return {
                email,
                kind
            }
        }
        else {
            console.error("EmailTarget.tryParse", x)
            return null
        }
    }
    console.error("EmailTarget.tryParse", x)
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "EmailTarget")

export function extractEmails(xs: T[]) {
    return xs.map(item => item.email)
}

export function search(xs: T[], v: EmailKind.Kind) {
    return xs.find(item => item.kind.kind === v) ?? null
}

export function getInfoOrFirst(xs: T[]) {
    return search(xs, "Info")
}

export function replace(xs: T[], v: T) {
    const cloned = lodash.cloneDeep(xs)
    const i = cloned.findIndex(item => item.kind.kind === v.kind.kind)
    if (i >= 0) {
        cloned.splice(i, 1, v)
        console.log("REPLACE", cloned, v)
        return cloned
    }
    else {
        cloned.push(v)
        return cloned
    }
}
