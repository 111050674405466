var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
        currency: _vm.currency,
        distractionFree: true,
        precision: 2,
        valueRange: { min: _vm.minRange },
        allowNegative: _vm.allowNegative,
        currencyDisplay: 'code',
    }),expression:"{\n        currency: currency,\n        distractionFree: true,\n        precision: 2,\n        valueRange: { min: minRange },\n        allowNegative: allowNegative,\n        currencyDisplay: 'code',\n    }"}],staticClass:"currency-input",attrs:{"label":_vm.label,"outlined":_vm.outlined,"dense":"","clearable":_vm.clearable,"hide-details":_vm.hideDetails},on:{"keydown":_vm.onKey,"change":_vm.change},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})
}
var staticRenderFns = []

export { render, staticRenderFns }