import * as datefns from "date-fns"
import * as BuildInfo from "./BuildInfo"
import * as AccessRightDescriptionList from "../InternalUser/AccessRightDescriptionList"
import * as InternalUser from '../InternalUser/InternalUser'
import * as VatRules from '../VAT/VatRules'

export type T = {
  token: string;
  access_rights: AccessRightDescriptionList.T;
  user: InternalUser.T;
  build_info: BuildInfo.T;
  vatRules: VatRules.T | null;
  login_time: Date;
}
export function tryParse(x: any): T {
  const user = InternalUser.tryParse(x.user)
  const build_info = BuildInfo.tryParse(x)
  const login_time = x.login_time ? datefns.parseJSON(x.login_time) : new Date()
  return {
    ...x,
    user,
    build_info,
    login_time
  }
}

export function isValid(x: T | null) {
  const relogin_time_in_hours = 12
  if (x) {
    const diff = datefns.differenceInHours(new Date(), x.login_time)
    console.log("DIFF", diff)
    if (diff >= relogin_time_in_hours) {
      return false
    }
    return true
  }
  return false
}