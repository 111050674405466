
export type T = {
    description: string;
    account: string;
}

export function getEmpty(): T {
    return {
        description: "NEEDS DESCRIPTION",
        account: "000000",
    }
}
