import * as Common from "@/lib/Util/Common"
import * as Email from "@/lib/Util/Types/Common/Email"

export type T = {
    timestamp: Date;
    user: Email.Email;
}

export function tryParse(x?: any): T | null {
    if (x) {

        const user = Email.Email.tryParse(x.user)
        const timestamp = Common.parseServerDate(x.timestamp)
        if (user && timestamp) {
            return {
                user,
                timestamp
            }
        }
        console.error("LogEntry.tryParse", x)
        return null
    }
    console.error("LogEntry.tryParse", x)
    return null
}

export function getEmpty(): T {
    return {
        timestamp: new Date(1970, 0, 1),
        user: Email.getEmpty()
    }
}
