
import Vue from "vue"
import { Component } from "vue-property-decorator"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"

import EditPartView from "./EditPart.vue"
import FilesPartView from "./FilesPart.vue"

import * as Shared from "./Shared"

import store from "@/store/store"

Component.registerHooks(["beforeRouteEnter"])
@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
        EditPartView,
        FilesPartView,
    },
})
export default class BankRemittanceSingleMainView extends Vue {
    headers = Shared.HEADERS

    beforeRouteEnter(to: any, from: any, next: any) {
        if (to.params.id) {
            store.dispatch.remittance.single.LOAD(to.params.id)
            next()
        } else {
            console.error("Could not enter Route", to, from)
        }
    }

    get loaded() {
        return store.state.remittance.single.value
    }

    get msg() {
        return store.state.remittance.single.message
    }

    async upload(x: any) {
        console.log("UPLOAD", x)
        const req = {
            data: x.value,
            id: this.loaded?.id ?? "NO ID",
        }
        await store.dispatch.remittance.single.UPLOAD(req)
    }
}
