
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import * as Target from "@/lib/Util/REST/Target"

import store from "@/store/store"
import router from "@/router/router"

Component.registerHooks(["beforeRouteEnter"])

@Component({})
export default class WorkflowMainView extends Vue {
    @Prop({ default: "Prepare" }) area!: Target.TargetKind

    beforeRouteEnter(to: any, from: any, next: any) {
        const regex = /^\/sections\/workflow\/(\w+)$/
        const result = (to.path as string).match(regex)

        if (result) {
            router.push({ path: to.path + "/list" })
        } else {
            next()
        }
    }

    get item() {
        return store.state.workflow.single.value
    }

    get id() {
        return this.item?.id ?? ""
    }
}
