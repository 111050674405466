import * as Types from '@/lib/Util/Types/Types'
import * as ConfirmationStrategy from "./ConfirmationStrategy"
import * as DeclineReason from "./DeclineReason"

export const VALUES = {
    Edit: true,
    Sealed: true,
    Confirmed: ConfirmationStrategy.getEmpty(),
    Declined: DeclineReason.getEmpty(),
    Canceled: true,
} as const

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getEmpty(): T {
    return { kind: "Canceled", Canceled: true }
}

export function tryParse(x?: any): T | null {
    if (x) {
        if (x.Confirmed) {
            const value = ConfirmationStrategy.tryParse(x.Confirmed)
            if (value) {
                return {
                    kind: "Confirmed",
                    Confirmed: value
                }
            }
        }
        else {
            const tryParse = Types.buildTryParse<T, true>(VALUES, "kind", true, "StepState")
            const result = tryParse(x)
            if (result) {
                return result
            }
        }
    }
    console.error("StepState.tryParse", x)
    return null
}
