
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import * as Common from "@/lib/Util/Common"

@Component({
    components: {},
})
export default class FilesPartView extends Vue {
    @Prop({ default: "" }) source!: string
    filename = "Drop the Advice File here to upload!"

    async addFile(e: any) {
        const files = e.dataTransfer.files as File[]
        const result = await Common.fromFileToString(files)
        this.filename = result?.name ?? this.filename
        this.$emit("upload", result)
    }
}
