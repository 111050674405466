import * as Types from '@/lib/Util/Types/Types'

export const VALUES = {
    Open: true,
    Closed: true,
    Canceled: true,
} as const

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export const tryParse = Types.buildTryParse<T, true>(VALUES, "kind", true, "Remittance.State")

export function getEmpty(): T {
    return {
        kind: "Open",
        Open: true
    }
}