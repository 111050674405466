
import Vue from "vue"
import { Component, Watch, Prop } from "vue-property-decorator"

import * as EmailTarget from "@/lib/Util/Types/Biz/EmailTarget"

import * as Supplier from "@/lib/Util/Types/Supplier/Supplier"
import SupplierDialog from "./SupplierDialog.vue"
import * as Target from "@/lib/Util/REST/Target"
import store from "@/store/store"

@Component({
    components: { SupplierDialog },
})
export default class SupplierPartView extends Vue {
    @Prop({ default: null }) item!: Supplier.T
    @Prop({ default: "Prepare" }) area!: Target.TargetKind

    search = ""
    isLoading = false
    showSupplier = false

    get items() {
        if (store.state.supplier.list.value.length > 0) {
            return store.state.supplier.list.value
        } else {
            if (this.item) {
                return [this.item]
            } else {
                return []
            }
        }
    }

    get loaded() {
        return this.item
    }

    set loaded(x: Supplier.T) {
        this.$emit("update", x)
    }

    get email() {
        const targets = this.loaded?.emails ?? []
        const target = EmailTarget.getInfoOrFirst(targets)
        if (target) {
            return target.email.Value
        }
        else {
            return "unknown@unknown.com"
        }
    }

    @Watch("search", { deep: true })
    async onChanged(nv: string, ov: any) {
        if (nv && nv.length >= 4) {
            this.isLoading = true
            await store.dispatch.supplier.list.LOAD({ account_id: nv })
            if (store.state.supplier.list.value.length === 0) {
                await store.dispatch.supplier.list.LOAD({ name: nv })
            }
            this.isLoading = false
        }
    }

    updateSupplier(x: Supplier.T | null) {
        console.log("UPDATESUPPLIER", x)
        this.showSupplier = false
        this.$emit("update", x)
    }
}
