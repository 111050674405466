
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import * as lodash from "lodash"

import * as Common from "@/lib/Util/Common"

import * as Country from "@/lib/Util/Types/Common/Country"
import * as Currency from "@/lib/Util/Types/Common/Currency"

import * as Measure from "@/lib/Util/Types/Workflow/Doc/Measure"
import * as LineItem from "@/lib/Util/Types/Workflow/Doc/LineItem"

import NumberInput from "@/components/Util/NumberInput.vue"

import * as Shared from "./Shared"
import store from "@/store/store"

@Component({
    components: {
        NumberInput,
    },
})
export default class ManualFeesPartView extends Vue {
    @Prop({ default: [] }) items!: LineItem.T[]
    @Prop({ default: null }) country!: Country.Kind | null
    @Prop({ default: null }) currency!: Currency.Kind | null

    loaded = lodash.cloneDeep(this.items)
    available_measures = Measure.VALS

    get utils() {
        return new Shared.Utils(this.currency, this.country, store.state.authentication?.vatRules ?? null)
    }

    get headers() {
        return Shared.getHeadersLineItem(this.currency ?? "EUR")
    }

    remove(x: number) {
        this.loaded = Common.removeArrayElement(this.loaded, x)
        console.log("INDEX", this.loaded)
    }

    add() {
        const result = this.utils.empty
        if (result) {
            this.loaded.push(result)
        } else {
            console.error("LineItem.T could not be created")
        }
    }

    @Watch("items")
    onItemChanged(nv: LineItem.T[] | null) {
        if (this.loaded === nv || lodash.isEqual(this.loaded, nv)) {
            return
        }
        this.loaded = lodash.cloneDeep(this.items)
    }

    @Watch("loaded", { deep: true })
    onChange(nv: LineItem.T[]) {
        this.$emit("update", nv)
    }
}
