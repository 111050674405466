
import Vue from "vue"
import { Component } from "vue-property-decorator"

import store from "@/store/store"

@Component({})
export default class ReportsToolbar extends Vue {
    mounted() {
        store.commit.updateToolbar(true)
    }
}
