
import Vue from "vue"
import { Component } from "vue-property-decorator"

import router from "@/router/router"

Component.registerHooks(["beforeRouteEnter"])

@Component({})
export default class WorkflowMainView extends Vue {
    beforeRouteEnter(to: any, from: any, next: any) {
        const regex = new RegExp("^/sections/workflow/?$")
        const result = (to.path as string).match(regex)

        if (result) {
            router.push({ path: "/sections/workflow/prepare" })
        } else {
            next()
        }
    }
}
