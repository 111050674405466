import * as Common from "@/lib/Util/Common"
import * as Types from "@/lib/Util/Types/Types"
import * as Currency from "@/lib/Util/Types/Common/Currency"

import * as VatType from "@/lib/Util/Types/VAT/VatType"
import * as Measure from "./Measure"

export type T = {
    amount_currency: Currency.T;
    amount_value: number;
    description: string;
    units_count: number;
    units_measures: Measure.T;
    vat: VatType.T;
}

export function getEmpty(): T {
    return {
        amount_currency: Currency.getEmpty(),
        amount_value: 0,
        description: "",
        units_count: 0,
        units_measures: Measure.getEmpty(),
        vat: VatType.getEmpty()
    }
}

export function tryParse(x?: any): T | null {
    if (x) {
        const amount_currency = Currency.tryParse(x.amount_currency)
        const units_measures = Measure.tryParse(x.units_measures)
        const vat = VatType.tryParse(x.vat)
        if (amount_currency && units_measures && vat) {
            return {
                ...x,
                amount_currency,
                units_measures,
                vat,
            }
        }
    }
    console.error("LineItem.tryParse", x)
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "WorkflowDoc")

export function calcVAT(x: T): number;
export function calcVAT(x: T[]): number;

export function calcVAT(x: T | T[]): number {
    if (Array.isArray(x)) {
        const vats = x.map(calcVAT)
        const result = vats.reduce((prev, current) => prev + current, 0)
        return Common.roundNumber(result, 2)
    }
    else {
        const result = x.amount_value * x.units_count * (x.vat.value ?? 0) / 100
        return Common.roundNumber(result, 2)
    }
}