import MasterdataClientMainView from "@/components/Sections/Masterdata/Client/Main.vue"
import MasterdataClientListMainView from "@/components/Sections/Masterdata/Client/List/Main.vue"
import MasterdataClientSingleMainView from "@/components/Sections/Masterdata/Client/Single/Main.vue"
import MasterdataClientSubItemMainView from "@/components/Sections/Masterdata/Client/SubItem/Main.vue"

const router = {
    path: 'client',
    component: MasterdataClientMainView,
    children: [
        {
            path: 'list',
            component: MasterdataClientListMainView
        },
        {
            path: 'single/:id?',
            component: MasterdataClientSingleMainView
        },
        {
            path: 'sub_item/:id?/:client_id?',
            component: MasterdataClientSubItemMainView
        },
    ]
}

export default router