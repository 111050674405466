import * as Common from "@/lib/Util/Common"
import * as Timespan from "@/lib/Util/Types/Common/Timespan"
import * as Country from "@/lib/Util/Types/Common/Country"
import * as PaymentStatusFine from "@/lib/Util/Types/Invoice/PaymentStatusFine";

import * as Shared from './Shared'
import * as IntervalRequest from "@/store/IntervalRequest"

type QuerySubset = {
    payment_status_fine?: PaymentStatusFine.Kind[];
    client_name?: string;
    country?: Country.Kind;
    terse_id?: string;
}
export type T =
    QuerySubset &
    {
        timespan: Timespan.Kind;
    }

export type Query =
    Shared.Query &
    QuerySubset &
    {
        terse_ids?: string[];
    }

export function getEmpty(): T {
    return {
        timespan: "All",
        payment_status_fine: PaymentStatusFine.NEEDS_ACTION,
    }
}

export function toQuery(x: T): Query {
    const dates = x.timespan ? IntervalRequest.mkCreatedDate(x.timespan) : undefined
    return {
        ...dates,
        payment_status_fine: x.payment_status_fine,
        client_name: x.client_name,
        country: x.country,
        terse_id: x.terse_id
    }
}

export function from(x: any): T {
    const timespan = x.timespan
    const payment_status_fine =
        x.payment_status_fine ?
            (Array.isArray(x.payment_status_fine) ? x.payment_status_fine : [x.payment_status_fine]) :
            undefined
    const client_name = x.client_name
    const terse_id = x.terse_id
    const result: T = {
        timespan,
        payment_status_fine,
        client_name,
        terse_id
    }
    return Common.removeUndefined(result)
}