import * as lodash from 'lodash/fp';
import { defineModule, localActionContext } from "direct-vuex"

import * as ExternalUser from "@/lib/Util/Types/Masterdata/BusinessProcess/ExternalUser";
import * as REST from '@/lib/Util/REST'
import { RootState } from '@/store/Shared'

const state = {
    value: null as ExternalUser.T | null,
    message: REST.getEmpty()
}

const mutations = {
    updateValue(state: State, v: ExternalUser.T | null) {
        state.value = v;
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    },
}

export function migrate(token?: string) {
    const req = {
        token: token ?? "NO TOKEN",
        url: "/users/migrate",
        payload: {},
        transformer: function (v: any) { console.log("TRANSFORM:", v); return null }
    };
    return REST.GET(state.message, req);
}

async function saving(context: any, url: string, x: ExternalUser.T) {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const { commit, state } = actionCtx(context)
    const rootState = context.rootState as RootState
    try {
        const loading_msg = {
            ...state.message,
            loading: true
        }
        commit.updateMsg(loading_msg)

        const req = {
            token: rootState.authentication?.token ?? "NO TOKEN",
            url,
            payload: x,
            transformer: ExternalUser.tryParse
        };
        console.log("100 ExternalUser.Saving")
        const result = await REST.POST(state.message, req);
        const text =
            result.msg.success
                ? `Successfully saved ExternalUser: ${x.email.Value}`
                : result.msg.text
        const msg = {
            ...lodash.cloneDeep(result.msg),
            text
        }
        commit.updateValue(result.value)
        commit.updateMsg(msg)
        console.log("900 ExternalUser.Saving", result.value)
    }
    catch (ex) {
        const msg = {
            ...lodash.cloneDeep(state.message),
            text: JSON.stringify(ex)
        }
        commit.updateMsg(msg)
    }
}

async function loading(context: any, url: string) {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const { commit, state } = actionCtx(context)
    const rootState = context.rootState as RootState
    try {
        const loading_msg = {
            ...state.message,
            loading: true
        }
        commit.updateMsg(loading_msg)
        const req = {
            token: rootState.authentication?.token ?? "NO TOKEN",
            url,
            payload: {},
            transformer: ExternalUser.tryParse
        };
        const result = await REST.GET(state.message, req);
        const msg = {
            ...lodash.cloneDeep(result.msg),
            text: `Successfully loaded ExternalUser ${result.value?.cpc_id}`
        }
        commit.updateValue(result.value)
        commit.updateMsg(msg)
    }
    catch (ex) {
        const msg = {
            ...lodash.cloneDeep(state.message),
            text: JSON.stringify(ex)
        }
        commit.updateMsg(msg)
    }
}

const actions = {
    async LOAD(context: any, x: string) {
        await loading(context, `/users/${x}`)
    },
    async SAVE(context: any, x: { user: ExternalUser.T; bp_id?: string }) {
        if (x.bp_id) {
            await saving(context, `/users/${x.bp_id}/create`, x.user)
            return
        }
        if (x.user.id) {
            await saving(context, `/users/${x.user.id}/update`, x.user)
            return
        }
        console.error("Serious error while saving ExternalUser", x)
    },
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    actions,
}

export type Mutations = typeof mutations
export type State = typeof state
export type Actions = typeof actions

export const module = defineModule(internal)

export default module

const actionCtx = (context: any) => localActionContext(context, module)
