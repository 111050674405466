import * as Types from '@/lib/Util/Types/Types'

export const VALUES = {
    DidNotInitiate: true,
    AmountMismatch: true,
    TooManyLineItems: undefined as string | undefined,
    TooFewLineItems: undefined as string | undefined,
    Other: "" as string
} as const

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getEmpty(): T {
    return { kind: "Other", Other: "" }
}

export function tryParse(x?: any): T | null {
    if (x) {
        if (x.DidNotInitiate) {
            return {
                kind: "DidNotInitiate",
                DidNotInitiate: true
            }
        }
        else if (x.AmountMismatch) {
            return {
                kind: "AmountMismatch",
                AmountMismatch: true
            }
        }
        else if (x.TooManyLineItems) {
            return {
                kind: "TooManyLineItems",
                TooManyLineItems: x.TooManyLineItems
            }
        }
        else if (x.TooFewLineItems) {
            return {
                kind: "TooFewLineItems",
                TooFewLineItems: x.TooFewLineItems
            }

        }
        else if (x.Other) {
            return {
                kind: "Other",
                Other: x.Other
            }
        }
    }
    console.error("DeclineReason.tryParse", x)
    return null
}