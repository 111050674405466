var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticStyle:{"margin-left":"0px","margin-right":"0px"},attrs:{"fluid":""}},[_c('v-row',[(!_vm.isStatic)?_c('v-col',{attrs:{"cols":"1"}},[_c('tooltip-icon',{attrs:{"click":_vm.addEmpty,"icon":"mdi-plus-circle","text":"Add Row"}})],1):_vm._e(),_c('v-spacer'),_vm._t("search",function(){return [(_vm.is_searcheable)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e()]})],2),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 item-size",attrs:{"dense":"","headers":_vm.headers,"items":_vm.loaded,"search":_vm.search,"hide-default-footer":_vm.showAll,"disable-pagination":_vm.showAll,"custom-filter":_vm.runFilter},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_vm._l((_vm.headers),function(header,index){return [(
                                    _vm.cellType(header.value, item.value) ===
                                    'any'
                                )?_c('td',{key:index},[_vm._v(" "+_vm._s(_vm._f("format")(_vm.getValue(header.value, item.value)))+" ")]):_vm._e(),(
                                    _vm.cellType(header.value, item.value) ===
                                    'boolean'
                                )?_c('td',{key:index,staticClass:"text-md-center"},[_c('v-simple-checkbox',{attrs:{"value":_vm.getValue(header.value, item.value),"disabled":""}})],1):_vm._e()]}),_c('td',{staticClass:"text-md-center"},[(!_vm.isStatic)?_c('tooltip-icon',{attrs:{"click":() => _vm.deleteRow(item.index),"icon":"mdi-minus-circle","text":"Remove Row"}}):_vm._e(),_c('tooltip-icon',{attrs:{"click":() => _vm.edit(item),"icon":"mdi-pencil","text":"Edit Row"}})],1)],2)]}}])})],1)],1),(_vm.item !== null)?_vm._t("default",null,{"item":_vm.item,"dialogSave":_vm.dialogSave,"dialogCancel":_vm.dialogCancel}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }