import * as Types from '@/lib/Util/Types/Types'
import * as Protocols from "@/lib/Util/Types/Protocols"

export type Record = {
    EUR: true;
    GBP: true;
    CHF: true;
    USD: true;
}

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const VALUES: Record = {
    EUR: true,
    GBP: true,
    CHF: true,
    USD: true,
}

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)
export const VALS = Types.getDUArray(VALUES)

export function getByKind(x: Kind): T {
    return Types.makeSingleDUFromString(x)
}

export function getEmpty(): T {
    return { kind: "EUR", EUR: true }
}

export const tryParse = Types.buildTryParse<T, true>(VALUES, "kind", true, "Currency")

export class SearcheableProtocol implements Protocols.Searcheable {
    search(x: string): boolean {
        const self = this as any as T
        return self.kind.includes(x)
    }
}