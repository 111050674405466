import BankMainView from "@/components/Sections/Bank/Main.vue";
import BankToolbarView from "@/components/Sections/Bank/Toolbar.vue"

import BankRemittanceRouter from "@/components/Sections/Bank/Remittance/router"
import BankSupplierPaymentsRouter from "@/components/Sections/Bank/SupplierPayments/router"

const router = {
    path: 'bank',
    components: {
        default: BankMainView,
        toolbar: BankToolbarView
    },
    children: [
        BankRemittanceRouter,
        BankSupplierPaymentsRouter,
    ]
}

export default router