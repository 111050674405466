import * as lodash from 'lodash/fp';
import { defineModule, localActionContext } from "direct-vuex"

import * as Advice from "@/lib/Util/Types/Remittance/Advice";
import * as REST from '@/lib/Util/REST'
import { RootState } from '@/store/Shared'

import * as Filter from "./FilterNew"

const init = {
    value: [] as Advice.T[],
    filter: Filter.getEmpty(),
    message: REST.getEmpty(),
}

const state = {
    value: init.value,
    filter: init.filter,
    message: init.message,
}

const mutations = {
    updateValue(state: State, model: Advice.T[] | null) {
        const values = model ?? []
        state.value = values;
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    },
    updateFilter(stare: State, v: Filter.T) {
        state.filter = v
    }
}

export function migrate(token?: string) {
    const req = {
        token: token ?? "NO TOKEN",
        url: "/remittance/migrate",
        payload: {},
        transformer: function (v: any) { console.log("TRANSFORM:", v); return null }
    };
    return REST.GET(state.message, req);
}

const getters = {
}

const actions = {
    async LOAD(context: any, payload: Filter.Query) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context)
        const rootState = context.rootState as RootState
        try {
            const loading_msg = {
                ...state.message,
                loading: true
            }
            commit.updateMsg(loading_msg)
            commit.updateValue([])
            const req = {
                token: rootState.authentication?.token ?? "NO TOKEN",
                url: "/remittance",
                payload,
                transformer: Advice.tryParseList
            };
            const result = await REST.GET(state.message, req);
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text: "Successfully loaded Remittance list",
                timeout: 5000
            }
            commit.updateValue(result.value)
            commit.updateMsg(msg)
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg(msg)
        }
    }
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    getters,
    actions
}

export type Mutations = typeof mutations
export type State = typeof state
export type Getters = typeof getters
export type Actions = typeof actions

export const module = defineModule(internal)
export type Module = typeof module
export default module

const actionCtx = (context: any) => localActionContext(context, module)
