
import Vue from "vue";

import * as CostTypes from "@/lib/Util/Types/Masterdata/BusinessProcess/CostTypes"

import CostKindView from "./CostKind.vue"

export default Vue.extend({
    name: "CostTypesMainView",
    components: {
        CostKindView,
    },
    props: {
        item: {
            type: Object as () => CostTypes.T | null,
        } as Vue.PropOptions<CostTypes.T | null>,
        bp_sid: {
            type: String
        }
    },
    watch: {
        item: {
            handler(nv: any, ov: any) {
                console.log("CostTypes.Main.watch.item", nv, ov)
            }
        }
    }
});
