
import Vue from "vue";

import * as ExternalUser from "@/lib/Util/Types/Masterdata/BusinessProcess/ExternalUser"

import array2Tree from "array-to-tree"
import * as Shared from "./Shared"

export default Vue.extend({
    name: "ExternalUsersView",
    props: {
        items: {
            type: Array,
        } as Vue.PropOptions<ExternalUser.T[]>,
    },
    data() {
        return {
            headers: Shared.SIMPLE_HEADERS
        }
    },
    computed: {
        tree() {
            const l = this.items.map(x => { return { ...x, parent_id: x.reports_to?.Value, tree_id: x.email.Value } })
            return array2Tree(l, { customID: "tree_id" })
        }
    },
});
