import * as lodash from "lodash/fp"
import * as Email from "@/lib/Util/Types/Common/Email"

export type T = {
    name: string;
    email: Email.Email;
    invoice: boolean;
    reminder1: boolean;
    reminder2: boolean;
}

export function getEmpty(): T {
    return {
        name: "",
        email: Email.getEmpty(),
        invoice: false,
        reminder1: false,
        reminder2: false
    }
}

export function sort(xs: T[]): T[] {
    const result = lodash.cloneDeep(xs)
    result.sort(function (a, b) {
        if (a.email.Value === b.email.Value) {
            return 0
        }
        if (a.email.Value < b.email.Value) {
            return -1
        }
        else return 1
    })
    return result
}

export function tryParse(x: any): T {
    const email = Email.Email.tryParse(x.email.Email)
    return {
        ...x,
        email
    }
}

import * as Types from "@/lib/Util/Types/Types"

export const tryParseList = Types.buildTryParseList(tryParse, "EmailReceiver")