
import Vue from "vue"
import * as lodash from "lodash/fp"
import * as Shared from "./Shared"

const checkLen = (v: string) => {
    return v.length > 0 || "Name must not be empty"
}

export default Vue.extend({
    name: "EditDialog",
    components: {},
    props: {
        item: {
            type: Object as () => Shared.EditWrapper,
        } as Vue.PropOptions<Shared.EditWrapper>,
        dialogSave: {
            type: Function,
        },
        dialogCancel: {
            type: Function,
        },
    },
    data() {
        return {
            valid: true,
            rules: {
                description: [checkLen],
                account: [checkLen],
                tax_key: [checkLen],
            },
        }
    },
    computed: {
        loaded() {
            return lodash.cloneDeep(this.item)
        },
    },
    watch: {
        valid: {
            handler(nv: boolean) {
                console.log("WATCH VALID:", nv)
            },
        },
    },
    methods: {
        save() {
            console.log(
                "EDITDIALOG-EMAILRECEIVERS.SAVE",
                this.loaded,
                this.valid
            )
            this.dialogSave(this.loaded)
        },
        cancel() {
            console.log(
                "EDITDIALOG-EMAILRECEIVERS.CANCEL",
                this.loaded,
                this.valid
            )
            this.dialogCancel()
        },
    },
})
