
import Vue from "vue"
import Component from "vue-class-component"

import router from "@/router/router"

Component.registerHooks(["beforeRouteEnter"])

@Component({})
export default class ServicesSoldMainView extends Vue {
    beforeRouteEnter(to: any, from: any, next: any) {
        const regex = new RegExp("^/sections/reports/services_sold/?$")
        const result = (to.path as string).match(regex)

        if (result) {
            router.push({ path: "/sections/reports/services_sold/list/" })
        } else {
            next()
        }
    }
}
