import * as Types from "@/lib/Util/Types/Types"
import * as Money from "../Common/Money"

const VALUES = {
    incoming: Money.zero(),
    outgoing: Money.zero(),
    autofees: Money.zero(),
    manualfees: Money.zero(),
    sum: Money.zero(),
} as const

export type T = typeof VALUES

export const getEmpty: () => T = Types.mkGetEmpty<T>(VALUES)

export function tryParse(x?: any): T | null {
    if (x) {
        const incoming = Money.tryParse(x.incoming)
        const outgoing = Money.tryParse(x.outgoing)
        const autofees = Money.tryParse(x.autofees)
        const manualfees = Money.tryParse(x.manualfees)
        const sum = Money.tryParse(x.sum)
        const result: Types.Nullable<T> = {
            incoming,
            outgoing,
            autofees,
            manualfees,
            sum,
        }
        if (Types.checkNonNull(result)) {
            return result as T
        }
    }
    console.error("Totals.tryParse", x)
    return null
}
