
import Vue from "vue"
import { Component } from "vue-property-decorator"

import * as Doc from "@/lib/Util/Types/Workflow/Summary/Doc"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"

import * as Shared from "./Shared"
import * as MainShared from "../Shared"
import * as Common from "@/lib/Util/Common"

import store from "@/store/store"

Component.registerHooks(["beforeRouteEnter"])

@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
    },
})
export default class BankSupplierPaymentsSingleMainView extends Vue {
    headers = Shared.HEADERS

    beforeRouteEnter(to: any, from: any, next: any) {
        if (to.params.id) {
            store.dispatch.supplier_payments.single.LOAD(to.params.id)
            next()
        } else if (to.params.account_id) {
            store.dispatch.supplier_payments.single.LOAD_BY_QUERY(to.params)
            next()
        } else {
            console.error("Could not enter Route", to, from)
        }
    }

    get loaded() {
        return store.state.supplier_payments.single.value
    }

    get discounted() {
        return Common.roundNumber(this.loaded?.discounted.value ?? 0, 2)
    }

    get items() {
        const result = store.state.supplier_payments.single.value?.line_items
        if (!result) {
            console.error("ReportsSupplierPaymentsSingleMainView.items", result)
            return []
        }
        return result.map(Doc.asBase)
    }

    get msg() {
        return store.state.supplier_payments.single.message
    }

    downloadCSV() {
        if (this.loaded) {
            const filename = `bank.supplier_payment.single.${this.loaded.id}.csv`
            MainShared.exportBankCSV([this.loaded], filename)
        }
    }
}
