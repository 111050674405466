import * as Country from '@/lib/Util/Types/Common/Country'
import * as Currency from '@/lib/Util/Types/Common/Currency'
import * as Key from '@/lib/Util/Types/Invoice/Dashboard/Key'
import * as Entry from '@/lib/Util/Types/Invoice/Dashboard/Entry'

import * as lodash from 'lodash'

type CountryVolume = Partial<Record<Country.Kind, Entry.Volume>>
export type ViewBucket =
    CountryVolume & {
        name: Key.Kind;
        currency: Currency.T;
    }

export type ViewBucketContainer = {
    currency: Currency.Kind;
    values: ViewBucket[];
}

/** This is subtract function
    @param { BaseBucket[] } xs this needs to be filtered to contain the same name and currency
*/
function toViewBucket(xs: Entry.Bucket[]): ViewBucket | null {
    function transform(x: Entry.Bucket): ViewBucket {
        const result: ViewBucket = {
            name: x.name,
            currency: x.currency,
        }
        result[x.country.kind] = x.volume
        return result
    }
    if (xs.length >= 1) {
        const buckets = xs.map(transform)
        return buckets.reduce((acc, v) => { return { ...acc, ...v } })
    }
    return null
}

export function toViewBucketList(xs: readonly Entry.Bucket[]): ViewBucketContainer[] {

    const grouped =
        lodash
            .chain(xs)
            .groupBy((x) => x.currency.kind)
    const mapped =
        grouped
            .map(x => lodash.chain(x).groupBy("name").map(toViewBucket).value())
            .filter(x => x !== null)
            .value() as ViewBucket[][]
    return mapped.map(x => { return { currency: x[0].currency.kind, values: x } })
}

export function getHeaders(x: ViewBucket) {
    const keys = Object.keys(x)
    const countries = keys.filter(y => Country.KEYS.includes(y as Country.Kind))
    return ["name", ...countries]
}