import * as Types from "@/lib/Util/Types/Types"
import * as PerVolume from "./PerVolume"
import * as PerLineItem from "./PerLineItem"
import * as PerInvoiceType from "./PerInvoiceType"

export type T = {
    per_volume_pricing: PerVolume.T[];
    per_line_item_pricing: PerLineItem.T[];
    per_invoice_type_pricing: PerInvoiceType.T[];
}

export function getEmpty(): T {
    return {
        per_volume_pricing: [],
        per_line_item_pricing: [],
        per_invoice_type_pricing: [
            { type: { kind: "Invoice", Invoice: true }, selling_price: 0 },
            { type: { kind: "Hotel", Hotel: true }, selling_price: 0 },
            { type: { kind: "Entertainment", Entertainment: true }, selling_price: 0 },
            { type: { kind: "Amazon", Amazon: true }, selling_price: 0 }
        ]
    }
}

export type PricingTypeKind =
    | "PerVolume"
    | "PerLineItem"
    | "PerInvoiceType"

export type PricingType =
    | PerInvoiceType.T
    | PerVolume.T
    | PerLineItem.T

export type PricingTypeList = Types.DU2Array<PricingType>

export function getPricingTypeKind(x: PricingType): PricingTypeKind {
    if (PerVolume.isType(x)) {
        return "PerVolume"
    }
    else if (PerInvoiceType.isType(x)) {
        return "PerInvoiceType"
    }
    else {
        return "PerLineItem"
    }
}

export function sortPricingTypeList(x: PerInvoiceType.T[]): PerInvoiceType.T[];
export function sortPricingTypeList(x: PerVolume.T[]): PerVolume.T[];
export function sortPricingTypeList(x: PerLineItem.T[]): PerLineItem.T[];
export function sortPricingTypeList(x: PricingTypeList): PricingTypeList {
    if (x.length === 0) {
        return []
    }

    const kind = getPricingTypeKind(x[0])
    switch (kind) {
        case "PerInvoiceType": return PerInvoiceType.sort(x as PerInvoiceType.T[])
        case "PerLineItem": return PerLineItem.sort(x as PerLineItem.T[])
        case "PerVolume": return PerVolume.sort(x as PerVolume.T[])
    }
}

export function tryParse(x: any): T {
    const per_invoice_type_pricing = PerInvoiceType.tryParseList(x.per_invoice_type_pricing)
    return {
        ...x,
        per_invoice_type_pricing
    }
}