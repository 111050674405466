import * as datefns from 'date-fns'

import * as Common from "@/lib/Util/Common"
import * as Types from "@/lib/Util/Types/Types"

import * as Protocols from "@/lib/Util/Types/Protocols"

import * as ProcessType from "@/lib/Util/Types/Masterdata/BusinessProcess/ProcessType"
import * as ProcessingState from "@/lib/Util/Types/Workflow/Doc/ProcessingState"

import * as Country from "@/lib/Util/Types/Common/Country"
import * as VatRules from "@/lib/Util/Types/VAT/VatRules"

import * as InvoiceReference from "@/lib/Util/Types/Workflow/Doc/InvoiceReference"
import * as PaidInfo from "@/lib/Util/Types/Workflow/Doc/PaidInfo"
import * as PaymentMode from "@/lib/Util/Types/Workflow/PaymentMode"
import * as PaymentState from "@/lib/Util/Types/Workflow/PaymentState"
import * as GlobalItem from "@/lib/Util/Types/Workflow/SearchListItem/Global"
import * as Item from "@/lib/Util/Types/Workflow/SearchListItem/Tail"

import * as DiscountState from "./DiscountState"
import * as PaidState from "./PaidState"

import * as store from "@/store/store"

export function getHeaders(x: Item.Kind) {
    return [
        {
            text: 'Short-ID',
            value: "short_id",
        },
        {
            text: 'Mode',
            value: 'payment_mode',
            width: "3%"
        },
        {
            text: 'Client',
            value: 'client_name',
        },
        {
            text: 'Currency',
            value: 'doc_summary.total.currency.kind',
        },
        {
            text: 'Buyer',
            value: 'buyer_email',
        },
        {
            text: 'Supplier',
            value: 'supplier.name',
        },
        {
            text: 'Amount',
            value: 'amount',
            align: 'end'
        },
        {
            text: 'Paid',
            value: 'paid',
        },
        x === "Pay" ? {
            text: 'CN',
            value: 'credit_note',
        } : {},
        x === "Pay" ? {
            text: 'Discount',
            value: 'discount_state',
        } : {},
        x === "Pay" ? {
            text: 'Due',
            value: 'due',
        } : {},
        x === "Pay" ? {
            text: 'Gen?',
            value: 'generated',
        } : {},
        {
            text: 'Received',
            value: 'received',
        },
        {
            text: '',
            value: 'actions',
        },
    ]
}

export function sort(xs: Item.T[]): Item.T[] {
    //return lodash.orderBy(xs, ["last_edit_time", "order_number"], ["desc", "asc"])
    return xs
}

export type CreditNoteReference = {
    oid: string;
    sid: string;
}

export type ListItem = Item.T & {
    paid_state: PaidState.Kind;
    discount_state: DiscountState.Kind | null;
    disabled_payment: boolean;
    credit_note: CreditNoteReference | null;
    payment_state: PaymentState.T | null;
}

function toCreditNote(x: GlobalItem.T): CreditNoteReference {
    return { oid: x.external_id?.substring(3) ?? "", sid: x.short_id }
}

export function toCreditNotes(xs: GlobalItem.T[]): CreditNoteReference[] {
    return xs.map(toCreditNote)
}

export function toListItem(x: Item.T, cn: CreditNoteReference | null, ps: PaymentState.T | null): ListItem {
    const disabled_payment =
        (x.prepayment && x.prepayment.kind !== "Nothing") ||
        PaidState.to(x) === "Finished" ||
        PaidState.to(x) === "Empty" ||
        (ps !== undefined && ps !== null && ps.payment_mode.kind === "ON_HOLD")
    return {
        ...x,
        paid_state: PaidState.to(x),
        discount_state: DiscountState.to(x),
        disabled_payment,
        credit_note: cn,
        payment_state: ps
    }
}

export function toListItems(xs: Item.T[], cns: CreditNoteReference[], pss: PaymentState.T[]): ListItem[] {
    const ps_lookup = Common.convertArrayToObject(pss, v => v.id)
    function toListItemIntern(x: Item.T) {
        const cn = cns?.find(v => v.oid === x.short_id || v.sid === x.short_id) ?? null
        const ps = ps_lookup[x.id]
        return toListItem(x, cn, ps)
    }
    return xs.map(x => toListItemIntern(x))
}

export const LANG = {
    short_id: { kind: "regex" },
    client: { kind: "regex" },
    supplier: { kind: "regex" },
    buyer: { kind: "regex" },
    received: { kind: "boolean" },
    process_type: { kind: "select_multi", value: ProcessType.KEYS },
    paid: { kind: "select_multi", value: PaidState.KEYS },
    country: { kind: "select_multi", value: Country.KEYS },
    discount: { kind: "select_multi", value: DiscountState.KEYS },
    payment_mode: { kind: "select_multi", value: PaymentMode.KEYS },
    payable: { kind: "boolean" },
    prepayment: { kind: "boolean" },
    external_id: { kind: "regex" },
    hasCreditNote: { kind: "boolean" }
} as const

type Filter = Types.RequireAtLeastOne<typeof LANG>

function toExternalId(x: Item.T): string {
    return x.doc_summary.external_id ?? x.short_id
}

export const filter: Protocols.Filter<Filter, ListItem> = (expression, values) => {
    const filters: [Protocols.ValuePath<ListItem>, Protocols.QueryExpressionValue | undefined][] = [
        [t => t.short_id, expression.short_id],
        [t => t.doc_summary.supplier.name, expression.supplier],
        [t => t.doc_summary.client, expression.client],
        [t => t.doc_summary.employee.Value, expression.buyer],
        [t => t.process_type?.kind, expression.process_type],
        [t => PaidState.to(t), expression.paid],
        [t => t.payment_summary.paid.kind !== "NotPaid", expression.received],
        [t => t.doc_summary.country?.kind, expression.country],
        [t => t.payment_state?.payment_mode.kind, expression.payment_mode],
        [t => DiscountState.to(t), expression.discount],
        [t =>
            (t.prepayment === null || t.prepayment === undefined || t.prepayment.kind === "Nothing") &&
            !(PaidState.to(t) === "Finished" || PaidState.to(t) === "Empty"), expression.payable,
        ],
        [t =>
            (t.prepayment !== null && t.prepayment !== undefined && t.prepayment.kind !== "Nothing") &&
            t.payment_summary.supplier_payment.kind === "OnRequest",
        expression.prepayment
        ],
        [t => toExternalId(t), expression.external_id],
        [t => t.credit_note ? true : false, expression.hasCreditNote],
    ]
    return Protocols.filter(values, filters)
}

//this can be much improved. mure type safety
export function parseFilter(x: any): Protocols.QueryExpression<typeof LANG> {
    const result: Protocols.QueryExpression<typeof LANG> = {}
    if (x.short_id) {
        result.short_id = { kind: "regex", value: x.short_id.toString() }
    }
    if (x.client) {
        result.client = { kind: "regex", value: x.client.toString() }
    }
    if (x.supplier) {
        result.supplier = { kind: "regex", value: x.supplier.toString() }
    }
    if (x.buyer) {
        result.buyer = { kind: "regex", value: x.buyer.toString() }
    }
    if (x.external_id) {
        result.external_id = { kind: "regex", value: x.external_id.toString() }
    }
    if (x.received) {
        const r = (x.received.toString() as string).toLowerCase() === "true" ? true : false
        result.received = { kind: "boolean", value: r }
    }
    if (x.process_type) {
        const r = (x.process_type.toString() as string).split(";")
        result.process_type = { kind: "select_multi", value: r }
    }
    if (x.paid) {
        const r = (x.paid.toString() as string).split(";")
        result.paid = { kind: "select_multi", value: r }
    }
    if (x.country) {
        const r = (x.country.toString() as string).split(";")
        result.country = { kind: "select_multi", value: r }
    }
    if (x.discount) {
        const r = x.discount.toString()
        result.discount = { kind: "select_single", value: r }
    }
    if (x.payable) {
        const r = (x.payable.toString() as string).toLowerCase() === "true" ? true : false
        result.payable = { kind: "boolean", value: r }
    }
    if (x.prepayment) {
        const r = (x.prepayment.toString() as string).toLowerCase() === "true" ? true : false
        result.prepayment = { kind: "boolean", value: r }
    }
    if (x.hasCreditNote) {
        const r = (x.hasCreditNote.toString() as string).toLowerCase() === "true" ? true : false
        result.hasCreditNote = { kind: "boolean", value: r }
    }
    return result
}

function asDate(x: Date | null | undefined) {
    if (x) {
        return datefns.format(x, "dd.MM.yyyy")
    }
    return ""
}

function paymentDate<T>(x: ProcessingState.T<T> | null) {
    if (x) {
        switch (x.kind) {
            case "Finished": return x.Finished[1].timestamp
        }
    }
    return null
}

function invoiceCreation(x: InvoiceReference.T) {
    switch (x.kind) {
        case "Single": return paymentDate(x.Single)
        case "Split": return paymentDate(x.Split[0])
    }
}

function paymentReceipt(x: PaidInfo.T) {
    switch (x.kind) {
        case "NotPaid": return null
        case "Single": return x.Single
        case "Split": {
            const [first, second] = x.Split
            if (first && second) {
                return first > second ? first : second
            }
            else if (first && second === null) {
                return first
            }
            else if (first === null && second) {
                return second
            }
            else {
                return null
            }
        }
    }
}

export function toCSVRecord(x: Item.T) {

    const country = x.doc_summary.country?.kind ?? "DE"

    const fees = x.doc_summary.manual_fees.value + x.doc_summary.auto_fees.value
    let fees_net = 0
    if (store.default.state.authentication) {
        const rules = store.default.state.authentication.vatRules?.[country as VatRules.NarrowCountry]
        if (rules) {
            fees_net = Common.roundNumber(fees / (100 + rules.Normal) * 100, 2)
        }
        else {
            fees_net = 0
        }
    }
    else {
        fees_net = 0
    }

    return {
        id: x.short_id,
        iban: x.doc_summary.supplier_account.kind,
        client: x.doc_summary.client,
        bp: x.doc_summary.bp_ref_desc,
        process_type: x.process_type.kind === "InvoiceToPay" ? "I2P" : "O2P",
        country: x.doc_summary.country?.kind ?? "DE",
        currency: x.doc_summary.total.currency?.kind ?? "EUR",
        last_edit_time: asDate(x.last_edit_time),
        duration_approve: x.doc_summary.durations?.duration_approve ?? 0,
        duration_prepare: x.doc_summary.durations?.duration_prepare ?? 0,
        duration_receipt: x.doc_summary.durations?.duration_receipt ?? 0,
        duration_total: x.doc_summary.durations?.duration_total ?? 0,
        due_date: asDate(x.doc_summary.payment_terms?.due_date),
        receipt_date: asDate(x.doc_summary.payment_terms?.receipt_date),
        using_invoice_date_and_npt: asDate(x.doc_summary.payment_terms?.using_invoice_date_and_npt),
        using_receipt_date_and_npt: asDate(x.doc_summary.payment_terms?.using_receipt_date_and_npt),
        npt: x.doc_summary.payment_terms?.npt ?? null,
        payment_term_diff: x.doc_summary.payment_terms?.payment_term_diff ?? null,
        given_payment_term_span: x.doc_summary.payment_terms?.given_payment_term_span ?? null,
        buyer: x.doc_summary.employee.Value,
        supplier_name: x.doc_summary.supplier.name,
        amount: x.doc_summary.incoming_position_data.value,
        fees: fees,
        fees_net: fees_net,
        supplier_payment_date: asDate(paymentDate(x.payment_summary.supplier_payment)),
        total: x.doc_summary.total.value,
        invoice_creation: asDate(invoiceCreation(x.payment_summary.invoice)),
        payment_receipt: asDate(paymentReceipt(x.payment_summary.paid)),
        order_number: x.doc_summary.order_no ?? ".",
    }
}

export function toInvoicePrepRecord(x: Item.T) {

    return {
        id: x.short_id,
        client: x.doc_summary.client,
        country: x.doc_summary.country?.kind ?? "DE",
        currency: x.doc_summary.total.currency?.kind ?? "EUR",
        due_date: asDate(x.doc_summary.payment_terms?.due_date),
        receipt_date: asDate(x.doc_summary.payment_terms?.receipt_date),
        payment_term_diff: x.doc_summary.payment_terms?.payment_term_diff ?? null,
        given_payment_term_span: x.doc_summary.payment_terms?.given_payment_term_span ?? null,
        buyer: x.doc_summary.employee.Value,
        supplier_name: x.doc_summary.supplier.name,
        supplier_payment_date: asDate(paymentDate(x.payment_summary.supplier_payment)),
        total: x.doc_summary.total.value,
        invoice_creation: asDate(invoiceCreation(x.payment_summary.invoice)),
        payment_receipt: asDate(paymentReceipt(x.payment_summary.paid))
    }
}
