import * as Common from "@/lib/Util/Common"

type Rules<T> = { [P in keyof T]?: ((x: T[P]) => string | boolean)[] };

export interface HasRules<T> {
    rules: Rules<T>;
}

type HasRuleCtor<T> = new () => HasRules<T>;

export function addHasRules<T>(x: T | null, c: HasRuleCtor<T>): T & HasRules<T> | null {
    if (x) {
        const result = Object.assign(new c(), x)
        return result
    }
    return null
}

export interface Searcheable {
    search(x: string): boolean;
}

type SearcheableCtor = new () => Searcheable;

export function addSearcheable<T>(x: T | null, c: SearcheableCtor): T & Searcheable | null {
    if (x) {
        const result = Object.assign(new c(), x)
        return result
    }
    return null
}

export type QueryType =
    | { kind: "number" }
    | { kind: "boolean" }
    | { kind: "string" }
    | { kind: "date" }
    | { kind: "date_range" }
    | { kind: "regex" }
    | { kind: "select_single"; value: string[] }
    | { kind: "select_multi"; value: string[] }

export type QueryExpressionValue =
    | { kind: "number"; value: number }
    | { kind: "boolean"; value: boolean }
    | { kind: "string"; value: string }
    | { kind: "date"; value: Date }
    | { kind: "date_range"; value: [Date, Date] }
    | { kind: "regex"; value: string }
    | { kind: "select_single"; value: string }
    | { kind: "select_multi"; value: string[] }

export type Writeable<T> = { -readonly [P in keyof T]: T[P] }
export type AbstractMapping<S, T> = {
    [K in keyof S]: T
}
export type QueryLanguage<S> = AbstractMapping<S, QueryType>
export type QueryExpression<S> = Partial<Writeable<AbstractMapping<S, QueryExpressionValue>>>

export type ExpressionType<T> = {
    key: keyof T
    value: QueryExpressionValue
}

export type ExpressionValueList<T> = ExpressionType<T>[]

export function toList<T>(x: QueryExpression<T>): ExpressionValueList<T> {
    const entries = Object.entries(x) as [keyof T, QueryExpressionValue][]
    const filtered = entries.filter(x => x[1] !== undefined)
    const result = filtered.map(x => {
        return { key: x[0], value: x[1] }
    })

    return result
}

export function createExpression<T>(x: QueryLanguage<T>): QueryExpression<T> {
    const keys = Object.keys(x) as (keyof QueryLanguage<T>)[]
    const result: QueryExpression<T> = {}
    keys.map(x => (result[x] = undefined))
    return result
}

export type Filter<Q, T> = (q: QueryExpression<Q>, xs: T[]) => T[]

export type QueryExpressionValueTypes = QueryExpressionValue["value"] | undefined
export type ValuePath<T> = (v: T) => QueryExpressionValueTypes

export function filter_single<T>(values: T[], path: ValuePath<T>, x?: QueryExpressionValue): T[] {
    if (x) {
        switch (x.kind) {
            case "boolean":
                return values.filter((w) => {
                    if (x.value) {
                        return path(w) === x.value
                    }
                    else {
                        return path(w) === false
                    }
                })
            case "number":
                return values.filter((w) => path(w) === x.value)
            case "string":
                return values.filter(w => (path(w) as string)?.includes(x.value) ?? false)
            case "select_single":
                return values.filter(w => (path(w) as string) === x.value)
            case "select_multi":
                return values.filter(w => x.value.includes(path(w) as string))
            case "regex": {
                const re = new RegExp(x.value)
                const result = values.filter(w => re.exec(path(w) as string) !== null)
                return result
            }
            case "date":
                return values.filter(w => {
                    const sv = Common.getJustDate(x.value).getTime()
                    const tv = Common.getJustDate(path(w) as Date).getTime()
                    return tv === sv
                })
        }
    }
    return values
}

export function filter<T>(values: T[], paths_expr: [ValuePath<T>, QueryExpressionValue | undefined][]): T[] {
    let result = values
    for (const [path, expr] of paths_expr) {
        result = filter_single(result, path, expr)
    }
    return result
}

export function clean(x: any) {
    for (const k in x) {
        x[k] = undefined
    }
}