import * as VatType from "@/lib/Util/Types/VAT/VatType"

export type T = {
    description: string;
    tax_rate: VatType.T;
    account: string;
    tax_key: string;
}
export function getEmpty(x: VatType.T) {
    return {
        description: "NEEDS DEASCRIPTION",
        tax_rate: x,
        account: "000000",
        tax_key: "0"
    }
}

export function tryParse(x: any): T | null {
    if (x) {
        const tax_rate = VatType.tryParse(x.tax_rate)
        if (tax_rate) {
            return {
                ...x,
                tax_rate,
            }
        }
    }
    return null
}

export function updateTaxRate(x: T, y: number): T {
    return {
        ...x,
        tax_rate: VatType.updateTaxRate(x.tax_rate, y)
    }
}
