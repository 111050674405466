import * as Country from "@/lib/Util/Types/Common/Country"
import * as VatRule from "./VatRule"
import * as VatType from "./VatType"

export type NarrowCountry =
    | "DE"
    | "AT"
    | "CH"

export type T = Record<NarrowCountry, VatRule.T>

export function getVatRule(x: T, country: NarrowCountry): VatRule.T {
    return x[country]
}

export function isNarrowCountry(x: Country.Kind): x is NarrowCountry {
    return ["DE", "AT", "CH"].includes(x)
}

export function getCountryDUArray(x: T, country: Country.Kind): VatType.T[] {
    if (isNarrowCountry(country)) {
        const rules = getVatRule(x, country)
        const result = VatRule.toList(rules)
        return result
    } else {
        return []
    }
}