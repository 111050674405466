import { ExportToCsv } from "export-to-csv"

import * as CostKind from "@/lib/Util/Types/Masterdata/BusinessProcess/CostKind"
import * as CodeInfo from "@/lib/Util/Types/Masterdata/BusinessProcess/CodeInfo"

import * as common from "@/components/Util/Common"
import * as Common from "@/lib/Util/Common";

export type IToKind = {
    toKind(): CostKind.T;
}

type IKind<T> = IToKind & {
    value: T;
    required: boolean;
    description: string;
}

export class TextKind implements IKind<string> {
    public constructor(x: any) {
        this.value = x.Text?.[0] ?? ""
        this.required = x.Text?.[1] ?? false
        this.description = x.Text?.[2] ?? ""
    }
    value: string;
    required: boolean;
    description: string;

    toKind(): CostKind.T {
        return {
            kind: "Text",
            Text: [
                this.value,
                this.required,
                this.description
            ]
        }
    }
}

export class ListKind implements IKind<CodeInfo.T[]> {
    public constructor(x: any) {
        this.value = x.List?.[0] ?? []
        this.required = x.List?.[1] ?? false
        this.description = x.List?.[2] ?? ""
    }
    value: CodeInfo.T[];
    required: boolean;
    description: string;

    toKind(): CostKind.T {
        return {
            kind: "List",
            List: [
                this.value,
                this.required,
                this.description
            ]
        }
    }
}

export class NothingKind implements IToKind {
    toKind(): CostKind.T {
        return { kind: "Nothing", Nothing: true }
    }
}

export type ExtendedCostKind =
    | { kind: "List"; value: ListKind }
    | { kind: "Text"; value: TextKind }
    | { kind: "Nothing"; value: NothingKind }

export function toExtendedCostKind(x: CostKind.T): ExtendedCostKind {
    switch (x.kind) {
        case "List": return { kind: "List", value: new ListKind(x) }
        case "Text": return { kind: "Text", value: new TextKind(x) }
        case "Nothing": return { kind: "Nothing", value: new NothingKind() }
    }
}

export class Protocols {
    search(x: string): boolean {
        const self = this as any as CodeInfo.T
        const result =
            self.code.includes(x) ||
            self.desc.includes(x)
        return result
    }
}

export type EditWrapper = common.EditWrapper<CodeInfo.T & Protocols>

export function toEditWrapper(x: CodeInfo.T, index: number, new_item = false): EditWrapper {
    const result = Object.assign(new Protocols(), x)
    return common.toEditWrapper(result, index, new_item)
}

export function toWrapperList(xs: CodeInfo.T[]): EditWrapper[] {
    return xs.map((x, i) => toEditWrapper(x, i, false))
}

export const HEADERS = [
    {
        text: 'Code',
        value: 'code',
        align: "start"
    },
    {
        text: 'Description',
        value: 'desc',
        align: "start"
    },
    {
        text: 'Actions',
        value: 'actions',
        align: "start"
    },
]

export function exportCSV(bp_sid: string, part_name: string, records: CodeInfo.T[]) {
    const filename = `${bp_sid}.cost_types-${part_name}.${Common.toServerDate(new Date())}`

    const options = {
        fieldSeparator: ";",
        quoteStrings: '"',
        decimalSeparator: ",",
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        filename: filename,
        useKeysAsHeaders: true,
    };

    const exporter = new ExportToCsv(options)

    exporter.generateCsv(records)
}