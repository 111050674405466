export enum URLS {
    DEV_URL = "http://localhost:8080/api",
    PROD_URL = "https://compraga-prod-web.azurewebsites.net/api",
    SOLUTIONS = "https://compraga-solutions-web.azurewebsites.net/api",
    LOCAL_TUNNEL = "https://compraga-dev-rk.loca.lt/api",
    PAGEKIT = "https://remotedevcompragark.pagekite.me/api"
}

//const TARGET: URLS = URLS.PROD_URL
//const TARGET: URLS = URLS.DEV_URL
const TARGET: URLS = URLS.SOLUTIONS
//const TARGET: URLS = URLS.LOCAL_TUNNEL
//const TARGET: URLS = URLS.REMOTE_DEV

export function getServer(): URLS {
    return TARGET
}
