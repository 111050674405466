export type T = {
    bp_description: string;
    bp_id: string;
    client_name: string;
}

export function tryParse(x?: any): T | null {
    if (x) {
        return x
    }
    console.error("InvoiceKey.tryParse", x)
    return null
}
