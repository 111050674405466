import { Email } from "../Common/Email"
import * as common from "@/lib/Util/Common"

export type T = {
    receivers: Email[];
    timestamp: Date;
}

export function getEmpty(): T {
    return {
        receivers: [],
        timestamp: new Date(1970, 1, 1)
    }
}

export function tryParse(x: any): T | null {
    const timestamp = common.parseServerDate(x.timestamp)
    const receivers = (x.receivers as any[]).map((x) => Email.tryParse(x.Email))
    const all_emails_valid = receivers.every((x) => x !== null)
    if (all_emails_valid && timestamp) {
        return {
            timestamp,
            receivers: receivers as Email[]
        }
    }
    return null
}
