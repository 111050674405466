
import Vue from "vue";

import * as CommonComponents from "@/components/Util/Common";
import * as Currency from "@/lib/Util/Types/Common/Currency";
import * as Shared from "./Shared";

import store from "@/store/store"

export default Vue.extend({
    name: "InvoiceDashboardMainView",
    beforeRouteEnter(to, from, next) {
        store.dispatch.invoice.dashboard.load()
        next()
    },
    computed: {
        msg: () => store.state.invoice.dashboard.message,
        items: () => {
            const result = Shared.toViewBucketList(store.getters.invoice.dashboard.filtered).map(x => x.values)
            if (result.length >= 1) {
                return result[0]
            }
            return []
        },
        headers: (): CommonComponents.DataTableHeaders => {
            const view_buckets = Shared.toViewBucketList(store.getters.invoice.dashboard.filtered)
            if (view_buckets.length >= 1) {
                const headers = Shared.getHeaders(view_buckets[0].values[0]);
                const result = headers.map((x) => { return { text: x, value: x, align: "center" } })
                return result
            }
            return []
        },
        currencies: () => store.getters.invoice.dashboard.avaliableCurrencies,
        filter: () => store.state.invoice.dashboard.filter
    },
    methods: {
        updateFilter(x: Currency.Kind) {
            store.commit.invoice.dashboard.updateFilter(x)
        }
    }

});
