
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import * as Country from "@/lib/Util/Types/Common/Country"

import * as lodash from "lodash/fp"

import * as Email from "@/lib/Util/Types/Common/Email"
import * as Supplier from "@/lib/Util/Types/Supplier/Supplier"
import * as Target from "@/lib/Util/REST/Target"
import * as EmailTarget from "@/lib/Util/Types/Biz/EmailTarget"
import * as EmailKind from "@/lib/Util/Types/Biz/EmailKind"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"

import store from "@/store/store"

@Component({
    components: {
        ErrorMessage,
        SuccessMessage,
    },
})
export default class SupplierDialog extends Vue {
    @Prop({ default: false }) show!: boolean
    @Prop({ default: null }) item!: Supplier.T
    @Prop({ default: "Prepare" }) area!: Target.TargetKind

    valid = true
    overlay = false
    rules = {
        email: [
            function checkLen(v?: string): boolean | string {
                return (v?.length ?? 0) !== 0 || "Email must not be empty"
            },
            function isEmail(v?: string): boolean | string {
                return Email.validate(v) || "Please enter valid email"
            },
        ],
        name: [
            (v: string) => {
                return v.length > 0 || "Name must not be empty"
            },
        ],
    }
    available_countries = Country.KEYS

    get loaded() {
        return lodash.cloneDeep(this.item)
    }

    extractEmail(x: EmailKind.Kind) {
        const found = EmailTarget.search(this.loaded.emails, x)
        return found?.email.Value ?? ""
    }

    replaceEmail(v: string, k: EmailKind.Kind) {
        const email = Email.Email.tryParse(v)
        if (email) {
            const value: EmailTarget.T = {
                kind: EmailKind.getByKind(k),
                email
            }
            this.loaded.emails = EmailTarget.replace(this.loaded.emails, value)
        }
    }

    get used_info_email() {
        return this.extractEmail("Info")
    }
    set used_info_email(v: string) {
        this.replaceEmail(v, "Info")
    }

    get used_sales_email() {
        return this.extractEmail("Sales")
    }
    set used_sales_email(v: string) {
        this.replaceEmail(v, "Sales")
    }
    get used_accounting_email() {
        return this.extractEmail("Accounting")
    }
    set used_accounting_email(v: string) {
        this.replaceEmail(v, "Accounting")
    }

    get used_country() {
        return this.loaded?.country.kind ?? "DE"
    }

    set used_country(v: Country.Kind) {
        if (this.loaded) {
            this.loaded.country = Country.getByKind(v)
        }
    }

    get msg() {
        return store.state.supplier.single.message
    }

    get isValid() {
        return this.loaded !== undefined && this.loaded !== null
    }

    get isIBAN() {
        console.log("IS IBAN", this.loaded?.account.kind === "IBAN", this.loaded?.account)
        return this.loaded?.account.kind === "IBAN" ?? false
    }

    set isIBAN(x: boolean) {
        if (this.loaded) {
            if (x === true) {
                this.loaded.account.kind = "IBAN"
            } else {
                this.loaded.account.kind = "OTHER"
            }
        }
    }

    cancel() {
        console.log("SUPPLIER-DIALOG.CANCEL", this.loaded, this.valid)
        this.$emit("updateSupplier", lodash.cloneDeep(this.item))
    }

    async save() {
        console.log("SUPPLIER-DIALOG.SAVE", this.loaded, this.item)
        if (this.loaded) {
            this.loaded.account.account_id = this.loaded.account.account_id.replaceAll(" ", "")
            this.loaded.vat_id = this.loaded.vat_id ? this.loaded.vat_id.replaceAll(" ", "") : undefined
            if ((this.area === "Prepare" || this.area === "Verify") && this.loaded) {
                const actual = await store.dispatch.supplier.single.LOAD(this.loaded.account.account_id)
                let supplier = lodash.cloneDeep(this.loaded)
                const last_loaded = store.state.supplier.single.value
                supplier._etag = last_loaded ? last_loaded._etag : ""

                const url = Target.LOOKUP.target[this.area]
                const req = {
                    url,
                    supplier,
                }
                const _ = await store.dispatch.supplier.single.SAVE(req)
                if (store.state.supplier.single.value) {
                    console.log("LOADED:", this.loaded.emails)
                    this.$emit("updateSupplier", lodash.cloneDeep(this.loaded))
                } else {
                    store.state.supplier.single.message.active = false
                    store.state.supplier.single.message.loading = false
                    this.overlay = true
                }
            } else {
                this.$emit("updateSupplier", lodash.cloneDeep(this.loaded))
            }
        }
    }
}
