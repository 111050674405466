import * as Country from "@/lib/Util/Types/Common/Country"
import * as Protocols from "@/lib/Util/Types/Protocols"

export type T = {
    street?: string;
    number?: string;
    zip?: string;
    city?: string;
    country: Country.T;
}

export function getEmpty(): T {
    return {
        street: "",
        number: "",
        zip: "",
        city: "",
        country: Country.getEmpty()
    }
}

export function tryParse(x: any) {
    if (x) {
        const country = Country.tryParse(x.country)
        return {
            ...x,
            country
        }
    }
    return null
}

export class SearcheableProtocol implements Protocols.Searcheable {
    search(x: string): boolean {
        const self = this as any as T
        const country = Protocols.addSearcheable(self.country, Country.SearcheableProtocol)
        return (self.street?.toLowerCase().includes(x) ?? false) ||
            (self.city?.toLowerCase().includes(x) ?? false) ||
            (self.zip?.toLowerCase().includes(x) ?? false) ||
            (self.zip?.toLowerCase().includes(x) ?? false) ||
            (country?.search(x) ?? false)
    }
}