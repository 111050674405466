import * as lodash from 'lodash/fp';
import { defineModule, localActionContext } from "direct-vuex"

import * as Template from "@/lib/Util/Types/Masterdata/Template/Template";
import * as REST from '@/lib/Util/REST'
import { RootState } from '@/store/Shared'

const state = {
    value: null as Template.T | null,
    message: REST.getEmpty()
}

const mutations = {
    updateValue(state: State, v: Template.T | null) {
        state.value = v;
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    },
}

export function migrate(token?: string) {
    const req = {
        token: token ?? "NO TOKEN",
        url: "/template/migrate",
        payload: {},
        transformer: function (v: any) { console.log("TRANSFORM:", v); return null }
    };
    return REST.GET(state.message, req);
}

const actions = {
    async SAVE(context: any, x: Template.T): Promise<Template.T | null> {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context)
        const rootState = context.rootState as RootState
        try {
            const loading_msg = {
                ...state.message,
                loading: true
            }
            commit.updateMsg(loading_msg)

            const req = {
                token: rootState.authentication?.token ?? "NO TOKEN",
                url: "/template/upload",
                payload: x,
                transformer: Template.tryParse
            };
            const result = await REST.POST(state.message, req);
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text: `Successfully create template: ${x.uri}`
            }
            commit.updateValue(result.value)
            commit.updateMsg(msg)
            return result.value
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg(msg)
            return null
        }
    }
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    actions,
}

export type Mutations = typeof mutations
export type State = typeof state
export type Actions = typeof actions

export const module = defineModule(internal)

export default module

const actionCtx = (context: any) => localActionContext(context, module)
