
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import * as Common from "@/lib/Util/Common"
import * as Target from "@/lib/Util/REST/Target"

import * as AssociatedFileType from "@/lib/Util/Types/Workflow/AssociatedFiles/AssociatedFileType"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import * as Shared from "./Shared"

import * as REST from "@/lib/Util/REST"
import store from "@/store/store"

@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
    },
})
export default class FilesImportDialog extends Vue {
    @Prop({ default: false }) show!: boolean
    @Prop({ default: "NO WORKFLOW ID" }) workflow_id!: string
    @Prop({ default: "preparation" }) area!: Target.ShortSourceKind

    isLoading = false
    data = null as null | string
    filename = ""
    msg = REST.getEmpty()
    selected_file_type: AssociatedFileType.Kind = "Invoice"
    available_file_types = AssociatedFileType.KEYS

    get valid() {
        return this.data !== null
    }

    async addFile(e: any) {
        const files = e.dataTransfer.files as File[]
        const result = await Common.fromFileToString(files)
        if (result) {
            this.data = result.value
            this.filename = result.name
        } else {
            console.error("600 FileImportDialog.addFile", e)
        }
    }

    cancel() {
        console.log("CANCEL")
        this.data = null
        this.filename = ""
        this.$emit("update:show", false)
    }

    async save() {
        this.msg = {
            ...this.msg,
            loading: true,
        }
        const payload = {
            workflow_id: this.workflow_id,
            file_type: this.selected_file_type,
            text: "No Text",
            data: this.data ?? "",
        }
        const result = await Shared.uploadImportedFile(
            this.area,
            payload,
            store.state.authentication?.token ?? "NO TOKEN"
        )

        if (result.value) {
            result.msg.text = `${this.selected_file_type.toUpperCase()}: '${this.filename}' successfully uploaded`
            store.commit.workflow.single.updateValue(result.value)
            store.commit.workflow.single.updateMsg(result.msg)
            this.msg = REST.getEmpty()
            console.log("Save")
            this.$emit("update:show", false)
        } else {
            this.msg = result.msg
        }
    }
}
