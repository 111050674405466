
import Vue from "vue"
import { Component } from "vue-property-decorator"

import store from "@/store/store"

import * as Shared from "./Shared"
import FilterView from "@/components/Sections/Masterdata/Client/List/Filter.vue"
import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"
import * as BusinessProcessModule from "@/store/Masterdata/Client/SubItemModule"
import * as MigrationModule from "@/store/SupplierPayments/ListModule"

//todo: leave here just in case migration is needed again
//import * as ClientModule from "@/store/Masterdata/Client/ListModule"
//import * as ExternalUserModule from "@/store/Masterdata/ExternalUser/SingleModule"

@Component({
    components: {
        FilterView,
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
    },
})
export default class MasterdataClientListMainView extends Vue {
    headers = Shared.HEADERS

    created() {
        store.dispatch.client.list.LOAD()
    }
    get items() {
        return store.getters.client.list.filtered.map(Shared.toListItem)
    }

    get avaliableClients() {
        return store.getters.client.list.avaliableClients
    }

    get avaliableContacts() {
        return store.getters.client.list.avaliableContacts
    }

    get msg() {
        return store.state.client.list.message
    }

    toggle(x: string) {
        return function () {
            store.dispatch.client.single.TOGGLE(x).then(function () {
                store.commit.client.list.toggleActivation(x)
            })
        }
    }

    async list_accounting_data() {
        const children = await BusinessProcessModule.load_list(store.state.authentication?.token)
        if (children.value) {
            const processes = Shared.list_accounting_data(this.items, children.value)
            const csv = Shared.transform_to_csv(processes)
            console.log("list_accounting_data SUCCESS", csv)
            Shared.export_csv(csv, "accounting_data.csv")
        }
        else {
            console.log("LOADING BUSINESS PROCESS CHILDREN FAILED")
        }
    }

    //todo: leave here just in case migration is needed again
    async migrate() {
        console.log("MIGRATE")
        await MigrationModule.migrate(store.state.authentication?.token)
    }
}
