import * as Types from "@/lib/Util/Types/Types"

export const VALUES = {
    Invoice: true,
    Hotel: true,
    Entertainment: true,
    Amazon: true
}

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getEmpty(): T {
    return { kind: "Invoice", Invoice: true }
}

export const tryParse = Types.buildTryParse<T, true>(VALUES, "kind", true, "InvoiceType")

export function getByKind(x: Kind): T {
    return Types.makeSingleDUFromString(x)
}
