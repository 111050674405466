import Vue from 'vue'
import VueRouter from 'vue-router'

import * as AuthenticationResult from "@/lib/Util/Types/Login/AuthenticationResult"
import store from "@/store/store"

import LoginView from '@/components/Login/Main.vue'

import SectionsView from '@/components/Sections/Main.vue'

import InvoiceRouter from "@/components/Sections/Invoice/router";
import ReportsRouter from "@/components/Sections/Reports/router";
import MasterdataRouter from "@/components/Sections/Masterdata/router";
import WorkflowRouter from "@/components/Sections/Workflow/router";
import BankRouter from "@/components/Sections/Bank/router";

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        component: LoginView
    },
    {
        path: '/sections',
        component: SectionsView,
        children: [
            InvoiceRouter,
            MasterdataRouter,
            ReportsRouter,
            WorkflowRouter,
            BankRouter
        ]
    },
    {
        path: '*',
        //component: LoginView
        redirect: "/login"
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: "/",
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path === "/login") {
        next()
    }
    else {
        if (AuthenticationResult.isValid(store.state.authentication)) {
            next()
        }
        else {
            next("/login?force=true")
        }
    }
})

export default router