
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import TooltipIcon from "@/components/Util/TooltipIcon.vue"
import * as Country from "@/lib/Util/Types/Common/Country"
import * as Timespan from "@/lib/Util/Types/Common/Timespan"
import * as PaymentStatusFine from "@/lib/Util/Types/Invoice/PaymentStatusFine"
import * as lodash from "lodash/fp"

import router from "@/router/router"
import * as Filter from "@/store/Invoice/Filter"
import store from "@/store/store"

@Component({
    components: { TooltipIcon },
})
export default class InvoiceListFilterView extends Vue {
    @Prop({ default: null }) item!: Filter.T

    timespans = Timespan.filter([
        { prefix: "2024", period: "A" },
        { prefix: "2024", period: "Q" },
        { prefix: "2024", period: "M" },
        { prefix: "2023", period: "A" },
        { prefix: "2023", period: "Q" },
        { prefix: "2023", period: "M" },
        { prefix: "2022", period: "A" },
        { prefix: "2022", period: "Q" },
        { prefix: "2022", period: "M" },
        { prefix: "2021", period: "A" },
        { prefix: "Last", period: "Q" },
        { prefix: "2021", period: "Q" },
        { prefix: "2021", period: "M" },
        { prefix: "2021", period: "Y" },
        { prefix: "2020", period: "Q" },
        { prefix: "2020", period: "Y" },
        { prefix: "2019", period: "Y" },
    ])
    paymentStates = PaymentStatusFine.KEYS
    countries = Country.KEYS
    loaded = lodash.cloneDeep(this.item)

    get avaliableClients() {
        return store.getters.invoice.list.avaliableClients
    }

    clearing() {
        router.push({
            path: "/sections/invoices/list",
            query: Filter.getEmpty(),
        })
    }

    searching() {
        router.push({
            path: "/sections/invoices/list",
            query: this.loaded,
        })
    }

    @Watch("item", { deep: true })
    onItemChanged(nv: Filter.T) {
        this.loaded = lodash.cloneDeep(this.item)
    }
}
