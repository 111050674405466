import { defineModule } from "direct-vuex"

import * as HeadListModule from './HeadListModule'
import * as TailListModule from './TailListModule'
import * as SearchListModule from './SearchListModule'

export const internal = {
    namespaced: true as const,
    modules: {
        head: HeadListModule.module,
        tail: TailListModule.module,
        search: SearchListModule.module
    }
}

export const module = defineModule(internal)

export default module
