
import Vue from "vue"
import { Prop, Watch } from "vue-property-decorator"
import Component from "vue-class-component"

import numeral from "numeral"

import * as Common from "@/lib/Util/Common"
import * as Country from "@/lib/Util/Types/Common/Country"
import * as Currency from "@/lib/Util/Types/Common/Currency"

@Component({
    components: {},
})
export default class CurrencyInput extends Vue {
    @Prop({ default: null }) item!: number | null
    @Prop({ default: null }) country!: Country.Kind | null
    @Prop({ default: null }) currency!: Currency.Kind | null
    @Prop({ default: "no label" }) label!: string
    @Prop({ default: false }) outlined!: boolean
    @Prop({ default: true }) clearable!: boolean
    @Prop({ default: false }) hideDetails!: boolean | string
    @Prop({ default: false }) allowNegative!: boolean

    value = this.initialize()

    initialize(): string | null {
        if (this.item) {
            console.log("INIT", this.item, Common.toCurrencyString(this.item, this.country))
            return Common.toCurrencyString(this.item, this.country)
        }
        return null
    }

    change(x: any) {
        numeral.locale(this.country?.toLowerCase() ?? "de")
        const result = numeral(this.value)
        this.value = this.initialize()
        console.log("ON BLUR", this.value, result.value())
        this.$emit("update:item", result.value())
    }

    onKey(e: KeyboardEvent) {
        const isRemoveKey = ["Backspace", "Delete"].includes(e.key)
        const isMinusKey = e.key === "-"
        const isNavigationKey = ["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDow", "Home", "End", "Tab"].includes(e.key)
        const isNumberKey = e.key >= "0" && e.key <= "9"
        const isGrouping = e.key === "." || e.key === ","
        if (!isNavigationKey && !isNumberKey && !isGrouping && !isRemoveKey && !isMinusKey) {
            e.preventDefault()
        }
    }

    @Watch("item")
    onItemChanged(x: number | null) {
        console.log("ON ITEM CHANGED", x, this.initialize(), this.item)
        this.value = this.initialize()
    }

    get minRange() {
        if (this.allowNegative) {
            return -100000
        } else {
            return 0
        }
    }
}
