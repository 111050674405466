import * as lodash from "lodash"

import * as Types from "@/lib/Util/Types/Types"
import * as Common from "@/lib/Util/Common"
import * as Protocols from "@/lib/Util/Types/Protocols"

import * as Storage from "@/lib/Util/Types/Storage/Storage"

import * as Money from "@/lib/Util/Types/Common/Money"
import * as Currency from "@/lib/Util/Types/Common/Currency"
import * as Country from "@/lib/Util/Types/Common/Country"
import * as AccountWithTaxRate from "@/lib/Util/Types/Masterdata/BusinessProcess/AccountWithTaxRate"
import * as AccountSimple from "@/lib/Util/Types/Masterdata/BusinessProcess/AccountSimple"
import * as VatType from "@/lib/Util/Types/VAT/VatType"

export type T = {
    id: string;
    workflow_ref: string;
    workflow_secondary_id: string;  // of initial WFI
    storage: Storage.T;
    client: string;   //Wfi.headerdata.client_name
    bp_name: string;
    bp_short_id: string;
    ref_id: string;

    invoice_no: string;
    invoice_date: Date;
    receipt_date: Date; //date of initial creation of WFI
    approval_date: Date; //The date of the final approval. As a shortcut the date when the WFI was moved into payment

    supplier_name: string;
    supplier_city: string;

    vat_kind: VatType.Kind; //We normalize VatKind because of Unique keys in Comosm db
    gross_amount: Money.T;  //Sum(LineItems) with the same VATKind
    net_amount: Money.T;  //Sum(LineItems) with the same VATKind
    vat_amount: Money.T;  //Sum(LineItems) with the same VATKind
    currency: Currency.T;
    country: Country.T;

    revenue_account: AccountWithTaxRate.T; //from bp.accounting_data.services_received[filter: VATKind].value
    asset_account: AccountSimple.T; //from bp.accounting_data.AccountsPayable
}


export function tryParse(x: any): T | null {
    if (x) {
        const storage = Storage.tryParse(x.storage)
        const invoice_date = Common.parseServerDate(x.invoice_date)
        const receipt_date = Common.parseServerDate(x.receipt_date)
        const approval_date = Common.parseServerDate(x.approval_date)

        const gross_amount = Money.tryParse(x.gross_amount)  //Sum(LineItems) with the same VATKind
        const net_amount = Money.tryParse(x.net_amount)
        const vat_amount = Money.tryParse(x.vat_amount)
        const currency = Currency.tryParse(x.currency)
        const country = Country.tryParse(x.country)

        const revenue_account = AccountWithTaxRate.tryParse(x.revenue_account)
        const ref_id =
            x.workflow_secondary_id.substr(5, 2) +
            x.workflow_secondary_id.substr(8, 3) +
            x.workflow_secondary_id.substr(14, 2) +
            x.workflow_secondary_id.substr(18)

        const result = {
            ...x,
            ref_id,
            storage,
            invoice_date,
            receipt_date,
            approval_date,

            gross_amount,
            net_amount,
            vat_amount,
            currency,
            country,

            revenue_account,
        }

        if (Types.checkNonNull(result)) {
            return result
        }
    }
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "ServicesReceived")

export function sort(xs: T[]): T[] {
    return lodash.orderBy(xs, ["approval_date", "ref_id"], ["desc", "asc"])
}
