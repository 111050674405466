import * as Types from "@/lib/Util/Types/Types"

import * as Common from "@/lib/Util/Common"
import * as Email from "@/lib/Util//Types/Common/Email"

import * as AssociatedFileType from "../AssociatedFiles/AssociatedFileType"

import * as Base from "./Base"


export type T = Base.T & {
    etag: string;
    version_number: number;

    associated_files: AssociatedFileType.T[];

    net_amount: number;
    gross_amount: number;

    buyer_email: Email.Email;
    client_name: string;
    supplier_name: string;
    order_number?: string;
    external_id?: string;

    cpc_id: string;
    start_date: Date;
    wa_change_time: Date;

    bp_name?: string;
    //todo: implement properties belopw
    //bp_config?: BpConfig;
    //user_config?: UserConfig;

}

export function tryParse(x?: any): T | null {
    if (x) {
        const source = Base.tryParse(x)
        if (source) {
            const start_date = Common.parseServerDate(x.start_date)
            const wa_change_date = Common.parseServerDate(x.wa_change_date)
            const buyer_email = Email.Email.tryParse(x.buyer_email)
            const associated_files = AssociatedFileType.tryParseList(x.associated_files)
            return {
                ...x,
                ...source,

                start_date,
                wa_change_date,
                buyer_email,
                associated_files
            }
        }
    }

    console.error("SearchListItem/Global.tryParse", x)
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "SearchListItem/Global")