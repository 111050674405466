import * as Types from "@/lib/Util/Types/Types"

import * as InvoiceKey from "@/lib/Util/Types/Biz/InvoiceKey"
import * as ProcessingState from "./ProcessingState"

export type Record = {
    Single: ProcessingState.T<InvoiceKey.T>;
    Split: [ProcessingState.T<InvoiceKey.T> | null, ProcessingState.T<InvoiceKey.T> | null];
    Empty: true;

}

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']
export const KEYS: Kind[] = ["Single", "Split"]

export function tryParse(x?: any): T | null {
    if (x) {
        if (x.Single) {
            const result = ProcessingState.tryParse(x.Single, InvoiceKey.tryParse)
            if (result) {
                return {
                    kind: "Single",
                    Single: result
                }
            }
        }
        else if (x.Split) {
            const first = ProcessingState.tryParse(x.Split[0], InvoiceKey.tryParse)
            const second = ProcessingState.tryParse(x.Split[1], InvoiceKey.tryParse)
            return {
                kind: "Split",
                Split: [first, second]
            }
        }
        else if (x.Empty) {
            return {
                kind: "Empty",
                Empty: true
            }
        }
    }
    console.error("ProcessingState.tryParse", x)
    return null
}
