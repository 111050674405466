
import * as Supplier from "@/lib/Util/Types/Supplier/Supplier"
import * as VatType from "@/lib/Util/Types/VAT/VatType"

import * as HeaderData from "./HeaderData"
import * as LineItem from "./LineItem"
import * as ProcessReference from "./ProcessReference"
import * as CostEntityLine from "./CostEntityLine"
import * as Payment from "./Payment"

export type T = {
    process_reference: ProcessReference.T | null;
    header_data: HeaderData.T | null;
    supplier: Supplier.T | null;

    manual_fees: LineItem.T[];
    auto_fees: LineItem.T[];
    position_data: LineItem.T[];
    cost_types: CostEntityLine.T[];
    vat_table: VatType.T[];

    payment: Payment.T | null;
}

export function tryParse(x?: any): T | null {
    if (x) {
        const process_reference = ProcessReference.tryParse(x.process_reference)
        const header_data = HeaderData.tryParse(x.header_data)
        const supplier = Supplier.tryParse(x.supplier) ?? Supplier.getEmpty()

        const manual_fees = LineItem.tryParseList(x.manual_fees)
        const auto_fees = LineItem.tryParseList(x.auto_fees)

        const position_data = LineItem.tryParseList(x.position_data)
        let cost_types = CostEntityLine.tryParseList(x.cost_types)
        let vat_table = VatType.tryParseList(x.vat_table)

        //this is to compensate for invalid data from the server
        if (position_data.length === 0) {
            cost_types = []
            vat_table = []
        }

        const payment = Payment.tryParse(x.payment)
        console.log("WORKFLOWDOC", payment, x.payment)

        const result = {
            process_reference,
            header_data,
            supplier,
            manual_fees,
            auto_fees,
            position_data,
            cost_types,
            vat_table,
            payment
        }

        return result
    }
    console.error("WorkflowDoc.tryParse", x)
    return null
}

export function getEmpty() {
    return {
        process_reference: ProcessReference.getEmpty(),
        header_data: HeaderData.getEmpty(),
        supplier: Supplier.getEmpty(),

        manual_fees: [],
        auto_fees: [],
        position_data: [],
        cost_types: [],
        vat_table: [],

        payment: null,
    }
}

export function calcVAT(x: T): number {
    return LineItem.calcVAT(x.position_data)
}