import { defineModule, localActionContext, localGetterContext } from "direct-vuex"

import { RootState } from '@/store/Shared'
import * as lodash from 'lodash/fp';

import * as REST from '@/lib/Util/REST'

import * as Invoice from "@/lib/Util/Types/Invoice/Invoice";

type Query = {
    bp_id?: string;
    year?: number;
    number?: number;
    terse_id?: string;
}

const state = {
    value: null as Invoice.T | null,
    message: REST.getEmpty(),
}

const mutations = {
    updateValue(state: State, v: Invoice.T | null) {
        state.value = v;
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    },
}

export function load(x: string, token?: string, msg?: REST.RestMessage) {
    const req = {
        token: token ?? "NO TOKEN",
        url: `/invoice/${x}`,
        payload: {},
        transformer: Invoice.tryParse
    };
    return REST.GET(msg ?? REST.getEmpty(), req);
}

export async function loading(context: any, x: { url: string, query: Query }) {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const { commit, state } = actionCtx(context)
    const rootState = context.rootState as RootState
    try {
        const loading_msg = {
            ...state.message,
            loading: true
        }
        commit.updateMsg(loading_msg)
        const req = {
            token: rootState.authentication?.token ?? "NO TOKEN",
            url: x.url,
            payload: x.query,
            transformer: Invoice.tryParse
        };
        const result = await REST.GET(state.message, req)
        if (result.value) {
            const text = `Successfully loaded invoice ${result.value.terse_id}`
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text
            }
            commit.updateValue(result.value)
            commit.updateMsg(msg)
        }
        else {
            commit.updateMsg(result.msg)
        }
    }
    catch (ex) {
        const msg = {
            ...lodash.cloneDeep(state.message),
            text: JSON.stringify(ex)
        }
        commit.updateMsg(msg)
    }
}

const actions = {
    async LOAD(context: any, x: string) {
        await loading(context, { url: `/invoice/${x}`, query: {} })
    },
    async LOAD_BY_QUERY(context: any, x: Query) {
        await loading(context, { url: "/invoice/get_by_query", query: x })
    },
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    actions,
}

export type Mutations = typeof mutations
export type State = typeof state
export type Actions = typeof actions

export const module = defineModule(internal)

export default module

const actionCtx = (context: any) => localActionContext(context, module)
