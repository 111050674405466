import * as IncrementalCounter from "@/lib/Util/Types/Biz/IncrementalCounter"
import * as Types from "@/lib/Util/Types/Types"
import * as Money from "@/lib/Util/Types/Common/Money"
import * as Currency from "@/lib/Util//Types/Common/Currency"
import * as Doc from "@/lib/Util/Types/Workflow/Summary/Doc"
import * as AccountKey from "@/lib/Util/Types/Biz/AccountKey"
import * as Common from "@/lib/Util/Common"

import * as lodash from "lodash"

export type T = {
    id: string;
    uid: IncrementalCounter.T<AccountKey.T>;
    created_at: Date;
    line_items: Doc.T[];
    total: Money.T;
    discounted: Money.T;
    count: number;
}

export function getEmpty(): T {
    return {
        id: "",
        uid: IncrementalCounter.getEmpty(AccountKey.getEmpty),
        created_at: new Date(),
        line_items: [],
        total: Money.zero(Currency.getEmpty()),
        discounted: Money.zero(Currency.getEmpty()),
        count: 0
    }
}

export function tryParse(x: any): T | null {
    if (x) {
        const line_items = Doc.tryParseList(x.line_items)
        const created_at = Common.parseServerDate(x.created_at) ?? undefined
        const total = Money.tryParse(x.total) ?? undefined
        const ds = line_items.map(Doc.asDocSummary).map(v => v.discounted)
        const discounted = Money.sum(ds) ?? Money.zero(total?.currency)
        const prep: Partial<T> = {
            id: x.id,
            uid: x.uid,
            created_at,
            line_items,
            total,
            count: line_items.length,
            discounted
        }
        if (Types.isOfType<T>(prep, getEmpty)) {
            return prep
        }
        console.error("100 SupplierPayments.tryParse", x)
        return null
    }
    console.error("200 SupplierPayments.tryParse", x)
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "SupplierPayments")

export function sort(xs: T[]): T[] {
    return lodash.orderBy(xs, ["id"], ["desc"])
}