import * as Protocols from "@/lib/Util/Types/Protocols"
import * as Common from "@/lib/Util/Common"

export function validate(x?: string) {
    if (x) {
        const re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return re.test(x.toLowerCase());
    }
    else {
        return false
    }
}

export class Email {
    protected constructor(x: string) {
        this._value = x.toLocaleLowerCase()
        this.Email = this._value
    }
    private _value: string;
    private Email: string;

    get Value() {
        return this._value
    }

    search(x: string) {
        return this.Value.toLowerCase().includes(x)
    }

    static tryParse(x: string | null): Email | null {
        if (x && validate(x) == true) {
            return new Email(x)
        }
        else {
            return null
        }
    }

    static tryParseList(x: any): Email[] | null {
        console.log("100 EMAIL.tryParseList", x)
        if (Common.isArrayOfStrings(x)) {
            const result = x.map(Email.tryParse)
            console.log("200 EMAIL.tryParseList", result)
            if (result.every(item => item)) {
                console.log("300 EMAIL.tryParseList", result)
                return result as Email[]
            }
            else {
                console.error("Email.tryParseList", result)
                return null
            }
        }
        else {
            console.error("Email.tryParseList", x)
            return null
        }
    }

    static parseDefault(x: string | null): Email {
        return Email.tryParse(x) ?? Email.tryParse("unknown@unknown.com") as Email
    }
}

export function getEmpty() {
    return Email.tryParse("unknown@unknown.com") as Email
}

export class SearcheableProtocol implements Protocols.Searcheable {
    search(x: string): boolean {
        const self = this as any as Email
        return self.search(x)
    }
}

export function search(xs: Email[], value: string, anyOrAll: "ANY" | "ALL") {
    if (anyOrAll === "ALL") {
        return xs.every(item => item.search(value))
    }
    else {
        return xs.find(item => item.search(value)) !== undefined
    }
}
