import lodash from "lodash"
import { createClient } from '@supabase/supabase-js'
import * as PaymentState from "@/lib/Util/Types/Workflow/PaymentState"
import * as GlobalSearchItem from "@/lib/Util/Types/Workflow/SearchListItem/Global"
import * as PaymentStateSearchItem from "@/lib/Util/Types/Workflow/SearchListItem/PaymentStateSearchItem"
import * as TailListItem from "@/lib/Util/Types/Workflow/SearchListItem/Tail"

import * as SearchListModule from "@/store/Workflow/List/SearchListModule"
import * as TailListModule from "@/store/Workflow/List/TailListModule"

const supabase = createClient('https://erknpmamzbaxzelkaiij.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVya25wbWFtemJheHplbGthaWlqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzI4MzM0MjMsImV4cCI6MTk4ODQwOTQyM30.SYf4O7q6G-CGkme3ZaabAPh-jtqazLLzljzjAas7C-Y')

/*
const channel = supabase
    .channel('table-db-changes')
    .on(
        'postgres_changes',
        {
            event: '*',
            schema: '*',
        },
        (payload) => console.log("CHANGES?", payload)
    )
    .subscribe()

console.log("CHANNEL?", channel)
*/

export async function loadOpenPaymentStates() {
    const { data: records, error } =
        await supabase
            .from('payment_state')
            .select()
            .eq("closed", false)

    if (records) {
        return PaymentState.tryParseList(records)
    }
    else {
        return null
    }
}

export async function loadPaymentState(id: string) {
    const { data: records, error } =
        await supabase
            .from('payment_state')
            .select()
            .eq("id", id)
    if (records) {
        return PaymentState.tryParseList(records)[0]
    }
    else {
        console.error("loadPaymentState failed:", error)
        return null
    }

}

async function closePaymentState(x?: PaymentState.T) {
    if (x) {
        const updated = { ...x, payment_mode: x.payment_mode.kind, closed: true }
        const { data: record, error } = await supabase
            .from('payment_state')
            .update(updated)
            .eq('id', updated.id)
            .select()
        if (record) {
            return record[0] as PaymentState.T
        }
        else {
            console.error("closePaymentState failed:", x)
            return null
        }
    }
    else {
        return null
    }
}

export async function save(x: PaymentState.T) {
    if (x) {
        const updated = { ...x, payment_mode: x.payment_mode.kind, updated_at: new Date() }
        const { data: record, error } = await supabase
            .from('payment_state')
            .upsert(updated, { ignoreDuplicates: false, onConflict: "short_id" })
            .select()
        if (record) {
            return x
        }
        else {
            console.error("save failed:", error)
        }
    }
    return null
}

function createFromTailListItem(a: TailListItem.T, b?: PaymentState.T): PaymentStateSearchItem.T {
    const result = {
        area: a.state.work_state,
        payment_state: b,
        supplier: a.doc_summary.supplier.name,
        invoice_no: a.doc_summary.invoice_no,
        client: a.doc_summary.client,
        buyer_email: a.doc_summary.employee.Value,
        isPaid: a.payment_summary.paid.kind !== "NotPaid"
    }
    return result
}

function createFromGlobalSearchItem(a: GlobalSearchItem.T, b?: PaymentState.T): PaymentStateSearchItem.T {
    const result = {
        area: a.state.work_state,
        payment_state: b,
        supplier: a.supplier_name,
        invoice_no: a.invoice_number,
        client: a.client_name,
        buyer_email: a.buyer_email.Value,
        isPaid: false
    }
    return result
}

function mergeFromTailListItems(glist: TailListItem.T[], plist: PaymentState.T[]): PaymentStateSearchItem.T[] {
    const findPaymentState = (v: TailListItem.T) => plist.find(u => u.id === v.id)
    const result = glist.map(v => {
        const p = findPaymentState(v)
        const r: PaymentStateSearchItem.T = createFromTailListItem(v, p)
        return r
    })
    return result
}

function mergeFromGlobalSearchItems(glist: GlobalSearchItem.T[], plist: PaymentState.T[]): PaymentStateSearchItem.T[] {
    const findPaymentState = (v: GlobalSearchItem.T) => plist.find(u => u.id === v.id)
    const result = glist.map(v => {
        const p = findPaymentState(v)
        const r: PaymentStateSearchItem.T = createFromGlobalSearchItem(v, p)
        return r
    })
    return result
}

async function loadPaymentWorkflowsChunks(xs: PaymentState.T[], token: string) {
    const id = xs.map(v => v.id)
    const req: TailListModule.Request = { area: "Pay", query: { id } }
    const result = await TailListModule.load(token, req)
    if (result.value) {
        return await mergeFromTailListItems(result.value, xs)
    }
    else {
        console.error("loadPaymentWorkflows")
        return []
    }
}

async function loadPaymentWorkflows(xs: PaymentState.T[], token: string) {
    const chunks = lodash.chunk(xs, 25)
    const result = chunks.map(async v => await loadPaymentWorkflowsChunks(v, token))
    return await Promise.all(result).then(v => v.flat());
}

async function loadSearchListWorkflowsChunk(xs: PaymentState.T[], token: string) {
    const id = xs.map(v => v.id)
    const req: SearchListModule.Request = { id }
    const result = await SearchListModule.load(token, req)
    if (result.value) {
        return await mergeFromGlobalSearchItems(result.value, xs)
    }
    else {
        console.error("loadSearchListWorkflows")
        return []
    }
}

async function loadSearchListWorkflows(xs: PaymentState.T[], token: string) {
    const chunks = lodash.chunk(xs, 25)
    const result = chunks.map(async v => await loadSearchListWorkflowsChunk(v, token))
    return await Promise.all(result).then(v => v.flat());
}


async function cleanFinishedOrCancelled(xs: PaymentStateSearchItem.T[]) {
    const not_active = xs.filter(
        v => v.area.kind === "Finish" || v.area.kind === "Cancel")
    not_active.forEach(v => closePaymentState(v.payment_state))
    const active = xs.filter(
        v => v.area.kind !== "Finish" && v.area.kind !== "Cancel")
    return active
}

export async function load(token: string) {
    const states_list = await loadOpenPaymentStates()
    if (states_list) {
        const payment_list = await loadPaymentWorkflows(states_list, token)
        if (payment_list) {
            if (payment_list.length === states_list.length) {
                return payment_list
            }
            else {
                const loaded = payment_list.map(v => v.payment_state?.id ?? "ERROR")
                const missing = states_list.filter(v => !loaded.includes(v.id))
                const search_item_list = await loadSearchListWorkflows(missing, token)
                const cleaned = await cleanFinishedOrCancelled(search_item_list)
                const result = payment_list.concat(cleaned)
                return result
            }
        }
    }
    return null
}