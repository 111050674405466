type KeyType =
    | "PaymentId"
    | "PrimaryId"

export type T = {
    value: string;
    key_type: KeyType;
}

export function tryParse(x: any): T | null {
    if (x) {
        let key_type: KeyType | null = null
        switch (x.key_type) {
            case "PaymentId":
                key_type = "PaymentId";
                break
            case "PrimaryId":
                key_type = "PrimaryId";
                break
        }
        if (key_type) {
            return {
                ...x,
                key_type
            }
        }
    }
    console.error("SecondaryId.tryParse", x)
    return null
}