import * as lodash from 'lodash/fp';
import { defineModule, localActionContext, } from "direct-vuex"

import * as Currency from "@/lib/Util/Types/Common/Currency"
import * as Common from "@/lib/Util/Common"
import * as REST from '@/lib/Util/REST'

import * as DashboardEntry from "@/lib/Util/Types/Invoice/Dashboard/Entry";

import { RootState } from '@/store/Shared'

const state = {
    value: [] as DashboardEntry.Bucket[],
    filter: "EUR" as Currency.Kind,
    message: REST.getEmpty()
}

const mutations = {
    updateValue(state: State, v: DashboardEntry.Bucket[]) {
        state.value = v;
    },
    updateFilter(state: State, v: Currency.Kind) {
        state.filter = v;
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    }
}

const getters = {
    filtered: (state: State) => state.value.filter((y) => y.currency.kind === state.filter),
    avaliableCurrencies: (state: State) => {
        return Common.getUnique((x: DashboardEntry.Bucket) => x.currency.kind, state.value).sort() as
            Currency.Kind[]
    },
}

const actions = {
    async load(context: any) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context)
        const rootState = context.rootState as RootState
        try {
            const loading_msg = {
                ...state.message,
                loading: true
            }
            commit.updateValue([])
            commit.updateMsg(loading_msg)
            const req = {
                token: rootState.authentication?.token ?? "NO TOKEN",
                url: "/dashboard_invoice",
                payload: {},
                transformer: DashboardEntry.tryParse
            };
            const result = await REST.GET(state.message, req);
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text: "Successfully loaded client list"
            }
            console.log("100 loadDashboard", result.value)
            const buckets = DashboardEntry.toBucketList(result.value)
            commit.updateValue(buckets)
            commit.updateMsg(msg)
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg(msg)
        }
    },
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    getters,
    actions,
}

export type Mutations = typeof mutations
export type State = typeof state
export type Getters = typeof getters
export type Actions = typeof actions

export const module = defineModule(internal)

export default module

const actionCtx = (context: any) => localActionContext(context, module)
