import * as BusinessProcess from "@/lib/Util/Types/Masterdata/BusinessProcess/BusinessProcess"
import * as AccountingData from "@/lib/Util/Types/Masterdata/BusinessProcess/AccountingData"

import * as Invoice from "@/lib/Util/Types/Invoice/Invoice"
import * as InvoiceState from "@/lib/Util/Types/Invoice/InvoiceState"

import * as LineItemParent from "@/lib/Util/Types/Remittance/LineItem"
import * as Source from "@/lib/Util/Types/Remittance/Source"
import * as lodash from "lodash/fp"

import * as InvoiceList from "@/store/Invoice/ListModule"
import * as InvoiceSingle from "@/store/Invoice/SingleModule"
import * as BusinessProcessModule from "@/store/Masterdata/Client/SubItemModule"

import store from "@/store/store"

export const HEADERS = [
    {
        text: 'Intern',
        value: 'source',
        sortable: false,
        width: "3%",
    },
    {
        text: 'Reference',
        value: 'reference',
        width: "25%",
        sortable: false,
    },
    {
        text: 'Amount',
        value: 'amount',
        align: 'end',
        width: "15%",
        sortable: false,
    },
    {
        text: '"',
        value: 'comment',
        width: "1%",
        sortable: false,
    },
    {
        text: 'Total',
        value: 'ref_amount',
        align: 'end',
        sortable: false,
    },
    {
        text: 'Paid',
        value: 'paid_amount',
        align: 'end',
        sortable: false,
    },
    {
        text: 'Diff 1',
        value: 'difference1',
        align: 'end',
        sortable: false,
    },
    {
        text: 'Diff 2',
        value: 'difference2',
        align: 'end',
        sortable: false,
    },
    {
        text: '',
        width: "15%",
        sortable: false,
    },
]

export const BANK_ACCOUNTS = [
    {
        id: "DE71 1101 0101 5952 6121 83",
        description: "Mandanten Eingang"
    },
    {
        id: "DE56 1101 0100 2075 0233 40",
        description: "Hauptkonto"
    },
    {
        id: "DE26 1101 0101 5930 8739 35",
        description: "Lieferanten Ausgang"
    },
]

export const BOOKING_ACCOUNTS = [
    {
        id: "8000",
        description: "Geldeingang Transfer"
    },
]

export let BUSINESS_PROCESSES = new Map<string, string>()

export async function loadBusinessProcesses() {
    const result = await BusinessProcessModule.load_list(store.state.authentication?.token)
    if (result.value) {
        const kvs = result.value.map(x => [x.id, x.short_id] as [string, string])
        BUSINESS_PROCESSES = kvs.reduce((map, [key, value]) => {
            map.set(key, value);
            return map;
        }, new Map<string, string>());
    }
}

export class LineItem implements LineItemParent.T {
    amount!: number;
    source!: Source.T;
    reference!: string;
    process_id!: string;
    ref_amount!: number;
    references: string[];
    accounting_data?: AccountingData.T;
    due_date!: Date;
    comment!: string;
    is_loading = false;
    paid_amount = false as number | false;
    paid_count = false as number | false;
    private _search: string;
    private _loaded: Map<string, Invoice.T>
    constructor(x: LineItemParent.T) {
        this.references = [x.reference]
        this.paid_amount = false
        this._search = ""
        this._loaded = new Map()
        Object.assign(this, x)
    }
    get search() {
        return this._search
    }

    set search(x: string) {
        const v = x ?? ""
        this._search = v
        if (this.reference != v) {
            if (this._search.length >= 6) {
                this.is_loading = true
                this.loadInvoices()
                this.is_loading = false
                console.log("500 SEARCH", this)
            }
        }
    }

    get difference() {
        return this.amount - this.ref_amount
    }

    set difference(x: number) {
        //
    }

    get diff2() {
        return this.amount - (this.paid_amount ? this.paid_amount : 0)
    }

    get isInternal() {
        return this.source.kind === "Internal"
    }

    set isInternal(x: boolean) {
        if (x) {
            this.source = { kind: "Internal", Internal: true }
        }
        else {
            this.source = { kind: "External", External: true }
        }
    }

    get loaded() {
        return this._loaded
    }

    initialize(x: Invoice.T) {
        this.ref_amount = x.total.value
        this.process_id = BUSINESS_PROCESSES.get(x.uid.key.bp_id) ?? "MISSING BP-ID"
        this.due_date = x.data.due_date
        this.accounting_data = x.data.accounting_data
        this.paid_amount = x.data.paid_amount
        this.paid_count = InvoiceState.extract(x.data.invoice_state)?.length ?? 0
    }

    loadInvoices() {
        const runner = async () => {
            try {
                const loaded = await InvoiceList.load({ terse_id: this._search }, store.state.authentication?.token)
                if (loaded.value) {
                    const result = loaded.value.map(x => x.terse_id)
                    this.references = result
                    this._loaded = new Map(loaded.value.map(item => [item.terse_id, item]))
                    console.log("LOADED INVOICES", this)
                }
                return await 0;
            } catch (e) {
                return 0; // fallback value;
            }
        };
        runner()
    }

    loadInvoice() {
        const runner = async () => {
            try {
                const loaded = await InvoiceSingle.load(this.reference, store.state.authentication?.token)
                if (loaded.value) {
                    this.initialize(loaded.value)
                    console.log("LOADED INVOICE", this)
                }
                return 0;
            } catch (e) {
                return 0; // fallback value;
            }
        };
        runner()
    }

    changed() {
        return (x: string) => {
            const i = this._loaded.get(x)
            console.log("CHANGE EVENT", i)
            if (i) {
                this.initialize(i)
            }
        }
    }
}

export function toLineItem(x: LineItemParent.T): LineItem {
    return new LineItem(x)
}

export function toLineItems(xs: LineItemParent.T[] | null) {
    if (xs) {
        return xs.map(toLineItem)
    }
    return []
}

export function toLineItemParent(x: LineItem): LineItemParent.T {
    return lodash.pick(
        [
            "amount",
            "source",
            "reference",
            "process_id",
            "ref_amount",
            "difference",
            "accounting_data",
            "due_date",
            "comment"
        ], x)
}

export function toLineItemParents(xs: LineItem[]): LineItemParent.T[] {
    return xs.map(toLineItemParent)
}
