import * as Types from '@/lib/Util/Types/Types'
import * as LogEntry from "./LogEntry"

export const VALUES = {
    None: true,
    OneStep: true,
    'TwoStep-A': true,
    'TwoStep-B': true
} as const

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getEmpty(): T {
    return { kind: "None", None: true }
}

export function tryParse(x?: any): T | null {
    if (x) {
        if (x.None) {
            return {
                kind: "None",
                None: true,
            }
        }
        else if (x.OneStep) {
            const value = x.OneStep
            if (value) {
                return {
                    kind: "OneStep",
                    OneStep: value,
                }
            }
        }
        else if (x['TwoStep-A']) {
            const value = x['TwoStep-A']
            if (value) {
                return {
                    kind: 'TwoStep-A',
                    'TwoStep-A': value,
                }
            }
        }
        else if (x['TwoStep-B']) {
            const value = x['TwoStep-B']
            if (value) {
                return {
                    kind: 'TwoStep-B',
                    'TwoStep-B': value,
                }
            }
        }
    }
    console.error("ConfirmationStrategy.tryParse", x)
    return null

}
