import * as Types from "@/lib/Util/Types/Types"
import * as Common from "@/lib/Util/Common"
import * as Money from "../Common/Money"

const VALUES = {
    due_date: new Date(1970, 0, 1),
    amount: Money.zero()
} as const

export type T = typeof VALUES

export const getEmpty: () => T = Types.mkGetEmpty<T>(VALUES)

export function tryParse(x?: any): T | null {
    if (x) {
        const due_date = Common.parseServerDate(x.due_date)
        const amount = Money.tryParse(x.amount)
        if (due_date && amount) {
            return {
                due_date,
                amount
            }
        }
    }
    console.error("Discount.tryParse", x)
    return null
}
