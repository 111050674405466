import * as AccountWithTaxRate from "@/lib/Util/Types/Masterdata/BusinessProcess/AccountWithTaxRate"
import * as common from "@/components/Util/Common"

export const HEADERS = [
    {
        text: 'Description',
        value: 'description',
    },
    {
        text: 'Tax Rate (Kind)',
        value: 'tax_rate.kind',
    },
    {
        text: 'Tax Rate (Value %)',
        value: 'tax_rate.value',
    },
    {
        text: 'Account',
        value: 'account',
    },
    {
        text: 'Tax Key',
        value: 'tax_key',
    },
]

export type EditWrapper = common.EditWrapper<AccountWithTaxRate.T>
