import * as Country from '@/lib/Util/Types/Common/Country'
import * as Currency from '@/lib/Util/Types/Common/Currency'

import * as Key from './Key'

export type Volume = {
    count: number;
    amount: number;
}

export type T = {
    country: Country.T;
    currency: Currency.T;
    over_due: Volume;
    over_due_older_than_30: Volume;
    over_due_from_15_to_30_ago: Volume;
    over_due_from_8_to_14_ago: Volume;
    over_due_from_1_to_7_ago: Volume;
    due_today: Volume;
    due_next_7: Volume;
    due_next: Volume;
}

export type Bucket = {
    name: Key.Kind;
    currency: Currency.T;
    country: Country.T;
    volume: Volume;
}

function toBucket(name: Key.Kind, x: T): Bucket {
    return {
        currency: x.currency,
        country: x.country,
        name,
        volume: x[name]
    }
}

export function toBucketList(x: T | readonly T[] | null): Bucket[] {
    if (x) {
        if (Array.isArray(x)) {
            const buckets = x.map(toBucketList)
            return buckets.reduce((acc, v) => acc.concat(v), [])
        }
        else {
            const p = x as T
            return Key.KEYS.map(v => toBucket(v, p))
        }
    }
    return []
}

export function tryParse(x: any[]): T[] | null {
    if (Array.isArray(x)) {
        const transform = (x: any): T | null => {
            const country = Country.tryParse(x.country)
            const currency = Currency.tryParse(x.currency)
            //fix for trailling space
            const over_due_from_1_to_7_ago = x["over_due_from_1_to_7_ago "]
            if (country && currency) {
                return {
                    ...x,
                    over_due_from_1_to_7_ago,
                    country,
                    currency
                }
            }
            else {
                return null
            }
        }
        return x.map(transform).filter(v => v !== null) as T[]

    }
    return null
}
