import * as Common from "@/lib/Util/Common"

export type T = {
    account: string;
    timestamp: Date;
}

export function getEmpty(): T {
    return {
        account: "",
        timestamp: new Date(),
    }
}

export function tryParse(x?: any): T | null {
    if (x) {
        const account = x.account
        const timestamp = Common.parseServerDate(x.timestamp)
        if (account && timestamp) {
            return {
                account,
                timestamp
            }
        }
    }
    console.error("AccountTimestamp.tryParse", x)
    return null
}
