import * as InternalUser from "@/lib/Util/Types/InternalUser/InternalUser"

export const HEADERS = [
    {
        text: 'Email',
        value: 'email.Value',
    },
    {
        text: 'Telephone',
        value: 'telephone',
    },
    {
        text: 'Created At',
        value: 'created_at',
    },
    {
        text: 'Country',
        value: 'country.kind',
    },
    {
        text: '',
        value: 'actions',
    },
]

export function toListItem(x: InternalUser.T): InternalUser.T & { actions: string } {
    return {
        ...x,
        actions: "xxxx"
    }
}

export function toListItems(xs: InternalUser.T[]): (InternalUser.T & { actions: string })[] {
    return xs.map(toListItem)
}
