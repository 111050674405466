import * as Types from '@/lib/Util/Types/Types'
import * as CodeInfo from "./CodeInfo"

export const VALUES = {
    List: [[], false, ""] as [CodeInfo.T[], boolean, string],
    Text: ["", false, ""] as [string, boolean, string],
    Nothing: true
}

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getEmpty(x?: Kind): T {
    if (x) {
        switch (x) {
            case "List": return { kind: "List", List: [[], false, ""] }
            case "Text": return { kind: "Text", Text: ["", false, ""] }
            case "Nothing": return { kind: "Nothing", Nothing: true }
        }
    }
    return { kind: "Nothing", Nothing: true }
}

export function tryParse(x: any): T | null {
    if (x.List) {
        return {
            kind: "List",
            List: x.List
        }
    }
    if (x.Text) {
        return {
            kind: "Text",
            Text: x.Text
        }
    }
    if (x.Nothing) {
        return { kind: "Nothing", Nothing: true }
    }
    return null
}
