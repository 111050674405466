import * as Types from '@/lib/Util/Types/Types'
import * as VatType from "@/lib/Util/Types/VAT/VatType"
import * as VatRule from "@/lib/Util/Types/VAT/VatRule"
import * as AccountWithTaxRate from "./AccountWithTaxRate"

export type T = {
    normal: AccountWithTaxRate.T;
    reduced: AccountWithTaxRate.T;
    normal_extended: AccountWithTaxRate.T;
    reduced_extended: AccountWithTaxRate.T;
    reduced_special: AccountWithTaxRate.T;
    zero: AccountWithTaxRate.T;
    zero_extended: AccountWithTaxRate.T;
}

export function getEmpty(x: VatRule.T): T {
    return {
        normal: AccountWithTaxRate.getEmpty({
            kind: "Normal",
            value: x.Normal,
            Normal: x.Normal
        }),
        reduced: AccountWithTaxRate.getEmpty({
            kind: "Reduced",
            value: x.Reduced,
            Reduced: x.Reduced
        }),
        normal_extended: AccountWithTaxRate.getEmpty({
            kind: "NormalExtended",
            value: x.NormalExtended,
            NormalExtended: x.NormalExtended
        }),
        reduced_extended: AccountWithTaxRate.getEmpty({
            kind: "ReducedExtended",
            value: x.ReducedExtended,
            ReducedExtended: x.ReducedExtended
        }),
        reduced_special: AccountWithTaxRate.getEmpty({
            kind: "ReducedSpecial",
            value: x.ReducedSpecial,
            ReducedSpecial: x.ReducedSpecial
        }),
        zero: AccountWithTaxRate.getEmpty({
            kind: "Zero",
            value: x.Zero,
            Zero: 0
        }),
        zero_extended: AccountWithTaxRate.getEmpty({
            kind: "ZeroExtended",
            value: x.ZeroExtended,
            ZeroExtended: 0
        })
    }
}

export function tryParse(x: any): T | null {
    if (x) {
        const result = {
            normal: AccountWithTaxRate.tryParse(x.normal),
            reduced: AccountWithTaxRate.tryParse(x.reduced),
            normal_extended: AccountWithTaxRate.tryParse(x.normal_extended),
            reduced_extended: AccountWithTaxRate.tryParse(x.reduced_extended),
            reduced_special: AccountWithTaxRate.tryParse(x.reduced_special),
            zero: AccountWithTaxRate.tryParse(x.zero),
            zero_extended: AccountWithTaxRate.tryParse(x.zero_extended),
        }

        if (Types.checkNonNull(result)) {
            return result as T
        }
        console.error("100 Services.tryParse", result)
        return null
    }
    console.error("200 Services.tryParse", x)
    return null
}

export function toList(x: T): AccountWithTaxRate.T[] {
    return [
        x.normal,
        x.normal_extended,
        x.reduced,
        x.reduced_extended,
        x.reduced_special,
        x.zero,
        x.zero_extended
    ]
}

export function fromList(xs: AccountWithTaxRate.T[]): T | null {
    function search(s: VatType.Kind): AccountWithTaxRate.T | null {
        return xs.find(v => v.tax_rate.kind === s) ?? null
    }
    const result = {
        normal: search("Normal"),
        reduced: search("Reduced"),
        normal_extended: search("NormalExtended"),
        reduced_extended: search("ReducedExtended"),
        reduced_special: search("ReducedSpecial"),
        zero: search("Zero"),
        zero_extended: search("ZeroExtended"),
    }
    if (Types.checkNonNull(result)) {
        return result as T
    }
    console.error("Services.fromList", result)

    return null
}

export function updateTaxRate(x: T, vatrules: VatRule.T): T {
    return {
        normal: AccountWithTaxRate.updateTaxRate(x.normal, vatrules.Normal),
        reduced: AccountWithTaxRate.updateTaxRate(x.reduced, vatrules.Reduced),
        normal_extended: AccountWithTaxRate.updateTaxRate(x.normal_extended, vatrules.NormalExtended ?? 0),
        reduced_extended: AccountWithTaxRate.updateTaxRate(x.reduced_extended, vatrules.ReducedExtended ?? 0),
        reduced_special: AccountWithTaxRate.updateTaxRate(x.reduced_special, vatrules.ReducedSpecial ?? 0),
        zero: AccountWithTaxRate.updateTaxRate(x.zero, 0),
        zero_extended: AccountWithTaxRate.updateTaxRate(x.zero_extended, 0),
    }
}
