
import Vue from "vue";

import * as PricingInformation from "@/lib/Util/Types/Masterdata/BusinessProcess/PricingInformation"
import * as PerVolume from "@/lib/Util/Types/Masterdata/BusinessProcess/PerVolume"
import * as PerLineItem from "@/lib/Util/Types/Masterdata/BusinessProcess/PerLineItem"

import TableView from "./TableView.vue"
import * as Shared from "./Shared"

export default Vue.extend({
    name: "MasterdataClientListMainView",
    components: {
        TableView,
    },
    props: {
        item: {
            type: Object as () => PricingInformation.T | null,
        } as Vue.PropOptions<PricingInformation.T | null>,
    },
    data() {
        return {
            per_volume_headers: Shared.PER_VOLUME_HEADERS,
            per_lineitem_headers: Shared.PER_LINEITEM_HEADERS,
            per_invoice_type_headers: Shared.PER_INVOICE_TYPE_HEADERS,
        }
    },
    computed: {
        per_volume: () => PerVolume.getEmpty,
        per_lineitem: () => PerLineItem.getEmpty
    },
});
