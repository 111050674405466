import lodash from "lodash"

import jsonexport from "jsonexport"

import * as Common from "@/lib/Util/Common"

import * as Filters from '@/Filters'
import * as Client from '@/lib/Util/Types/Masterdata/Client/Client'
import * as BusinessProcess from '@/lib/Util/Types/Masterdata/BusinessProcess/BusinessProcess'

export const HEADERS = [
    {
        text: 'ShortID',
        value: 'short_id',
    },
    {
        text: 'Group',
        value: 'group_id',
    },
    {
        text: 'Name',
        value: 'name',
    },
    {
        text: 'Contact',
        value: 'contact',
    },
    {
        text: 'Email',
        value: 'email',
    },
    {
        text: 'Process#',
        value: 'process_count',
    },
    {
        text: 'Created At',
        value: 'created_at',
    },
    {
        text: 'Actions',
        value: 'actions',
    },
]

export type ListItem = {
    id: string;
    short_id: string;
    group_id: string;
    name: string;
    contact: string;
    email: string;
    process_count: number;
    created_at: string;
    actions: string;
    original: Client.T;
}

export function toListItem(x: Client.T): ListItem {
    return {
        id: x.id ?? "NO ID",
        short_id: x.short_id ?? "NO SHORT ID",
        group_id: x.group_id ?? "",
        name: x.name,
        contact: x.last_name,
        email: x.email.Value,
        process_count: x.process_count,
        created_at: Filters.format(x.created_at),
        actions: 'foo',
        original: x,
    }
}

type BusinessProcessWithParent = BusinessProcess.T & { parent: ListItem }

export function list_accounting_data(list_items: ListItem[], processes: BusinessProcess.T[]) {
    const tupled: [string, ListItem][] = list_items.map(v => [v.id ?? "", v])
    const imap = new Map(tupled)
    const processes_with_parent = processes.map(v => {
        const i = imap.get(v.client_id)
        if (i) {
            return {
                ...v,
                parent: i
            } as BusinessProcessWithParent
        }
        else {
            return null
        }
    }) as BusinessProcessWithParent[]
    const result = lodash.sortBy(processes_with_parent, v => `${v.parent.group_id}${v.country.kind}${v.parent.name}`)
    return result

}

export function transform_to_csv(xs: BusinessProcessWithParent[]) {
    const result =
        xs.map(v => {
            const result = {
                group: v.parent.group_id,
                country: v.country.kind,
                name: v.parent.name,
                process: v.description,
                account_payable: v.accounting_data.accounts_payable.account,
                account_receivable: v.accounting_data.accounts_receivable.account,

                vat_services_received_normal: v.accounting_data.services_received.normal.account,
                vat_services_received_reduced: v.accounting_data.services_received.reduced.account,
                vat_services_received_reduced_special: v.accounting_data.services_received.reduced_special.account,
                vat_services_received_zero: v.accounting_data.services_received.zero.account,

                vat_services_sold_normal: v.accounting_data.services_sold.normal.account,
                vat_services_sold_reduced: v.accounting_data.services_sold.reduced.account,
                vat_services_sold_reduced_special: v.accounting_data.services_sold.reduced_special.account,
                vat_services_sold_zero: v.accounting_data.services_sold.zero.account,

                contact_name: v.parent.contact,
                contact_email: v.parent.email
            }
            return result
        })
    return result
}

export async function export_csv(x: Record<string, string>[], filename: string) {
    const csv = await jsonexport(x, { rowDelimiter: ';' })
    Common.downloadCSV(csv, filename)
}
