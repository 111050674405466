import * as Types from "@/lib/Util/Types/Types"

export const VALUES = {
    Package: true,
    Kilogramm: true,
    Gramm: true,
    Liter: true,
    Meter: true,
    Piece: true,
    Hour: true,
    HalfHour: true,
    QuarterHour: true,
    Minute: true,
    Day: true,
} as const


type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)
export const VALS = Types.getDUArray(VALUES)

export function getEmpty(): T {
    return { kind: "Piece", Piece: true }
}

export function getByKind(x: Kind): T {
    return Types.makeSingleDUFromString(x)
}

export const tryParse = Types.buildTryParse<T, true>(VALUES, "kind", true, "Measure")