
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import * as Target from "@/lib/Util/REST/Target"

import * as Account from "@/lib/Util/Types/Common/Account"
import * as Workflow from "@/lib/Util/Types/Workflow/Workflow"
import * as InvoiceReference from "@/lib/Util/Types/Workflow/Doc/InvoiceReference"
import * as ProcessingState from "@/lib/Util/Types/Workflow/Doc/ProcessingState"
import * as WorkflowDoc from "@/lib/Util/Types/Workflow/Doc/WorkflowDoc"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"

@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
    },
})
export default class WorkflowSingleOverView extends Vue {
    @Prop({ default: "Prepare" }) area!: Target.TargetKind
    @Prop({ default: null }) item!: Workflow.T

    get doc_summary() {
        return this.item.doc.payment?.doc_summary
    }

    get payment_summary() {
        return this.item.doc.payment?.payment_summary
    }

    get process_ref() {
        return this.item.doc.process_reference
    }

    get durations() {
        return this.item.doc.payment?.doc_summary.durations
    }

    get VATs() {
        return WorkflowDoc.calcVAT(this.item.doc)
    }

    getInvoiceDate(x: InvoiceReference.T) {
        if (x.kind === "Single") {
            if (x.Single.kind === "Finished") {
                return x.Single.Finished[1].timestamp
            }
            return null
        }
        return null
    }

    getInvoiceUrl(x: InvoiceReference.T) {
        if (x.kind === "Single") {
            if (x.Single.kind === "Finished") {
                const fin = x.Single.Finished[0]
                return `${fin.key.bp_id}/${fin.year}/${fin.number}`
            }
            return null
        }
        return null
    }

    getSupplierPaymentDate(x: ProcessingState.T<Account.T>) {
        if (x.kind === "Finished") {
            return x.Finished[1].timestamp
        }
        return null
    }

    getSupplierPaymentUrl(x: ProcessingState.T<Account.T>) {
        if (x.kind === "Finished") {
            const fin = x.Finished[0]
            return `${fin.key.account_id}/${fin.year}/${fin.number}`
        }
        return null
    }

    changeView() {
        console.log("EMIT")
        this.$emit("change-view")
    }
}
