
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import * as Workflow from "@/lib/Util/Types/Workflow/Workflow"
import * as EditState from "@/lib/Util/Types/Workflow/EditState"

import TooltipIcon from "@/components/Util/TooltipIcon.vue"

@Component({
    components: { TooltipIcon },
})
export default class WorkflowStateToolbar extends Vue {
    @Prop({ default: null }) item!: Workflow.T
    @Prop({ default: null }) run!: (x: EditState.Kind) => void
    @Prop({ default: true }) active!: boolean
}
