import * as Money from "../Common/Money"
import * as Types from '../Types'

export const VALUES = {
    Normal: 0,
    Reduced: 0,
    ReducedSpecial: null as number | null,
    NormalExtended: null as number | null,
    ReducedExtended: null as number | null,
    Zero: 0,
    ZeroExtended: 0
}

export type Record = typeof VALUES

export type T = Types.RecToDUWithKindValueAndOriginal<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)
export const VALS = Types.getDUArray(VALUES)

export function getValue(x: T) {
    return x.value ?? 0
}

export function hasSameKind(left: T, right: T) {
    return left.kind == right.kind
}

export function calc(v: T, m: Money.T) {
    const val = getValue(v)
    const vat = val == 0 ? 1 : (val / 100 + 1)
    const new_val = val * vat
    return { value: new_val, currency: m.currency }
}

export function getEmpty(): T {
    return {
        kind: "Zero",
        value: 0,
        Zero: 0
    }
}

export function isExtended(x: Kind | T): boolean {
    if ((x as T).kind) {
        return isExtended((x as T).kind)
    }
    else {
        switch (x) {
            case "Normal": return false
            case "Reduced": return false
            case "ReducedSpecial": return false
            case "Zero": return false
            default: return true
        }
    }
}

export function tryParse(x: any): T | null {
    if (x) {
        if (Types.isValidValue(x.Normal)) {
            return { kind: "Normal", value: x.Normal, Normal: x.Normal }
        }
        if (Types.isValidValue(x.Reduced)) {
            return {
                kind: "Reduced",
                value: x.Reduced,
                Reduced: x.Reduced
            }
        }
        if (Types.isValidValue(x.ReducedSpecial)) {
            return {
                kind: "ReducedSpecial",
                value: x.ReducedSpecial,
                ReducedSpecial: x.ReducedSpecial
            }
        }
        if (Types.isValidValue(x.NormalExtended)) {
            return {
                kind: "NormalExtended",
                value: x.NormalExtended,
                NormalExtended: x.NormalExtended,
            }
        }
        if (Types.isValidValue(x.ReducedExtended)) {
            return {
                kind: "ReducedExtended",
                value: x.ReducedExtended,
                ReducedExtended: x.ReducedExtended,
            }
        }
        if (Types.isValidValue(x.Zero)) {
            return {
                kind: "Zero",
                value: 0,
                Zero: 0
            }
        }
        if (Types.isValidValue(x.ZeroExtended)) {
            return {
                kind: "ZeroExtended",
                value: 0,
                ZeroExtended: 0
            }
        }
        console.error("100 VatType.tryParse", x)
        return null
    }
    console.error("200 VatType.tryParse", x)
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "VatType")

export function updateTaxRate(x: T, tax_rate: number): T {
    switch (x.kind) {
        case "Normal": return { ...x, value: tax_rate, Normal: tax_rate }
        case "Reduced": return { ...x, value: tax_rate, Reduced: tax_rate }
        case "ReducedSpecial": return { ...x, value: tax_rate, ReducedSpecial: tax_rate }
        case "NormalExtended": return { ...x, value: tax_rate, NormalExtended: tax_rate }
        case "ReducedExtended": return { ...x, value: tax_rate, ReducedExtended: tax_rate }
        case "Zero": return { ...x, value: tax_rate, Zero: tax_rate }
        case "ZeroExtended": return { ...x, value: tax_rate, ZeroExtended: tax_rate }
    }
}
