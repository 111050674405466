import * as common from "@/components/Util/Common"
import * as EmailReceiver from "@/lib/Util/Types/Masterdata/BusinessProcess/EmailReceiver"

export type EditWrapper = common.EditWrapper<EmailReceiver.T>

export const HEADERS = [
    {
        text: 'Name',
        value: 'name',
        align: "start"
    },
    {
        text: 'Email',
        value: 'email.Value',
        align: "start"
    },
    {
        text: 'Invoice',
        value: 'invoice',
    },
    {
        text: 'Reminder 1',
        value: 'reminder1',
    },
    {
        text: 'Reminder 2',
        value: 'reminder2',
    },
    {
        text: '',
        value: 'actions',
        align: "center"
    },
]
