import * as lodash from "lodash"
import * as VatType from "@/lib/Util/Types/VAT/VatType"

import * as LineItem from "@/lib/Util/Types/Workflow/Doc/LineItem"
import * as CostEntityLine from "@/lib/Util/Types/Workflow/Doc/CostEntityLine"
import * as WorkflowDoc from "@/lib/Util/Types/Workflow/Doc/WorkflowDoc"

//this type is needed to work easily with the Postiondata Part view
export type T = LineItem.T & {
    vat_out: VatType.T;
    cost_entity_line: CostEntityLine.T;
}

function getLineItems(xs: T[]): LineItem.T[] {
    const copy = lodash.cloneDeep(xs)
    copy.forEach((v: any) => delete v.vat_out)
    return copy
}

function getVatOuts(xs: T[]): VatType.T[] {
    const copy = lodash.cloneDeep(xs)
    return copy.map(v => v.vat_out)
}

function getCostEntityLines(xs: T[]): CostEntityLine.T[] {
    const copy = lodash.cloneDeep(xs)
    return copy.map(v => v.cost_entity_line)
}

export function bundlePosDataLineItems(x: WorkflowDoc.T): T[] {
    const transform = (v: [LineItem.T, VatType.T?, CostEntityLine.T?]): T => {
        const [li, vt, cl] = v
        const vt_ = vt ?? li.vat
        const cl_ = cl ?? {}
        const result: T = {
            ...li,
            vat_out: vt_,
            cost_entity_line: cl_
        }
        return result
    }
    const zipped = lodash.zip(x.position_data, x.vat_table, x.cost_types)
    const filtered = zipped.filter(([a, b]) => a !== undefined) as [LineItem.T, VatType.T?, CostEntityLine.T?][]
    const transformed = filtered.map(transform)
    if (x.position_data.length != x.vat_table.length || x.position_data.length != x.cost_types.length) {
        console.warn("Waring in <bundlePosDataLineItems>. Array sizes are different", x.position_data, x.vat_table, x.cost_types)
    }
    return transformed
}

export function unbundlePosDataLineItems(x: WorkflowDoc.T, lines: T[]): WorkflowDoc.T {
    const lis = getLineItems(lines)
    const vats = getVatOuts(lines)
    const cost_lines = getCostEntityLines(lines)

    const result = lodash.cloneDeep(x)
    result.position_data = lis
    result.vat_table = vats
    result.cost_types = cost_lines

    return result
}
