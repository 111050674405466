
import Vue from "vue"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"

import * as lodash from "lodash/fp"
import Papa from "papaparse"

import EditTable from "@/components/Util/EditTable.vue"

import * as CostKind from "@/lib/Util/Types/Masterdata/BusinessProcess/CostKind"
import * as CodeInfo from "@/lib/Util/Types/Masterdata/BusinessProcess/CodeInfo"
import * as Shared from "./Shared"
import EditDialog from "./EditDialog.vue"

@Component({
    components: {
        EditDialog,
        EditTable,
    },
})
export default class CostKindView extends Vue {
    @Prop({ default: "No Title!" }) title!: string
    @Prop({ default: "No Part name!" }) part_name!: string
    @Prop({ default: "No BP SID!" }) bp_sid!: string
    @Prop({ default: null }) item!: CostKind.T //this should never be null thou

    available_types = CostKind.KEYS
    selected_type = this.item.kind
    headers = Shared.HEADERS
    addRow = CodeInfo.getEmpty
    convertRow = Shared.toEditWrapper
    convertRowList = Shared.toWrapperList
    sort = CodeInfo.sort
    loaded = Shared.toExtendedCostKind(this.item)
    filename = "Drop CSV File here for Import"

    updateList(xs: CodeInfo.T[]) {
        console.log("CostKindView.updateList", xs, this.loaded)
        if (this.loaded.kind === "List") {
            this.loaded.value.value = xs
        }
    }

    exportCSV() {
        if (this.loaded.kind === "List") {
            console.log("EXPORTCSV:", this.part_name)
            Shared.exportCSV(this.bp_sid, this.part_name, this.loaded.value.value)
        }
    }

    async addFile(e: any) {
        const files = e.dataTransfer.files as File[]
        if (files && this.loaded) {
            this.filename = files[0].name
            const buffer = await files[0].text()
            const parsed = Papa.parse(buffer, {
                quoteChar: '"',
                delimiter: ";",
            })
            const last = parsed.data[parsed.data.length - 1] as string[]
            if (last.length === 1 && last[0] === "") {
                parsed.data.pop()
            }
            const clean = parsed.data.slice(1) as string[][]
            const cis = CodeInfo.from(clean)
            if (cis) {
                switch (this.loaded.kind) {
                    case "List":
                        this.loaded.value.value = cis
                }
            }
            console.log("CostKindView.addFile", buffer, parsed, clean)
        } else {
            console.error("Template.EditDialog.addFile", e)
        }
    }

    @Watch("selected_type", { deep: true })
    onSelectedTypeChanged(nv: CostKind.Kind) {
        if (nv !== this.loaded.kind) {
            const result = CostKind.getEmpty(nv)
            this.$emit("update:item", result)
        }
    }

    @Watch("item", { deep: true })
    onItemChanged(nv: CostKind.Kind) {
        console.log("100 CostKindView.onItemChanged", nv, this.item)
        console.log
        if (nv) {
            this.selected_type = this.item.kind
            this.loaded = Shared.toExtendedCostKind(this.item)
            console.log("200 CostKindView.onItemChanged", nv, this.item)
        } else {
            console.error("CostKindView.onItemChange")
        }
    }

    @Watch("loaded", { deep: true })
    onLoadedChanged(nv: CostKind.Kind) {
        console.log("100 CostKindView.onLoadedChanged", nv, this.item)
        const compare = Shared.toExtendedCostKind(this.item)
        if (!lodash.isEqual(nv, compare)) {
            console.log("200 CostKindView.onLoadedChanged", nv, this.item)
            const e = this.loaded.value.toKind()
            this.$emit("update:item", e)
        }
    }
}
