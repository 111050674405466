import * as lodash from "lodash/fp"
import * as Types from '@/lib/Util/Types/Types'

export type T = {
    code: string;
    desc: string;
}

export function getEmpty(): T {
    return {
        code: "",
        desc: ""
    }
}

export function tryParse(x?: any): T | null {
    if (x) {
        if (Types.isOfType(x, getEmpty)) {
            return x
        }
    }
    console.error("CodeInfo.tryParse", x)
    return null
}

export function sort(xs: T[]): T[] {
    const result = lodash.cloneDeep(xs)
    result.sort(function (a, b) {
        if (a.code === b.code) {
            return 0
        }
        if (a.code < b.code) {
            return -1
        }
        else return 1
    })
    return result
}

export function from(xs: string[]): T | null;
export function from(xs: string[][]): T[] | null;

export function from(xs: string[] | string[][]): T | T[] | null {
    if (xs.length >= 0) {
        if (Array.isArray(xs[0])) {
            const arr = xs as string[][]
            const mapped = arr.map(v => from(v))
            const result = mapped.filter(v => v !== null) as T[]
            return result
        }
        else {
            if (xs.length >= 2) {
                return {
                    code: xs[0],
                    desc: xs[1]
                } as T
            }
            return null
        }
    }
    return null
}