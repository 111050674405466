import * as Types from '@/lib/Util/Types/Types'
import * as Email from '@/lib/Util/Types/Common/Email'

export const VALUES = {
    NotAtAll: true,
    MustBeApproved: Email.getEmpty(),
    MustBeApprovedOnLimit: [0, Email.getEmpty()] as [number, Email.Email],
    TwoSteps: [Email.getEmpty(), 0, Email.getEmpty()] as [Email.Email, number, Email.Email],
}

type Record = typeof VALUES

export type T = Types.RecToDUWithKindValueAndOriginal<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getEmpty(): T {
    return {
        kind: "NotAtAll",
        NotAtAll: true,
        value: true
    }
}

export type CreationRequest = {
    e1: Email.Email;
    limit?: number;
    e2?: Email.Email;
}

export function create(req?: CreationRequest): T {
    if (req) {
        if (req.e2 && req.limit) {
            return {
                kind: "TwoSteps",
                TwoSteps: [req.e1, req.limit, req.e2],
                value: [req.e1, req.limit, req.e2]
            }
        }
        if (req.e2 && !req.limit) {
            return {
                kind: "TwoSteps",
                TwoSteps: [req.e1, 0, req.e2],
                value: [req.e1, 0, req.e2]
            }
        }
        if (!req.e2 && req.limit) {
            return {
                kind: "MustBeApprovedOnLimit",
                MustBeApprovedOnLimit: [req.limit, req.e1],
                value: [req.limit, req.e1]
            }
        }
        return {
            kind: "MustBeApproved",
            MustBeApproved: req.e1,
            value: req.e1
        }
    }
    return getEmpty()
}

export function tryParse(x: any): T | null {
    try {

        if (x.NotAtAll) {
            return {
                kind: "NotAtAll",
                NotAtAll: true,
                value: true
            }
        }
        if (x.MustBeApproved) {
            const email = Email.Email.tryParse(x.MustBeApproved.Email)
            if (email) {
                return {
                    kind: "MustBeApproved",
                    MustBeApproved: email,
                    value: email
                }
            }
            console.error("ApprovalProcess.tryParse | MustBeApproved", x);
            return null
        }
        if (x.MustBeApprovedOnLimit && x.MustBeApprovedOnLimit.length === 2) {
            const limit = x.MustBeApprovedOnLimit[0]
            const email = Email.Email.tryParse(x.MustBeApprovedOnLimit[1].Email)
            if (email) {
                return {
                    kind: "MustBeApprovedOnLimit",
                    MustBeApprovedOnLimit: [limit, email],
                    value: [limit, email]
                }
            }
            console.error("ApprovalProcess.tryParse | MustBeApprovedOnLimit", x);
            return null
        }
        if (x.TwoSteps && x.TwoSteps.length === 3) {
            const email1 = Email.Email.tryParse(x.TwoSteps[0].Email)
            const limit = x.TwoSteps[1]
            const email2 = Email.Email.tryParse(x.TwoSteps[2].Email)
            if (email1 && email2) {
                return {
                    kind: "TwoSteps",
                    TwoSteps: [email1, limit, email2],
                    value: [email1, limit, email2]
                }
            }
            console.error("ApprovalProcess.tryParse | TwoSteps", x);
            return null
        }
        console.error("ApprovalProcess.tryParse | ???", x);
        return null
    }
    catch (ex) {
        console.error("ApprovalProcess.tryParse | PANIC", ex, x);
        return null
    }
}