
export const HEADERS = [
    {
        text: 'Kind',
        value: 'uid.key.kind',
    },
    {
        text: 'Account',
        value: 'uid.key.account_id',
    },
    {
        text: 'Year',
        value: 'uid.year',
    },
    {
        text: '#',
        value: 'uid.number',
    },
    {
        text: 'Created At',
        value: 'created_at'
    },
    {
        text: 'Total',
        value: 'total',
        align: 'end'
    },
    {
        text: 'discounted',
        value: 'discounted.value',
        align: 'end'
    },
    {
        text: 'Currency',
        value: 'total.currency.kind'
    },
    {
        text: 'Count',
        value: 'count',
        align: 'end'
    },
    {
        text: '',
        value: 'actions',
    },
]
