
import Vue from "vue"
import lodash from "lodash"

import { Component, Watch } from "vue-property-decorator"
import { ExportToCsv } from "export-to-csv"
import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"

import FilterView from "./Filter.vue"
import * as Common from "@/lib/Util/Common"

import * as Timespan from "@/lib/Util/Types/Common/Timespan"

import * as Shared from "./Shared"
import router from "@/router/router"

import store from "@/store/store"
import * as Filter from "@/store/Remittance/FilterNew"

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"])

@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
        FilterView,
    },
})
export default class BankRemittanceListMainView extends Vue {
    headers = Shared.HEADERS

    get items() {
        const result = lodash.orderBy(store.state.remittance.list.value, "payment_date", "desc")
        return result
    }

    get msg() {
        return store.state.remittance.list.message
    }

    getClientNameById(x: string) {
        if (store.state.client.list.full_list?.result) {
            return store.state.client.list.full_list.result.find((v, i) => v.id === x)?.name ?? ""
        }
        return ""
    }

    created() {
        if (store.state.client.list.full_list?.result === undefined) {
            store.dispatch.client.list.LOAD_LIST()
        }
    }

    get filter() {
        return store.state.remittance.list.filter
    }

    set filter(x: Filter.T) {
        store.commit.remittance.list.updateFilter(x)
        console.log("100 Remittance.List.set filter", x)
        router.push({
            path: "/sections/bank/remittance/list",
            query: x,
        })
    }

    beforeRouteEnter(to: any, from: any, next: any) {
        console.log("100 Remittance beforeRouteEnter", to)
        const filter = Filter.from(to.query)
        console.log("200 Remittance beforeRouteEnter", filter)
        if (!Common.isEmpty(filter)) {
            console.log("210 Remittance beforeRouteEnter", filter)
            store.commit.remittance.list.updateFilter(filter)
            const query = Filter.toQuery(filter)
            store.dispatch.remittance.list.LOAD(query)
            next()
        } else {
            console.log("300 Remittance beforeRouteEnter", Common.toQueryString(Filter.getEmpty()))
            router.push({
                path: "/sections/bank/remittance/list",
                query: Filter.getEmpty(),
            })
        }
    }

    beforeRouteUpdate(to: any, from: any, next: any) {
        console.log("100 Remittance.List.beforeRouteUpdate", to)
        const query = Filter.toQuery(to.query)
        store.dispatch.remittance.list.LOAD(query)
        next()
    }

    async add() {
        console.log("EMPTY WORKFLOW")
        const _ = await store.dispatch.remittance.single.CREATE()
        const id = store.state.remittance.single.value?.id ?? "new"
        router.push({ path: `/sections/bank/remittance/single/${id}` })
    }

    exportCSV() {
        const filename = `supplier_payment`
        const records = this.items.map(x => x).flat()
        const options = {
            fieldSeparator: ";",
            quoteStrings: '"',
            decimalSeparator: ",",
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            filename: filename,
            useKeysAsHeaders: true,
        }

        const exporter = new ExportToCsv(options)

        exporter.generateCsv(records)
    }
}
