
import Vue from "vue"

import * as App from "@/lib/App"
import store from "@/store/store"

export default Vue.extend({
    name: "SectionMainView",
    store: store.original,

    beforeRouteEnter(to: any, from: any, next: any) {
        if (!store.state.authentication) {
            next({ path: "/login" })
        }
        next()
    },
    computed: {
        authentication: () => store.state.authentication,
        toolbar() {
            return store.state.toolbar
        },
    },
})
