import * as Common from "@/lib/Util/Common"

export type T = {
    uri: string;
    timestamp: Date;
    active: boolean;
    text?: string;
}

export function getEmpty(): T {
    return {
        uri: "https://this.is.the.road.to.nowhere",
        timestamp: new Date(1970, 0, 1),
        active: false
    }
}

export function tryParse(x?: any): T | null {
    if (x) {
        const timestamp = Common.parseServerDate(x.timestamp)
        if (timestamp) {
            return {
                ...x,
                timestamp
            }
        }
    }
    console.error("FileResource.tryParse", x)
    return null
}

