
import Vue from "vue"
import * as lodash from "lodash/fp"
import * as Email from "@/lib/Util/Types/Common/Email";

import * as Shared from "./Shared"


export default Vue.extend({
    name: "EditDialog",
    components: {
    },
    props: {
        item: {
            type: Object as () => Shared.EditWrapper,
        } as Vue.PropOptions<Shared.EditWrapper>,
        dialogSave: {
            type: Function
        },
        dialogCancel: {
            type: Function
        }
    },
    data() {
        return {
            valid: true,
            rules: {
                email: [
                    function checkLen(v?: string): boolean | string {
                        return (v?.length ?? 0) !== 0 || "Email must not be empty";
                    },
                    function isEmail(v?: string): boolean | string {
                        return Email.validate(v) || "Please enter valid email";
                    },
                ],
                name: [
                    (v: string) => {
                        return v.length > 0 || "Name must not be empty"
                    }
                ]
            }
        }
    },
    computed: {
        loaded() {
            return lodash.cloneDeep(this.item)
        },
        used_email: {
            get: function (): string {
                return this.loaded?.value.email.Value ?? "unknown@unknown.com";
            },
            set: function (v: string) {
                if (this.loaded) {
                    this.loaded.value.email = Email.Email.tryParse(v) ?? Email.getEmpty();
                }
            }
        },
    },
    watch: {

        valid: {
            handler(nv: boolean) {
                console.log("WATCH VALID:", nv)
            }
        }
    },
    methods: {
        save() {
            console.log("EDITDIALOG-EMAILRECEIVERS.SAVE", this.loaded, this.valid)
            this.dialogSave(this.loaded)
        },
        cancel() {
            console.log("EDITDIALOG-EMAILRECEIVERS.CANCEL", this.loaded, this.valid)
            this.dialogCancel()
        }
    }
});
