import * as lodash from 'lodash/fp';
import { defineModule, localActionContext } from "direct-vuex"

import * as Supplier from "@/lib/Util/Types/Supplier/Supplier";
import * as REST from '@/lib/Util/REST'
import { RootState } from '@/store/Shared'

export type Filter = {
    name?: string;
    city?: string;
    account_id?: string;
}
const state = {
    value: [] as Supplier.T[],
    message: REST.getEmpty(),
    filter: {} as Filter,
    timestamp: new Date(),
}

const mutations = {
    updateValue(state: State, model: Supplier.T[] | null) {
        const values = model ?? []
        state.value = values;
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    },
    updateFilter(state: State, filter: Filter) {
        state.filter = filter
    }
}

const getters = {
}

const actions = {
    async LOAD(context: any, filter: Filter) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context)
        const rootState = context.rootState as RootState
        try {
            const loading_msg = {
                ...state.message,
                loading: true
            }
            commit.updateMsg(loading_msg)
            commit.updateValue([])
            const req = {
                token: rootState.authentication?.token ?? "NO TOKEN",
                url: "/suppliers",
                payload: filter,
                transformer: Supplier.tryParseList
            };
            const result = await REST.GET(state.message, req);
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text: "Successfully loaded supplier list",
                timeout: 5000
            }
            commit.updateValue(result.value)
            commit.updateMsg(msg)
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg(msg)
        }
    },
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    getters,
    actions
}

export type Mutations = typeof mutations
export type State = typeof state
export type Getters = typeof getters
export type Actions = typeof actions

export const module = defineModule(internal)
export type Module = typeof module
export default module

const actionCtx = (context: any) => localActionContext(context, module)
