
import Vue from "vue"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"

import TooltipIcon from "@/components/Util/TooltipIcon.vue"

import * as datefns from "date-fns"
import * as lodash from "lodash/fp"
import * as Protocols from "@/lib/Util/Types/Protocols"

@Component({
    components: {
        TooltipIcon,
    },
})
export default class SearchForm<T> extends Vue {
    @Prop({
        default: () => {
            return {}
        },
    })
    lang!: Protocols.QueryLanguage<T>

    @Prop({
        default: () => {
            return {}
        },
    })
    initial_filter!: Protocols.QueryExpression<T>

    property_key = null as keyof T | null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    property_value = null as any
    expr = this.merge()

    merge(): Protocols.QueryExpression<T> {
        const v = Protocols.createExpression(this.lang)
        return {
            ...v,
            ...this.initial_filter,
        }
    }

    @Watch("initial_filter", { deep: true })
    onInitialFilterChanged(newval: Protocols.QueryExpression<T>, oldval: Protocols.QueryExpression<T>) {
        //updating the initial_filter will overwrite already selected filters
        this.expr = this.merge()
    }

    getQueryTypeKind(x: keyof T | null): Protocols.QueryType["kind"] | null {
        if (x) {
            return this.lang[x]?.kind ?? null
        }
        return null
    }

    getQueryTypeValues(x: keyof T) {
        const slot = this.lang[x] as Protocols.QueryType
        switch (slot.kind) {
            case "select_single":
                return slot.value
            case "select_multi":
                return slot.value
            default:
                return []
        }
    }

    stringify(x: Protocols.ExpressionType<T>) {
        switch (x.value.kind) {
            case "string":
                return x.value.value
            case "number":
                return x.value.value.toString()
            case "boolean":
                if (x.value.value) {
                    return x.value.value.toString()
                } else {
                    return "false"
                }
            case "regex":
                return x.value.value.toString()
            case "date":
                return datefns.format(x.value.value, "yyyy-MM-dd")
            case "select_single":
                return x.value.value
            case "select_multi":
                return x.value.value.join(";")
        }
    }

    get isAddButtonDisabled() {
        if (!this.property_key) {
            return true
        }

        const result = this.property_value === null && this.getQueryTypeKind(this.property_key) !== "boolean"
        return result
    }

    addExpression(key: keyof T | null, v: Protocols.QueryExpressionValue) {
        if (key) {
            this.expr[key] = v
            this.$emit("UpdateFilter", this.expr)
        }
    }

    removeExpression(key: keyof T | null) {
        if (key) {
            this.expr[key] = undefined
            this.$emit("UpdateFilter", this.expr)
        }
    }

    onAddExpression() {
        switch (this.getQueryTypeKind(this.property_key)) {
            case "string":
                this.addExpression(this.property_key, {
                    kind: "string",
                    value: this.property_value,
                })
                this.property_key = null
                this.property_value = null
                return
            case "regex":
                this.addExpression(this.property_key, {
                    kind: "regex",
                    value: this.property_value,
                })
                this.property_key = null
                this.property_value = null
                return
            case "boolean":
                this.addExpression(this.property_key, {
                    kind: "boolean",
                    value: this.property_value,
                })
                this.property_key = null
                this.property_value = null
                return
            case "date":
                this.addExpression(this.property_key, {
                    kind: "date",
                    value: new Date(this.property_value),
                })
                this.property_key = null
                this.property_value = null
                return
            case "select_multi":
                this.addExpression(this.property_key, {
                    kind: "select_multi",
                    value: this.property_value,
                })
                this.property_key = null
                this.property_value = null
                return
            case "select_single":
                this.addExpression(this.property_key, {
                    kind: "select_single",
                    value: this.property_value,
                })
                this.property_key = null
                this.property_value = null
                return
            default:
                return
        }
    }

    get available() {
        const lang_keys = Object.keys(this.lang)
        const expr_keys = Protocols.toList(this.expr).map((x) => x.key) as string[]
        const result = lodash.difference(lang_keys, expr_keys) as (keyof T)[]
        return result
    }

    get expressions() {
        return Protocols.toList(this.expr)
    }

    clear() {
        this.property_key = null
    }
}
