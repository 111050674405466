
import Vue from "vue"
import { Component } from "vue-property-decorator"


import * as REST from "@/lib/Util/REST"
import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"
import SearchForm from "@/components/Util/SearchForm/Main.vue"

import * as PaymentStateSearchItem from "@/lib/Util/Types/Workflow/SearchListItem/PaymentStateSearchItem"
import * as PaymentMode from "@/lib/Util/Types/Workflow/PaymentMode"

import store from "@/store/store"
import * as WarningStore from "@/store/Warning/store"

import * as Shared from "./Shared"

@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
        SearchForm,
    },
})
export default class WarnMainView extends Vue {
    headers = Shared.HEADERS
    items: PaymentStateSearchItem.T[] = []
    msg: REST.RestMessage = REST.getEmpty()

    async mounted() {
        this.msg.active = true
        this.msg.loading = true
        const result = await WarningStore.load(store.state.authentication?.token ?? "NO TOKEN")
        if (result) {
            this.items = result
            this.msg.loading = false
            this.msg.text = "Loaded Warnings"
            this.msg.success = true
        }
        else {
            this.msg.loading = false
            this.msg.text = "Something Bad happened"
            this.msg.success = false
        }
    }

    area(x: PaymentStateSearchItem.T) {
        return x.area.kind.toLowerCase() ?? "prepare"
    }

    modeIcon(x: PaymentStateSearchItem.T) {
        const icons: Record<PaymentMode.Kind, string> = {
            PREPAYMENT: "mdi-cash-plus",
            AFTER_PAYMENT: "mdi-cash-clock",
            FASTTRACK: "mdi-fast-forward",
            REMINDER_1: "mdi-numeric-1-circle",
            REMINDER_2: "mdi-numeric-2-circle",
            REMINDER_3: "mdi-numeric-3-circle",
            COLLECTION_THREAT: "mdi-bomb",
            COLLECTION: "mdi-nuke",
            CLIENT_REQUEST: "mdi-chat-question",
            ON_HOLD: "mdi-lock",
        }
        if (x.payment_state) {
            return icons[x.payment_state.payment_mode.kind]
        }
        else {
            return "mdi-mdi-alert-circle"
        }
    }

    iconText(x: PaymentStateSearchItem.T) {
        return x.payment_state?.payment_mode.kind + ": " + x.payment_state?.comment ?? ""
    }

    areaIcon(x: PaymentStateSearchItem.T) {
        switch (x.area.kind) {
            case "Prepare": return "mdi-clipboard-edit-outline"
            case "Verify": return "mdi-eye-check-outline"
            case "Approve": return "mdi-clipboard-check-outline"
            case "Pay": return "mdi-contactless-payment"
            case "Finish": return "mdi-toggle-switch-off"
            case "Cancel": return "mdi-close-circle"
        }
    }

}
