import _ from 'lodash'

type DiscriminateUnion<T, K extends keyof T, V extends T[K]> = T extends Record<K, V> ? T : never

type Raw<T extends string> = T
type Kinded2<T extends string> = { kind: T }
type DUTransformer<T> = T extends Kinded2<infer K> ? T & { [K1 in K]: true } : never

export type Kinded<T> = T extends Raw<infer K> ? { kind: K } : never
export type KindedUnionCase<T, V = true> =
    T extends Raw<infer K> ?
    (({ kind: K } & { [K1 in K]: V }) extends infer O ? { [P in keyof O]: O[P] } : never)
    : never

export type KindedValuedUnionCase<T, V = true> =
    T extends Raw<infer K> ?
    ((KindedUnionCase<T, V> & { value: K }) extends infer O ? { [P in keyof O]: O[P] } : never)
    : never

export type MapDiscriminatedUnion<T extends Record<K, string>, K extends keyof T> = { [V in T[K]]: DiscriminateUnion<T, K, V> };
export type MapDiscriminatedUnionShallow<T extends Record<K, string>, K extends keyof T> = { [V in T[K]]: "" };

export type DURecMergeWithKindKey<
    D extends Record<keyof D, PropertyKey>,
    R extends Partial<Record<D[keyof D], any>>
    > = {
        [K in D[keyof D]]: (
            Extract<D, Record<keyof D, K>> & (K extends keyof R ? Pick<R, K> : unknown)
        ) extends infer O ? { [P in keyof O]: O[P] } : never
    }[D[keyof D]];

//works only if record property has object as value (see code example below)
export type DURecMergeWithoutKey<
    D extends Record<keyof D, PropertyKey>,
    R extends Partial<Record<D[keyof D], any>>
    > = {
        [K in D[keyof D]]: (
            Extract<D, Record<keyof D, K>> & R[K]
        ) extends infer O ? { [P in keyof O]: O[P] } : never
    }[D[keyof D]];

export type DURecMergeWithKey<
    D extends Record<keyof D, PropertyKey>,
    R extends Partial<Record<D[keyof D], any>>,
    N extends string = "value"
    > = {
        [K in D[keyof D]]: (
            Extract<D, Record<keyof D, K>> & Record<N, R[K]>
        ) extends infer O ? { [P in keyof O]: O[P] } : never
    }[D[keyof D]];

export type RecToDURaw<T> = {
    [K in keyof T]: Record<K, T[K]>
}[keyof T]

export type RecToDU<T, N extends string = "kind"> = {
    [K in keyof T]:
    (Record<N, K> & (T[K])) extends infer O ? { [P in keyof O]: O[P] } : never
}[keyof T]

export type RecToDUWithValue<T, N extends string = "kind", V extends string = "value"> = {
    [K in keyof T]:
    (Record<N, K> & (T[K] extends null ? Record<string, unknown> : Record<V, T[K]>)) extends infer O ? { [P in keyof O]: O[P] } : never
}[keyof T]

export type RecToDUWithDefaultValue<T, W, N extends string = "kind", V extends string = "value"> = {
    [K in keyof T]:
    (Record<N, K> & (T[K] extends null ? Record<V, W> : Record<V, T[K]>)) extends infer O ? { [P in keyof O]: O[P] } : never
}[keyof T]

export type RecToDUWithValueOfKindName<T, N extends string = "kind"> = {
    [K in keyof T]:
    (Record<N, K> & (T[K] extends null ? Record<string, unknown> : Record<K, T[K]>)) extends infer O ? { [P in keyof O]: O[P] } : never
}[keyof T]

export type RecToDUWithKindValueAndOriginal<T, K extends string = "kind", V extends string = "value"> =
    RecToDUWithValueOfKindName<T> &
    RecToDUWithValue<T, K, V> extends infer O ? { [P in keyof O]: O[P] } : never

export type SingleDUFromString<X extends string> = (Record<X, true> & Record<"kind", X>) extends infer O ? { [P in keyof O]: O[P] } : never
export type SinglePropertyRecord<X extends string, Y> = Record<X, Y> extends infer O ? { [P in keyof O]: O[P] } : never

export type DU2Array<T> = T extends Record<string, unknown> ? T[] : never

export type Nullable<Type> = {
    [Property in keyof Type]: Type[Property] | null;
};

export type RecToDUUnique<T> = unknown extends {
    [K in keyof T]-?: T[K] extends Omit<T, K>[Exclude<keyof T, K>] ? unknown : never
}[keyof T] ? never : T[keyof T]

export type RequireAtLeastOne<T> = {
    [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T]

export type Reverser<T extends Record<PropertyKey, PropertyKey>> = {
    [P in keyof T as T[P]]: P
}

type Id<T> = {} & { [P in keyof T]: T[P] }

type PickIfNotPrimitive<T, K extends keyof T, V = T[K]> =
    V extends Date | string | number | bigint | boolean
    ? Record<K, V>
    : Pick<T, K>

export type Accumulate<T, Keys extends string[], B = {}, R = never> =
    Keys extends [infer Head, ...infer Tail] ?
    Tail extends string[]
    ? Accumulate<
        T,
        Tail,
        Required<PickIfNotPrimitive<T, Head & keyof T>> & B,
        // New result
        | R & Partial<PickIfNotPrimitive<T, Head & keyof T>> // Add new partial values
        | Required<PickIfNotPrimitive<T, Head & keyof T>> & B & { type: `${Head & string}Required` }>
    : never
    : Id<R>

export type UniqueReverser<T extends Record<keyof T, PropertyKey>> = unknown extends {
    [K in keyof T]-?: T[K] extends Omit<T, K>[Exclude<keyof T, K>] ? unknown : never
}[keyof T] ? never : { [P in keyof T as T[P]]: P }

export function isValidValue(x: any) {
    return x !== undefined && x !== null
}

export function buildTryParse<T, V>(values: any, kindname: string, defval: V, typename: string) {
    return function (x?: any): T | null {
        if (x) {
            const k = Object.getOwnPropertyNames(x)
            if (k.length === 1) {
                //we assume the following form here {"SomeKey": defval}
                const key = k[0]
                const propnames = Object.getOwnPropertyNames(values)
                if (propnames.includes(key)) {
                    const result: { [k: string]: any } = {};
                    result[kindname] = key
                    result[key] = defval
                    return result as T
                }
            }
            else if (x[kindname] && x[x[kindname]] === defval) {
                //this has already the correct shape
                return x as T
            }
            console.error(`100 ${typename}.tryParse`, x)
            return null

        }
        console.error(`200 ${typename}.tryParse`, x)
        return null
    }
}

export function buildParseDefault<T>(parser: (x: any) => T | null, empty: () => T): (v: any) => T {
    const result = (v: any) => parser(v) ?? empty()
    return result
}

export function buildTryParseList<T>(f: (x: any | null) => T | null, typename: string) {
    const result = (xs: any[] | null) => {
        if (xs) {
            if (Array.isArray(xs)) {
                const result = xs.map(f).filter(v => v !== null) as T[]
                if (result.length !== xs.length) {
                    console.warn("could not parse all items from the source array", xs, result)
                }
                return result
            }
            console.error(`value for ${typename} was not an Array`, xs)
            return []
        }
        console.error(`array for ${typename} was null || undefined`)
        return []
    }
    return result
}

export function mkGetEmpty<T>(x: any): () => T {
    return (() => _.cloneDeep(x) as T)

}

export function getKindNames<Keys extends Record<Kinds, any>, Kinds extends string>(x: Keys): Kinds[] {
    return Object.getOwnPropertyNames(x) as Kinds[]
}

export function getDUArray<Rec extends Record<string, any>>(x: Rec): RecToDUWithKindValueAndOriginal<Rec>[] {
    const names = Object.getOwnPropertyNames(x)
    const result = names.map(v => {
        return {
            kind: v,
            value: x[v],
            [v]: x[v],
        }
    })
    return result as unknown as RecToDUWithKindValueAndOriginal<Rec>[]
}

export function makeSingleDUFromString<T extends string>(x: T): SingleDUFromString<T> {
    const result: { [k: string]: any } = {};
    result[x] = true
    result.kind = x
    return result as SingleDUFromString<T>
}

export function tryMakeSingleDUFromString<T extends string>(v: string, keys: T[]): SingleDUFromString<T> | null {
    if (keys.includes(v as T)) {
        const result: { [k: string]: any } = {};
        result[v] = true
        result.kind = v
        return result as SingleDUFromString<T>
    }
    else {
        return null
    }
}

export function makeSinglePropertyRecord<K extends string, P>(k: K, p: P): SinglePropertyRecord<K, P> {
    const result: { [k: string]: any } = {};
    result[k] = p
    return result as SinglePropertyRecord<K, P>
}

export function checkNonNullWithKeys<T>(keys: (keyof T)[], x: T): boolean {
    const checks: [keyof T, boolean][] = keys.map(v => [v, (x[v] ? true : false)])
    const result = checks.every(v => v)
    if (!result) {
        console.error("checkNonNullWithKeys", checks)
    }
    return result
}

export function checkNonNull(x: any): boolean {
    const keys = Object.keys(x)
    const checks = keys.map(v => isValidValue(x[v]) ? true : false)
    return checks.every(v => v)
}

export function getKeys<T>(x: T): (keyof T)[] {
    return Object.keys(x) as (keyof T)[]
}

export function reverse<T extends Record<PropertyKey, PropertyKey>>(x: T): UniqueReverser<T> {
    const keys: (keyof T)[] = Object.keys(x)
    const result: any = {}
    for (const key of keys) {
        result[x[key]] = key
    }
    return (result as any as UniqueReverser<T>)
}

export function buildLookup<T extends Record<PropertyKey, PropertyKey>>(x: T) {
    const target = reverse(x)
    const findInSource = (v: string | null): (keyof UniqueReverser<T>) | null => {
        if (v) {
            const result = x[v] ?? null
            return result as any as (keyof UniqueReverser<T>) | null
        }
        return null
    }
    const findInTarget = (v: string | null): (keyof UniqueReverser<T>) | null => {
        if (v) {
            const result = x[v] ?? null
            return result as any as (keyof UniqueReverser<T>) | null
        }
        return null
    }
    type Lookup = {
        readonly source: T;
        readonly target: UniqueReverser<T>;
    }
    const result: Lookup = {
        source: x,
        target
    }
    return result
}
export function isOfType<T>(x: any): x is T;
export function isOfType<T>(x: any, mempty: () => T): x is T;

//checks the shape of x by checking for mandatory vs optional props
//very lightweight Type Guard
export function isOfType<T>(x: any, mempty?: () => T): x is T {
    if (mempty) {
        const keys = getKeys(mempty())
        const result = checkNonNullWithKeys(keys, x)

        return result
    }
    else {
        const result = checkNonNull(x)
        return result
    }
}

// example section
type AA =
    | { kind: "A"; b: "B" }
    | { kind: "B"; c: "Maybe C" }

type AAA = DU2Array<AA>
type SimpleUnion =
    | "foo"
    | "bar"
    | "baz"

type SU1 = KindedUnionCase<SimpleUnion, 1>
type SU2 = KindedValuedUnionCase<SimpleUnion, 1>
type SU3 = KindedUnionCase<SimpleUnion, "lala">
type SU4 = KindedValuedUnionCase<SimpleUnion, "NO!">
type SU5 = KindedUnionCase<SimpleUnion>
type SU6 = KindedValuedUnionCase<SimpleUnion>

const aaa: AAA = [{ kind: "A", b: "B" }, { kind: "A", b: "B" }]
type AAKeyof = keyof AA
type ExtractKeyOfAA = AA[keyof AA]
type RecordAA = Record<AA[keyof AA], any>
type PartialAA = Partial<Record<AA[keyof AA], any>>
type ToDU1 = { [K in AA[keyof AA]]: (Record<"descriminator", K>) extends infer O ? { [P in keyof O]: O[P] } : never }[AA[keyof AA]]
type ToDU2 = { [K in AA[keyof AA]]: (Record<"descriminator", K> & { foo: boolean }) extends infer O ? { [P in keyof O]: O[P] } : never }[AA[keyof AA]]
type ToDU3 = { [K in AA[keyof AA]]: { descriminator: K } & { foo: boolean } }[AA[keyof AA]]

type Kinded3<T extends Record<string, unknown>> = T

const f: ToDU1 = { descriminator: "A" }


type DU1 =
    | { kind: 'foo' }
    | { kind: 'bar' }
    | { kind: 'baz' }

type DU4 = DU2Array<DU1>

type REC1 = {
    foox: { a: string; b: number };
    bar: { c: boolean };
    bax: null;
    foobar: { d?: Date };
}

type REC2 = {
    foo: string;
    bar: 0;
    bax: null | number;
    foobar: Date | null;
}

enum E1 {
    foo = "foo",
    bar = "bar"
}

const SOURCE = {
    field1: "fieldA",
    field2: "fieldB",
    field3: "fieldC"
} as const

const lookup = buildLookup(SOURCE)
lookup.source.field1
lookup.target.fieldA

type VALTYPE2 = Reverser<typeof SOURCE>

type RECA1 = keyof REC1
type IsUnknown = null extends null ? { isUnknown: true } : { isUnknown: false }
type RECA = RecToDU<REC1>
type RECARaw = RecToDURaw<REC1>
type RECB = RecToDUWithValue<REC1>
type RECC = RecToDUWithValueOfKindName<REC1>
type RECD = RecToDUWithValue<REC2>
type RECE = RecToDUWithDefaultValue<REC2, true>
type RECF = RecToDUWithKindValueAndOriginal<REC2>

type Prover<D, R, RKEYS extends keyof R & DKEYS, DKEYS extends D[keyof D] = D[keyof D]> = R
type Proved = Prover<DU1, REC1, "bar">

type RecKeyOf = keyof REC1
type Merged1 = DURecMergeWithKindKey<DU1, REC1>
type Merged2 = DURecMergeWithoutKey<DU1, REC1>
type Merged3 = DURecMergeWithKey<DU1, REC1>
type Merged4 = DURecMergeWithKey<DU1, REC1, "wert">

type NullRec = Nullable<REC1>

const rec1 = {
    Invoice: true,
    Hotel: 1,
    Entertainment: "lala",
    Amazon: true
}

const duarray = getDUArray(rec1)

type MailStatus = {
    InvoiceSent: Date;
    ReminderSent: Date;
    FinalReminderSent: Date;
}



type X = Accumulate<MailStatus, [
    "InvoiceSent",
    "ReminderSent",
    "FinalReminderSent"
]>