
import Vue from "vue"
import TriStateCheckBox from "@/components/Util/TriStateCheckBox.vue"

import * as Filter from "@/store/Masterdata/Client/Filter"
import * as ListModule from "@/store/Masterdata/Client/ListModule"
import store from "@/store/store"

export default Vue.extend({
    name: "InvoiceListFilterView",
    components: {
        TriStateCheckBox,
    },
    data() {
        return {
            isActive: false as boolean | null,
            selectedClients: [] as string[],
            selectedContacts: [] as string[],
        }
    },
    watch: {
        isActive: function (v) {
            this.emitUpdate()
        },
        selectedClients: function (_) {
            this.emitUpdate()
        },
        selectedContacts: function (_) {
            this.emitUpdate()
        },
    },
    computed: {
        clients: () => store.getters.client.list.avaliableClients,
        contacts: () => store.getters.client.list.avaliableContacts,
    },
    methods: {
        emitUpdate() {
            const filter: Filter.T = {
                isActive: this.isActive,
                clients: this.selectedClients,
                contacts: this.selectedContacts,
            }
            store.commit.client.list.updateFilter(filter)
        },
        clear() {
            this.isActive = null
            this.selectedClients = []
            this.selectedContacts = []
            store.commit.client.list.updateFilter(Filter.getEmpty())
        },
        reload() {
            store.dispatch.client.list.LOAD()
            ListModule.migrate(store.state.authentication?.token)
        },
    },
})
