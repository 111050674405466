import * as common from "@/components/Util/Common"
import * as Common from "@/lib/Util/Common"

import * as Template from "@/lib/Util/Types/Masterdata/Template/Template"

export const HEADERS = [

    {
        text: 'Country',
        value: 'country.kind',
    },
    {
        text: 'Template Type',
        value: 'template_type.kind',
    },
    {
        text: 'Batch Type',
        value: 'batch_output_type.kind',
    },
    {
        text: 'Date',
        value: 'upload_date',
    },
    {
        text: 'Download',
        value: 'download',
        cell_type: "link"
    },
    {
        text: 'Active',
        value: 'download',
        cell_type: "link"
    },
    {
        text: 'Actions',
        value: 'actions',
    },
]

export function toEditWrapper(x: Template.T, index: number, new_item = false): common.LinkType<Template.T> {
    const link = {
        href: x.uri ?? "no link",
        download: x.country.kind
            + "." + x.template_type.kind
            + "." + x.batch_output_type.kind
            + "." + Common.toServerDate(x.upload_date) + ".docx",
        text: "download template"
    }
    return {
        value: x,
        index,
        new_item,
        link
    }
}
