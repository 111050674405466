import MasterdataInternalUserMainView from "@/components/Sections/Masterdata/InternalUser/Main.vue"
import MasterdataInternalUserListMainView from "@/components/Sections/Masterdata/InternalUser/List/Main.vue"
import MasterdataInternalUserSingleMainView from "@/components/Sections/Masterdata/InternalUser/Single/Main.vue"

const router = {
    path: 'internal_user',
    component: MasterdataInternalUserMainView,
    children: [
        {
            path: 'list',
            component: MasterdataInternalUserListMainView
        },
        {
            path: 'single/:id?',
            component: MasterdataInternalUserSingleMainView
        },

    ]
}

export default router