
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import * as DeclineReason from "@/lib/Util/Types/Workflow/DeclineReason"

@Component({
    components: {},
})
export default class DeclineDialog extends Vue {
    @Prop({ default: false }) show!: boolean

    selected_item: DeclineReason.Kind = "Other"
    available_items = DeclineReason.KEYS
    comments = ""

    cancel() {
        this.$emit("update:show", false)
    }

    async save() {
        let result: DeclineReason.T | null = null

        switch (this.selected_item) {
            case "DidNotInitiate":
                result = {
                    kind: "DidNotInitiate",
                    DidNotInitiate: true,
                }
                break
            case "AmountMismatch":
                result = {
                    kind: "AmountMismatch",
                    AmountMismatch: true,
                }
                break
            case "TooManyLineItems":
                result = {
                    kind: "TooManyLineItems",
                    TooManyLineItems: this.comments,
                }
                break
            case "TooFewLineItems":
                result = {
                    kind: "TooFewLineItems",
                    TooFewLineItems: this.comments,
                }
                break
            case "Other":
                result = {
                    kind: "Other",
                    Other: this.comments,
                }
                break
        }

        this.$emit("decline", result)
        this.$emit("update:show", false)
    }
}
