import * as Timespan from "@/lib/Util/Types/Common/Timespan";
import * as Common from "@/lib/Util/Common";

type T = {
    from?: string;
    to?: string;
}

export function mkIntervalRequest(x: Timespan.Kind): T {
    const interval = Timespan.getInterval(x);
    const from = () => {
        if (interval) {
            return Common.toServerDate(interval.start as Date)
        }
        return undefined
    }
    const to = () => {
        if (interval) {
            return Common.toServerDate(interval.end as Date)
        }
        return undefined
    }
    return {
        from: from(),
        to: to()
    }
}

export function mkIntervalTupleString(x?: Timespan.Kind): string | undefined {
    if (x) {

        const interval = mkIntervalRequest(x)
        if (interval.from && interval.to) {
            return `${interval.from}*${interval.to}`
        }
        else if (interval.from && !interval.to) {
            return `${interval.from}*`
        }
        else if (interval.to && !interval.from) {
            return `*${interval.to}`
        }
        return undefined
    }
    return undefined
}

export type CreatedDate = {
    created_at_from?: string;
    created_at_to?: string;
}

export function mkCreatedDate(x: Timespan.Kind): CreatedDate {
    const req = mkIntervalRequest(x)
    return {
        created_at_from: req.from,
        created_at_to: req.to,
    }
}

export type ApprovalDate = {
    approval_date_from?: string;
    approval_date_to?: string;
}

export function mkApprovalDate(x: Timespan.Kind): ApprovalDate {
    const req = mkIntervalRequest(x)
    return {
        approval_date_from: req.from,
        approval_date_to: req.to,
    }
}

export type InvoiceDate = {
    invoice_date_from?: string;
    invoice_date_to?: string;
}

export function mkInvoiceDate(x: Timespan.Kind): InvoiceDate {
    const req = mkIntervalRequest(x)
    return {
        invoice_date_from: req.from,
        invoice_date_to: req.to,
    }
}

export type AdviceDate = {
    advice_date_from?: string;
    advice_date_to?: string;
}

export function mkAdviceDate(x: Timespan.Kind): AdviceDate {
    const interval = mkIntervalRequest(x)
    return {
        advice_date_from: interval.from,
        advice_date_to: interval.to
    }
}
