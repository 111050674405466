export const PAYMENT_ITEM_HEADERS = [
    {
        text: "Timestamp",
        value: "timestamp",
    },
    {
        text: "Amount",
        value: "amount",
    },
    {
        text: "Reference",
        value: "remittance_reference",
    },
    {
        text: "Description",
        value: "description",
    },
];

export const COMMUNICATION_ITEM_HEADERS = [
    {
        text: "Type",
        value: "kind",
    },
    {
        text: "Timestamp",
        value: "timestamp",
    },
    {
        text: "Receivers",
        value: "receivers",
    },
];

export const WORKFLOW_ITEM_HEADERS = [
    {
        text: "ShortID",
        value: "short_id",
    },
    {
        text: "Created At",
        value: "created_at",
    },
    {
        text: "InvoiceNo",
        value: "invoice_no",
    },
    {
        text: "OrderNo",
        value: "order_no",
    },
    {
        text: "Total",
        value: "total",
        align: "right"
    },
    {
        text: "Employee Email",
        value: "employee_email",
    },
    {
        text: "Supplier Name",
        value: "supplier_name",
    },
    {
        text: "Actions",
        value: "actions"
    }
]
