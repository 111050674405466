import * as Types from "@/lib/Util/Types/Types"
import * as Country from "@/lib/Util/Types/Common/Country"
import * as Email from "@/lib/Util/Types/Common/Email"

import * as Protocols from "@/lib/Util/Types/Protocols"

import * as Validation from "@/lib/Util/Validation"
import * as Common from "@/lib/Util/Common"

import * as AreasAccessLevels from "./AreasAccessLevels"

export type T = {
    id?: string;
    created_at: Date;
    active: boolean;
    _etag: string;

    email: Email.Email;
    telephone: string;
    country: Country.T;

    areas: AreasAccessLevels.T;
    //todo: implement correct behavior
    //bp_access: AccessKind.T;
    bp_access: "All";
}

export function tryParse(x: any): T | null {
    const country = Country.tryParse(x.country)
    const email = Email.Email.tryParse(x.email.Email)
    const created_at = Common.parseServerDate(x.created_at)
    if (country && email && created_at) {
        return {
            ...x,
            country,
            email,
            created_at
        }
    }
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "InternalUser")

export function getEmpty(): T {
    return {
        email: Email.getEmpty(),
        telephone: "0",
        created_at: new Date(),
        areas: AreasAccessLevels.getEmpty(),
        active: true,
        country: Country.getEmpty(),
        bp_access: "All",
        _etag: "",
    }
}

export class ValidationProtocol {
    public get rules() {
        return {
            telephone: [
                (v: string) => Validation.isNumeric(v, "de"),
            ],
        }
    }
}

export function addValidationProtocol(x: T | null): T & ValidationProtocol | null {
    if (x) {
        const result = Object.assign(new ValidationProtocol(), x)
        return result
    }
    return null
}

export const LANG = {
    active: { kind: "boolean" },
    email: { kind: "string" },
    telephone: { kind: "string" },
    country: { kind: "select_multi", value: Country.KEYS },
} as const

export const filter: Protocols.Filter<typeof LANG, T> = (x, y) => {
    let result = y
    if (x.active !== undefined) {
        result = result.filter(v => v.active === x.active?.value)
    }
    if (x.email && x.email.kind === "string") {
        const expr = x.email
        result = result.filter(v => v.email.Value.includes(expr.value))

    }
    if (x.telephone && x.telephone.kind === "string") {
        const expr = x.telephone
        result = result.filter(v => v.telephone.includes(expr.value))
    }
    if (x.country && x.country.kind === "select_multi") {
        const expr = x.country
        result = result.filter(v => expr.value.includes(v.country.kind))
    }
    return result
}