
export const HEADERS = [
    {
        text: 'Key',
        value: 'key',
    },
    {
        text: 'Access Level 1',
        value: 'access_level_1',
        align: "center"
    },
    {
        text: 'Access Level 2',
        value: 'access_level_2',
        align: "center"
    },
    {
        text: 'Access Level 3',
        value: 'access_level_3',
        align: "center"
    },
    {
        text: 'Access Level 4',
        value: 'access_level_4',
        align: "center"
    },
    {
        text: 'Access Level 5',
        value: 'access_level_5',
        align: "center"
    },
    {
        text: 'Actions',
        value: 'actions',
    },
]