import * as datefns from 'date-fns'
import * as lodash from 'lodash'
import * as Filters from '@/Filters'

import * as Invoice from '@/lib/Util/Types/Invoice/Invoice'
import * as InvoiceMailReport from '@/lib/Util/Types/Invoice/InvoiceMailReport'
import * as Doc from '@/lib/Util/Types/Workflow/Summary/Doc'

import * as Shared from '@/components/Sections/Invoice/Shared'

export const HEADERS = [
    {
        text: 'Status',
        value: 'status',
    },
    {
        text: 'Id',
        value: 'terse_id',
    },
    {
        text: 'Date',
        align: 'start',
        value: 'creation_date',
    },
    {
        text: 'Due Date',
        value: 'due_date',
    },
    {
        text: 'Total',
        value: 'total',
        align: 'end',
        sortable: false,
    },
    {
        text: 'Fees',
        value: 'fees',
        align: 'end',
        sortable: false,
    },
    {
        text: 'Outgoing',
        value: 'outgoing',
        align: 'end',
        sortable: false,
    },
    {
        text: 'Diff',
        value: 'diff',
        align: 'end',
        sortable: false,
    },
    {
        text: 'Currency',
        value: 'currency',
        sortable: false,
    },
    {
        text: 'Paid Date',
        value: 'paid_date',
    },
    {
        text: 'Client',
        value: 'client',
    },
    {
        text: 'Process',
        value: 'process'
    },
    {
        text: 'Country',
        value: 'country',
        sortable: false,
    },
    {
        text: 'Count',
        value: 'count',
        align: 'end',
        sortable: false,
    },
    {
        text: 'pro Forma',
        value: 'pro_forma',
    },
    {
        text: 'Actions',
        value: 'actions',
    },
]

export type StatusIconWithToolTip = Shared.StatusIcon & {
    tooltip?: InvoiceMailReport.T;
}

function getLastMailReport(xs: InvoiceMailReport.T[]): InvoiceMailReport.T | undefined {
    if (xs.length >= 1) {
        return xs[0]
    }
    return undefined;
}

function getStatusIcon(x: Invoice.T): StatusIconWithToolTip {
    const icon = Shared.getStatusIcon(x)
    const tooltip = lodash.isEqual(icon, Shared.UNKNOWN) ? undefined : getLastMailReport(x.data.email_reports)
    return {
        ...icon,
        tooltip: tooltip
    }
}

export type ListItem = {
    status: StatusIconWithToolTip;
    id: string;
    creation_date: string;
    due_date: string;
    paid_date: string;
    terse_id: string;
    pro_forma: boolean;
    client: string;
    process: string;
    country: string;
    count: number;
    diff: number | null;
    incoming: string;
    outgoing: string;
    manual_fees: string;
    auto_fees: string;
    fees: string;
    total: string;
    currency: string;
    raw: Invoice.T;
}

export function fromInvoice(x: Invoice.T): ListItem {
    return {
        status: getStatusIcon(x),
        id: x.id,
        creation_date: Filters.format(x.created_at),
        due_date: Filters.format(x.data.due_date),
        paid_date: Filters.format(x.data.last_paid_date),
        terse_id: x.terse_id,
        pro_forma: x.data.is_pro_forma_invoice,
        client: x.data.name.substr(0, 10) + "...",
        process: x.data.process_des,
        country: x.data.country.kind,
        count: x.line_items.length,
        diff: x.difference,
        incoming: Filters.format(x.totals.incoming.value),
        outgoing: Filters.format(x.totals.outgoing.value),
        manual_fees: Filters.format(x.totals.manualfees.value),
        auto_fees: Filters.format(x.totals.autofees.value),
        fees: Filters.format(x.totals.autofees.value + x.totals.manualfees.value),
        total: Filters.format(x.totals.sum.value),
        currency: x.total.currency.kind,
        raw: x
    }
}

export function fromInvoiceList(xs: Invoice.T[]): ListItem[] {
    return xs.map(fromInvoice)
}

export function toCSVRecord(x: Invoice.T) {
    const order_num = x.line_items.length === 1 ? Doc.asDocSummary(x.line_items[0]).order_no : undefined
    const mail_reports = InvoiceMailReport.asPartial(x.data.email_reports)
    function asDate(x: Date | null | undefined) {
        if (x) {
            return datefns.format(x, "dd.MM.yyyy")
        }
    }

    return {
        id: x.terse_id,
        order_num: order_num,
        date: asDate(x.created_at),
        bp: x.data.process_des,
        total: x.total.value,
        due_date: asDate(x.data.due_date),
        is_pro_forma_invoice: x.data.is_pro_forma_invoice,
        client: x.data.name,
        count: x.line_items.length,
        incoming: x.totals.incoming.value,
        outgoing: x.totals.outgoing.value,
        auto_fees: x.totals.autofees.value,
        manual_fees: x.totals.manualfees.value,
        currency: x.total.currency.kind,
        bp_country: x.data.bp_country.kind,
        invoice_state: x.data.invoice_state.kind,
        paid_at_1: asDate(x.data.last_paid_date), //todo: WRONG
        amount_paid_1: x.data.paid_amount, //todo: WRONG
        paid_at_2: null, //todo: WRONG
        amount_paid_2: null, //todo: WRONG
        paid_at_3: null, //todo: WRONG
        amount_paid_3: null, //todo: WRONG
        // mail_reports
        invoice: asDate(mail_reports.Invoice?.timestamp),
        first_reminder: asDate(mail_reports.Reminder1?.timestamp),
        second_reminder: asDate(mail_reports.Reminder2?.timestamp),
    }
}
