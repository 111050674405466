import * as Types from "@/lib/Util/Types/Types"
import * as Common from "@/lib/Util/Common"
import * as Source from "./Source"

import * as AccountingData from "@/lib/Util/Types/Masterdata/BusinessProcess/AccountingData"

export type T = {
    amount: number;
    source: Source.T;
    reference: string;
    process_id: string;
    ref_amount: number;
    difference: number;
    accounting_data?: AccountingData.T;
    due_date: Date;
    comment?: string;
}

export function tryParse(x: any): T | null {
    if (x) {
        const source = Source.tryParse(x.source) ?? Source.getEmpty()
        const accounting_data = AccountingData.tryParse(x.accounting_data)
        const due_date = Common.parseServerDate(x.due_date)
        const comment = x.comment ?? ""
        return {
            ...x,
            source,
            accounting_data,
            due_date,
            comment
        }
    }
    console.error("Could not parse Remittance.LineItem", x)
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "Remittance.LineItem")

export function getEmpty(): T {
    return {
        amount: 0,
        source: Source.getEmpty(),
        reference: "",
        process_id: "",
        ref_amount: 0,
        difference: 0,
        due_date: new Date(),
        comment: ""
    }
}