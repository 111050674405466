import * as lodash from 'lodash/fp';
import { defineModule, localActionContext } from "direct-vuex"

import * as SupplierPayments from "@/lib/Util/Types/SupplierPayments/SupplierPayments";
import * as REST from '@/lib/Util/REST'
import { RootState } from '@/store/Shared'
import { AnyObject } from '@/lib/Util/Common';

export type CreateRequest = {
    account?: string;
    wf_id: string[];
}

type Query = {
    bp_id?: string;
    year?: number;
    number?: number;
}

const state = {
    value: null as SupplierPayments.T | null,
    message: REST.getEmpty()
}

const mutations = {
    updateValue(state: State, v: SupplierPayments.T | null) {
        state.value = v;
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    },
}

async function loading(context: any, x: { url: string, query: Query }) {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const { commit, state } = actionCtx(context)
    const rootState = context.rootState as RootState
    try {
        const loading_msg = {
            ...state.message,
            loading: true
        }
        commit.updateMsg(loading_msg)
        const req = {
            token: rootState.authentication?.token ?? "NO TOKEN",
            url: x.url,
            payload: x.query,
            transformer: SupplierPayments.tryParse
        };
        const result = await REST.GET(state.message, req);
        const msg = {
            ...lodash.cloneDeep(result.msg),
            text: `Successfully loaded SupplierPayment ${result.value?.id}`
        }
        commit.updateValue(result.value)
        commit.updateMsg(msg)
    }
    catch (ex) {
        const msg = {
            ...lodash.cloneDeep(state.message),
            text: JSON.stringify(ex)
        }
        commit.updateMsg(msg)
    }
}

export async function CREATE(create_req: CreateRequest, token?: string) {
    const req = {
        token: token ?? "NO TOKEN",
        url: "/supplier_payments/create",
        payload: create_req as unknown as AnyObject,
        transformer: SupplierPayments.tryParse
    };
    const result = await REST.POST(state.message, req);
    return result
}

const actions = {
    async LOAD(context: any, x: string) {
        await loading(context, { url: `/supplier_payments/${x}`, query: {} })
    },
    async LOAD_BY_QUERY(context: any, x: Query) {
        await loading(context, { url: "/supplier_payments/get_by_query", query: x })
    }
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    actions,
}

export type Mutations = typeof mutations
export type State = typeof state
export type Actions = typeof actions

export const module = defineModule(internal)

export default module

const actionCtx = (context: any) => localActionContext(context, module)
