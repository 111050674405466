
import Vue from "vue"
import * as lodash from "lodash/fp"
import numeral from "numeral"

import * as common from "@/components/Util/Common"
import * as Common from "@/lib/Util/Common"

import * as Email from "@/lib/Util/Types/Common/Email"
import * as Gender from "@/lib/Util/Types/Common/Gender"
import * as Locale from "@/lib/Util/Types/Common/Locale"

import * as ActivationStatus from "@/lib/Util/Types/Biz/ActivationStatus"

import * as Buyer from "@/lib/Util/Types/Masterdata/BusinessProcess/Buyer"
import * as ReportPeriod from "@/lib/Util/Types/Masterdata/BusinessProcess/ReportPeriod"
import * as ExternalUser from "@/lib/Util/Types/Masterdata/BusinessProcess/ExternalUser"

import * as Shared from "./Shared"
import store from "@/store/store"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"

export default Vue.extend({
    name: "EditDialog",
    components: {
        SuccessMessage,
        ErrorMessage,
    },
    props: {
        item: {
            type: Object as () => Shared.EditWrapper,
        } as Vue.PropOptions<Shared.EditWrapper>,
        emails: {
            type: Array,
        } as Vue.PropOptions<string[]>,
        cpc_ids: {
            type: Array,
        } as Vue.PropOptions<string[]>,
        bp_sid: {
            type: String,
        },
        bp_id: {
            type: String,
        },
        dialogSave: {
            type: Function,
        },
        dialogCancel: {
            type: Function,
        },
    },
    data() {
        //console.log("EDIT DIALOG", this.item);
        const locale = Locale.getLocale(store.state.client.sub_item.value?.country.kind ?? "DE")
        return {
            valid: true,
            locale: locale,
            available_genders: Gender.KEYS,
            available_buyers: Buyer.KEYS,
            available_report_periods: ReportPeriod.KEYS,
            available_stati: ActivationStatus.KEYS,
            cpc_id: this.item?.value.cpc_id ?? "",
            first_approver_emails: this.emails,
            currency_format: {
                locale: locale.language,
                currency: locale.currency,
                distractionFree: true,
                allowNegative: false,
            },
            rules: {
                email: [
                    function checkLen(v?: string): boolean | string {
                        return (v?.length ?? 0) !== 0 || "Email must not be empty"
                    },
                    function isEmail(v?: string): boolean | string {
                        return Email.validate(v) || "Please enter valid email"
                    },
                ],
                emailOptional: [
                    function isEmail(v?: string): boolean | string {
                        return v === undefined || v?.length === 0 || Email.validate(v) || "Please enter valid email"
                    },
                ],
                name: [
                    (v: string) => {
                        return v.length > 0 || "Name must not be empty"
                    },
                    (v: string) => {
                        return v.split(" ").length === 2 || "Name must contain exactly one firstname and one lastname"
                    },
                    (v: string) => {
                        const names = v.split(" ")
                        const firstname = names[0]
                        return firstname?.length >= 2 || "firstname must contain at least 2 characters"
                    },
                    (v: string) => {
                        const names = v.split(" ")
                        const lastname = names[1]
                        return lastname?.length >= 2 || "lastname must contain at least 2 characters"
                    },
                ],
                first_reminder: [
                    function check(v?: string): boolean | string {
                        const result = parseInt(v ?? "")
                        if (!isNaN(result)) {
                            return result > 0 && result <= 10 ? true : "value mus be > 0 && <= 10"
                        }
                        return "value must be integer between 1 - 10"
                    },
                ],
                second_reminder: [
                    function check(v?: string): boolean | string {
                        const result = parseInt(v ?? "")
                        if (!isNaN(result)) {
                            return result > 0 && result <= 10 ? true : "value mus be > 0 && <= 10"
                        }
                        return "value must be integer between 1 - 10"
                    },
                ],
            },
        }
    },
    computed: {
        loaded() {
            console.log("LOADED", this.item)
            return lodash.cloneDeep(this.item)
        },
        msg() {
            return store.state.external_user.single.message
        },
        used_email: {
            get: function (): string {
                return this.loaded?.value.email.Value ?? "unknown@unknown.com"
            },
            set: function (v: string) {
                if (this.loaded) {
                    this.loaded.value.email = Email.Email.tryParse(v) ?? Email.getEmpty()
                    this.first_approver_emails = [this.loaded.value.email.Value, ...this.emails]
                }
            },
        },
        used_name: {
            get: function (): string {
                return this.loaded?.value.name
            },
            set: function (v: string) {
                if (this.loaded) {
                    this.loaded.value.name = v
                    this.updateCpcId(v)
                }
            },
        },
        used_gender: {
            get: function (): Gender.Kind {
                return this.loaded?.value.gender?.kind ?? "M"
            },
            set: function (v: Gender.Kind) {
                if (this.loaded) {
                    this.loaded.value.gender = Gender.getByKind(v)
                }
            },
        },
        used_status: {
            get: function (): ActivationStatus.Kind {
                return this.loaded?.value.status?.kind ?? ActivationStatus.getEmpty().kind
            },
            set: function (v: ActivationStatus.Kind) {
                if (this.loaded) {
                    this.loaded.value.status = ActivationStatus.make(v)
                }
            },
        },
        used_buyer: {
            get: function (): boolean {
                return this.loaded?.value.buyer?.kind === "Yes"
            },
            set: function (v: boolean) {
                if (this.loaded) {
                    this.loaded.value.buyer = Buyer.getByKind(v ? "Yes" : "No")
                }
            },
        },
        used_line_manager_reminder: {
            get: function (): boolean {
                return this.loaded?.value.line_manager_reminder?.kind === "Yes"
            },
            set: function (v: boolean) {
                if (this.loaded) {
                    this.loaded.value.line_manager_reminder = Buyer.getByKind(v ? "Yes" : "No")
                }
            },
        },
        used_reports_to: {
            get: function (): string {
                return this.loaded?.value.reports_to?.Value ?? ""
            },
            set: function (v: string) {
                if (this.loaded) {
                    this.loaded.value.reports_to = Email.Email.tryParse(v) ?? undefined
                }
            },
        },
        used_is_approved_by: {
            get: function (): string {
                return this.loaded?.value.is_approved_by?.Value ?? ""
            },
            set: function (v: string) {
                if (this.loaded) {
                    this.loaded.value.is_approved_by = Email.Email.tryParse(v) ?? undefined
                }
            },
        },
        used_limit_is_approved_by: {
            get: function (): string {
                return this.loaded?.value.limit_is_approved_by?.Value ?? ""
            },
            set: function (v: string) {
                if (this.loaded) {
                    this.loaded.value.limit_is_approved_by = Email.Email.tryParse(v) ?? undefined
                }
            },
        },
        used_approval_limit: {
            get: function (): string {
                if (this.loaded.value.approval_limit) {
                    return common.format(this.loaded.value.approval_limit, this.locale.country)
                }
                return ""
            },
            set: function (v: string) {
                this.loaded.value.approval_limit = common.parse(v, this.locale.country) ?? undefined
            },
        },
        used_line_manager_report: {
            get: function (): ReportPeriod.Kind {
                return this.loaded?.value.line_manager_report?.kind ?? "No"
            },
            set: function (v: ReportPeriod.Kind) {
                if (this.loaded) {
                    this.loaded.value.line_manager_report = ReportPeriod.getByKind(v)
                }
            },
        },
        used_buyer_report: {
            get: function (): ReportPeriod.Kind {
                return this.loaded?.value.buyer_report?.kind ?? "No"
            },
            set: function (v: ReportPeriod.Kind) {
                if (this.loaded) {
                    this.loaded.value.buyer_report = ReportPeriod.getByKind(v)
                }
            },
        },
        used_involve_line_manager: {
            get: function (): string {
                console.log("used_involve_line_manager", this.loaded?.value.reminders)
                return this.loaded?.value.reminders.involve_line_manager?.Value ?? ""
            },
            set: function (v: string) {
                if (this.loaded) {
                    this.loaded.value.reminders.involve_line_manager = Email.Email.tryParse(v) ?? undefined
                }
            },
        },
        used_first_reminder: {
            get: function (): string {
                if (this.loaded.value.reminders.first_reminder) {
                    return common.format(this.loaded.value.reminders.first_reminder, this.locale.country)
                }
                return ""
            },
            set: function (v: string) {
                this.loaded.value.reminders.first_reminder = common.parse(v, this.locale.country) ?? undefined
            },
        },
        used_second_reminder: {
            get: function (): string {
                if (this.loaded.value.reminders.second_reminder) {
                    return common.format(this.loaded.value.reminders.second_reminder, this.locale.country)
                }
                return ""
            },
            set: function (v: string) {
                this.loaded.value.reminders.second_reminder = common.parse(v, this.locale.country) ?? undefined
            },
        },
        used_limit_trigger_notification: {
            get: function (): string {
                return this.loaded?.value.limit_trigger_notification?.Value ?? ""
            },
            set: function (v: string) {
                if (this.loaded) {
                    this.loaded.value.limit_trigger_notification = Email.Email.tryParse(v) ?? undefined
                }
            },
        },
        used_monthly_limit_trigger: {
            get: function (): string {
                if (this.loaded.value.monthly_limit_trigger) {
                    return common.format(this.loaded.value.monthly_limit_trigger, this.locale.country)
                }
                return ""
            },
            set: function (v: string) {
                this.loaded.value.monthly_limit_trigger = common.parse(v, this.locale.country) ?? undefined
            },
        },
        used_trx_limit_trigger: {
            get: function (): string {
                if (this.loaded.value.trx_limit_trigger) {
                    return common.format(this.loaded.value.trx_limit_trigger, this.locale.country)
                }
                return ""
            },
            set: function (v: string) {
                this.loaded.value.trx_limit_trigger = common.parse(v, this.locale.country) ?? undefined
            },
        },
        all_emails: function () {
            const r = lodash.cloneDeep(this.emails)
            r.push(this.loaded.value.email.Value)
            return r
        },
    },
    watch: {
        valid: {
            handler(nv: boolean) {
                console.log("WATCH VALID:", nv)
            },
        },
        trx_limit_trigger: {
            handler(nv: string) {
                if (nv === "") {
                    this.item.value.trx_limit_trigger = numeral(nv).value() ?? undefined
                }
            },
        },
        item: {
            handler(nv: Shared.EditWrapper | null) {
                this.cpc_id = nv?.value.cpc_id ?? ""
            },
        },
    },
    methods: {
        async save() {
            const bp_id = this.loaded.new_item ? this.bp_id : undefined
            await store.dispatch.external_user.single.SAVE({
                user: this.loaded.value,
                bp_id,
            })
            if (store.state.external_user.single.message.success) {
                const updated = Shared.toEditWrapper(
                    store.state.external_user.single.value as ExternalUser.T,
                    this.loaded.index,
                    this.loaded.new_item
                )
                this.dialogSave(updated)
            }
        },
        cancel() {
            console.log("EDITDIALOG.CANCEL", this.loaded, this.valid)
            this.dialogCancel()
        },
        updateCpcId(v: string) {
            if (this.loaded.new_item) {
                const name = v.split(" ")
                let fn = name[0]
                let ln = name[name.length - 1]
                if (fn === "") {
                    fn = "Vorname"
                }
                if (ln === "") {
                    ln = "Nachname"
                }
                const ids = this.cpc_ids.map((x) => x.substr(5))
                const new_id = Common.createCombinedID(fn, ln, ids)
                const sidparts = this.bp_sid.split("-")
                if (fn.length < 3) {
                    const sidpart = sidparts[1].substr(1, 2)
                    const len = 3 - fn.length
                    fn = fn + sidpart.substr(0, len)
                }
                if (ln.length < 3) {
                    const sidpart = sidparts[2].substr(0, 3)
                    const len = 3 - ln.length
                    ln = ln + sidpart.substr(0, len)
                }
                const result = sidparts[0] + sidparts[1].substr(0, 1) + "-" + new_id
                this.cpc_id = result.toUpperCase()
                this.loaded.value.cpc_id = this.cpc_id
            }
        },
    },
})
