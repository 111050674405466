
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import * as lodash from "lodash/fp"

import * as PaymentState from "@/lib/Util/Types/Workflow/PaymentState"
import * as PaymentMode from "@/lib/Util/Types/Workflow/PaymentMode"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"

import store from "@/store/store"

@Component({
    components: {
        ErrorMessage,
        SuccessMessage,
    },
})
export default class PaymentStateDialog extends Vue {
    @Prop({ default: false }) show!: boolean
    @Prop({ default: null }) item!: PaymentState.T

    available_modes = PaymentMode.KEYS
    error_overlay = false

    get loaded() {
        return lodash.cloneDeep(this.item)
    }

    get used_mode() {
        return this.loaded?.payment_mode.kind ?? PaymentMode.getEmpty().kind
    }

    set used_mode(v: PaymentMode.Kind) {
        if (this.loaded) {
            this.loaded.payment_mode = PaymentMode.getByKind(v)
        }
    }

    get msg() {
        return store.state.supplier.single.message
    }

    cancel() {
        console.log("CANCEL", this.item)
        this.$emit("updatePaymentState", this.item)
    }

    async save() {
        console.log("SAVE", this.loaded)
        this.$emit("updatePaymentState", lodash.cloneDeep(this.loaded))
    }
}
