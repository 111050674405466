export type T = {
    build_timestamp: string;
    version_core: string;
    version_web: string;
}

export function tryParse(x: T): any {
    return {
        build_timestamp: x.build_timestamp,
        version_core: x.version_core,
        version_web: x.version_web
    }
}