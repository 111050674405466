export type T = {
    access_level_1: boolean;
    access_level_2: boolean;
    access_level_3: boolean;
    access_level_4: boolean;
    access_level_5: boolean;
}

export const isAnyAllowed = (x: T): boolean => (
    x.access_level_1 ||
    x.access_level_2 ||
    x.access_level_3 ||
    x.access_level_4 ||
    x.access_level_5
)

export const getEmpty = (): T => ({
    access_level_1: false,
    access_level_2: false,
    access_level_3: false,
    access_level_4: false,
    access_level_5: false,
})