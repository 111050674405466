import * as Common from "@/lib/Util/Common"

import * as State from "./State"
import * as AssociatedFileType from "./AssociatedFiles/AssociatedFileType"
import * as WorkflowDoc from "./Doc/WorkflowDoc"
import * as DeclineReason from "./DeclineReason"

export type T = {
    id: string;
    short_id: string;
    secondary_id: string;
    etag: string
    payment_short_id?: string;

    wa_change_time: Date;
    order_number?: string;
    invoice_number?: string;

    doc: WorkflowDoc.T;
    associated_files: AssociatedFileType.T[];
    state: State.T;
    last_decline?: DeclineReason.T;
}

export function tryParse(x?: any): T | null {
    if (x) {
        try {
            const wa_change_time = Common.parseServerDate(x.wa_change_time)
            const doc = WorkflowDoc.tryParse(x.doc)
            const state = State.tryParse(x.state)
            const associated_files = AssociatedFileType.tryParseList(x.associated_files).filter(AssociatedFileType.isActive)
            console.log("WORKFLOW.TRYPARSE ASSOCFILES", associated_files)
            const last_decline = DeclineReason.tryParse(x.last_decline) ?? undefined

            if (wa_change_time && doc && state && state && associated_files) {
                return {
                    ...x,
                    state,
                    doc,
                    wa_change_time,
                    associated_files,
                    last_decline,
                }
            }
        }
        catch (err) {
            console.error("Workflow.tryParse", err)
        }
    }

    console.error("Workflow.tryParse", x)
    return null
}
