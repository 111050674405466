import * as Types from "@/lib/Util/Types/Types"

import * as Email from '@/lib/Util/Types/Common/Email'
import * as Gender from '@/lib/Util/Types/Common/Gender'
import * as Country from '@/lib/Util/Types/Common/Country'

import * as Common from '@/lib/Util/Common'

import * as BusinessProcess from '@/lib/Util/Types/Masterdata/BusinessProcess/BusinessProcess'

export type T = {
    id?: string;
    short_id?: string;
    version_number?: number;
    _etag: string;
    active: boolean;
    created_at: Date;

    name: string;
    legal_form: string;
    group_id?: string;
    vat_id?: string;
    supplier_id?: string;

    first_name: string;
    last_name: string;
    gender: Gender.T;
    email: Email.Email;

    street: string;
    number: string;
    zip: string;
    city: string;
    country: Country.T;

    title?: string;
    mobile?: string;
    telephone?: string;

    process_count: number;
    business_processes: BusinessProcess.T[];
}

export function getEmpty(): T {
    return {
        _etag: "",
        active: true,
        created_at: new Date(),

        name: "undefined",
        legal_form: "undefined",
        first_name: "undefined",
        last_name: "undefined",
        gender: Gender.getEmpty(),
        email: Email.getEmpty(),

        street: "undefined",
        number: "undefined",
        zip: "undefined",
        city: "undefined",
        country: Country.getEmpty(),

        process_count: 0,
        business_processes: []
    }
}

export function tryParse(x: any): T | null {
    if (x) {
        const created_at = Common.parseServerDate(x.created_at)
        const email = Email.Email.tryParse(x.email?.Email)
        const process_count = parseInt(x.number_bps)
        const gender = Gender.tryParse(x.gender);
        const country = Country.tryParse(x.country)

        const business_processes = BusinessProcess.tryParseList(x.business_processes)

        let group_id = x.group_id
        if (!group_id) {
            const name: string = x.name
            console.log("Client.tryParse", name.split(" ")[0].toUpperCase())
            group_id = name.split(" ")[0].toUpperCase()
        }

        if (created_at && email) {
            return {
                ...x,
                created_at,
                email,
                process_count,
                gender,
                country,
                business_processes,
                group_id
            }
        }
    }
    return null
}

export const tryParseList = Types.buildTryParseList(tryParse, "Client")