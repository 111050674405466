
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import * as Types from "@/lib/Util/Types/Types"

import * as Workflow from "@/lib/Util/Types/Workflow/Workflow"
import * as EditState from "@/lib/Util/Types/Workflow/EditState"

import TooltipIcon from "@/components/Util/TooltipIcon.vue"

type Keys = 0 | 1 | 2
const map: Record<string, Keys> = {
    Opened: 0,
    Closed: 1,
    Clearing: 2,
}

const lookup = Types.buildLookup(map)

@Component({
    components: { TooltipIcon },
})
export default class EditStateToolbar extends Vue {
    @Prop({ default: false }) active!: boolean
    @Prop({ default: null }) item!: Workflow.T
    @Prop({ default: null }) run!: (x: EditState.Kind) => void

    get selected() {
        const kind = this.item.state.edit_state?.kind ?? "Opened"
        return lookup.source[kind]
    }

    set selected(x: Keys) {
        const s = lookup.target[x]
        this.run(s as EditState.Kind)
    }
}
