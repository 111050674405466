
import Vue from "vue";

import store from "@/store/store";

export default Vue.extend({
    name: "MasterdataInternalUserMainView",
    computed: {
        item: () => store.state.internal_user.single.value,
    },
});
