import numeral from "numeral"
import * as Money from "@/lib/Util/Types/Common/Money"
import * as Account from "@/lib/Util/Types/Common/Account"

import * as ProcessingState from "@/lib/Util/Types/Workflow/Doc/ProcessingState"
import * as InvoiceReference from "../Doc/InvoiceReference"
import * as PaidInfo from "../Doc/PaidInfo"

export type T = {
    supplier_payment: ProcessingState.T<Account.T>;
    invoice: InvoiceReference.T;
    paid: PaidInfo.T;
    gross: Money.T;
}

export function tryParse(x?: any): T | null {
    if (x) {
        const supplier_payment = ProcessingState.tryParse(x.supplier_payment, Account.tryParse)
        const invoice = InvoiceReference.tryParse(x.invoice)
        const paid = PaidInfo.tryParse(x.paid)
        const gross = Money.tryParse(x.gross)
        if (supplier_payment && gross && invoice && paid) {
            return {
                supplier_payment,
                invoice,
                paid,
                gross
            }
        }
        console.error("100 Summary.Payment.tryParse", supplier_payment, invoice, paid, gross)
        return null
    }
    console.error("200 Summary.Payment..tryParse", x)
    return null
}

export function getSupplierPaymentId(x: ProcessingState.T<Account.T>) {
    if (x.kind === "Finished") {
        const [counter, _] = x.Finished
        const key = `${counter.key.kind}_${counter.key.account_id}`
        const n = numeral(counter.number).format("000000")
        return `${key}_${counter.year}_${n}`
    }
    return null
}