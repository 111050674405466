import Vue from 'vue';
import * as lodash from 'lodash/fp';
import { defineModule, localActionContext, localGetterContext } from "direct-vuex"
import * as Common from "@/lib/Util/Common";

import * as Client from "@/lib/Util/Types/Masterdata/Client/Client";
import * as Filter from './Filter'
import * as REST from '@/lib/Util/REST'
import * as Shared from '@/store/Shared'

export type Query = {
    email?: string;
    name?: string;
    active?: boolean;
    short_id?: string;
    ids?: string[];
}

const state = {
    value: [] as Client.T[],
    filter: Filter.getEmpty(),
    message: REST.getEmpty(),
    full_list: null as null | Shared.TimedResult<Client.T[]>
}

const mutations = {
    updateValue(state: State, model: Client.T[] | null) {
        const values = model ?? []
        state.value = values;
    },
    updateListWithItem(state: State, model: Client.T | null) {
        if (model) {
            const index = state.value.findIndex(x => x.id === model.id)
            if (index >= 0) {
                Vue.set(state.value, index, model)
            }
            else {
                state.value.push(model)
            }
        }
    },
    updateFilter(state: State, v: Filter.T) {
        state.filter = v
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    },
    toggleActivation(state: State, x: string) {
        const found = state.value.find(v => v.id === x)
        if (found) {
            found.active = !found.active
        }
    },
    updateFullList(state: State, x: Shared.TimedResult<Client.T[]> | null) {
        state.full_list = x
    }
}

const getters = {
    filtered: (state: State) => Filter.filter(state.filter, state.value),
    avaliableClients: (state: State) => { return Common.getUnique((x: Client.T) => x.name, state.value).sort() },
    avaliableContacts: (state: State) => {
        return Common.getUnique((x: Client.T) => x.last_name, state.value).sort();
    }
}

export function load(token?: string, payload: Query = {}) {
    const req = {
        token: token ?? "NO TOKEN",
        url: "/clients",
        payload,
        transformer: Client.tryParseList
    };
    return REST.GET(state.message, req);
}

export function migrate(token?: string) {
    const req = {
        token: token ?? "NO TOKEN",
        url: "/clients/migrate",
        payload: {},
        transformer: function (v: any) { console.log("TRANSFORM:", v); return null }
    };
    return REST.GET(state.message, req);
}

export async function loadList(context: any): Promise<Client.T[] | null> {
    //do we need to reload
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const { commit, state } = actionCtx(context)
    const rootState = context.rootState as Shared.RootState
    if (state.full_list && state.full_list.expires_at > Date.now()) {
        //is the list still fresh?
        return state.full_list.result
    }
    else {
        const result = await load(rootState.authentication?.token)
        if (result.value) {
            const v = {
                expires_at: Date.now() + 900000,
                result: result.value
            }
            commit.updateFullList(v)
            return result.value
        }
        else {
            console.error("Client LoadList failed", result)
            commit.updateFullList(null)
            return null
        }
    }
}

const actions = {
    async LOAD(context: any, payload: Query = {}) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = actionCtx(context)
        const rootState = context.rootState as Shared.RootState
        try {
            const loading_msg = {
                ...state.message,
                loading: true
            }
            commit.updateMsg(loading_msg)
            commit.updateValue([])
            const result = await load(rootState.authentication?.token, payload)
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text: "Successfully loaded client list",
                timeout: 5000
            }
            commit.updateValue(result.value)
            commit.updateMsg(msg)
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg(msg)
        }
    },
    async LOAD_LIST(ctx: any) {
        return loadList(ctx)
    }
}

export const internal = {
    namespaced: true as const,
    state,
    mutations,
    getters,
    actions
}

export type Mutations = typeof mutations
export type State = typeof state
export type Getters = typeof getters
export type Actions = typeof actions

export const module = defineModule(internal)
export type Module = typeof module
export default module

const actionCtx = (context: any) => localActionContext(context, module)
