import * as common from "@/lib/Util/Common"

import * as Country from "../Common/Country"

import * as AccountingData from "@/lib/Util/Types/Masterdata/BusinessProcess/AccountingData"
import * as InvoicingTerms from "../Biz/InvoicingTerms"
import * as InvoiceState from "./InvoiceState"
import * as InvoiceMailReport from './InvoiceMailReport'
import * as PaymentStatusFine from "./PaymentStatusFine"


export type T = {
    terse_id: string;

    process_des: string; // BP Description
    bp_country: Country.T;
    legal_form: string;

    name: string;
    street: string;
    number: string;
    zip: string;
    city: string;
    country: Country.T;

    due_date: Date;
    invoicing_terms: InvoicingTerms.T;
    invoice_state: InvoiceState.T;
    is_pro_forma_invoice: boolean;
    email_reports: InvoiceMailReport.T[];
    //services_sold:          any; needs to be defined

    vat_id?: string;
    group_id: string;
    accounting_data?: AccountingData.T;

    last_paid_date: Date | null;
    paid_amount: number;
    payment_status_fine: PaymentStatusFine.T;
}

export function tryParse(x: any): T | null {
    const bp_country = Country.tryParse(x.bp_country)
    const country = Country.tryParse(x.country)
    const invoicing_terms = InvoicingTerms.tryParse(x.invoicing_terms)
    const invoice_state = InvoiceState.tryParse(x.invoice_state)
    const due_date = common.parseServerDate(x.due_date)
    const email_reports = InvoiceMailReport.tryParseList(x.email_reports as any[])
    const last_paid_date = common.parseServerDate(x.last_paid_date)
    const paid_amount = x.paid_amount
    const accounting_data = AccountingData.tryParse(x.accounting_data)
    const payment_status_fine = PaymentStatusFine.tryParse(x.payment_status_fine) ?? PaymentStatusFine.VALUES.Unknown


    if (bp_country && country && invoicing_terms) {
        const result = {
            ...x,
            ...{
                bp_country,
                country,
                invoicing_terms,
                invoice_state,
                due_date,
                email_reports,
                last_paid_date,
                paid_amount,
                accounting_data,
                payment_status_fine
            }
        }
        console.log("200 InvoiceHeaderData.TryParse", result)
        return result
    }
    return null
}
