import Vue from "vue";
import Vuex from 'vuex';
import * as Types from "@/lib/Util/Types/Types"
import * as DirectVuex from "direct-vuex"
import * as lodash from "lodash/fp"

import * as REST from '@/lib/Util/REST'
import * as AuthenticationResult from '@/lib/Util/Types/Login/AuthenticationResult'
import * as VatRules from '@/lib/Util/Types/VAT/VatRules'
import * as VatRule from '@/lib/Util/Types/VAT/VatRule'
import * as Country from "@/lib/Util/Types/Common/Country"
import * as Locale from "@/lib/Util/Types/Common/Locale"

import * as Client from '@/store/Masterdata/Client/MainModule'
import * as Template from '@/store/Masterdata/Template/MainModule'
import * as ExternalUser from '@/store/Masterdata/ExternalUser/MainModule'

import * as Invoice from '@/store/Invoice/MainModule'
import * as InternalUser from '@/store/InternalUser/MainModule'
import * as Supplier from '@/store/Supplier/MainModule'
import * as SupplierPayments from '@/store/SupplierPayments/MainModule'
import * as ServicesReceived from '@/store/Reports/ServicesReceived/MainModule'
import * as ServicesSold from '@/store/Reports/ServicesSold/MainModule'
import * as Remittance from '@/store/Remittance/MainModule'

import * as Workflow from '@/store/Workflow/MainModule'
import * as Lookup from '@/store/Lookup/MainModule'

Vue.use(Vuex);

function initAuthentication(): AuthenticationResult.T | null {
    const item = localStorage.getItem("authentication-result");
    if (item) {
        return AuthenticationResult.tryParse(JSON.parse(item))
    }
    return null;
}

const state = {
    authentication: initAuthentication(),
    message: REST.getEmpty(),
    //todo: review periodically
    //needed for proper toolbar layout
    //not sure if this is the best way thou
    toolbar: false
}

const mutations = {
    updateAuthentication(state: State, v: AuthenticationResult.T | null) {
        state.authentication = v
        if (v) {
            localStorage.setItem("authentication-result", JSON.stringify(v))
        }
        else {
            localStorage.removeItem("authentication-result")
        }
    },
    updateVatRules(state: State, rules: VatRules.T) {
        console.log("Store.updateVatRules", state, rules)
        if (state.authentication) {
            state.authentication.vatRules = rules
            localStorage.setItem("authentication-result", JSON.stringify(state.authentication))
        }
    },
    updateMsg(state: State, v: REST.RestMessage) {
        state.message = v
    },
    updateToolbar(state: State, x: boolean) {
        state.toolbar = x
    }
}

const getters = {
    token: (state: State) => state.authentication?.token ?? "NO TOKEN",
    locale: (state: State) => {
        if (state.authentication) {
            return Locale.getLocale(state.authentication.user.country.kind)
        }
        return Locale.getLocale("DE")
    }
}

const actions = {
    async loadVatRuleByCountry(context: any, country: Country.Kind) {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, state } = context
        try {
            const loading_msg = {
                ...state.message,
                loading: true
            }
            commit("updateMsg", loading_msg)
            const req = {
                token: state.authentication?.token ?? "NO TOKEN",
                url: `/vattypes?country=${country}`,
                payload: {},
                transformer: VatRule.tryParse
            };
            const result = await REST.GET(state.message, req);
            const msg = {
                ...lodash.cloneDeep(result.msg),
                text: `Successfully loaded VATRULE for ${country}`
            }
            commit("updateMsg", msg)
            return result.value
        }
        catch (ex) {
            const msg = {
                ...lodash.cloneDeep(state.message),
                text: JSON.stringify(ex)
            }
            commit.updateMsg(state, msg)
            return null
        }
    },
    async loadVatRules(context: any) {
        console.log("100 loadVatRules", context)
        /* eslint-disable @typescript-eslint/no-use-before-define */
        const { commit, dispatch } = context
        console.log("200 loadVatRules", dispatch)
        const DE = await dispatch("loadVatRuleByCountry", "DE")
        const AT = await dispatch("loadVatRuleByCountry", "AT")
        const CH = await dispatch("loadVatRuleByCountry", "CH")
        const result = {
            DE,
            AT,
            CH,
        } as VatRules.T
        if (Types.checkNonNull(result)) {
            commit("updateVatRules", result)
        }
        else {
            console.error("store.loadVatRules", result)
        }
    },
}

const { store, rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext } =
    DirectVuex.createDirectStore({
        state,
        mutations,
        getters,
        actions,
        modules: {
            client: Client.module,
            template: Template.module,
            invoice: Invoice.module,
            internal_user: InternalUser.module,
            supplier: Supplier.module,
            supplier_payments: SupplierPayments.module,
            services_received: ServicesReceived.module,
            services_sold: ServicesSold.module,
            external_user: ExternalUser.module,
            workflow: Workflow.module,
            lookup: Lookup.module,
            remittance: Remittance.module
        }
    })

export type State = typeof state
export type Mutations = typeof mutations
export type Actions = typeof actions
export type Store = typeof store

// Export the direct-store instead of the classic Vuex store.
export default store

// The following exports will be used to enable types in the
// implementation of actions and getters.
export {
    rootActionContext,
    moduleActionContext,
    rootGetterContext,
    moduleGetterContext
}

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store
declare module "vuex" {
    interface Store<S> {
        direct: AppStore;
    }
}