
import Vue from "vue"
import { Prop } from "vue-property-decorator"
import Component from "vue-class-component"

import * as Common from "@/lib/Util/Common"
import * as datefns from "date-fns"

@Component({
    components: {},
})
export default class DateInput extends Vue {
    @Prop({ default: null }) item!: Date | null
    @Prop({ default: "no label" }) label!: string
    @Prop({ default: null }) min!: Date | null
    @Prop({ default: null }) max!: Date | null
    @Prop({ default: false }) disabled!: boolean

    rules = {
        isDate: (x: string) => {
            const result = Common.parseISODate(x)
            return result !== null || "Please enter a correct date!"
        },
        isFormat: (x: string) => {
            const result = x.match(/\d{4}-\d{2}-\d{2}/) !== null
            return result || "Enter the date in the format 'YYYY-MM-DD'"
        },
        isMax: (x: string) => {
            if (this.max) {
                const d = Common.parseISODate(x)
                if (d && d <= this.max) {
                    return true
                } else {
                    const v = datefns.formatISO(this.max, { representation: "date" })
                    return `The date must not be greater than ${v}`
                }
            }
            return true
        },
        isMin: (x: string) => {
            if (this.min) {
                const d = Common.parseISODate(x)
                if (d && d <= this.min) {
                    return true
                } else {
                    const v = datefns.formatISO(this.min, { representation: "date" })
                    return `The date must not be smaller than ${v}`
                }
            }
            return true
        },
    } as Record<string, (x: string) => string | boolean>

    checkAllRules(x: string | null) {
        if (x) {
            const rules = Object.values(this.rules)
            const results = rules.map((v) => v(x))
            return results.every((v) => typeof v === "boolean")
        }
        return true
    }

    get value(): string | null {
        if (this.item) {
            return datefns.formatISO(this.item, { representation: "date" })
        }
        return ""
    }

    set value(x: string | null) {
        if (this.checkAllRules(x)) {
            const result = Common.parseISODate(x)
            this.$emit("update:item", result)
        } else {
            this.$emit("error", this.label)
        }
    }

    getKey(e: KeyboardEvent) {
        const isRemoveKey = ["Backspace", "Delete"].includes(e.key)
        const isNavigationKey = ["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDow", "Home", "End", "Tab"].includes(e.key)
        const isNumberKey = e.key >= "0" && e.key <= "9"
        const isGrouping = e.key === "-"
        if (!isNavigationKey && !isNumberKey && !isGrouping && !isRemoveKey) {
            e.preventDefault()
        }
    }
}
