
import Vue from "vue"
import { parse } from "vue-currency-input"

import * as common from "@/components/Util/Common"
import * as Locale from '@/lib/Util/Types/Common/Locale';
import * as PerLineItem from "@/lib/Util/Types/Masterdata/BusinessProcess/PerLineItem"
import * as Shared from "../Shared"

import store from "@/store/store"

export default Vue.extend({
    name: "EditDialogPerLineitem",
    props: {
        item: {
            type: Object as () => Shared.EditPrice<PerLineItem.T>,
        } as Vue.PropOptions<Shared.EditPrice<PerLineItem.T>>,
        valid: {
            type: Boolean
        }
    },
    data() {
        const locale = Locale.getLocale(store.state.client.sub_item.value?.country.kind ?? "DE")
        return {
            limit: common.format(this.item.value.limit, locale.country),
            selling_price: common.format(this.item.value.selling_price, locale.country),
            currency_format: {
                locale: locale.language,
                currency: locale.currency,
                distractionFree: true,
                allowNegative: false,
            },
            rules: {
                limit: [
                    (v: string) => (parse(v, {}) ?? 0) > 0 || "Not a number or must be greater 0",
                ],
            },
            isValid: this.valid
        }
    },
    watch: {
        limit() {
            this.item.value.limit = common.getRefValue(this.$refs.limit)
        },
        selling_price() {
            this.item.value.selling_price = common.getRefValue(this.$refs.selling_price)
        },
        isValid(nv: boolean) {
            this.$emit("update:valid", nv)
        }
    },
});
