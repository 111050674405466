import * as Currency from "./Currency"
import * as Protocols from "@/lib/Util/Types/Protocols"

export type T = {
    value: number;
    currency: Currency.T;
}

export function create(v?: number, c?: Currency.T): T {
    return {
        value: v ?? 0,
        currency: c ?? { kind: "EUR", EUR: true }
    }
}
export function zero(c?: Currency.T): T { return create(0, c) }
export function one(c?: Currency.T): T { return create(1, c) }

function algebra(f: (x: number, y: number) => number, m1: T, m2: T): T | null {
    if (m1.currency.kind === m2.currency.kind) {
        return {
            value: f(m1.value, m2.value),
            currency: m1.currency
        }
    }
    else {
        return null
    }
}

export const add = (m1: T, m2: T): T | null => (algebra((x, y) => (x + y), m1, m2))
export const subtract = (m1: T, m2: T): T | null => algebra((x, y) => (x - y), m1, m2)
export const multiply = (m1: T, m2: T): T | null => algebra((x, y) => (x * y), m1, m2)
export const divide = (m1: T, m2: T): T | null => algebra((x, y) => (x / y), m1, m2)
export function sum(xs: T[]): T | null {
    if (xs && xs.length > 0) {
        let result = zero(xs[0].currency)
        for (const item of xs) {
            const r = add(result as T, item)
            if (r) {
                result = r
            }
            else {
                console.error("could not sum", xs)
                return null
            }
        }
        return result
    }
    return null
}

export function tryParse(x: any): T | null {
    if (x) {
        const c = Currency.tryParse(x.currency)
        if (c) {
            return {
                ...x,
                ...{ currency: c }
            }
        }
    }
    console.error("Money.tryParse", x)
    return null
}

export class SearcheableProtocol implements Protocols.Searcheable {
    search(x: string): boolean {
        const self = this as any as T
        return self.value.toString().includes(x)
    }
}