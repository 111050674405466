import * as Protocols from "@/lib/Util/Types/Protocols"

export type IBAN = {
    kind: "IBAN";
    account_id: string;
    bic?: string;
}

export type OTHER = {
    kind: "OTHER";
    account_id: string;
}

export type T =
    | IBAN
    | OTHER

export type Kind = T['kind']

export const KEYS: Kind[] = ["OTHER", "IBAN"]

export function getEmpty(): T {
    return {
        kind: "OTHER",
        account_id: "",
    }
}

export function tryParse(x?: any): T | null {
    if (x) {
        return x
    }
    console.log("Account.tryParse", x)
    return null
}

export class SearcheableProtocol implements Protocols.Searcheable {
    search(x: string): boolean {
        const self = this as any as T
        return self.account_id.toLowerCase().includes(x)
    }
}
