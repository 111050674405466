
import Vue from "vue"
import { Prop } from "vue-property-decorator"
import Component from "vue-class-component"

import * as lodash from "lodash"
import * as Common from "@/lib/Util/Common"

import * as common from "@/components/Util/Common"
import * as Country from "@/lib/Util/Types/Common/Country"
import * as BatchOutputType from "@/lib/Util/Types/Biz/BatchOutputType"

import * as Template from "@/lib/Util/Types/Masterdata/Template/Template"
import * as TemplateType from "@/lib/Util/Types/Masterdata/Template/TemplateType"

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"])

@Component
export default class TemplateEditDialog extends Vue {
    @Prop({ default: null }) item!: common.EditWrapper<Template.T> | null
    @Prop({ default: null }) dialogSave!: ((x: common.EditWrapper<Template.T>) => void) | null
    @Prop({ default: null }) dialogCancel!: (() => void) | null

    valid = true

    available_countries = Country.KEYS
    available_template_types = TemplateType.KEYS
    available_batch_output_types = BatchOutputType.KEYS

    filename = "Drop the Template File here!"

    get loaded() {
        return lodash.cloneDeep(this.item)
    }

    get used_country() {
        return this.loaded?.value.country.kind ?? "DE"
    }

    set used_country(v: Country.Kind) {
        if (this.loaded) {
            this.loaded.value.country = Country.getByKind(v)
        }
    }

    get used_batch_output_type() {
        return this.loaded?.value.batch_output_type.kind ?? "Full"
    }

    set used_batch_output_type(v: BatchOutputType.Kind) {
        if (this.loaded) {
            this.loaded.value.batch_output_type = BatchOutputType.getByKind(v)
        }
    }

    get used_template_type() {
        return this.loaded?.value.template_type.kind ?? "SingleInvoice"
    }

    set used_template_type(v: TemplateType.Kind) {
        if (this.loaded) {
            this.loaded.value.template_type = TemplateType.getByKind(v)
        }
    }

    save() {
        console.log("EDITDIALOG-TEMPLATE.SAVE", this.loaded, this.valid)
        if (this.dialogSave && this.loaded) {
            this.dialogSave(this.loaded)
        }
    }
    cancel() {
        console.log("EDITDIALOG-TEMPLATE.CANCEL", this.loaded, this.valid)
        if (this.dialogCancel) {
            this.dialogCancel()
        }
    }

    async addFile(e: any) {
        console.log("ADD FILE", e)
        const files = e.dataTransfer.files as File[]
        const result = await Common.fromFileToString(files)
        this.filename = result?.name ?? "No file?!"
        if (result && this.loaded) {
            this.loaded.value.data = result.value
        } else {
            console.error("Template.EditDialog.addFile", e)
        }
    }
}
