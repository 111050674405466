
import Vue from "vue";

import * as Common from "@/components/Util/Common"
import EditTable from "@/components/Util/EditTable.vue"

import * as PricingInformation from "@/lib/Util/Types/Masterdata/BusinessProcess/PricingInformation"

import EditDialog from "./EditDialog/Main.vue"
import * as Shared from "./Shared"

export default Vue.extend({
    name: "TableView2",
    components: {
        EditDialog,
        EditTable
    },
    props: {
        title: {
            type: String
        },
        headers: {
            type: Array,
            default() { return [] }
        } as Vue.PropOptions<Common.DataTableHeaders>,
        items: {
            type: Array,
        } as Vue.PropOptions<PricingInformation.PricingTypeList>,
        isStatic: {
            type: Boolean
        } as Vue.PropOptions<boolean>,
        addRow: {
            type: Function as Vue.PropType<() => PricingInformation.PricingType>
        }
    },
    data() {
        console.log("DATA", this.items, this.headers)
        return {
            convertRow: Shared.toEditPrice,
            convertRowList: Shared.toEditPriceList,
            sort: PricingInformation.sortPricingTypeList
        }
    },
    watch: {
        items: {
            handler(nv: any, ov: any) {
                console.log("TABLEVIEW.WATCH.items", nv, ov)
            }
        }
    },
    methods: {
        updateList(xs: any[]) {
            console.log("TABLEVIEW2,UPDATELIST", xs)
            this.$emit("update:items", xs)
        },
    }
});
