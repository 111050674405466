
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import EditToolbar from "@/components/Util/EditToolbar.vue"
import UnsavedChangesDialog from "@/components/Util/UnsavedChangesDialog.vue"

@Component({
    components: {
        EditToolbar,
        UnsavedChangesDialog,
    },
})
export default class EditForm extends Vue {
    @Prop({ default: null }) save!: (() => void) | null
    @Prop({ default: null }) cancel!: (() => void) | null
    @Prop({ default: false }) isDirty!: boolean
    @Prop({ default: null }) bus!: any | null

    leave_route_info = {
        route: null as any | null,
        show: false,
        force_route: false,
    }
    onRouteLeave(event: any) {
        console.log("EditForm.onRouteLeave", event.to, event.from, this.isDirty, this.leave_route_info)
        if (this.isDirty && !this.leave_route_info.force_route) {
            this.leave_route_info = {
                route: event.to,
                show: true,
                force_route: false,
            }
            event.next(false)
        } else {
            this.leave_route_info = {
                route: null,
                show: false,
                force_route: false,
            }
            event.next()
        }
    }

    unsavedChangesDialogClosed(x: string) {
        console.log("EditForm.unsavedChangesDialogClosed", x)
        switch (x) {
            case "progress":
                this.leave_route_info = {
                    route: this.leave_route_info.route,
                    show: false,
                    force_route: true,
                }
                this.$router.push(this.leave_route_info.route)
                break
            case "dismiss":
                this.leave_route_info = {
                    route: null,
                    show: false,
                    force_route: false,
                }
                break
            case "save":
                this.leave_route_info = {
                    route: this.leave_route_info.route,
                    show: false,
                    force_route: true,
                }
                if (this.save) {
                    //call save here because we havent saved yet but want to
                    //move to another route
                    this.save()
                    this.$router.push(this.leave_route_info.route)
                } else {
                    console.error("EditForm.save is null")
                }
                break
        }
    }

    mounted() {
        this.bus.$on("on-route-leave", this.onRouteLeave)
    }
}
