import MasterdataSupplierMainView from "@/components/Sections/Masterdata/Supplier/Main.vue"
import MasterdataSupplierListView from "@/components/Sections/Masterdata/Supplier/List/Main.vue"
import MasterdataSupplierSingleView from "@/components/Sections/Masterdata/Supplier/Single/Main.vue"

const router = {
    path: 'supplier',
    component: MasterdataSupplierMainView,
    children: [
        {
            path: 'list',
            component: MasterdataSupplierListView
        },
        {
            path: 'single/:id',
            component: MasterdataSupplierSingleView
        },

    ]
}

export default router
