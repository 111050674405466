import * as Common from "@/lib/Util/Common"
import * as SecondaryId from "./SecondaryId"

export type T = {
    id: string;
    short_id: string;
    secondary_id: SecondaryId.T;
    partition_key: string;
    created_at: Date;
    updated_at: Date;
    active?: boolean;
    parent_id?: string;
    version_number: number;
    is_test_data: boolean;
}

export function tryParse(x: any): T | null {
    if (x) {
        const created_at = Common.parseServerDate(x.created_at)
        const updated_at = Common.parseServerDate(x.updated_at)
        const secondary_id = SecondaryId.tryParse(x.secondary_id)

        return {
            ...x,
            created_at,
            updated_at,
            secondary_id,
        }
    }
    console.error("SupplierPayments.tryParse", x)
    return null
}
