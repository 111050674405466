
import Vue from "vue"
import { Component } from "vue-property-decorator"

import { ExportToCsv } from "export-to-csv"

import * as Common from "@/lib/Util/Common"

import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"

import InvoiceListFilterView from "./Filter.vue"
import * as Shared from "./Shared"
import * as MainShared from "../Shared"

import store from "@/store/store"
import * as Filter from "@/store/Invoice/Filter"
import router from "@/router/router"

Component.registerHooks(["beforeRouteUpdate"])

@Component({
    components: {
        InvoiceListFilterView,
        SuccessMessage,
        ErrorMessage,
    },
})
export default class InvoiceListMainView extends Vue {
    headers = Shared.HEADERS
    sendEmail = store.dispatch.invoice.list.sendEmail
    loadDocument = MainShared.loadDocument

    static routeChange(to: any, from: any, next: any) {
        const filter = Filter.from(to.query)
        console.log("100 ROUTE CHANGE", filter)
        if (!Common.isEmpty(filter)) {
            store.commit.invoice.list.updateFilter(filter)
            const query = Filter.toQuery(filter)
            console.log("200 ROUTE CHANGE", query)
            store.dispatch.invoice.list.LOAD(query)
            next()
        } else {
            console.log("300 ROUTE CHANGE", to)
            router.push({
                path: "/sections/invoices/list",
                query: Filter.getEmpty(),
            })
        }
    }

    beforeRouteEnter(to: any, from: any, next: any) {
        InvoiceListMainView.routeChange(to, from, next)
    }

    beforeRouteUpdate(to: any, from: any, next: any) {
        console.log("100 ROUTE UPDATE", to)
        InvoiceListMainView.routeChange(to, from, next)
    }

    get items() {
        return store.state.invoice.list.value.map(Shared.fromInvoice)
    }
    get msg() {
        return store.state.invoice.list.message
    }

    get filter() {
        return store.state.invoice.list.filter
    }

    set filter(x: Filter.T) {
        store.commit.invoice.list.updateFilter(x)
        const query = Filter.toQuery(x)
        store.dispatch.invoice.list.LOAD(query)
    }

    exportCSV() {
        const filename = `invoice.${Common.toServerDate(new Date())}.csv`
        const records = store.state.invoice.list.value.map(Shared.toCSVRecord)
        const options = {
            fieldSeparator: ";",
            quoteStrings: '"',
            decimalSeparator: ",",
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            filename: filename,
            useKeysAsHeaders: true,
        }

        const exporter = new ExportToCsv(options)

        exporter.generateCsv(records)
    }
}
