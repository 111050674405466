
import Vue from "vue";
import * as lodash from "lodash/fp";

import * as common from "@/components/Util/Common";

import * as AreasAccessLevels from "@/lib/Util/Types/InternalUser/AreasAccessLevels";

export default Vue.extend({
    name: "EditDialog",
    components: {},
    props: {
        item: {
            type: Object as () => common.EditWrapper<AreasAccessLevels.T>,
        } as Vue.PropOptions<common.EditWrapper<AreasAccessLevels.T>>,
        dialogSave: {
            type: Function,
        },
        dialogCancel: {
            type: Function,
        },
    },
    data() {
        return {
            valid: true,
        };
    },
    computed: {
        loaded() {
            return lodash.cloneDeep(this.item);
        },
    },
    watch: {
        valid: {
            handler(nv: boolean) {
                console.log("WATCH VALID:", nv);
            },
        },
    },
    methods: {
        save() {
            this.dialogSave(this.loaded);
        },
        cancel() {
            this.dialogCancel();
        },
    },
});
