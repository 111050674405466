
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import * as Types from "@/lib/Util/Types/Types"

import * as Workflow from "@/lib/Util/Types/Workflow/Workflow"
import * as StepState from "@/lib/Util/Types/Workflow/StepState"

import TooltipIcon from "@/components/Util/TooltipIcon.vue"

type Keys = 0 | 1 | 2 | 3 | 4
const map: Record<string, Keys> = {
    Edit: 0,
    Sealed: 1,
    Confirmed: 2,
    Declined: 3,
    Canceled: 4,
}

const lookup = Types.buildLookup(map)

@Component({
    components: { TooltipIcon },
})
export default class StepStateToolbar extends Vue {
    @Prop({ default: true }) active!: boolean
    @Prop({ default: null }) item!: Workflow.T
    @Prop({ default: null }) run!: (x: StepState.Kind) => void

    get selected() {
        const kind = this.item.state.step_state?.kind ?? "Edit"
        return lookup.source[kind]
    }

    set selected(x: Keys) {
        const s = lookup.target[x] as StepState.Kind
        this.run(s)
    }

    get isEditActive() {
        return (
            ((this.item.state.step_state?.kind === "Edit" ?? false) ||
                (this.item.state.step_state?.kind === "Sealed" ?? false)) &&
            this.active
        )
    }

    get isSealedActive() {
        return (
            ((this.item.state.step_state?.kind === "Edit" ?? false) ||
                (this.item.state.step_state?.kind === "Sealed" ?? false)) &&
            this.active
        )
    }
    get isConfirmedActive() {
        return (
            ((this.item.state.step_state?.kind === "Confirmed" ?? false) ||
                (this.item.state.step_state?.kind === "Sealed" ?? false)) &&
            this.active
        )
    }
}
