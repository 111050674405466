import * as Common from "@/lib/Util/Common"
import * as Timespan from "@/lib/Util/Types/Common/Timespan"
import * as Country from "@/lib/Util/Types/Common/Country"
import * as Currency from "@/lib/Util/Types/Common/Currency"
import * as VatType from "@/lib/Util/Types/VAT/VatType"

import * as IntervalRequest from "@/store/IntervalRequest"

type QuerySubset = {
    invoice_no?: string;
    country?: Country.Kind[];
    currency?: Currency.Kind[];
    vat_kind?: VatType.Kind[];
}
export type T =
    QuerySubset &
    {
        timespan: Timespan.Kind;
    }

export type Query =
    QuerySubset &
    IntervalRequest.InvoiceDate

export function getEmpty(): T {
    return {
        timespan: Timespan.getPrevMonthKind(new Date()),
    }
}

export function toQuery(x: T): Query {
    const dates = x.timespan ? IntervalRequest.mkInvoiceDate(x.timespan) : undefined
    return {
        ...dates,
        invoice_no: x.invoice_no,
        country: x.country,
        currency: x.currency,
        vat_kind: x.vat_kind
    }
}

export function from(x: any): T {
    const timespan = x.timespan
    const invoice_no = x.invoice_no
    const country = x.country
    const currency = x.currency
    const vat_kind = x.vat_kind
    const result: T = {
        timespan,
        invoice_no,
        country,
        currency,
        vat_kind
    }
    return Common.removeUndefined(result)
}