import { defineModule, localActionContext, } from "direct-vuex"

import * as ListModule from './ListModule'
import * as SingleModule from './SingleModule'
import * as DashboardModule from './DashboardModule'

export const internal = {
    namespaced: true as const,
    modules: {
        list: ListModule.module,
        single: SingleModule.module,
        dashboard: DashboardModule.module
    }
}

export const module = defineModule(internal)

export default module

const actionCtx = (context: any) => localActionContext(context, module)
