
import Vue from "vue"
import { Component } from "vue-property-decorator"

import store from "@/store/store"

import * as Timespan from "@/lib/Util/Types/Common/Timespan"

@Component({})
export default class WorkflowToolbar extends Vue {
    mounted() {
        store.commit.updateToolbar(true)
    }
    get finishLink() {
        return `/sections/workflow/finish/list?timespan=${Timespan.getActiveKind("M")}`
    }
    get payLink() {
        return "/sections/workflow/pay/list?received=true&paid=Overdue;Urgent;Alarm"
    }
}
