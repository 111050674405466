
import Vue from "vue"
import Component from "vue-class-component"

import * as Common from "@/lib/Util/Common"

import store from "@/store/store"
import router from "@/router/router"

@Component({})
export default class SupplierPaymentsMainView extends Vue {
    get item() {
        return store.state.supplier_payments.single.value
    }

    get listFilter() {
        return store.state.supplier_payments.list.filter
    }

    beforeRouteEnter(to: any, from: any, next: any) {
        Common.rerouteRegex(router, to, from, next, "/sections/bank/supplier_payments/", "list")
    }

    beforeRouteUpdate(to: any, from: any, next: any) {
        Common.rerouteRegex(router, to, from, next, "/sections/bank/supplier_payments/", "list")
    }
}
