import * as Types from '@/lib/Util/Types/Types'

export const VALUES = {
    PREPAYMENT: true,
    FASTTRACK: true,
    REMINDER_1: true,
    REMINDER_2: true,
    REMINDER_3: true,
    COLLECTION_THREAT: true,
    COLLECTION: true,
    CLIENT_REQUEST: true,
    ON_HOLD: true,
    AFTER_PAYMENT: true
} as const

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getEmpty(): T {
    return { kind: "REMINDER_1", REMINDER_1: true }
}

export function getByKind(x: Kind): T {
    return Types.makeSingleDUFromString(x) as T
}

export const tryGetByKind = (x: string): T | null => Types.tryMakeSingleDUFromString(x, KEYS)
export const getByKindDefault = (x: string): T => tryGetByKind(x) ?? getEmpty()
