
import { Vue, Component, Prop } from "vue-property-decorator"
import * as lodash from "lodash/fp"
import * as common from "./Common"

import TooltipIcon from "@/components/Util/TooltipIcon.vue"
import { AnyObject } from "@/lib/Util/Common"

@Component({
    components: {
        TooltipIcon,
    },
})
export default class FlexibleEditTableView<T> extends Vue {
    @Prop({ default: () => [] }) headers!: common.DataTableHeaders
    @Prop({ default: () => [] }) items!: T[]
    @Prop({ default: () => [] }) actions!: common.Action<T>[]

    @Prop({ default: false }) showAll!: boolean

    @Prop({ default: null }) onCreate!: (() => T) | null
    @Prop({ default: null }) onSort!: ((x: T[]) => T[]) | null
    @Prop({ default: null }) onSave!: ((x: T, n: boolean) => T) | null
    @Prop({ default: null }) onFilter!:
        | ((s: string, i: common.EditWrapper<T>) => boolean)
        | null
    @Prop({ default: null }) onAction!:
        | ((s: string, i: common.EditWrapper<T>) => boolean)
        | null
    @Prop({ default: null }) convertRow!: (
        x: T,
        i: number,
        n: boolean
    ) => common.EditWrapper<T> | null

    item = null as common.EditWrapper<T> | null
    search = ""
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getValue = (path: string, v: AnyObject): any => lodash.get(path, v)

    get sorting(): (v: T[]) => T[] {
        if (this.onSort) {
            return this.onSort
        }
        return (v: T[]) => v
    }

    get loaded(): common.EditWrapper<T>[] {
        const sorted = this.sorting(this.items)
        const result = sorted.map((x, i) => this.convertRow(x, i, false))
        return result.filter(x => x !== null) as common.EditWrapper<T>[]
    }

    filtering(
        x: AnyObject,
        search: string,
        item: common.EditWrapper<T>
    ): boolean {
        if (this.onFilter) {
            return this.onFilter(search, item)
        }
        return true
    }

    allowAction(command: string, x: common.EditWrapper<T>): boolean {
        if (this.onAction) {
            return this.onAction(command, x)
        }
        return true
    }

    cellType(x: common.DataTableHeaderItem): "actions" | common.TableItemType {
        if (!x.cell_type) {
            if (x.text === "Actions") {
                return "actions"
            }
            return "text"
        }
        return x.cell_type
    }

    addEmpty(): void {
        if (this.onCreate) {
            const result = this.convertRow(this.onCreate(), 0, true)
            this.item = result
        } else {
            this.item = null
        }
    }

    deleteRow(x: number): void {
        const prep = lodash.cloneDeep(this.loaded)
        if (prep) {
            prep.splice(x, 1)
            const result = prep.map(x => x.value)
            console.log("EDITTABLE.DELETEROW")
            this.$emit("update-list", result)
        }
    }

    edit(x: common.EditWrapper<T>): void {
        console.log("EDITTABLE.EDIT", x)
        this.item = lodash.cloneDeep(x)
    }

    async dialogSave(x: common.EditWrapper<T>): Promise<void> {
        console.log("100 EDITTABLE.DIALOG-SAVE", x)
        this.item = null
        let saved: common.EditWrapper<T> | null = x
        if (this.onSave) {
            console.log("200 EDITTABLE.DIALOG-SAVE", x)
            const s = await this.onSave(x.value, x.new_item)
            console.log("210 EDITTABLE.DIALOG-SAVE", s)
            saved = this.convertRow(s, x.index, x.new_item)
            console.log("220 EDITTABLE.DIALOG-SAVE", saved)
        }
        if (saved) {
            console.log("300 EDITTABLE.DIALOG-SAVE", x)
            if (x.new_item) {
                console.log("400 EDITTABLE.DIALOG-SAVE", x)
                this.updateList(saved, 0)
            } else {
                console.log("500 EDITTABLE.DIALOG-SAVE", x)
                this.updateList(saved, 1)
            }
        } else {
            console.error("FlexibleEditTable.dialogSave")
        }
    }

    dialogCancel(): void {
        console.log("EDITTABLE.DIALOG-CANCEL", this.loaded)
        this.item = null
    }

    updateList(x: common.EditWrapper<T>, replace_count: number): void {
        console.log("EDITTABLE.UPDATELIST", x, replace_count)
        const prep = lodash.cloneDeep(this.loaded)
        if (prep) {
            prep.splice(x.index, replace_count, x)
            const extracted = prep.map(x => x.value)
            const result = this.sorting(extracted)
            this.$emit("update-list", result)
        }
    }
}
