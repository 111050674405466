import * as Common from "@/lib/Util/Common"
import * as Timespan from "@/lib/Util/Types/Common/Timespan"
import * as Country from "@/lib/Util/Types/Common/Country"
import * as Currency from "@/lib/Util/Types/Common/Currency"
import * as VatType from "@/lib/Util/Types/VAT/VatType"

import * as IntervalRequest from "@/store/IntervalRequest"

type QuerySubset = {
    workflow_ref?: string;
    country?: Country.Kind[];
    currency?: Currency.Kind[];
    vat_kind?: VatType.Kind[];
}
export type T =
    QuerySubset &
    {
        timespan: Timespan.Kind;
    }

export type Query =
    QuerySubset &
    IntervalRequest.ApprovalDate

export function getEmpty(): T {
    return {
        timespan: Timespan.getPrevMonthKind(new Date()),
    }
}

export function toQuery(x: T): Query {
    const dates = x.timespan ? IntervalRequest.mkApprovalDate(x.timespan) : undefined
    return {
        ...dates,
        workflow_ref: x.workflow_ref,
        country: x.country,
        currency: x.currency,
        vat_kind: x.vat_kind
    }
}

export function from(x: any): T {
    const timespan = x.timespan
    const workflow_ref = x.workflow_ref
    const country = x.country
    const currency = x.currency
    const vat_kind = x.vat_kind
    const result: T = {
        timespan,
        workflow_ref,
        country,
        currency,
        vat_kind
    }
    return Common.removeUndefined(result)
}