import * as lodash from "lodash"

import jsonexport from "jsonexport"

import * as Common from "@/lib/Util/Common"
import * as ComponentCommon from "@/components/Util/Common"
import * as SupplierPayments from "@/lib/Util/Types/SupplierPayments/SupplierPayments"
import * as Doc from "@/lib/Util/Types/Workflow/Summary/Doc"

export const HEADERS = [
    {
        text: "ShortID",
        value: "short_id",
    },
    {
        text: "Name",
        value: "name",
    },
    {
        text: "Contact",
        value: "contact",
    },
    {
        text: "Email",
        value: "email",
    },
    {
        text: "Process#",
        value: "process_count",
    },
    {
        text: "Created At",
        value: "created_at",
    },
    {
        text: "Actions",
        value: "actions",
    },
]

type Selected =
    | "short_id"
    | "bp_ref_id"
    | "secondary_id"
    | "client"
    | "created_at"
    | "incoming_position_data"
    | "invoice_no"
    | "supplier_account"
    | "supplier"
    | "total"
    | "discounted"
    | "accounting_data"

type SelectedDoc = Pick<Doc.DocSummary, Selected>

type SupplierDoc = Omit<
    SelectedDoc,
    | "currency"
    | "incoming_position_data"
    | "created_at"
    | "supplier_account"
    | "supplier"
    | "total"
    | "discounted"
    | "accounting_data"
> & {
    supplier_payment_at: string
    supplier_payment_id: string
    currency: string
    payout: string
    created_at: string
    supplier_account: string
    supplier_account_kind: string
    text?: string
    supplier_name: string
    booking_account: string
    discount_diff: string
}

export function toCSVRecords(x: SupplierPayments.T) {
    const sps = {
        supplier_payment_at: Common.toShortDate(x.created_at),
        supplier_payment_id: x.id,
    }

    const toSelectedDoc = (v: Doc.DocSummary): SelectedDoc => {
        return lodash.pick(v, [
            "short_id",
            "bp_ref_id",
            "secondary_id",
            "client",
            "created_at",
            "invoice_no",
            "supplier_account",
            "supplier",
            "total",
            "incoming_position_data",
            "discounted",
            "accounting_data",
        ])
    }

    const toSupplierDoc = (v: SelectedDoc): SupplierDoc => {
        const text = `SID: ${v.short_id}, PID: ${v.secondary_id ?? ""}, RID: ${v.invoice_no}`
        const currency = v.total?.currency.kind ?? ("EUR" as string)
        const supplier_name = v.supplier.name
        const cleaned_v = lodash.omit(v, [
            "supplier",
            "total",
            "discounted",
            "incoming_position_data",
            "accounting_data",
        ])
        return {
            ...cleaned_v,
            ...sps,
            currency,
            created_at: Common.toShortDate(v.created_at),
            supplier_account_kind: v.supplier_account.kind,
            supplier_account: v.supplier_account.account_id,
            supplier_name,
            payout: ComponentCommon.format(v.discounted.value, "DE"),
            text,
            invoice_no: "'" + v.invoice_no,
            booking_account: v.accounting_data?.accounts_payable.account ?? "UNKNOWN BOOKING ACCOUNT",
            discount_diff: ComponentCommon.format(v.incoming_position_data.value - v.discounted.value, "DE"),
        }
    }
    const result = x.line_items
        .map(Doc.asDocSummary)
        .map(toSelectedDoc)
        .map(toSupplierDoc)
    return result
}

export async function exportAccountingCSV(xs: SupplierPayments.T[], filename: string) {
    const selected: (keyof SupplierDoc)[] = [
        "supplier_account",
        "supplier_payment_at",
        "supplier_name",
        "payout",
        "invoice_no",
        "booking_account",
        "text",
        "client",
        "currency",
        "discount_diff",
    ]
    const picker = (v: SupplierDoc) => lodash.pick(v, selected)
    const records = xs.map(toCSVRecords).flat()
    const picked = records.map(picker)
    const csv = await jsonexport(picked, { rowDelimiter: ";", headers: selected })
    Common.downloadCSV(csv, filename)
}

export async function exportBankCSV(xs: SupplierPayments.T[], filename: string) {
    const selected: (keyof SupplierDoc)[] = ["supplier_name", "supplier_account", "payout", "currency", "text"]
    const picker = (v: SupplierDoc) => lodash.pick(v, selected)
    console.log("PICKER", picker)
    const records = xs.map(toCSVRecords).flat()
    console.log("RECORDS", records)
    const picked = records.map(picker)
    const picked_cleaned = picked.map(v => {
        const beneficiary_name = v.supplier_name
            .replaceAll("ä", "ae")
            .replaceAll("ö", "oe")
            .replaceAll("ü", "ue")
            .replaceAll("ß", "ss")
            .replaceAll("Ä", "Ae")
            .replaceAll("Ö", "Oe")
            .replaceAll("Ü", "Ue")
            .replaceAll("é", "e")
            .replaceAll("è", "e")
            .replaceAll("ê", "e")
            .replaceAll("à", "a")
            .replaceAll("â", "a")
            .replaceAll("&", "+")

        return {
            beneficiary_name,
            iban: v.supplier_account,
            amount: v.payout.replaceAll(".", ""),
            currency: v.currency,
            reference: v.text?.replaceAll(",", "."),
        }
    })
    console.log("PICKED", picked_cleaned)
    const qounto_headers = ["beneficiary_name", "iban", "amount", "currency", "reference"]
    const csv = await jsonexport(picked_cleaned, { rowDelimiter: ";", headers: qounto_headers })
    Common.downloadCSV(csv, filename)
}
