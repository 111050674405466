import datefns from "date-fns"
import * as Types from "@/lib/Util/Types/Types"

import * as common from "../../Common"
import * as Money from "../Common/Money"
import * as InvoiceKey from "../Biz/InvoiceKey"
import * as Doc from "../Workflow/Summary/Doc"
import * as IncrementalCounter from "../Biz/IncrementalCounter"
import * as InvoiceHeaderData from "./InvoiceHeaderData"
import * as Totals from "./Totals"

// TODO: Use a generic version of BatchOutput instead
// type Invoice = BatchOutput<InvoiceKey, InvoiceHeaderData>
export type T = {
    id: string;
    uid: IncrementalCounter.T<InvoiceKey.T>;
    created_at: Date;
    data: InvoiceHeaderData.T;
    line_items: Doc.T[];
    total: Money.T;
    totals: Totals.T;
    is_difference: boolean;
    difference: number;

    //todo: remove in 2023 most likely not as it is used in remittances <blaeh!>
    terse_id: string;
}

export function tryParse(x: any): T | null {
    try {
        const created_at = common.parseServerDate(x.created_at)
        const data = InvoiceHeaderData.tryParse(x.data)
        const line_items_raw = x.line_items ?? x.lineitems
        const line_items = (line_items_raw as any[]).map(Doc.tryParse)
        const ok_lineitems = line_items.every((x) => x !== null)
        const terse_id = data?.terse_id ? data.terse_id : x.terse_id

        if (ok_lineitems && data) {
            const totals = Totals.tryParse(x.totals)
            const total = Money.tryParse(x.total)
            const difference = x.difference ?? (data?.paid_amount ?? 0) - (totals?.sum.value ?? 0)
            const isDifference = x.is_difference ?? difference <= -1.0
            return {
                ...x,
                ...{
                    created_at,
                    total,
                    data,
                    line_items,
                    totals,
                    isDifference,
                    difference,
                    terse_id
                },
            }
        }
        return null
    }
    catch (ex) {
        console.error("990 Invoice.TryParse", ex)
        return null
    }
}

export const tryParseList = Types.buildTryParseList(tryParse, "Invoice")

export function findById(x: string, xs: T[]) {
    return xs.filter(v => v.id === x || v.terse_id === x)
}