
import Vue from "vue"
import Component from "vue-class-component"

import store from "@/store/store"

import * as Shared from "./Shared"
import SuccessMessage from "@/components/Util/SuccessMessage.vue"
import ErrorMessage from "@/components/Util/ErrorMessage.vue"
import TooltipIcon from "@/components/Util/TooltipIcon.vue"
import SearchForm from "@/components/Util/SearchForm/Main.vue"
import * as Protocols from "@/lib/Util/Types/Protocols"

import * as InternalUser from "@/lib/Util/Types/InternalUser/InternalUser"

//todo: leave here just in case migration is needed again
import * as SupplierModule from "@/store/Supplier/SingleModule"
import * as TemplateModule from "@/store/Masterdata/Template/SingleModule"
import * as InternalUserModule from "@/store/InternalUser/SingleModule"


const lang = InternalUser.LANG

@Component({
    components: {
        SuccessMessage,
        ErrorMessage,
        TooltipIcon,
        SearchForm,
    },
})
export default class MasterdataInternalUserListMainView extends Vue {
    headers = Shared.HEADERS
    lang = lang
    filter = {} as Protocols.QueryExpression<typeof lang>

    created(): void {
        store.dispatch.internal_user.list.LOAD()
    }

    get items() {
        const filtered = InternalUser.filter(this.filter, store.state.internal_user.list.value)
        return Shared.toListItems(filtered)
    }
    get msg() {
        return store.state.client.list.message
    }

    toggle(x: string) {
        return function (): void {
            store.dispatch.internal_user.single.TOGGLE(x).then(function () {
                store.commit.internal_user.list.toggleActivation(x)
            })
        }
    }

    updateFilter(x: Protocols.QueryExpression<typeof lang>) {
        this.filter = x
    }

    async migrate() {
        console.log("MIGRATE")
        //await SupplierModule.migrate(store.state.authentication?.token)
        //await InternalUserModule.migrate(store.state.authentication?.token)
        await TemplateModule.migrate(store.state.authentication?.token)
    }

}
