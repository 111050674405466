import * as Types from '@/lib/Util/Types/Types'
import * as CodeInfo from "@/lib/Util/Types/Masterdata/BusinessProcess/CodeInfo"

export const VALUES = {
    ListItem: CodeInfo.getEmpty(),
    TextItem: "" as string
} as const

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']

export const KEYS: Kind[] = Types.getKindNames<Record, Kind>(VALUES)

export function getEmpty(): T {
    return { kind: "TextItem", TextItem: "" }
}

export function tryParse(x?: any): T | null {
    if (x) {
        if (x.LineItem) {
            const result = CodeInfo.tryParse(x.LineItem)
            if (result) {
                return {
                    kind: "ListItem",
                    ListItem: result
                }
            }
        }
        else if (x.TextItem) {
            return {
                kind: "TextItem",
                TextItem: x.TextItem
            }
        }
    }
    console.error("CostInfo.tryParse", x)
    return null
}