import * as lodash from "lodash"
import * as Target from "@/lib/Util/REST/Target"
import * as Global from "@/lib/Util/Types/Workflow/SearchListItem/Global"

export const HEADERS = [
    {
        text: 'Short-ID',
        value: "short_id",
    },
    {
        text: 'State',
        value: 'state',
        width: "7%"
    },

    {
        text: 'O2P',
        value: 'process_type',
        width: "3%"
    },
    {
        text: 'Start Date',
        value: 'start_date'
    },
    {
        text: 'Client',
        value: 'client_name',
    },

    {
        text: 'Buyer',
        value: 'buyer_email',
    },
    {
        text: 'PO',
        value: 'order_number',
    },
    {
        text: 'Supplier',
        value: 'supplier_name',
    },
    {
        text: 'Invoice#',
        value: 'invoice_number',
    },
    {
        text: '',
        value: 'actions',
    },
]

export const DASHBOARD_HEADERS = [
    {
        text: 'Year',
        value: "year",
    },
    {
        text: 'Total',
        value: 'total',
        align: 'end'
    },
    {
        text: 'Total#',
        value: 'total_count',
        align: 'end'
    },

    {
        text: 'Payment',
        value: 'payment',
        align: 'end'
    },
    {
        text: 'Payment#',
        value: 'payment_count',
        align: 'end'
    },
    {
        text: 'Finished',
        value: 'finished',
        align: 'end'
    },
    {
        text: 'Finished#',
        value: 'finished_count',
        align: 'end'
    },
    {
        text: 'Unapproved',
        value: 'unapproved',
        align: 'end',
    },
    {
        text: 'Unapproved#',
        value: 'unapproved_count',
        align: 'end'
    },
]

export function mkDashboard(xs: Global.T[]) {
    type WithStartDate = Omit<Global.T, "start_date"> & { start_date: Date }
    type Extended = WithStartDate & { year: string, area: Target.TargetKindFull['kind'] }
    const filtered = xs.filter(v => v.start_date !== undefined) as WithStartDate[]
    const extend = (v: WithStartDate): Extended => {
        return {
            ...v,
            year: v.start_date.getFullYear().toString(),
            area: v.state.work_state.kind
        }
    }
    const fold = (year: string, xs: Extended[]) => {
        const result = {
            year,
            total: 0,
            total_count: 0,
            payment: 0,
            payment_count: 0,
            finished: 0,
            finished_count: 0,
            unapproved: 0,
            unapproved_count: 0,
        }
        for (const v of xs) {
            if (v.area === "Prepare" || v.area === "Verify" || v.area === "Approve") {
                result.unapproved += result.unapproved + (v.gross_amount ?? 0)
                result.unapproved_count += 1
            }
            if (v.area === "Pay") {
                result.payment += result.unapproved + (v.gross_amount ?? 0)
                result.payment_count += 1
            }
            if (v.area === "Finish") {
                result.finished += result.unapproved + (v.gross_amount ?? 0)
                result.finished_count += 1
            }
        }
        result.total = result.unapproved + result.payment + result.finished
        result.total_count = result.unapproved_count + result.payment_count + result.finished_count
        return result
    }
    const with_year = filtered.map(extend)
    const grouped_by_year = lodash.groupBy(with_year, "year") as Record<string, Extended[]>

    const keys = Object.keys(grouped_by_year)
    const result = []
    for (const key of keys) {
        const r = fold(key, grouped_by_year[key])
        result.push(r)
    }
    return result
}