import * as Types from '@/lib/Util/Types/Types'

export const VALUES = {
    Internal: true,
    External: true,
} as const

type Record = typeof VALUES

export type T = Types.RecToDUWithValueOfKindName<Record>
export type Kind = T['kind']
export const VALS = Types.getDUArray(VALUES)

export function getEmpty(): T {
    return { kind: "Internal", Internal: true }
}

export function getByKind(x: Kind): T {
    return Types.makeSingleDUFromString(x)
}

export const tryParse = Types.buildTryParse<T, true>(VALUES, "kind", true, "Source.Source")