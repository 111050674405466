import * as Types from "@/lib/Util/Types/Types"

import *as lodash from "lodash/fp"
import * as InvoiceType from "@/lib/Util/Types/Biz/InvoiceType"

export type T = {
    type: InvoiceType.T;
    selling_price: number;
}

export function getEmpty(): T {
    return {
        selling_price: 0,
        type: InvoiceType.getEmpty()
    }
}

export function isType(x: any): x is T {
    return x.type !== undefined
        && x.selling_price !== undefined
}

export function sort(x: T[]): T[] {
    const result = lodash.cloneDeep(x)
    result.sort(function (a, b) {
        if (a.type.kind === b.type.kind) {
            return 0
        }
        if (a.type.kind < b.type.kind) {
            return -1
        }
        else return 1
    })
    return result
}

export function tryParse(x: any): T {
    const type = InvoiceType.tryParse(x.type)
    return {
        ...x,
        type
    }
}

export const tryParseList = Types.buildTryParseList(tryParse, "PerInvoiceType")